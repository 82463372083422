import { styles } from '../styles';
import { Box, styled } from '@mui/material';
import DurationFilter from '../durationFilter';
import CommonTabularGraph from '../graphRepresentation/commonTabularGraph';
const PhotographyVsVideographyEarn = ({
    filteredPhotographyVsVideography,
    photoVsVideographyState,
    onDurationFilter,
}: any) => {
    const keysObj = { creatorType: 'Service', bookingsCount: 'Bookings', amount: 'Value' };
    const ItemContainer = styled('div')(() => ({
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #000000;',
        padding: '20px',
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        position: 'relative',
    }));

    return (
        <ItemContainer sx={{ width: '48%' }}>
            <Box sx={styles.reportAndAnalytics}>
                <Box sx={styles.tabularText}>Photography vs Videography Earnings</Box>
                <DurationFilter
                    durationState={photoVsVideographyState}
                    filterKey={5}
                    onDurationFilter={onDurationFilter}
                />
            </Box>
            <Box sx={styles.tableDimension}>
                <CommonTabularGraph keysObj={keysObj} result={filteredPhotographyVsVideography} />
            </Box>
        </ItemContainer>
    );
};

export default PhotographyVsVideographyEarn;
