import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

interface Props {
    label: string;
    format: string;
    onChange: Function;
    value: Dayjs | null;
    width: number;
    disabled?: boolean;
}

export default function BasicDatePicker(props: Props) {
    const { label, format, onChange, value, width, disabled } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    sx={{ width }}
                    disabled={disabled}
                    label={label}
                    format={format}
                    value={value}
                    onChange={(e) => onChange(e)}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
