//types for auth
export const UPDATE_AUTH = 'UPDATE_AUTH';

//types for photographer listing
export const UPDATE_PHOTOGRAPHERS_REDUCER = 'UPDATE_PHOTOGRAPHERS_REDUCER';

//types for photographer detail
export const UPDATE_PHOTOGRAPHER_DETAIL_REDUCER = 'UPDATE_PHOTOGRAPHER_DETAIL_REDUCER';

//types for photographer Booking made detail
export const UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER = 'UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER';

//types for client listing
export const UPDATE_CLIENT_REDUCER = 'UPDATE_CLIENT_REDUCER';

//types for category listing
export const UPDATE_CATEGORY_REDUCER = 'UPDATE_CATEGORY_REDUCER';

//types for client detail page
export const UPDATE_CLIENT_DETAIL_REDUCER = 'UPDATE_CLIENT_DETAIL_REDUCER';

//types for report and anlytics page
export const UPDATE_REPORTS_DATA_REDUCER = 'UPDATE_REPORTS_DATA_REDUCER';
//types for client booking made page
export const UPDATE_CLIENT_BOOKING_MADE_REDUCER = 'UPDATE_CLIENT_BOOKING_MADE_REDUCER';

//types for client booking Detail Reducer
export const UPDATE_CLIENT_BOOKING_DETAIL_REDUCER = 'UPDATE_CLIENT_BOOKING_DETAIL_REDUCER';

//types for rating and review listing
export const UPDATE_RATING_AND_REVIEW_REDUCER = 'UPDATE_RATING_AND_REVIEW_REDUCER';

//types for rating and review detail page
export const UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER = 'UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER';

//types for earn and transaction listing
export const UPDATE_EARN_AND_TRANSACTION_REDUCER = 'UPDATE_EARN_AND_TRANSACTION_REDUCER';

//types for earn and transaction detail page
export const UPDATE_EARN_AND_TRANSACTION_DETAIL_REDUCER =
    'UPDATE_EARN_AND_TRANSACTION_DETAIL_REDUCER';

//types for transaction detail page
export const UPDATE_TRANSACTION_DETAIL_REDUCER = 'UPDATE_TRANSACTION_DETAIL_REDUCER';

//types for notification listing
export const UPDATE_NOTIFICATION_REDUCER = 'UPDATE_NOTIFICATION_REDUCER';

//types for notification detail
export const UPDATE_NOTIFICATION_DETAIL_REDUCER = 'UPDATE_NOTIFICATION_DETAIL_REDUCER';

//types for manage dispute
export const UPDATE_MANAGE_DISPUTE_REDUCER = 'UPDATE_MANAGE_DISPUTE_REDUCER';

//types for manage dispute detail
export const UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER = 'UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER';

//types for flagged list
export const UPDATE_FLAGGED_REDUCER = 'UPDATE_FLAGGED_REDUCER';

//types for flagged detail
export const UPDATE_FLAGGED_DETAIL_REDUCER = 'UPDATE_FLAGGED_DETAIL_REDUCER';

//types for manage flagged detail
export const UPDATE_TECHNICAL_ISSUE_DETAIL_REDUCER = 'UPDATE_TECHNICAL_ISSUE_DETAIL_REDUCER';

//types for content management
export const UPDATE_CONTENT_MANAGEMENT_REDUCER = 'UPDATE_CONTENT_MANAGEMENT_REDUCER';

//types for customer satisfaction
export const UPDATE_CUSTOMER_SATISFACTION_REDUCER = 'UPDATE_CUSTOMER_SATISFACTION_REDUCER';

//types for rewards listing
export const UPDATE_REWARDS_REDUCER = 'UPDATE_REWARDS_REDUCER';

//types for rewards details
export const UPDATE_REWARDS_DETAILS_REDUCER = 'UPDATE_REWARDS_DETAILS_REDUCER';

//types for offers listing
export const UPDATE_OFFERS_REDUCER = 'UPDATE_OFFERS_REDUCER';

//types for offers details
export const UPDATE_OFFER_DETAIL_REDUCER = 'UPDATE_OFFER_DETAIL_REDUCER';
