import { Avatar, ButtonBase, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const BackArrowIcon = () => {
    const theme: any = useTheme();
    const navigate: any = useNavigate();
    return (
        <ButtonBase
            sx={{ borderRadius: '12px', marginBottom: '20px' }}
            onClick={() => {
                navigate(-1);
            }}>
            <Avatar
                variant='rounded'
                sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.mediumAvatar,
                    transition: 'all .2s ease-in-out',
                    background: theme.palette.secondary.light,
                    color: theme.palette.secondary.dark,
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        background: theme.palette.secondary.dark,
                        color: theme.palette.secondary.light,
                    },
                    borderRadius: '25px',
                    width: '100px',
                    height: '40px',
                }}
                aria-haspopup='true'
                color='inherit'>
                <ArrowBackIcon />
                <span style={{ marginLeft: '10px' }}>Back</span>
            </Avatar>
        </ButtonBase>
    );
};

export default BackArrowIcon;
