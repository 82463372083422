import { useState } from 'react';
import {
    Autocomplete,
    // Autocomplete,
    Box,
    Button,
    FormControl,
    IconButton,
    Menu,
    Slider,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import AnimateButton from 'components/animateButton';
import { commonFilterStyles as styles } from '../styles';
import BasicDatePicker from 'components/datePicker';
import { Dayjs } from 'dayjs';
import { StatusFilterModal } from 'utils/modals';

interface Props {
    applyFilters: Function;
    fromDate: Dayjs | null;
    toDate: Dayjs | null;
    fromRange: number;
    toRange: number;
    transactionFilter: StatusFilterModal | null;
}

const EarnAndTransactionFilter = ({
    applyFilters,
    fromDate,
    toDate,
    fromRange,
    toRange,
    transactionFilter,
}: Props) => {
    const theme = useTheme();
    const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLButtonElement>(null);
    const open = Boolean(anchorFilterEl);

    const [selectedFromDate, setSelectedFromDate] = useState(fromDate);
    const [selectedToDate, setSelectedToDate] = useState(toDate);
    const [selectedTransStatus, setSelectedTransStatus] = useState<StatusFilterModal | null>(
        transactionFilter,
    );
    const [selectedRange, setSelectedRange] = useState<number[]>([fromRange, toRange]);

    const transactionStatusArr: any = [
        {
            label: 'Pending',
            valueArr: ['1', '2'],
            value: '1',
        },
        {
            label: 'Upcoming',
            valueArr: ['6', '7', '9', '14', '22', '23', '24', '25', '26', '27', '28', '34'],
            value: '2',
        },
        {
            label: 'Completed',
            valueArr: [
                '10',
                '11',
                '12',
                '13',
                '15',
                '16',
                '17',
                '18',
                '29',
                '30',
                '31',
                '32',
                '33',
            ],
            value: '3',
        },
        {
            label: 'Cancelled',
            valueArr: ['3', '4', '19', '20', '21', '35', '36', '37', '38'],
            value: '4',
        },
        {
            label: 'Rejected',
            valueArr: ['5', '8'],
            value: '5',
        },
    ];
    const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorFilterEl(null);
    };

    const onClearClick = () => {
        applyFilters({
            fromDate: null,
            toDate: null,
            fromRange: 0,
            toRange: 0,
            transactionFilter: null,
        });
        setSelectedRange([0, 0]);
        setSelectedFromDate(null);
        setSelectedToDate(null);
        setSelectedTransStatus(null);
        handleCloseFilter();
    };

    const onApplyClick = () => {
        applyFilters({
            selectedFromDate,
            selectedToDate,
            selectedFromRange: selectedRange[0],
            selectedToRange: selectedRange[1],
            selectedTransStatus: selectedTransStatus,
        });
        handleCloseFilter();
    };

    const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setSelectedRange([Math.min(newValue[0], selectedRange[1] - 10), selectedRange[1]]);
        } else {
            setSelectedRange([selectedRange[0], Math.max(newValue[1], selectedRange[0] + 10)]);
        }
    };
    const transactionRange = [
        {
            value: 40,
            label: '40$',
        },
        {
            value: 1000,
            label: '1000$',
        },
    ];

    return (
        <Box>
            <Box sx={styles.buttonStyle}>
                <IconButton disableRipple onClick={handleClickFilter}>
                    <TuneIcon sx={styles.iconColor} />
                </IconButton>
            </Box>
            <Menu
                open={open}
                anchorEl={anchorFilterEl}
                onClose={() => handleCloseFilter()}
                PaperProps={{ elevation: 1, sx: styles.paper }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Box sx={{ ...theme.typography, m: 2 }}>
                    <Box>
                        <Typography sx={styles.heading} variant='h4'>
                            Filter
                        </Typography>
                    </Box>
                    <Typography id='track-inverted-slider' gutterBottom>
                        Transaction Range
                    </Typography>
                    <Stack sx={{ width: 300 }} spacing={1} direction='row'>
                        <Slider
                            sx={{
                                '&.MuiSlider-marked': {
                                    marginBottom: '20px !important',
                                },
                                '& .MuiSlider-markLabel': {
                                    '&:nth-child(6)': {
                                        left: '94% !important',
                                    },
                                    '&:nth-child(4)': {
                                        left: '4% !important',
                                    },
                                },
                                '& .MuiSlider-thumb': {
                                    height: '15px',
                                    width: '15px',
                                },
                            }}
                            getAriaLabel={() => 'Transaction Range'}
                            orientation='horizontal'
                            value={selectedRange}
                            onChange={handleChange}
                            min={40}
                            max={1000}
                            step={2}
                            marks={transactionRange}
                            valueLabelDisplay='auto'
                        />
                    </Stack>

                    <Autocomplete
                        autoHighlight
                        value={selectedTransStatus}
                        options={transactionStatusArr}
                        sx={styles.zipCodeField}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_: any, value) => setSelectedTransStatus(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Transaction Status'
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                    />

                    <FormControl sx={{ ...styles.selectDropdown, margin: '25px 0px', width: 300 }}>
                        <BasicDatePicker
                            width={300}
                            label='From Date'
                            format='MM/DD/YYYY'
                            value={selectedFromDate}
                            onChange={(date: any) => setSelectedFromDate(date)}
                        />
                    </FormControl>

                    <FormControl sx={styles.selectDropdown}>
                        <BasicDatePicker
                            width={300}
                            label='To Date'
                            format='MM/DD/YYYY'
                            value={selectedToDate}
                            onChange={(date: any) => setSelectedToDate(date)}
                        />
                    </FormControl>
                    <Box sx={styles.buttonBox}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                size='large'
                                type='submit'
                                variant='contained'
                                onClick={onClearClick}>
                                {'Reset Filter'}
                            </Button>
                        </AnimateButton>
                        <AnimateButton>
                            <Button
                                disableElevation
                                size='large'
                                type='submit'
                                variant='contained'
                                onClick={onApplyClick}>
                                {'Apply Filter'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </Box>
            </Menu>
        </Box>
    );
};

export default EarnAndTransactionFilter;
