const Styles = {
    paper: {
        margin: '0 auto',
        borderRadius: '5px',
        backgroundColor: '#fff',
    },
    details: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '30px',
    },
    heading: {
        fontSize: 20,
    },
    value: {},
    subHeading: {
        font: 'normal normal 700 18px/22px SofiaPro',
        margin: '0 0 15px 0',
    },
    data: {
        width: '61%',
        display: 'flex',
        marginTop: '25px',
        justifyContent: 'space-between',
    },
    name: {
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: 'rgba(0, 0, 0, 0.8)',
    },
    email: {
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: '#979797',
        display: 'flex',
    },
    profileContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },

    profileBg: {
        height: '140px',
        width: '140px',
        backgroundColor: 'gray',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    initials: {
        font: 'normal normal 700 72px/50px SofiaPro',
    },
    mail: {
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: 'rgba(0, 0, 0, 0.8)',
        marginTop: '20px',
    },
};
export default Styles;
