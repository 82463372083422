import * as React from 'react';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { ReducersModal } from 'utils/modals';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TransitionProps } from '@mui/material/transitions';
import DialogContentText from '@mui/material/DialogContentText';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function SessionExpiry() {
    const { isSessionValid, adminId } = useSelector((state: ReducersModal) => state.AuthReducer);

    const isOpen = React.useMemo(() => {
        if (adminId.length > 0 && !isSessionValid) {
            return true;
        }
        return false;
    }, [isSessionValid, adminId]);

    const handleClose = () => {
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <Dialog
            keepMounted
            open={isOpen}
            TransitionComponent={Transition}
            aria-describedby='session-expiry-alert'
        >
            <DialogTitle sx={{ fontSize: 18 }}>{'Session Expired.'}</DialogTitle>
            <DialogContent>
                <DialogContentText id='session-expiry-alert'>
                    {'Your session has expired, Please login again to continue.'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{'Login again'}</Button>
            </DialogActions>
        </Dialog>
    );
}
