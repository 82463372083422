import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import { CircularProgress, Dialog, IconButton, Typography, styled } from '@mui/material';
import USER_IMG from '../../../../assets/images/user-image.png';
import { styles } from './styles';
import ContainedButton from 'components/button/contained-button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    UPDATE_CLIENT_BOOKING_MADE_REDUCER,
    UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
} from 'store/actionTypes';
import { getVendorBookingMadeListing } from 'modules/photographersManagement/action';
import { ReducersModal } from 'utils/modals';
import ROUTE_NAMES from 'routes/routeNames';
import { Box } from '@mui/system';

const Container = styled('div')(() => ({
    width: '100%',
    maxHeight: '700px',
    minHeight: '350px',
    backgroundColor: '#fff',
}));

const HeaderBox = styled('div')(() => ({
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    justifyContent: 'center',
    padding: '0px 10px 0px 10px',
    borderRadius: '12px 12px 0px 0px',
    borderBottom: '1px solid lightGray',
    position: 'sticky',
}));

const LowerContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '450px',
    minHeight: '150px',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    overflowY: 'auto',
    paddingBottom: '30px',
}));

const NoDataContainer = styled('div')(() => ({
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    height: '60px',
    margin: '0px 20px 0px 20px',
    borderBottom: '1px solid lightGray',
    padding: '20px',
}));

const UserInfoContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    alignitem: 'center',
    width: '25%',
}));

const AmountContainer = styled('div')(() => ({
    width: '25%',
    display: 'flex',
    justifyContent: 'center',
}));

const RatingContainer = styled('div')(() => ({
    width: '25%',
    display: 'flex',
    justifyContent: 'center',
}));

const ButtonContainer = styled('div')(() => ({
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
}));

interface Props {
    onClose: () => void;
}

export default function VendorTotalBookingPopup({ onClose }: Props) {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const { result, loader } = useSelector(
        (state: ReducersModal) => state.PhotographerBookingMadeReducer,
    );
    const vendorId = new URLSearchParams(window.location.search).get('id');

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
            payload,
        });
    };
    const dispatchClientValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
            payload,
        });
    };

    useEffect(() => {
        dispatchValue({
            vendorId: vendorId,
            limit: 100,
        });
        dispatchClientValue({
            bookingStatusFilter: [
                '10',
                '11',
                '12',
                '13',
                '15',
                '16',
                '17',
                '18',
                '29',
                '30',
                '31',
                '32',
                '33',
            ],
        });
        dispatch(getVendorBookingMadeListing());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId]);

    const renderAlbumView = () => {
        if (result.length === 0 && loader) {
            return (
                <CircularProgress size={40} variant={'indeterminate'} sx={{ margin: '0 auto' }} />
            );
        }
        if (result.length === 0 && !loader) {
            return (
                <NoDataContainer>
                    <Typography variant='h3' component='h3' sx={{ marginTop: '80px' }}>
                        {'No Data Exists.'}
                    </Typography>
                </NoDataContainer>
            );
        }
        return (
            <React.Fragment>
                {result &&
                    result.map((item: any) => {
                        const clientName = item?.clientDetails?.fullName;
                        const averageRating =
                            item?.ratingDetails?.rating?.averageRatings.toFixed(1);
                        const clientId = item?.clientDetails?.id;
                        const clientImage = item?.clientDetails?.profilePic;
                        const bookingAmount = item?.bookingDetails?.amount.toFixed(2);
                        return (
                            <LineContainer>
                                <UserInfoContainer>
                                    <img
                                        src={clientImage || USER_IMG}
                                        alt='user-img'
                                        style={{ ...styles.user_img, borderRadius: '20px' }}></img>
                                    <Typography>{clientName}</Typography>
                                </UserInfoContainer>
                                <AmountContainer>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            width: '100%',
                                            paddingLeft: '80px',
                                        }}>
                                        <Typography sx={{ alignSelf: 'left' }}>{`$${
                                            bookingAmount || '0'
                                        }`}</Typography>
                                    </Box>
                                </AmountContainer>
                                <RatingContainer>
                                    <Typography>{`${'Rating: '}${averageRating}`}</Typography>
                                </RatingContainer>
                                <ButtonContainer>
                                    <ContainedButton
                                        title='View Profile'
                                        style={{ height: '40px' }}
                                        onClick={() => {
                                            navigate(
                                                `${ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT_DETAIL}?id=${clientId}`,
                                            );
                                        }}
                                    />
                                </ButtonContainer>
                            </LineContainer>
                        );
                    })}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Dialog open={true} fullWidth maxWidth='md'>
                <Container sx={{ overflowY: 'hidden' }}>
                    <HeaderBox>
                        <Typography sx={{ color: '#673ab7' }} variant='h2' component='h2'>
                            {'Clients Booked'}
                        </Typography>
                        <IconButton sx={{ position: 'absolute', right: 10 }} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </HeaderBox>
                    <HeaderBox sx={{ padding: '0px 70px 0px 60px' }}>
                        <UserInfoContainer>
                            <Typography variant='h5'>{'Client Name'}</Typography>
                        </UserInfoContainer>
                        <AmountContainer>
                            <Typography variant='h5'>{'Booking Amount'}</Typography>
                        </AmountContainer>
                        <RatingContainer>
                            <Typography variant='h5' sx={{ marginLeft: '15px' }}>
                                {'Rating Received'}
                            </Typography>
                        </RatingContainer>
                        <ButtonContainer sx={{ paddingRight: '20px' }}>
                            <Typography variant='h5'>{'Action'}</Typography>
                        </ButtonContainer>
                    </HeaderBox>
                    <LowerContainer>{renderAlbumView()}</LowerContainer>
                </Container>
            </Dialog>
        </React.Fragment>
    );
}
