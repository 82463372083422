import { UPDATE_REPORTS_DATA_REDUCER } from 'store/actionTypes';
import { ActionType, ReportsAndAnalyticsModal } from 'utils/modals';

export const ReportsAndAnalyticsReducer = (
    state: ReportsAndAnalyticsModal = new ReportsAndAnalyticsModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_REPORTS_DATA_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
