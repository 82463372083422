import { ENDPOINTS } from 'utils/endpoints';
import { GET_API_CALL, DELETE_API_CALL, POST_API_CALL, PATCH_API_CALL } from 'utils/services';
import { APIResponseModal } from 'utils/modals';
import { UPDATE_NOTIFICATION_DETAIL_REDUCER, UPDATE_NOTIFICATION_REDUCER } from 'store/actionTypes';
import SHOW_TOAST from 'utils/showToast';
import axios from 'axios';
import dayjs from 'dayjs';

export const getNotificationListing = () => {
    return (dispatch: Function, getState: Function) => {
        const { page, limit, search, sortedBy, sortOrder, fromDate, toDate, filterStatus } =
            getState().NotificationReducer;
        const checkStatus = () => {
            if (filterStatus === 'sent') {
                return true;
            } else {
                return false;
            }
        };
        let parameters = '?';

        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;
        if (fromDate) parameters += `fromDate=${dayjs(fromDate).format('YYYY-MM-DD')}&`;
        if (toDate) parameters += `toDate=${dayjs(toDate).format('YYYY-MM-DD')}&`;
        if (filterStatus) parameters += `isSent=${checkStatus()}&`;

        dispatch({
            type: UPDATE_NOTIFICATION_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.NOTIFICATION_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_NOTIFICATION_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_NOTIFICATION_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

export const addNotification = (data: any, callback: Function) => {
    return (dispatch: Function) => {
        POST_API_CALL(ENDPOINTS.NOTIFICATION_MANAGEMENT.ADD, data, (response: APIResponseModal) => {
            if (response.isSuccess) {
                const { message } = response?.data;
                SHOW_TOAST(message, 'success');
                callback();
            }
        });
    };
};

export const RemoveNotification = (toDeleteId: string, callback: any) => {
    return () => {
        DELETE_API_CALL(
            `${ENDPOINTS.NOTIFICATION_MANAGEMENT.DELETE}?notificationId=${toDeleteId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                callback(response);
            },
        );
    };
};

export const getNotificationDetail = (notificationId: string, callback: Function) => {
    return (dispatch: Function) => {
        GET_API_CALL(
            ENDPOINTS.NOTIFICATION_MANAGEMENT.DETAIL,
            `/${notificationId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_NOTIFICATION_DETAIL_REDUCER,
                        payload: { ...response.data },
                    });
                } else {
                }
                callback(response);
            },
        );
    };
};

export const requestFileUpload = (dataToSend: any, callback: Function) => {
    return () => {
        POST_API_CALL(
            ENDPOINTS.COMMON.FILE_UPLOAD_REQUEST,
            dataToSend,
            (response: APIResponseModal) => {
                callback(response);
            },
        );
    };
};

export const uploadImageToS3 = (dataResp: any, callback: Function) => {
    return (_: any, getState: Function) => {
        const { adminId } = getState().AuthReducer;

        let data = new FormData();

        data.append('Key', dataResp.Key);
        data.append('acl', dataResp.acl);
        data.append('bucket', dataResp.bucket);
        data.append('X-Amz-Algorithm', dataResp['X-Amz-Algorithm']);
        data.append('X-Amz-Credential', dataResp['X-Amz-Credential']);
        data.append('X-Amz-Date', dataResp['X-Amz-Date']);
        data.append('Policy', dataResp.Policy);
        data.append('X-Amz-Signature', dataResp['X-Amz-Signature']);
        data.append('userId', adminId);
        data.append('file', dataResp.file);

        let config = {
            data: data,
            method: 'post',
            url: dataResp.url,
            maxBodyLength: Infinity,
        };

        axios
            .request(config)
            .then((response: any) => {
                if (response && response.status === 204) {
                    callback({ isSuccess: true, imgUrl: `${dataResp.url}/${dataResp.Key}` });
                } else {
                    callback({ isSuccess: false });
                }
            })
            .catch(() => {
                callback({ isSuccess: false });
            });
    };
};

export const editNotification = (data: any, callback: Function) => {
    return (dispatch: Function) => {
        PATCH_API_CALL(
            ENDPOINTS.NOTIFICATION_MANAGEMENT.ADD,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                    callback();
                }
            },
        );
    };
};

export const getClientListingForSelection = (callback: Function) => {
    return (dispatch: Function, getState: Function) => {
        const { clientStatus } = getState().ClientReducer;
        const page = 1;
        const limit = 20000;

        let parameters = '?';

        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (clientStatus === 2 || clientStatus === 3) parameters += `clientStatus=${clientStatus}&`;

        dispatch({
            type: UPDATE_NOTIFICATION_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.CLIENT_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    callback(response.data.result);
                }
            },
        );
    };
};

export const getVendorListingForSelection = (callback: Function) => {
    return (dispatch: Function, getState: Function) => {
        const { vendorStatus } = getState().PhotographerReducer;
        const page = 1;
        const limit = 20000;

        let parameters = '?';

        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (vendorStatus === 2 || vendorStatus === 3) parameters += `vendorStatus=${vendorStatus}&`;

        GET_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    callback(response.data.result);
                }
            },
        );
    };
};
