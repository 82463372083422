export const styles = {
    container: {
        padding: '20px ',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
        minHeight: '600px',
    },
    span: {
        marginLeft: '4px',
        fontWeight: 400,
        fontSize: '16px',
    },
    textCss: {
        fontWeight: 400,
        fontSize: '16px',
        maxWidth: '450px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        marginRight: '2px',
    },
    ratingBarCont: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '25px',
        marginBottom: '10px',
    },
    input: {
        width: '100px',
        height: '100px',
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px dotted Gray',
    },
    headContainer: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        marginBottom: '16px',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ratingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    reviewRatingCont: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '3px',
    },
    ratingParentCont: {
        flexDirection: 'row',
        marginLeft: '10px',
    },
    reviewImage: {
        width: '80px',
        height: '80px',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
    profileImgCon: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
    RatingImgCon: {
        width: '60px',
        height: '60px',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalRatingText: {
        fontWeight: 400,
        marginTop: '13px',
        fontSize: '16px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textAlign: 'left',
    },
    ratingClientText: {
        flexDirection: 'column',
        maxWidth: '270px',
        minWidth: '200px',
        alignItems: 'flex-start',
    },
    ratingCategoryText: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '3px',
        marginLeft: '30px',
    },
    progressBarCss: {
        width: '110px',
        marginLeft: '20px',
        borderRadius: '5px',
        height: '5px',
    },
    averageRating: {
        fontWeight: 700,
        fontSize: '24px',
        marginLeft: '18px',
        marginBottom: '34px',
    },
    img: {
        width: '100%',
        height: '100%',
    },
    name: {
        fontWeight: 500,
        fontSize: '0.80rem',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: 'grey',
    },
    ViewProfileBtnCon: {
        height: '40px',
        width: '126px',
        display: 'flex',
        borderRadius: '7px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    locationText: {
        fontWeight: 400,
        fontSize: '16px',
        width: '300px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
    topBar: {
        height: '50px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0px 5px 0px',
    },
    topBar_admin: {
        height: '30px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0px 5px 0px',
    },
    userInfo: {
        height: 'inheit',
        width: '900px',
        paddingleft: '10px',
    },
    menu: {
        height: '45px',
        width: '45px',
        position: 'absolute',
        top: '15px',
        right: '0px',
    },
};
