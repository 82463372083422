import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { hideRating, removeRatingReviews } from 'modules/photographersManagement/action';

const ITEM_HEIGHT = 48;

interface props {
    isHide: boolean;
    ratingId: string;
    isAdminRating: boolean;
    onRemoveSuccess: Function;
}

export default function LongMenu({ isHide, ratingId, isAdminRating, onRemoveSuccess }: props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch: any = useDispatch();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onRemoveRatingClick = () => {
        handleClose();
        dispatch(
            removeRatingReviews(ratingId, () => {
                onRemoveSuccess();
            }),
        );
    };

    const onHideRatingClick = (isHide: any) => {
        const data = {
            ratingId,
            isHide,
        };
        handleClose();
        dispatch(
            hideRating(data, () => {
                onRemoveSuccess();
            }),
        );
    };

    return (
        <Box>
            <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '100px',
                    },
                }}>
                <MenuItem
                    onClick={() => {
                        onRemoveRatingClick();
                    }}>
                    {'Remove'}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onHideRatingClick(!isHide);
                    }}>
                    {(isHide && 'Unhide') || 'Hide'}
                </MenuItem>
            </Menu>
        </Box>
    );
}
