export const styles = {
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        marginTop: '0px',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
        width: '100%',
        padding: '24px',
        minHeight: '575px',
    },
    item_cont: {
        minHeight: '200px',
        width: '100%',
        backGround: 'lightGray',
        border: '1px solid black',
        borderRadius: '12px',
        padding: '16px 24px',
        marginBottom: '16px',
    },
    top_bar: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    btn_box: {
        width: '110px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '15px',
        cursor: 'pointer',
    },
    tag: {
        height: '24px',
        width: '90px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid black',
        borderRadius: '12px',
    },
    edit_btn: {
        height: '30px',
        width: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
        cursor: 'pointer',
        '& img': {
            height: '24px',
            width: '24px',
        },
    },
    bottom_box: {
        minHeight: '150px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    bottom_left: {
        paddingTop: '8px',
        width: '70%',
    },
    bottom_right: {
        height: '100%',
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        marginTop: '20px',
        gap: '10px',
    },
    reward_detail_text: {
        maxWidth: '500px',
        minHeight: '30px',
        display: 'flex',
        alignItems: 'top',
        gap: '5px',
        paddingBottom: '10px',
    },
    tick: { height: '15px', width: '15px', marginTop: '2px' },
    validity_left: {
        width: '50%',
        display: 'flex',
        boxSizing: 'border-box',
        margin: '5px 16px',
    },
    validity_right: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        margin: '0px 16px',
    },
    selectDropdown: {
        display: 'flex',
        height: '100%',
        fontSize: '0.8125rem',
        color: '#364152',
        fontWeight: '400',
        boxShadow: 'none',
        '& input': {
            fontSize: '0.8125rem',
            color: '#364152',
            fontWeight: '300',
            boxShadow: 'none',
        },
        '& MuiOutlinedInput': {
            fontSize: '0.8125rem',
            color: '#364152',
            fontWeight: '300',
            boxShadow: 'none',
        },
        '& MuiTextField': {
            maxWidth: '100px',
        },
    },
    button_box: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '20px',
        position: 'absolute',
        top: '100px',
        right: '50px',
    },
    reward_type_cont: {
        display: 'flex',
        alignItems: 'center',
        gap: '50px',
        height: '20px',
    },
    client_count_text: {
        alignSelf: 'baseline',
        marginLeft: '5px',
        fontStyle: 'italic',
        marginTop: '25px',
        fontWeight: 'bold',
    },
    selection_bar: {
        width: '100%',
        height: '60px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '15px',
    },
    radio_cont: {
        height: '100%',
        width: '85%',
    },
};
