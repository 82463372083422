import { styles } from './styles';
import { Box, Link, Rating, Typography, styled } from '@mui/material';
import PackagesSkeleton from './bookingSkeleton';
import React, { useEffect, useState } from 'react';
import USER_IMG from 'assets/images/user-image.png';
import LongMenu from './menu';
import { useDispatch, useSelector } from 'react-redux';
import { APIResponseModal, ClientVendorBookingDetailModal, ReducersModal } from 'utils/modals';
import { getClientBookingDetail } from 'modules/clientManagement/action';
import CALENDER_IMG from 'assets/images/calendar.png';
import {
    BOOKING_STATUS,
    BOOKING_STATUS_BORDER,
    BOOKING_STATUS_CSS,
    BOOKING_STATUS_FONT,
} from 'utils/enum';
import { getTransactionDetail } from 'modules/earningTransactionManagement/action';
import BackArrowIcon from 'components/backArrow';
import { getFormattedDate } from 'utils/constants';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '12px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    padding: '18px 32px 5px 32px',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));
const CardContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
    paddingRight: '18px',
}));

const HeadingContainer = styled('div')(() => ({
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid black',
    paddingLeft: '20px',
}));

const DetailContainer = styled('div')(() => ({
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '20px',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
}));

const ClientBookingDetail = () => {
    const dispatch: any = useDispatch();
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<ClientVendorBookingDetailModal>();
    const bookingId = new URLSearchParams(window.location.search).get('id');
    const { result } = useSelector((state: ReducersModal) => state.TransactionDetailReducer);

    const fetchClientBookingDetails = () => {
        setLoading(true);
        dispatch(
            getClientBookingDetail(bookingId, ({ isSuccess, data }: APIResponseModal) => {
                if (isSuccess) {
                    setListData({ ...data });
                    dispatch(
                        getTransactionDetail(bookingId, () => {
                            setLoading(false);
                        }),
                    );
                }
            }),
        );
    };

    useEffect(() => {
        fetchClientBookingDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '323px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderPackageHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '295px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderValue = (value: any) => {
        return (
            <Typography variant='h4' component={'h4'} sx={styles.value_text}>
                {value}
            </Typography>
        );
    };

    const renderLocation = (val: any) => {
        if (val?.street || val?.city || val?.postalCode || val?.country) {
            return (
                <Typography
                    variant='h4'
                    component={'h4'}
                    sx={{ ...styles.value_text, maxWidth: '450px' }}>
                    {`${val?.street}${val?.street && val?.city && ','}  ${val?.city} ${val?.country && val?.city && ','
                        } ${val?.country}${val?.postalCode && val?.country && ','}  ${val?.postalCode}`}
                </Typography>
            );
        }
        return (
            <Typography
                variant='h4'
                component={'h4'}
                sx={{ ...styles.value_text, maxWidth: '450px' }}>
                {'N/A'}
            </Typography>
        );
    };

    const renderVendorView = (data: any) => {
        const user_img = data?.vendorDetails?.profilePic;
        const user_name = data?.vendorDetails?.fullName;
        return (
            <Box sx={styles.vendor_view}>
                <img style={styles.img} src={user_img || USER_IMG} alt='vendor-img'></img>
                {renderHeading(user_name)}
            </Box>
        );
    };

    const renderLink = (data: any) => {
        if (data?.dropboxLink1 || data?.dropboxLink2) {
            return (
                <Box>
                    <Link
                        variant='h4'
                        component={'h4'}
                        onClick={() => window.open(data?.dropboxLink1)}
                        sx={styles.link_text}>
                        {data?.dropboxLink1}
                    </Link>
                    <Link
                        variant='h4'
                        component={'h4'}
                        onClick={() => window.open(data?.dropboxLink2)}
                        sx={styles.link_text}>
                        {data?.dropboxLink2}
                    </Link>
                </Box>
            );
        }
        return (
            <Typography variant='h4' component={'h4'} sx={styles.value_text}>
                {'N/A'}
            </Typography>
        );
    };

    const renderStatusTag = (status: any) => {
        return (
            <Box
                sx={{
                    background: BOOKING_STATUS_CSS[status],
                    border: BOOKING_STATUS_BORDER[status],
                    ...styles.status_tag,
                }}>
                <Typography
                    variant='h3'
                    component={'h3'}
                    sx={{
                        fontWeight: 500,
                        fontSize: '18px',
                        color: BOOKING_STATUS_FONT[status],
                    }}>
                    {BOOKING_STATUS[status]}
                </Typography>
            </Box>
        );
    };

    const renderCalenderImg = () => {
        return (
            <Box sx={{ height: '20px', width: '20px', marginRight: '10px' }}>
                <img
                    src={CALENDER_IMG}
                    alt='calender-img'
                    style={{ height: '18px', width: '18px' }}></img>
            </Box>
        );
    };

    const renderProfilePic = (value: any) => {
        let format = value?.profilePic?.substring(
            value?.profilePic?.lastIndexOf('.') + 1,
            value?.profilePic?.length,
        ),
            imgSrc = USER_IMG;
        if (format === 'jpg' || format === 'png' || format === 'jpeg') {
            imgSrc = value?.profilePic;
        }
        return (
            <Box sx={styles.icon} id={'user-img'}>
                <img src={imgSrc} style={styles.img} alt='User-img' />
            </Box>
        );
    };

    const renderAddonsDetails = () => {
        let addOnsData: any = [];
        if (result.length > 0) {
            addOnsData = result.filter((data) => {
                return data?.transactionType === 2 || data?.transactionType === 3;
            });
        }
        if (addOnsData.length > 0) {
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Add Ons Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer
                            sx={{ minHeight: '75px', height: 'unset', padding: '20px' }}>
                            {addOnsData.map((entry: any, index: any) => {
                                const {
                                    paymentDetails: { metadata, amount },
                                } = entry;
                                return (
                                    <LineContainer key={index}>
                                        {renderHeading(
                                            metadata?.addOnName
                                                ? metadata?.addOnName
                                                : `${metadata?.editCount} Additional Edits`,
                                        )}
                                        {renderValue(`$${(amount / 100).toFixed(2) || '0'}`)}
                                    </LineContainer>
                                );
                            })}
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderTipsDetails = () => {
        let tipsData: any = [];
        if (result.length > 0) {
            tipsData = result.filter((data) => {
                return data?.transactionType === 4;
            });
        }
        if (tipsData.length > 0) {
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Tip and Overtime Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer sx={{ height: '77px', padding: '20px' }}>
                            {tipsData.map((entry: any, index: any) => {
                                const {
                                    paymentDetails: { amount },
                                } = entry;

                                return (
                                    <LineContainer key={index}>
                                        {renderHeading('Paid')}
                                        {renderValue(`$${(amount / 100).toFixed(2) || '0'}`)}
                                    </LineContainer>
                                );
                            })}
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderPaymentDetails = () => {
        let basePrice: any = [];

        if (result.length > 0) {
            basePrice = result.filter((data) => {
                return data?.transactionType === 1;
            });
        }
        if (basePrice.length > 0 && listData && listData?.paymentDetails) {
            const {
                orderCalculations: {
                    addOnsCost,
                    totalBaseShootCharges,
                    offerAmount,
                    referralAmount,
                },
            } = basePrice[0];
            const baseChargesPercent =
                (totalBaseShootCharges && (totalBaseShootCharges * 13) / 100) || 0;
            const addOnChargesPercent = (addOnsCost && (addOnsCost * 3) / 100) || 0;
            const bookingFee = (baseChargesPercent + addOnChargesPercent).toFixed(2) || '0';
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Payment Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer
                            sx={{ minHeight: '225px', height: 'unset', padding: '20px' }}>
                            <LineContainer>
                                {renderHeading('Pricing')}
                                {renderValue(`${'$'}${totalBaseShootCharges || '0'}`)}
                            </LineContainer>
                            {bookingFee && bookingFee.length > 0 && (
                                <LineContainer>
                                    {renderHeading('Booking Fee')}
                                    {renderValue(`$${bookingFee || 0}`)}
                                </LineContainer>
                            )}

                            {addOnsCost && addOnsCost > 0 ? (
                                <LineContainer>
                                    {renderHeading('Studio Fee')}
                                    {renderValue(`$${addOnsCost || 0}`)}
                                </LineContainer>
                            ) : null}
                            {offerAmount > 0 && (
                                <LineContainer>
                                    {renderHeading('Offer Discount')}
                                    {renderValue(`-$${offerAmount.toFixed(2) || 0}`)}
                                </LineContainer>
                            )}
                            {referralAmount > 0 && (
                                <LineContainer>
                                    {renderHeading('Referral Discount')}
                                    {renderValue(`-$${referralAmount.toFixed(2) || 0}`)}
                                </LineContainer>
                            )}
                            <LineContainer>
                                {renderHeading('Paid')}
                                {renderValue(`$${listData?.paymentDetails.paid || '0'}`)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Paid on')}
                                {renderValue(
                                    getFormattedDate(
                                        listData.paymentDetails.paidOn,
                                        'MM/DD/YYYY, HH:MM A',
                                    ),
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Payment Method')}
                                {renderValue(listData?.paymentDetails.paymentMethod[0])}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderPackageDetails = () => {
        if (listData && listData?.bookingDetails?.package) {
            const packagePostFixArray = listData?.bookingDetails?.package?.packageHours.split(':');
            const packagePostFix = parseInt(packagePostFixArray[0]) > 0 ? 'Hrs' : 'Minutes';
            const durationPostFixArray = listData?.bookingDetails?.package?.videoLength.split(':');
            const durationPostFix =
                parseInt(durationPostFixArray[0]) > 0
                    ? 'Hrs'
                    : parseInt(durationPostFixArray[1]) > 0
                        ? 'Minutes'
                        : 'Secs';
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Package Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer sx={{ height: listData?.bookingDetails?.package?.description?.length > 170 ? '380px' : '355px' }}>
                            <LineContainer>
                                {renderPackageHeading('Name')}
                                {renderValue(`${listData?.bookingDetails?.package?.name || 'N/A'}`)}
                            </LineContainer>
                            <LineContainer sx={{ alignItems: 'flex-start' }}>
                                {renderPackageHeading('Description')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.description || 'N/A'}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Price')}
                                {renderValue(`$${listData?.bookingDetails?.package?.price || 0}`)}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Package Hours')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.packageHours || 0
                                    } ${packagePostFix}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Edited Photos Count')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.editedPhotosCount || 0}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Edited Videos Count')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.editedVideosCount || 0}`,
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderPackageHeading('Video length')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.videoLength || 0
                                    } ${durationPostFix}`,
                                )}
                            </LineContainer>
                            <LineContainer sx={{ alignItems: 'flex-start' }}>
                                {renderPackageHeading('With Assistant Photographer/Videographer')}
                                {renderValue(
                                    `${listData?.bookingDetails?.package?.isAssistant
                                        ? 'Yes'
                                        : 'No'
                                    }`,
                                )}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer >
            );
        }
        return null;
    };

    const renderCancellationDetails = () => {
        if (listData && listData?.transactionData?.paymentDetails?.canceled_at) {
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Cancellation Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer sx={{ minHeight: '220px' }}>
                            <LineContainer>
                                {renderHeading('Canceled by')}
                                {renderValue(listData?.transactionData?.paymentDetails?.customer)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Cancellation Date')}
                                {renderValue(
                                    getFormattedDate(
                                        listData?.cancellationDetails?.date,
                                        'MM/DD/YYYY, HH:MM A',
                                    ),
                                )}
                            </LineContainer>
                            <LineContainer sx={{ marginBottom: '5px' }}>
                                {renderHeading('Cancellation Policy')}
                                {renderValue(listData?.bookingDetails?.cancellationPolicy?.title)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('')}
                                {renderValue(listData?.bookingDetails?.cancellationPolicy?.details)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Refund Status')}
                                {renderValue('status')}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderRatingReviews = () => {
        if (listData && listData?.ratingReviews?.length > 0) {
            return (
                <React.Fragment>
                    <Typography variant='h3'>{'Rating & Reviews'}</Typography>
                    <br></br>
                    {listData &&
                        listData?.ratingReviews?.map((item: any) => {
                            const topBarStyle = !item.isAdminRating
                                ? styles.topBar
                                : styles.topBar_admin;
                            return (
                                <TableContainer key={item._id}>
                                    <ItemContainer sx={{ padding: '10px 20px 10px 20px' }}>
                                        <Box sx={topBarStyle}>
                                            {!item.isAdminRating &&
                                                renderProfilePic(item?.clientDetails?.profilePic)}
                                            <Box sx={styles.userInfo}>
                                                <Typography variant='h5'>
                                                    {item?.vendorDetails?.fullName}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '10px',
                                                        alignItems: 'center',
                                                    }}>
                                                    <Typography color={'black'} fontSize={'20px'}>
                                                        {item?.rating
                                                            ? `${item.rating}${'.0'}`
                                                            : '0.0'}
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            '& > legend': { mt: 2 },
                                                        }}>
                                                        <Rating
                                                            name='simple-controlled'
                                                            value={item.rating}
                                                        />
                                                    </Box>
                                                    <Typography
                                                        variant='inherit'
                                                        color={'gray'}
                                                        sx={{ marginLeft: '20px' }}>
                                                        {item?.updatedAt
                                                            ? getFormattedDate(
                                                                item.updatedAt,
                                                                'MM/DD/YYYY, hh:mm A',
                                                            )
                                                            : 'N/A'}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={styles.menu}>
                                                <LongMenu
                                                    ratingId={item?._id}
                                                    isAdminRating
                                                    isHide={item?.isHide}
                                                    onRemoveSuccess={fetchClientBookingDetails}
                                                />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontSize: '15px', color: 'black' }}>
                                                {item?.review}
                                            </Typography>
                                        </Box>
                                    </ItemContainer>
                                </TableContainer>
                            );
                        })}
                </React.Fragment>
            );
        }
        return null;
    };

    const renderPackages = () => {
        const bookingLocation = listData?.bookingDetails?.shootLocation;
        let totalBookingAmount = 0;
        if (result.length > 0) {
            result.map((data) => {
                const {
                    paymentDetails: { amount },
                } = data;
                totalBookingAmount += amount / 100;
                return null;
            });
        }
        return (
            <React.Fragment>
                <TableContainer sx={{ display: 'block', marginBottom: '10px' }}>
                    <ItemContainer>
                        <LineContainer>
                            {renderHeading('Photographer')}
                            {renderVendorView(listData)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Booking Ref')}
                            {renderValue(listData?.bookingRef)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Booking Location')}
                            {renderLocation(bookingLocation)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Date  and Time')}
                            {renderCalenderImg()}
                            {renderValue(
                                listData?.startTime
                                    ? `${getFormattedDate(
                                        listData?.startTime,
                                        'MM/DD/YYYY, hh:mm A',
                                    )}${' - '} ${getFormattedDate(listData.endTime, 'hh:mm A')}`
                                    : 'N/A',
                            )}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Booking Total')}
                            {renderValue(`${'$'}${totalBookingAmount.toFixed(2)}`)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Dropbox Link')}
                            {renderLink(listData?.album)}
                        </LineContainer>
                        {renderStatusTag(listData?.status)}
                    </ItemContainer>
                </TableContainer>
                {renderPackageDetails()}
                {renderPaymentDetails()}
                {renderAddonsDetails()}
                {renderTipsDetails()}
                {renderCancellationDetails()}
                {renderRatingReviews()}
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (!listData && loading) {
            return <PackagesSkeleton />;
        }
        return renderPackages();
    };

    return (
        <React.Fragment>
            <BackArrowIcon />
            <Box sx={styles.paper}>
                <Box sx={styles.container}>{renderView()}</Box>
            </Box>
        </React.Fragment>
    );
};

export default ClientBookingDetail;
