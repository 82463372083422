import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { StyledEngineProvider, CssBaseline } from '@mui/material';

// defaultTheme
import themes from 'themes';
import RouteManager from './routes';
import { store, persistor } from 'store';
import SessionExpiry from 'components/sessionExpiry';
import NoInternetConnection from 'components/noInternetConnection';

function App() {
    const themeCustomization = {
        opened: true,
        borderRadius: 12,
        defaultId: 'default',
        fontFamily: `'Roboto', sans-serif`,
        isOpen: [], // for active default   menu
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(themeCustomization)}>
                <CssBaseline />
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <BrowserRouter>
                            <RouteManager />
                        </BrowserRouter>
                        <ToastContainer />
                        <NoInternetConnection />
                        <SessionExpiry />
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
