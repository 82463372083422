import { styles } from './styles';
import { Box, Skeleton, Typography, styled } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import USER_IMG from 'assets/images/user-image.png';
import { useDispatch, useSelector } from 'react-redux';
import { getEarnAdTransactionDetail, getTransactionDetail } from './action';
import { ReducersModal } from 'utils/modals';
import {
    BOOKING_STATUS,
    BOOKING_STATUS_BORDER,
    BOOKING_STATUS_CSS,
    BOOKING_STATUS_FONT,
} from 'utils/enum';
import CALENDER_IMG from 'assets/images/calendar.png';
import BackArrowIcon from 'components/backArrow';
import { getFormattedDate } from 'utils/constants';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    padding: '24px 32px 18px 30px',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));
const CardContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '588px',
    overflowY: 'auto',
}));

const HeadingContainer = styled('div')(() => ({
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid black',
    paddingLeft: '20px',
}));

const DetailContainer = styled('div')(() => ({
    width: '100%',
    height: '225px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '20px',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '17px',
}));

const EarningsTransactionsDetail = () => {
    const [loading, setLoading] = useState(false);
    const dispatch: any = useDispatch();

    const {
        bookingDetails,
        bookingRef,
        transactionData,
        vendorDetails,
        clientDetails,
        startTime,
        endTime,
        paymentDetails,
        status: bookingStatus,
    } = useSelector((state: ReducersModal) => state.EarnAndTransactionDetailReducer);

    const { result } = useSelector((state: ReducersModal) => state.TransactionDetailReducer);

    const bookingId = new URLSearchParams(window.location.search).get('id') || '';

    useLayoutEffect(() => {
        setLoading(true);
    }, []);

    useEffect(() => {
        if (bookingId && bookingId.length > 0) {
            dispatch(
                getEarnAdTransactionDetail(bookingId, () => {
                    dispatch(
                        getTransactionDetail(bookingId, () => {
                            setLoading(false);
                        }),
                    );
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const renderHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '323px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderCalenderImg = () => {
        return (
            <Box sx={styles.calendarImgCont}>
                <img
                    src={CALENDER_IMG}
                    alt='calender-img'
                    style={{ height: '18px', width: '18px' }}></img>
            </Box>
        );
    };

    const EarningSkeleton = () => {
        return (
            <React.Fragment>
                <Skeleton
                    height={'310px'}
                    width={'100%'}
                    variant='rectangular'
                    sx={{ borderRadius: '10px' }}
                />
            </React.Fragment>
        );
    };

    const renderProfileView = (type: number) => {
        const user_img = type === 1 ? vendorDetails?.profilePic : clientDetails?.profilePic;
        const user_name = type === 1 ? vendorDetails?.fullName : clientDetails?.fullName;
        return (
            <Box sx={styles.clientProfilePicCont}>
                <img style={styles.img} src={user_img || USER_IMG} alt='vendor-img'></img>
                {user_name ? renderHeading(user_name) : 'N/A'}
            </Box>
        );
    };

    const renderValue = (value: string | number) => {
        return (
            <Typography variant='h4' component={'h4'} sx={styles.addOnText}>
                {value}
            </Typography>
        );
    };

    const renderDateRange = (startTime: string, endTime: string) => {
        return (
            <Box sx={styles.flexContainer}>
                {renderCalenderImg()}
                <Typography variant='h4' component={'h4'} sx={styles.addOnText}>
                    {startTime}
                    {' - '}
                    {endTime}
                </Typography>
            </Box>
        );
    };
    const renderPaymentDetails = () => {
        let basePrice: any = [];

        if (result.length > 0) {
            basePrice = result.filter((data) => {
                return data?.transactionType === 1;
            });
        }
        if (basePrice.length > 0 && paymentDetails) {
            const {
                orderCalculations: {
                    addOnsCost,
                    totalBaseShootCharges,
                    offerAmount,
                    referralAmount,
                    platformCharges = 0,
                    penaltyAmount,
                    refundAmount
                },
            } = basePrice[0];
            const baseChargesPercent =
                (totalBaseShootCharges && (totalBaseShootCharges * 13) / 100) || 0;
            const addOnChargesPercent = (addOnsCost && (addOnsCost * 3) / 100) || 0;
            const bookingFee = (baseChargesPercent + addOnChargesPercent).toFixed(2) || '0';

            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Payment Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer
                            sx={{ minHeight: '225px', height: 'unset', padding: '20px' }}>
                            <LineContainer>
                                {renderHeading('Pricing')}
                                {renderValue(
                                    `${'$'}${transactionData?.orderCalculations?.totalBaseShootCharges ||
                                    '0'
                                    }`,
                                )}
                            </LineContainer>
                            {platformCharges && platformCharges > 0 ? (
                                <LineContainer>
                                    {renderHeading('Platform Fee')}
                                    {renderValue(`$${platformCharges || 0}`)}
                                </LineContainer>
                            ) : null}
                            {bookingFee && bookingFee.length > 0 && (
                                <LineContainer>
                                    {renderHeading('Booking Fee')}
                                    {renderValue(`$${bookingFee || 0}`)}
                                </LineContainer>
                            )}

                            {addOnsCost && addOnsCost > 0 ? (
                                <LineContainer>
                                    {renderHeading('Studio Fee')}
                                    {renderValue(`$${addOnsCost || 0}`)}
                                </LineContainer>
                            ) : null}
                            {offerAmount > 0 && (
                                <LineContainer>
                                    {renderHeading('Offer Discount')}
                                    {renderValue(`-$${offerAmount.toFixed(2) || 0}`)}
                                </LineContainer>
                            )}
                            {referralAmount > 0 && (
                                <LineContainer>
                                    {renderHeading('Referral Discount')}
                                    {renderValue(`-$${referralAmount.toFixed(2) || 0}`)}
                                </LineContainer>
                            )}
                            {penaltyAmount &&
                                <LineContainer>
                                    {renderHeading('Penalty Amount')}
                                    {renderValue(`$${penaltyAmount.toFixed(2) || 0}`)}
                                </LineContainer>
                            }
                            {refundAmount &&
                                <LineContainer>
                                    {renderHeading('Refund Amount')}
                                    {renderValue(`$${refundAmount.toFixed(2) || 0}`)}
                                </LineContainer>
                            }
                            <LineContainer>
                                {renderHeading('Paid')}
                                {renderValue(`$${paymentDetails.paid || '0'}`)}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Paid on')}
                                {renderValue(
                                    getFormattedDate(paymentDetails.paidOn, 'MM/DD/YYYY, HH:MM A'),
                                )}
                            </LineContainer>
                            <LineContainer>
                                {renderHeading('Payment Method')}
                                {renderValue(paymentDetails.paymentMethod[0])}
                            </LineContainer>
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderAddonsDetails = () => {
        let addOnsData: any = [];
        if (result.length > 0) {
            addOnsData = result.filter((data) => {
                return data?.transactionType === 2 || data?.transactionType === 3;
            });
        }
        if (addOnsData.length > 0) {
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Add Ons Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer
                            sx={{ minHeight: '75px', height: 'unset', padding: '20px' }}>
                            {addOnsData.map((entry: any, index: any) => {
                                const {
                                    paymentDetails: { metadata, amount },
                                } = entry;
                                return (
                                    <LineContainer key={index}>
                                        {renderHeading(
                                            metadata?.addOnName
                                                ? metadata?.addOnName
                                                : metadata?.editCount
                                                    ? `${metadata?.editCount} Additional Edits`
                                                    : 'Paid',
                                        )}
                                        {renderValue(
                                            `$${metadata?.addOnName
                                                ? (amount / 100).toFixed(2)
                                                : metadata?.editCount
                                                    ? (amount / 100).toFixed(2)
                                                    : paymentDetails.paid || '0'
                                            }`,
                                        )}
                                    </LineContainer>
                                );
                            })}
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderTipsDetails = () => {
        let tipsData: any = [];
        if (result.length > 0) {
            tipsData = result.filter((data) => {
                return data?.transactionType === 4;
            });
        }
        if (tipsData.length > 0) {
            return (
                <TableContainer>
                    <CardContainer>
                        <HeadingContainer>
                            <Typography variant='h4'>{'Tip and Overtime Details'}</Typography>
                        </HeadingContainer>
                        <DetailContainer sx={{ height: '77px', padding: '20px' }}>
                            {tipsData.map((entry: any, index: any) => {
                                const {
                                    paymentDetails: { amount },
                                } = entry;

                                return (
                                    <LineContainer key={index}>
                                        {renderHeading('Paid')}
                                        {renderValue(`$${(amount / 100).toFixed(2) || '0'}`)}
                                    </LineContainer>
                                );
                            })}
                        </DetailContainer>
                    </CardContainer>
                </TableContainer>
            );
        }
        return null;
    };

    const renderLocation = (location: any) => {
        return (
            <LineContainer>
                {renderHeading('Booking Location')}
                <Typography sx={styles.addOnText} variant='h4' component={'h4'}>
                    {`${location?.street}${location?.street && location?.city && ','}  ${location?.city
                        } ${location?.country && location?.city && ','} ${location?.country}${location?.postalCode && location?.country && ','
                        }  ${location?.postalCode}`}
                </Typography>
            </LineContainer>
        );
    };

    const renderUpperRightSection = () => {
        return (
            <Box sx={styles.button_stack}>
                <Box
                    sx={[
                        styles.approvedRejectedBtnCon,
                        {
                            background: BOOKING_STATUS_CSS[bookingStatus],
                            border: BOOKING_STATUS_BORDER[bookingStatus],
                        },
                    ]}>
                    <Typography
                        sx={[
                            styles.name,
                            {
                                color: BOOKING_STATUS_FONT[bookingStatus],
                            },
                        ]}>
                        {BOOKING_STATUS[bookingStatus]}
                    </Typography>
                </Box>
            </Box>
        );
    };

    const renderTransaction = () => {
        let totalBookingAmount = 0;
        if (result.length > 0) {
            result.map((data) => {
                const {
                    paymentDetails: { amount },
                } = data;
                totalBookingAmount += amount / 100;
                return null;
            });
        }
        return (
            <React.Fragment>
                <BackArrowIcon />
                <TableContainer sx={{ display: 'block' }}>
                    <ItemContainer>
                        <LineContainer>
                            {renderHeading('Transaction ID')}
                            {renderValue(transactionData?.transactionRef)}
                            {renderUpperRightSection()}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Client Name')}
                            {renderProfileView(2)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Photographer Name')}
                            {renderProfileView(1)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Booking Ref')}
                            {renderValue(bookingRef)}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Category')}
                            {renderValue(bookingDetails?.category?.name)}
                        </LineContainer>
                        {renderLocation(bookingDetails?.shootLocation)}
                        <LineContainer>
                            {renderHeading('Date and Time')}
                            {renderDateRange(
                                getFormattedDate(startTime, 'MMM DD, yyyy, hh:mm A'),
                                getFormattedDate(endTime, 'hh:mm A'),
                            )}
                        </LineContainer>
                        <LineContainer>
                            {renderHeading('Booking Total')}
                            {renderValue(`${'$'}${totalBookingAmount.toFixed(2)}`)}
                        </LineContainer>
                    </ItemContainer>
                </TableContainer>
                {renderPaymentDetails()}
                {renderAddonsDetails()}
                {renderTipsDetails()}
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (loading) {
            return <EarningSkeleton />;
        }
        return renderTransaction();
    };

    return renderView();
};

export default EarningsTransactionsDetail;
