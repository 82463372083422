import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    styled,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Divider,
} from '@mui/material';
import PackagesSkeleton from './packagesSkeleton';
import React, { useEffect, useState } from 'react';
import { APIResponseModal, PackageDataModal, ReducersModal } from 'utils/modals';
import { getPhotographerPackageListing } from 'modules/photographersManagement/action';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    padding: '24px 32px 18px 30px',

    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
    paddingRight: '18px',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '17px',
}));

const NoDataContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Packages = () => {
    const { addOnServices } = useSelector(
        (state: ReducersModal) => state.PhotographerDetailReducer,
    );
    const dispatch: any = useDispatch();

    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState<PackageDataModal[]>([]);

    const photographerId = new URLSearchParams(window.location.search).get('id') || '';

    useEffect(() => {
        setLoading(true);
        dispatch(
            getPhotographerPackageListing(
                photographerId,
                ({ isSuccess, data }: APIResponseModal) => {
                    if (isSuccess) {
                        const { result } = data;
                        setListData([...result]);
                    }
                    setLoading(false);
                },
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '323px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderValue = (value: string | number) => {
        return (
            <Typography
                variant='h4'
                component={'h4'}
                sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    maxWidth: '500px',
                    display: 'block,',
                    textOverflow: 'ellipsis',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                }}>
                {value}
            </Typography>
        );
    };

    const renderPricing = (price: number) => {
        return (
            <Typography
                variant='h3'
                component={'h3'}
                sx={{
                    fontWeight: 700,
                    fontSize: '30px',
                    position: 'absolute',
                    top: '32px',
                    right: '32px',
                }}>
                {`$${price}`}
            </Typography>
        );
    };

    const renderAddons = () => {
        if (addOnServices && addOnServices.length > 0) {
            return (
                <Box>
                    <br></br>
                    <Divider sx={{ borderColor: 'darkGray' }} />
                    <Box style={styles.table}>
                        <Typography variant='subtitle1' sx={{ mt: '20px', mb: '10px' }}>
                            {'Add ons'}
                        </Typography>
                        <TableContainer sx={{ height: 'fit-content' }}>
                            <Table sx={{ width: '100%', border: '1px solid black' }}>
                                <TableHead sx={styles.table_head}>
                                    <TableRow style={styles.table_row}>
                                        <TableCell sx={styles.table_cell}>{'Add ons'}</TableCell>
                                        <TableCell sx={styles.table_cell}>{'Pricing'}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {addOnServices.map((item: any, i) => {
                                        return (
                                            <TableRow style={styles.table_row}>
                                                <TableCell sx={styles.table_cell_left}>
                                                    {item?.name}
                                                </TableCell>
                                                <TableCell sx={styles.table_cell_right}>
                                                    {`$${item?.rate}`}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    const renderPackages = () => {
        if (listData.length === 0 && !loading) {
            return (
                <NoDataContainer>
                    <Typography
                        sx={{ color: '#673ab7', marginTop: '180px' }}
                        variant='h2'
                        component='h2'>
                        {'No Package Exists.'}
                    </Typography>
                </NoDataContainer>
            );
        }
        return (
            <React.Fragment>
                <Typography
                    sx={{ color: '#673ab7', marginBottom: '24px' }}
                    variant='h3'
                    component='h3'>
                    {'Available Packages'}
                </Typography>
                <TableContainer sx={{ display: 'block' }}>
                    {listData.map((item) => {
                        return (
                            <ItemContainer key={item._id}>
                                <LineContainer>
                                    {renderHeading('Package Name')}
                                    {renderValue(item.name)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Number of hours')}
                                    {renderValue(item.packageHours)}
                                    <span style={styles.span}>{'Hours'}</span>
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Number of Edited Photos')}
                                    {renderValue(item.editedPhotosCount)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Number of Videos')}
                                    {renderValue(item.editedVideosCount)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Length of Edited Videos (In Hours)')}
                                    {renderValue(item.videoLength)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Description')}
                                    {renderValue(item.description)}
                                </LineContainer>
                                {renderPricing(item.price)}
                            </ItemContainer>
                        );
                    })}
                </TableContainer>
                {renderAddons()}
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (listData.length === 0 && loading) {
            return <PackagesSkeleton />;
        }
        return renderPackages();
    };

    return <Box sx={styles.container}>{renderView()}</Box>;
};

export default Packages;
