import { Box, LinearProgress, Typography, styled } from '@mui/material';
import { styles } from './styles';
// import EDIT_ICON from 'assets/images/edit.png';
import TICK_ICON from 'assets/images/tick.png';
// import { useNavigate } from 'react-router-dom';
// import ROUTE_NAMES from 'routes/routeNames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRewardsList, updateIndivisualRewardStatus } from './action';
import { ReducersModal } from 'utils/modals';
import Loader from 'components/loader';
import RewardsSkeleton from './rewardsSkeleton';
import { referralTitle } from 'utils/enum';

const RewardsProgram = () => {
    const NoDataContainer = styled('div')(() => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));
    // const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const { result, loader, clientsCount, usersCount, photographersCount } = useSelector(
        (state: ReducersModal) => state.RewardsManagementReducer,
    );
    useEffect(() => {
        dispatch(getRewardsList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleIndivisualRewardActivation = (e: any, referralId: string, status: number) => {
        const params = {
            referralId: referralId,
            status,
        };
        dispatch(
            updateIndivisualRewardStatus(params, () => {
                dispatch(getRewardsList());
            }),
        );
    };

    const renderRewardDetail = (benefit: string) => {
        return (
            <Box sx={styles.reward_detail_text}>
                <img style={styles.tick} alt='tick' src={TICK_ICON}></img>
                <Typography variant='caption'>
                    {benefit.split('\n').map((value) => {
                        return (
                            <>
                                {value}
                                <br />
                            </>
                        );
                    })}
                </Typography>
            </Box>
        );
    };

    const renderView = () => {
        if (result.length === 0 && loader) {
            return <RewardsSkeleton />;
        }
        if (result.length === 0 && !loader) {
            return (
                <NoDataContainer>
                    <Typography
                        sx={{ color: '#673ab7', marginTop: '180px' }}
                        variant='h2'
                        component='h2'>
                        {'No Reward Exists.'}
                    </Typography>
                </NoDataContainer>
            );
        }
        return (
            <Box>
                {result.length > 0 &&
                    result.map(({ benefits, status, title, _id, value, count }) => {
                        const total =
                            title === 8
                                ? photographersCount
                                : title === 9
                                    ? clientsCount
                                    : usersCount;

                        let redeemedCount = count || 0;
                        return (
                            <Box sx={styles.item_cont} key={_id}>
                                <Box sx={styles.top_bar}>
                                    <Typography
                                        variant='h2'
                                        sx={{
                                            maxWidth: '80%',
                                            wordWrap: 'break-word',
                                            paddingBottom: '6px',
                                        }}>
                                        {referralTitle[title]}
                                    </Typography>
                                    <Box sx={styles.btn_box}>
                                        <Box
                                            sx={styles.tag}
                                            onClick={(e: any) => {
                                                handleIndivisualRewardActivation(
                                                    e,
                                                    _id,
                                                    status === 1 ? 2 : 1,
                                                );
                                            }}>
                                            {status === 1 ? 'Active' : 'In-Active'}
                                        </Box>
                                        {/* <Box
                                                sx={styles.edit_btn}
                                                onClick={() =>
                                                    navigate(
                                                        `${ROUTE_NAMES.MAIN_ROUTES.EDIT_REWARDS}?id=${_id}`,
                                                    )
                                                }>
                                                <img src={EDIT_ICON} alt='Edit Icon'></img>
                                            </Box> */}
                                    </Box>
                                </Box>
                                <Box sx={styles.bottom_box}>
                                    <Box sx={styles.bottom_left}>
                                        <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                            {`${title === 10
                                                ? 'Benefits for Client and Photographer'
                                                : title === 9
                                                    ? 'Benefits for Client'
                                                    : 'Benefits for Photographer'
                                                }`}
                                        </Typography>
                                        {benefits.map((benefit) => {
                                            return renderRewardDetail(benefit);
                                        })}
                                    </Box>
                                    <Box sx={styles.bottom_right}>
                                        <Typography variant='h2'>
                                            {'$'}
                                            {value}
                                            {` OFF`}
                                        </Typography>
                                        <Typography variant='h5' sx={styles.client_count_text}>
                                            {`Offer redeemed by : ${redeemedCount} ${title === 9
                                                ? redeemedCount > 1
                                                    ? 'Clients'
                                                    : 'Client'
                                                : title === 10
                                                    ? redeemedCount > 1
                                                        ? 'Users'
                                                        : 'User'
                                                    : redeemedCount > 1
                                                        ? 'Photographers'
                                                        : 'Photographer'
                                                }`}
                                        </Typography>
                                        <Box sx={{ width: '300px' }}>
                                            <LinearProgress variant='determinate' value={50} />
                                        </Box>
                                        <Typography variant='caption' sx={{ fontStyle: 'italic' }}>
                                            {`${total} ${title === 9
                                                ? total > 1
                                                    ? 'Clients'
                                                    : 'Client'
                                                : title === 10
                                                    ? total > 1
                                                        ? 'Users'
                                                        : 'User'
                                                    : total > 1
                                                        ? 'Photographers'
                                                        : 'Photographer'
                                                }`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
        );
    };
    return (
        <Box sx={styles.paper}>
            <>
                <Box>{renderView()}</Box>
                {loader && result.length > 0 && <Loader />}
            </>
        </Box>
    );
};

export default RewardsProgram;
