import React from 'react';
import { Box, Typography, Tooltip as MaterialToolTip } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { commonTableStyles as styles } from 'components/tables/styles';
import { styles as customStyles } from 'modules/reportAndAnalytics/styles';
import CircleIcon from '@mui/icons-material/Circle';
import DurationFilter from 'modules/reportAndAnalytics/durationFilter';
import { DataFormater, DataFormaterWithoutDecimal, getFormattedDate } from 'utils/constants';

const RenderBarChart = ({ filteredData, durationState, onDurationFilter, totalOrderValue, totalPlandidEarnings }: any) => {
    const customizedGroupTick = (props: any) => {
        const { x, y, payload } = props;
        if (payload?.value) {
            return (
                <g>
                    {durationState === 1 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {payload?.value}
                        </text>
                    )}

                    {durationState === 2 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {payload?.value}
                        </text>
                    )}

                    {durationState === 3 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {getFormattedDate(payload?.value, 'MM/DD/YYYY')}
                        </text>
                    )}

                    {durationState === 4 && (
                        <text x={x - 17} y={y + 40} fill='#262626'>
                            {payload?.value}
                        </text>
                    )}
                </g>
            );
        }
        return <g></g>;
    };

    const customizedMonthTick = (props: any) => {
        const { x, y, payload } = props;

        if (payload?.value) {
            return (
                <g>
                    <text x={x - 12} y={y - 10}>
                        {payload?.value?.slice(0, 3)}
                    </text>
                </g>
            );
        }
        return <text></text>;
    };

    const customizedDateTick = (props: any) => {
        const { x, y, payload } = props;

        if (payload?.value) {
            return (
                <g>
                    <text x={x - 35} y={y + 20}>
                        {payload?.value}
                    </text>
                </g>
            );
        }
        return <text></text>;
    };

    const xDataKey =
        durationState === 1
            ? 'hour'
            : durationState === 2
                ? 'day'
                : durationState === 3
                    ? 'date'
                    : 'year';
    const xDataLabel =
        durationState === 1
            ? 'Today'
            : durationState === 2
                ? 'Week'
                : durationState === 3
                    ? 'Month'
                    : 'Year';

    const defaultState = [
        {
            totalOrderValue: 0,
            totalPlandidEarnings: 0,
            date: '',
        },
    ];

    return (
        <Box sx={styles.paper}>
            <Box sx={[styles.top_container, { padding: '20px' }]}>
                <Box sx={[styles.top_container_info, { marginLeft: '0px' }]}>
                    <Box sx={styles.top_cnt_int}>
                        <CircleIcon sx={{ color: '#673AB7' }} />
                        <Typography color={'#673AB7'} variant='h5'>
                            {'Transactional Earnings'}
                        </Typography>
                        <CircleIcon sx={{ color: '#2196f3' }} />
                        <Typography color={'#2196f3'} variant='h5'>
                            {'Plandid Earnings'}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={customStyles.earnAndTransContainer}>
                    <Box
                        sx={[
                            customStyles.reportAndAnalytics,
                            { width: '34%', marginRight: '4.875rem', justifyContent: 'flex-start' },
                        ]}>
                        <Box sx={[customStyles.tabularText, { flex: 'unset', marginRight: '20px' }]}>
                            Transactional Earnings
                        </Box>
                        <MaterialToolTip title={totalOrderValue ? totalOrderValue : ''}>
                            <Box sx={[customStyles.tabularText, { flex: 'unset' }]}>
                                {totalOrderValue ? `$${DataFormater(totalOrderValue)}` : 0}
                            </Box>
                        </MaterialToolTip>

                    </Box>
                    <Box
                        sx={[
                            customStyles.reportAndAnalytics,
                            { width: '34%', justifyContent: 'flex-start' },
                        ]}>
                        <Box sx={[customStyles.tabularText, { flex: 'unset', marginRight: '20px' }]}>
                            Plandid Earnings
                        </Box>
                        <MaterialToolTip title={totalPlandidEarnings ? totalPlandidEarnings : ''}>
                            <Box sx={[customStyles.tabularText, { flex: 'unset' }]}>
                                {totalPlandidEarnings ? `$${DataFormater(totalPlandidEarnings)}` : 0}
                            </Box>
                        </MaterialToolTip>
                    </Box>
                </Box>
                <DurationFilter
                    durationState={durationState}
                    filterKey={1}
                    onDurationFilter={onDurationFilter}
                />
            </Box>

            <br></br>
            <Box sx={styles.chartDimension}>
                <React.Fragment>
                    <ResponsiveContainer width='100%' height='100%'>
                        <BarChart
                            width={1000}
                            height={300}
                            margin={{
                                right: 30,
                                left: 20,
                                bottom: 43,
                                top: 20
                            }}
                            data={filteredData?.length > 0 ? filteredData : defaultState}>
                            {durationState === 4 && (
                                <XAxis
                                    dataKey='month'
                                    axisLine={false}
                                    tickLine={false}
                                    tick={customizedMonthTick}
                                    xAxisId='quarter'
                                />
                            )}
                            {durationState === 2 &&
                                <XAxis
                                    dataKey='date'
                                    axisLine={false}
                                    tickLine={false}
                                    interval={0}
                                    tick={customizedDateTick}
                                    xAxisId='dateId'
                                />
                            }
                            <XAxis dataKey={xDataKey} stroke='#673AB7' tick={customizedGroupTick}>
                                <Label
                                    position='center'
                                    value={xDataLabel}
                                    dy={durationState === 4 ? 55 : durationState === 2 ? 70 : 36}

                                />
                            </XAxis>
                            <YAxis tickFormatter={DataFormaterWithoutDecimal}>
                                <Label
                                    style={{
                                        textAnchor: 'middle',
                                    }}
                                    position='insideLeft'
                                    offset={8}
                                    angle={270}
                                    value={'Amount (in $)'}
                                />
                            </YAxis>
                            {filteredData?.length > 0 && (
                                <Tooltip
                                    formatter={(value) =>
                                        `${DataFormater(parseFloat(value.toString()))}`
                                    }
                                    labelFormatter={(value) => {
                                        if (durationState === 3) {
                                            return `${getFormattedDate(value, 'MM/DD/YYYY')}`;
                                        } else {
                                            return `${value}`;
                                        }
                                    }}
                                />
                            )}
                            <Bar dataKey='totalOrderValue' fill='#673AB7' barSize={20} />
                            <Bar dataKey='totalPlandidEarnings' fill='#2196f3' barSize={20} />
                        </BarChart>
                    </ResponsiveContainer>
                </React.Fragment>
            </Box>
        </Box>
    );
};
export default RenderBarChart;
