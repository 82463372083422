import { UPDATE_REPORTS_DATA_REDUCER } from 'store/actionTypes';
import { ENDPOINTS } from 'utils/endpoints';
import { APIResponseModal } from 'utils/modals';
import { GET_API_CALL } from 'utils/services';

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @param reportOf will be a number which show 1 for client and 2 for photographer
 * @returns will return reports of Active clients/Active Photographer
 */
export const getActiveClientPhotographerData = (dateFilter: number, reportOf: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;
        if (reportOf !== 3) {
            parameters += `&reportOf=${reportOf}`;
        }
        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.CLIENT_PHOTOGRAPHER_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    if (reportOf === 1) {
                        dispatch({
                            type: UPDATE_REPORTS_DATA_REDUCER,
                            payload: { activeClient: response.data.result },
                        });
                    } else if (reportOf === 2) {
                        dispatch({
                            type: UPDATE_REPORTS_DATA_REDUCER,
                            payload: { activePhotographer: response.data.result },
                        });
                    } else if (reportOf === 3) {
                        dispatch({
                            type: UPDATE_REPORTS_DATA_REDUCER,
                            payload: { activeAllUsers: response.data.result },
                        });
                    }
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return top 5 most popular category data
 */
export const getPopularCategoryData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.MOST_POPULAR_CATEGORY_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { mostPopularCategory: response?.data?.result?.splice(0, 5) },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return 5 top most active client data
 */

export const getTopActiveClientData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.TOP_ACTIVE_CLIENTS_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { topActiveClient: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return instant and scheduled earning data
 */

export const getInstantScheduleData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.INSTANT_SCHEDULE_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { instantVsScheduleEarn: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return photography vs videography earning data
 */

export const getPhotographyVsVideographyData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.PHOTOGRAPHY_VS_VIDEOGRAPHY_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { photographyVsVideographyEarn: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return 5 top most booked photographer data
 */

export const getMostBookedPhotographerData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.MOST_BOOKED_PHOTOGRAPHER_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { mostBookedPhotographer: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return 5 top most rated photographer data
 */

export const getTopRatedPhotographerData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.TOP_RATED_PHOTOGRAPHER_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { topRatedPhotographer: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return booking vs tip earning data
 */

export const getTotalBookingVsTipsData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.TOTAL_BOOKING_VS_TIPS_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { totalBookingVsTipsData: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return booking vs tip earning data
 */

export const getBookingVsTipEarningData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.BOOKING_VS_TIPS_EARNING_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: {
                            bookingVsTipsEarning: response.data.result,
                            averageTip: response.data.averageTip,
                        },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return 5 top most booked location data
 */

export const getMostBookedLocationData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.MOST_BOOKED_LOCATION_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { mostBookedLocation: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return 10 top most booked category data
 */

export const getMostBookedCategoryData = () => {
    return (dispatch: Function) => {
        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.MOST_BOOKED_CATEGORY_REPORTS,
            '',
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: { mostBookedCategory: response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return average photoshoot duration data
 */

export const getAveragePhotoShootDurationData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.AVERAGE_PHOTOSHOOT_DURATION_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: {
                            avrShootDuration: response.data.result,
                            averageDuration: response.data.maxId,
                        },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return average photoshoot cost data
 */

export const getAveragePhotoShootCostData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.AVERAGE_PHOTOSHOOT_COST_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: {
                            avrShootCost: response.data.result,
                            averageCost: response.data.averagePhotoShootCost,
                        },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return average hourly rate data
 */

export const getAverageHourlyRateData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.AVERAGE_HOURLY_RATE_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: {
                            avrHourlyRate: response.data.result,
                            averageHourlyCharges: response.data.averageHourlyCharges,
                        },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param dateFilter will be dropdown value selected from duration filter
 * @returns will return 5 top most booked price range data
 */

export const getMostBookedPriceRangeData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_REPORTS_DATA_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REPORT_AND_ANALYTICS_MANAGEMENT.MOST_BOOKED_PRICE_RANGE_REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REPORTS_DATA_REDUCER,
                        payload: {
                            mostBookedPriceRange: response.data.result,
                            bookedPriceRange: response.data.maxId,
                        },
                    });
                }
                dispatch({
                    type: UPDATE_REPORTS_DATA_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};
