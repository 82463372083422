import { styles } from './styles';
import PropTypes from 'prop-types';
import Loader from 'components/loader';
import { Box, Card, CardContent, Skeleton, Tab, Tabs, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchSection from 'components/search';
import { getManageAndDisputeListing } from './action';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_FLAGGED_REDUCER, UPDATE_MANAGE_DISPUTE_REDUCER } from 'store/actionTypes';
import { FlaggedAccountListDataModal, ReducersModal } from 'utils/modals';
import ManageDisputeTable from 'components/tables/customerSatisfaction/reportedAccount';
import FlaggedAccountTable from 'components/tables/customerSatisfaction/flaggedAccount';
import TechnicalIssuesTable from 'components/tables/customerSatisfaction/technicalIssues';
import FeedbackTable from 'components/tables/customerSatisfaction/feedback';
import CustomerSatisfactionFilter from 'components/filter/customerSatisfactionFilter';
import ContainedButton from 'components/button/contained-button';
import { getFirebaseMessageList } from 'utils/constants';
import { auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import SHOW_TOAST from 'utils/showToast';
import { useNavigate } from 'react-router';
import ROUTE_NAMES from 'routes/routeNames';

const StyledTabs = styled((props: any) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
        style={{ width: '100%' }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgb(33, 150, 243)',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 800,
        width: '100%',
        backgroundColor: 'rgb(33, 150, 243)',
    },
});

const StyledTab = styled((props: any) => <Tab {...props} style={{ width: '700px' }} />)(
    ({ theme }) => ({
        textTransform: 'none',
        marginRight: theme.spacing(1),
        color: 'black',
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '0.875rem',
        lineHeight: '1.25',
        maxWidth: '24.4%',

        '&.Mui-selected': {
            color: 'rgb(33, 150, 243)',
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#30c1d1',
        },
    }),
);

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomerSatisfaction = () => {
    const {
        search,
        loader,
        result,
        page,
        statusFilter,
        commentFilter,
        grievanceType,
        limit,
        fromDate,
        toDate,
        sortedBy,
        sortOrder,
    } = useSelector((state: ReducersModal) => state.ManageDisputeReducer);
    const { limit: flaggedLimit, page: flaggedPage } = useSelector(
        (state: ReducersModal) => state.FlaggedAccountReducer,
    );
    const { flaggedListDataArray } = useSelector(
        (state: ReducersModal) => state.FlaggedAccountReducer,
    );
    const { clientStatus, vendorStatus } = useSelector(
        (state: ReducersModal) => state.ManageDisputeDetailsReducer,
    );
    const [value, setValue] = useState(0);
    const [activeTab, setActiveTab] = useState(1);
    const [flaggedSearchArr, setFlaggedSearchArr] =
        useState<FlaggedAccountListDataModal[]>(flaggedListDataArray);
    const [flaggedTotal, setFlaggedTotal] = useState<number>(0);
    const theme: any = useTheme();
    const navigate: any = useNavigate();
    const dispatch: any = useDispatch();
    const grievanceVal: any = new URLSearchParams(window.location.search).get('grievanceType');
    const type = !grievanceVal ? 1 : parseInt(grievanceVal);

    const [user] = useAuthState(auth);

    useEffect(() => {
        getFirebaseMessageList(activeTab, flaggedPage, flaggedLimit, dispatch);
    }, [activeTab, flaggedPage, flaggedLimit, dispatch]);

    useLayoutEffect(() => {
        if (type !== 4) {
            dispatchValue({ grievanceType: type, search: '', loader: true });
        }
        if (type === 1) {
            setValue(0);
        } else if (type === 4) {
            setValue(1);
        } else if (type === 3) {
            setValue(2);
        } else if (type === 2) {
            setValue(3);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (type !== 4) {
            dispatchValue({ grievanceType: type, search: '', loader: true });
            if (type === 1) {
                setValue(0);
                dispatchValue({ senderType: 1, result: [] });
            } else if (type === 4) {
                setValue(1);
            } else if (type === 3) {
                setValue(2);
            } else if (type === 2) {
                setValue(3);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grievanceVal]);

    useEffect(() => {
        setFlaggedSearchArr(flaggedListDataArray);
        commonDataFilterization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(flaggedListDataArray)]);

    const dispatchFlaggedValue = (payload: any) => {
        dispatch({
            type: UPDATE_FLAGGED_REDUCER,
            payload,
        });
    };

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_REDUCER,
            payload,
        });
    };

    const onTabClick = (val: number) => {
        setActiveTab(val);
        dispatchValue({ senderType: val, result: [] });
        dispatchFlaggedValue({ page: 1, limit: 10 });
    };

    useEffect(() => {
        if (grievanceType !== 4) {
            dispatch(getManageAndDisputeListing());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        search,
        statusFilter,
        commentFilter,
        activeTab,
        grievanceType,
        page,
        limit,
        fromDate,
        toDate,
        clientStatus,
        vendorStatus,
    ]);

    useEffect(() => {
        if (grievanceType === 4) {
            commonDataFilterization();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        search,
        activeTab,
        grievanceType,
        flaggedPage,
        flaggedLimit,
        fromDate,
        toDate,
        sortOrder,
        sortedBy,
    ]);

    const handleSearch = (searchVal: string) => {
        dispatch({ type: UPDATE_MANAGE_DISPUTE_REDUCER, payload: { search: searchVal, page: 1 } });
        dispatchFlaggedValue({ page: 1, limit: 10 });
    };

    const handleChange = (e: any, newValue: any) => {
        if (newValue === 1) {
            if (!user) {
                SHOW_TOAST('Firebase authentication failed!!!', 'error');
                return;
            }
        }
        setValue(newValue);
        dispatchValue({
            statusFilter: '',
            commentFilter: '',
            search: '',
            page: 1,
            limit: 10,
            loader: true,
            result: [],
        });
        // Always open tab with role type 1 "By Client"
        setActiveTab(1);
        if (newValue === 0) {
            // If tab value 0 then open REPORT Type
            navigate(`${ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION}`);
            dispatchValue({ grievanceType: 1, senderType: 1 });
        } else if (newValue === 1) {
            // If tab value 1 then open FLAGGED ACCOUNT Type
            navigate(`${ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION}?grievanceType=${4}`);
            dispatchValue({ grievanceType: 4, senderType: 1 });
        } else if (newValue === 2) {
            // If tab value 2 then open TECHNICAL_ISSUE Type
            navigate(`${ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION}?grievanceType=${3}`);
            dispatchValue({ grievanceType: 3, senderType: 1 });
        } else if (newValue === 3) {
            // If tab value 3 then open FEEDBACK Type
            navigate(`${ROUTE_NAMES.MAIN_ROUTES.CUSTOMER_SATISFACTION}?grievanceType=${2}`);
            dispatchValue({ grievanceType: 2, senderType: 1 });
        }
    };

    const commonDataFilterization = () => {
        dispatchValue({
            loader: true,
        });
        let filteredData = [...flaggedListDataArray];

        if (filteredData.length > 0 && search !== '') {
            const flaggedArr = filteredData.filter((data: any) => {
                const { clientDetails, vendorDetails } = data;
                if (activeTab === 1) {
                    return clientDetails?.fullName.toLowerCase().includes(search.toLowerCase());
                } else {
                    return vendorDetails?.fullName.toLowerCase().includes(search.toLowerCase());
                }
            });
            filteredData = flaggedArr;
        } else if (filteredData.length > 0 && search !== '') {
            filteredData = [];
        } else if (search === '') {
            filteredData = flaggedListDataArray;
        }

        if (sortedBy !== '') {
            if (sortOrder === '1') {
                if (activeTab === 1) {
                    if (sortedBy === 'fullName') {
                        filteredData
                            .sort((a: any, b: any) =>
                                a?.clientDetails?.fullName?.split(' ')[0] <
                                b?.clientDetails?.fullName?.split(' ')[0]
                                    ? -1
                                    : 1,
                            )
                            .sort((a, b) =>
                                a?.clientDetails?.fullName?.split(' ')[0] <
                                b?.clientDetails?.fullName?.split(' ')[0]
                                    ? -1
                                    : 1,
                            );
                    }
                } else if (activeTab === 2) {
                    if (sortedBy === 'fullName') {
                        filteredData
                            .sort((a: any, b: any) =>
                                a?.vendorDetails?.fullName?.split(' ')[0] <
                                b?.vendorDetails?.fullName?.split(' ')[0]
                                    ? -1
                                    : 1,
                            )
                            .sort((a, b) =>
                                a?.vendorDetails?.fullName?.split(' ')[0] <
                                b?.vendorDetails?.fullName?.split(' ')[0]
                                    ? -1
                                    : 1,
                            );
                    }
                }
                if (sortedBy === 'createdAt') {
                    filteredData.sort((a: any, b: any) => {
                        let messageObjOne: any = Object.values(b?.flaggedMessages)[0];
                        let dateOne: any = messageObjOne?.createdAt;
                        let messageObjTwo: any = Object.values(a?.flaggedMessages)[0];
                        let dateTwo: any = messageObjTwo?.createdAt;
                        return new Date(dateOne).getTime() > new Date(dateTwo).getTime() ? 1 : -1;
                    });
                }
            } else if (sortOrder === '-1') {
                if (activeTab === 1) {
                    if (sortedBy === 'fullName') {
                        filteredData
                            .sort((a: any, b: any) =>
                                b?.clientDetails?.fullName?.split(' ')[0] <
                                a?.clientDetails?.fullName?.split(' ')[0]
                                    ? -1
                                    : 1,
                            )
                            .sort((a, b) =>
                                b?.clientDetails?.fullName?.split(' ')[0] <
                                a?.clientDetails?.fullName?.split(' ')[0]
                                    ? -1
                                    : 1,
                            );
                    }
                } else if (activeTab === 2) {
                    if (sortedBy === 'fullName') {
                        filteredData
                            .sort((a: any, b: any) =>
                                b?.vendorDetails?.fullName?.split(' ')[0] <
                                a?.vendorDetails?.fullName?.split(' ')[0]
                                    ? -1
                                    : 1,
                            )
                            .sort((a, b) =>
                                b?.vendorDetails?.fullName?.split(' ')[0] <
                                a?.vendorDetails?.fullName?.split(' ')[0]
                                    ? -1
                                    : 1,
                            );
                    }
                }
                if (sortedBy === 'createdAt') {
                    filteredData.sort((a: any, b: any) => {
                        let messageObjOne: any = Object.values(a?.flaggedMessages)[0];
                        let dateOne: any = messageObjOne?.createdAt;
                        let messageObjTwo: any = Object.values(b?.flaggedMessages)[0];
                        let dateTwo: any = messageObjTwo?.createdAt;
                        return new Date(dateOne).getTime() > new Date(dateTwo).getTime() ? 1 : -1;
                    });
                }
            }
        }

        let selectedStartDate: any = fromDate;
        let selectedEndDate: any = toDate;

        if (selectedStartDate || selectedEndDate) {
            let startDate = selectedStartDate ? new Date(new Date(selectedStartDate)) : null;
            let endDate = selectedEndDate
                ? new Date(new Date(selectedEndDate).setHours(23, 59, 59, 999))
                : null;

            let resultProductData = filteredData.filter((content) => {
                let date = new Date(Object.values(content.flaggedMessages)[0]?.createdAt);
                if (startDate && endDate) {
                    return date >= startDate && date <= endDate;
                } else if (startDate && !endDate) {
                    return date >= startDate;
                } else if (!startDate && endDate) {
                    return date <= endDate;
                }
                return null;
            });
            if (
                (resultProductData.length > 0 && (fromDate || toDate)) ||
                (resultProductData.length === 0 && (fromDate || toDate))
            ) {
                filteredData = resultProductData;
            }
        }
        let paginatedData = [];
        for (var i = 0; i < filteredData.length; i++) {
            if (i >= (flaggedPage - 1) * flaggedLimit && i < flaggedPage * flaggedLimit) {
                paginatedData.push(filteredData[i]);
            }
        }
        setFlaggedSearchArr(paginatedData);
        setFlaggedTotal(filteredData.length);
        dispatchValue({
            loader: false,
        });
    };

    const onApplyFilters = ({ requestStatus, comment, selectedFromDate, selectedToDate }: any) => {
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_REDUCER,
            payload: {
                page: 1,
                statusFilter: requestStatus,
                commentFilter: comment,
                fromDate: selectedFromDate,
                toDate: selectedToDate,
            },
        });
    };

    const renderLoading = () => {
        return (
            <React.Fragment>
                <Box sx={styles.searchAndFilter}>
                    <Skeleton
                        height={48}
                        width={300}
                        variant='rectangular'
                        sx={{ borderRadius: '12px' }}
                    />
                </Box>
                <Skeleton
                    height={500}
                    width={'100%'}
                    variant='rectangular'
                    sx={{ borderRadius: '8px', marginTop: '16px' }}
                />
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (loader && result.length === 0 && page === 1 && search.length === 0) {
            return renderLoading();
        }

        return (
            <Box sx={styles.paper}>
                <Card sx={{ minHeight: 84 + 'vh' }}>
                    <CardContent>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                                <StyledTabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label='customer satisfaction'>
                                    <StyledTab label='Reported Accounts' {...a11yProps(0)} />
                                    <StyledTab
                                        label='Flagged Account'
                                        {...a11yProps(1)}
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            transition: 'all .2s ease-in-out',
                                        }}
                                    />
                                    <StyledTab
                                        label='Technical Issue'
                                        {...a11yProps(1)}
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            transition: 'all .2s ease-in-out',
                                        }}
                                    />
                                    <StyledTab
                                        label='Feedbacks'
                                        {...a11yProps(1)}
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            transition: 'all .2s ease-in-out',
                                        }}
                                    />
                                </StyledTabs>
                            </Box>
                            <Box sx={[styles.searchAndFilter, { padding: '20px' }]}>
                                <Box sx={[styles.searchAndFilter, styles.toogleContainer]}>
                                    {activeTab === 1 ? (
                                        <ContainedButton
                                            onClick={() => {
                                                onTabClick(1);
                                            }}
                                            externalStyles={styles.toogleBtn}
                                            title={'By Client'}
                                        />
                                    ) : (
                                        <Box
                                            onClick={() => onTabClick(1)}
                                            sx={[styles.toogleBtn, styles.paddingCss]}>
                                            By Client
                                        </Box>
                                    )}
                                    {activeTab === 2 ? (
                                        <ContainedButton
                                            onClick={() => {
                                                onTabClick(2);
                                            }}
                                            externalStyles={styles.btn}
                                            title={'By Photographer'}
                                        />
                                    ) : (
                                        <Box
                                            onClick={() => onTabClick(2)}
                                            sx={[styles.toogleBtn, styles.paddingCss]}>
                                            By Photographer
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box sx={styles.paper}>
                                <Card sx={{ minHeight: 84 + 'vh' }}>
                                    <CardContent>
                                        <Box sx={styles.searchAndFilter}>
                                            <SearchSection
                                                value={search}
                                                isDisabled={loader}
                                                onChange={handleSearch}
                                                placeholder={'Search by Name'}
                                            />
                                            {value === 0 && (
                                                <CustomerSatisfactionFilter
                                                    applyFilters={onApplyFilters}
                                                    requestStatusValue={statusFilter}
                                                    commentValue={commentFilter}
                                                    commentState={true}
                                                    requestState={true}
                                                    dateState={true}
                                                    activeTab={activeTab}
                                                    fromDate={fromDate}
                                                    toDate={toDate}
                                                />
                                            )}
                                            {value === 1 && (
                                                <CustomerSatisfactionFilter
                                                    applyFilters={onApplyFilters}
                                                    requestStatusValue={statusFilter}
                                                    commentState={false}
                                                    requestState={false}
                                                    dateState={true}
                                                    activeTab={activeTab}
                                                    fromDate={fromDate}
                                                    toDate={toDate}
                                                />
                                            )}
                                            {(value === 2 || value === 3) && (
                                                <CustomerSatisfactionFilter
                                                    applyFilters={onApplyFilters}
                                                    requestStatusValue={statusFilter}
                                                    commentState={false}
                                                    requestState={true}
                                                    dateState={true}
                                                    activeTab={activeTab}
                                                    fromDate={fromDate}
                                                    toDate={toDate}
                                                />
                                            )}
                                        </Box>
                                        <TabPanel value={value} index={0}>
                                            <ManageDisputeTable activeTab={activeTab} />
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <FlaggedAccountTable
                                                activeTab={activeTab}
                                                flaggedSearchArr={flaggedSearchArr}
                                                flaggedTotal={flaggedTotal}
                                            />
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <TechnicalIssuesTable />
                                        </TabPanel>
                                        <TabPanel value={value} index={3}>
                                            <FeedbackTable />
                                        </TabPanel>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        );
    };

    return (
        <Box>
            {renderView()}
            {loader && (result.length > 0 || (result.length === 0 && search.length > 0)) && (
                <Loader />
            )}
            {loader &&
                (flaggedListDataArray.length > 0 ||
                    (flaggedListDataArray.length === 0 && search.length > 0)) && <Loader />}
        </Box>
    );
};

export default CustomerSatisfaction;
