import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from '@emotion/react';
import styles from './styles';
import AccountSettings from './accountSettings';
import ChangePassword from './changePassword';

const StyledTabs = styled((props: any) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgb(33, 150, 243)',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 120,
        width: '100%',
        backgroundColor: 'rgb(33, 150, 243)',
    },
});

const StyledTab = styled((props: any) => <Tab {...props} />)(({ theme }) => ({
    textTransform: 'none',
    marginRight: theme.spacing(1),
    color: 'black',
    fontWeight: 500,

    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1.25',
    '&.Mui-selected': {
        color: 'rgb(33, 150, 243)',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#30c1d1',
    },
}));

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function List() {
    const [value, setValue] = React.useState(0);
    const theme: any = useTheme();

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    return (
        <Box sx={styles.paper}>
            <Card sx={{ minHeight: 84 + 'vh' }}>
                <CardContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <StyledTabs
                                value={value}
                                onChange={handleChange}
                                aria-label='styled tabs example'
                            >
                                <StyledTab
                                    label='Account Settings'
                                    {...a11yProps(0)}
                                    icon={<AccountCircleIcon />}
                                    iconPosition='start'
                                />
                                <StyledTab
                                    label='Change Password'
                                    {...a11yProps(1)}
                                    icon={<LockIcon />}
                                    iconPosition='start'
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        transition: 'all .2s ease-in-out',
                                    }}
                                />
                            </StyledTabs>
                        </Box>

                        <TabPanel value={value} index={0}>
                            <AccountSettings />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ChangePassword />
                        </TabPanel>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
