import { styles } from './styles';
import { Box, FormControl, MenuItem, Select, Typography, styled } from '@mui/material';
import CustomerSatisfactionSkeleton from './satisfactionSkeleton';
import React, { useEffect, useState } from 'react';
import USER_IMG from 'assets/images/user-image.png';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from 'utils/modals';
import { getManageDisputeDetails, updateDisputeStatus } from 'modules/customerSatisfaction/action';
import { DISPUTE_STATUS } from 'utils/enum';
import { UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER } from 'store/actionTypes';
import BackArrowIcon from 'components/backArrow';
import { getFormattedDate } from 'utils/constants';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '12px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    padding: '18px 32px 5px 32px',
    background: 'white',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
}));

const TechincalIssueDetail = () => {
    const {
        title,
        message,
        createdAt,
        vendorDetails,
        clientDetails,
        givenBy,
        loader,
        requestStatus,
    } = useSelector((state: ReducersModal) => state.ManageDisputeDetailsReducer);
    const dispatch: any = useDispatch();
    const [imgError, setImgError] = useState('valid');

    const grievanceId = new URLSearchParams(window.location.search).get('id');

    useEffect(() => {
        dispatch(
            getManageDisputeDetails(
                grievanceId,
                window.location.pathname.includes('feedback') ? 2 : 3,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e: any) => {
        const status = e.target.value;
        dispatchDisputeValue({ requestStatus: status });
        // setStatus(e.target.value);
        const dataToSend = {
            grievanceId,
            status,
        };
        dispatch(updateDisputeStatus(dataToSend, () => {}));
    };

    const dispatchDisputeValue = (payload: any) => {
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
            payload,
        });
    };
    const renderHeading = (label: string, status?: number) => {
        return (
            <Typography
                variant='h3'
                component={'h3'}
                sx={{ minWidth: status === 1 ? 'unset' : '50px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderVerifiedStatus = () => {
        return (
            <FormControl
                sx={{
                    width: '130px',
                    height: '38px',
                    boxShadow: 'none',
                    fontSize: '30px',
                }}>
                <Select
                    displayEmpty
                    onChange={handleChange}
                    sx={styles.selectDropdown}
                    value={requestStatus}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {Object.values(DISPUTE_STATUS).map((status: any, index: number) => {
                        return (
                            <MenuItem
                                disabled={status === Object.keys(DISPUTE_STATUS)[index]?.toString()}
                                value={Object.keys(DISPUTE_STATUS)[index]?.toString()}
                                key={index}>
                                {status}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    const renderProfileView = (type: number) => {
        const user_img = type === 2 ? vendorDetails?.profilePic : clientDetails?.profilePic;
        const user_name = type === 2 ? vendorDetails?.fullName : clientDetails?.fullName;
        // To check weather image is valid or not through image event handler
        const handleError = (e: any) => {
            if (e.type === 'error') {
                setImgError('error');
            }
        };
        const checkSrc = () => {
            if (!user_img || imgError === 'error') {
                return USER_IMG;
            } else {
                return user_img;
            }
        };
        return (
            <Box sx={styles.clientProfilePicCont}>
                <img
                    onError={(e: any) => handleError(e)}
                    style={styles.img}
                    src={checkSrc()}
                    alt='vendor-img'></img>
                {user_name ? renderHeading(user_name) : 'N/A'}
            </Box>
        );
    };

    const renderValue = (value: any) => {
        return (
            <Typography variant='h4' component={'h4'} sx={styles.value_text}>
                {value}
            </Typography>
        );
    };
    const renderBasicDetail = () => {
        return (
            <React.Fragment>
                <TableContainer sx={{ display: 'block' }}>
                    <ItemContainer>
                        <LineContainer sx={{ justifyContent: 'space-between' }}>
                            <LineContainer>
                                <Typography
                                    variant='h3'
                                    component={'h3'}
                                    sx={{
                                        minWidth: '160px',
                                        fontSize: '18px',
                                    }}>
                                    Reported Time
                                </Typography>
                                {renderValue(getFormattedDate(createdAt, 'MM/DD/YYYY, hh:mm A'))}
                            </LineContainer>
                            {renderVerifiedStatus()}
                        </LineContainer>
                        <Box sx={[styles.profile_cont, { marginBottom: '36px', marginTop: '0px' }]}>
                            <LineContainer>
                                {renderHeading(givenBy === 1 ? 'Client' : 'Photographer', givenBy)}
                                {renderProfileView(givenBy)}
                            </LineContainer>
                        </Box>
                        <LineContainer>
                            {renderHeading(title ? title : 'Description')}
                        </LineContainer>
                        <LineContainer>
                            <Typography
                                variant='h4'
                                component={'h4'}
                                sx={[
                                    styles.value_text,
                                    { maxWidth: 'unset', whiteSpace: 'break-spaces' },
                                ]}>
                                {message}
                            </Typography>
                        </LineContainer>
                    </ItemContainer>
                </TableContainer>
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (loader) {
            return <CustomerSatisfactionSkeleton />;
        }
        return renderBasicDetail();
    };

    return (
        <React.Fragment>
            <BackArrowIcon />
            <Box sx={styles.paper}>
                <Box sx={styles.container}>{renderView()}</Box>
            </Box>
        </React.Fragment>
    );
};

export default TechincalIssueDetail;
