import axios from 'axios';
import { store } from 'store';
import SHOW_TOAST, { SHOW_INTERNET_TOAST } from './showToast';
import { UPDATE_AUTH } from 'store/actionTypes';

export const status_code = {
    success: 200,
    invalid: 400,
    timeout: 408,
    notFound: 204,
    badRequest: 400,
    userDelete: 410,
    serverError: 500,
    Unauthorized: 401,
    successAction: 201,
};

const successCodes = [200, 201];
const sessionExpiryCodes = [403, 401];

/**
 * create axios instance
 */
const $http = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN}`,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic cGxhbmRpZDpYenlzJXtKTl53OUFzQw==',
    },
});

/**
 * create axios instance
 */
const $http_file_download = axios.create({
    baseURL: `${process.env.REACT_APP_DOMAIN}`,
    timeout: 30000,
    responseType: 'blob',
    headers: {
        Authorization: 'Basic cGxhbmRpZDpYenlzJXtKTl53OUFzQw==',
    },
});

$http.interceptors.request.use(async (config: any) => {
    if (config.headers) {
        const getState = store?.getState();
        if (getState) {
            const { token = '' } = getState.AuthReducer;
            if (token && token.length > 0) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        }
        return config;
    }
    return config;
});

$http_file_download.interceptors.request.use(async (config: any) => {
    if (config.headers) {
        const getState = store?.getState();
        if (getState) {
            const { token = '' } = getState.AuthReducer;
            if (token && token.length > 0) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        }
        return config;
    }
    return config;
});

const sessionExpireHandler = () => {
    store.dispatch({ type: UPDATE_AUTH, payload: { isSessionValid: false } });
};

/**
 * post api call common function
 * @param endPoint endpoint of api
 * @param params data to send
 * @param callback callback function
 */
export const POST_API_CALL = (
    endPoint: string,
    params: object | Array<Object>,
    callback: Function,
) => {
    $http
        .post(endPoint, params)
        .then((response: any) => {
            if (response && response?.status && successCodes.includes(response?.status)) {
                let dataToSend = response?.data?.data || response?.data;
                callback({
                    isSuccess: true,
                    data: { ...dataToSend, ...{ message: response?.data?.message } },
                });
            } else {
                SHOW_TOAST(response?.data?.message);
                callback({ isSuccess: false, data: response?.response?.data });
            }
        })
        .catch((error: any) => {
            if (error?.response) {
                //if error contains response
                const statusCode = error?.response?.status || error?.response?.data?.status;

                let message = error?.response?.data?.error;
                if (Array.isArray(message)) {
                    message = message[0];
                }

                if (sessionExpiryCodes.includes(statusCode)) {
                    //session expired
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                    sessionExpireHandler();
                } else {
                    //some other error is there
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                }
            } else if (!error.response) {
                //if error does not contain response
                if (error?.code === 'ERR_NETWORK') {
                    SHOW_INTERNET_TOAST();
                } else {
                    SHOW_TOAST();
                }
                callback({ isSuccess: false, data: {} });
            }
        });
};

export const PUT_API_CALL = (endPoint: string, params: object, callback: Function) => {
    $http
        .put(endPoint, params)
        .then((response: any) => {
            if (response && response?.status && successCodes.includes(response?.status)) {
                let dataToSend = response?.data?.data || response?.data;
                callback({
                    isSuccess: true,
                    data: { ...dataToSend, ...{ message: response?.data?.message } },
                });
            } else {
                SHOW_TOAST(response?.data?.message);
                callback({ isSuccess: false, data: response?.response?.data });
            }
        })
        .catch((error: any) => {
            if (error?.response) {
                //if error contains response
                const statusCode = error?.response?.status || error?.response?.data?.status;

                let message = error?.response?.data?.error;
                if (Array.isArray(message)) {
                    message = message[0];
                }

                if (sessionExpiryCodes.includes(statusCode)) {
                    //session expired
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                    sessionExpireHandler();
                } else {
                    //some other error is there
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                }
            } else if (!error.response) {
                //if error does not contain response
                if (error?.code === 'ERR_NETWORK') {
                    SHOW_INTERNET_TOAST();
                } else {
                    SHOW_TOAST();
                }
                callback({ isSuccess: false, data: {} });
            }
        });
};

export const GET_API_CALL = (endPoint: string, params: string, callback: Function) => {
    $http
        .get(endPoint + params, {})
        .then((response: any) => {
            if (response && response?.status && successCodes.includes(response?.status)) {
                let dataToSend = response?.data?.data || response?.data;
                callback({
                    isSuccess: true,
                    data: { ...dataToSend, ...{ message: response?.data?.message } },
                });
            } else {
                SHOW_TOAST(response?.data?.message);
                callback({ isSuccess: false, data: response?.response?.data });
            }
        })
        .catch((error: any) => {
            if (error?.response) {
                //if error contains response
                const statusCode = error?.response?.status || error?.response?.data?.status;

                let message = error?.response?.data?.error;
                if (Array.isArray(message)) {
                    message = message[0];
                }

                if (sessionExpiryCodes.includes(statusCode)) {
                    //session expired
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                    sessionExpireHandler();
                } else {
                    //some other error is there
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                }
            } else if (!error.response) {
                //if error does not contain response
                if (error?.code === 'ERR_NETWORK') {
                    SHOW_INTERNET_TOAST();
                } else {
                    SHOW_TOAST();
                }
                callback({ isSuccess: false, data: {} });
            }
        });
};

export const DELETE_API_CALL = (endPoint: string, callback: Function) => {
    $http
        .delete(endPoint)
        .then((response: any) => {
            if (response && response?.status && successCodes.includes(response?.status)) {
                let dataToSend = response?.data?.data || response?.data;
                callback({
                    isSuccess: true,
                    data: { ...dataToSend, ...{ message: response?.data?.message } },
                });
            } else {
                SHOW_TOAST(response?.data?.message);
                callback({ isSuccess: false, data: response?.response?.data });
            }
        })
        .catch((error: any) => {
            if (error?.response) {
                //if error contains response
                const statusCode = error?.response?.status || error?.response?.data?.status;

                let message = error?.response?.data?.error;
                if (Array.isArray(message)) {
                    message = message[0];
                }

                if (sessionExpiryCodes.includes(statusCode)) {
                    //session expired
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                    sessionExpireHandler();
                } else {
                    //some other error is there
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                }
            } else if (!error.response) {
                //if error does not contain response
                if (error?.code === 'ERR_NETWORK') {
                    SHOW_INTERNET_TOAST();
                } else {
                    SHOW_TOAST();
                }
                callback({ isSuccess: false, data: {} });
            }
        });
};

/**
 * patch api call common function
 * @param endPoint endpoint of api
 * @data data to send
 * @param callback callback function
 */
export const PATCH_API_CALL = (endPoint: string, data: any, callback: Function) => {
    $http
        .patch(endPoint, data)
        .then((response: any) => {
            if (response && response?.status && successCodes.includes(response?.status)) {
                let dataToSend = response?.data?.data || response?.data;
                callback({
                    isSuccess: true,
                    data: { ...dataToSend, ...{ message: response?.data?.message } },
                });
            } else {
                SHOW_TOAST(response?.data?.message);
                callback({ isSuccess: false, data: response?.response?.data });
            }
        })
        .catch((error: any) => {
            if (error?.response) {
                //if error contains response
                const statusCode = error?.response?.status || error?.response?.data?.status;

                let message = error?.response?.data?.error;
                if (Array.isArray(message)) {
                    message = message[0];
                }

                if (sessionExpiryCodes.includes(statusCode)) {
                    //session expired
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                    sessionExpireHandler();
                } else {
                    //some other error is there
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                }
            } else if (!error.response) {
                //if error does not contain response
                if (error?.code === 'ERR_NETWORK') {
                    SHOW_INTERNET_TOAST();
                } else {
                    SHOW_TOAST();
                }
                callback({ isSuccess: false, data: {} });
            }
        });
};

/**
 * post api call common function
 * @param endPoint endpoint of api
 * @param params data to send
 * @param callback callback function
 */
export const POST_API_CALL_FILE_DOWNLOAD = (
    endPoint: string,
    params: object | Array<Object>,
    callback: Function,
) => {
    $http_file_download
        .post(endPoint, params)
        .then((response: any) => {
            callback({
                isSuccess: true,
                data: response.data,
            });
        })
        .catch((error: any) => {
            if (error?.response) {
                //if error contains response
                const statusCode = error?.response?.status || error?.response?.data?.status;

                let message = error?.response?.data?.error;
                if (Array.isArray(message)) {
                    message = message[0];
                }

                if (sessionExpiryCodes.includes(statusCode)) {
                    //session expired
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                    sessionExpireHandler();
                } else {
                    //some other error is there
                    SHOW_TOAST(message);
                    callback({ isSuccess: false, data: {} });
                }
            } else if (!error.response) {
                //if error does not contain response
                if (error?.code === 'ERR_NETWORK') {
                    SHOW_INTERNET_TOAST();
                } else {
                    SHOW_TOAST();
                }
                callback({ isSuccess: false, data: {} });
            }
        });
};
