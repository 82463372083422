import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { commonTableStyles as styles } from '../styles';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import SHOW_TOAST from 'utils/showToast';
import { useSelector } from 'react-redux';
import { ReducersModal } from 'utils/modals';
import { getUnixFormattedDate } from 'utils/constants';

const ReportedDetailsTable = ({ renderData, bookingId, senderType }: any) => {
    const navigate = useNavigate();
    const { messages } = useSelector((state: ReducersModal) => state.FlaggedAccountReducer);
    return (
        <Box sx={styles.paper}>
            <TableContainer>
                <Table sx={{ ...styles.tableHeader_cnt_algn }}>
                    <TableHead sx={{ ...styles.tableHeader }}>
                        <TableRow sx={styles.headerRow}>
                            <TableCell>{'S.No.'}</TableCell>
                            <TableCell>{'Reported Date'}</TableCell>
                            <TableCell>{'Flagged content'}</TableCell>
                            <TableCell className='action-table-header'>{'Action'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(renderData).length > 0 ? (
                            Object.values(renderData)?.map((message: any, idx: number) => {
                                let flaggedContent: any = message?.messageContent;
                                return (
                                    <TableRow key={idx} sx={{ ...styles.customer_satisfaction }}>
                                        <TableCell>{idx + 1}</TableCell>
                                        <TableCell>
                                            {message?.createdAt &&
                                                getUnixFormattedDate(
                                                    message?.createdAt,
                                                    'MMMM DD [,] YYYY ',
                                                )}
                                            {message?.createdAt && (
                                                <span style={{ fontSize: '35px' }}>.</span>
                                            )}
                                            {message?.createdAt
                                                ? getUnixFormattedDate(
                                                    message?.createdAt,
                                                    ' hh:mm:ss A',
                                                )
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell title={flaggedContent}>
                                            {flaggedContent}
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={styles.action_table_cell_container}>
                                                <Button
                                                    className='action-view-btn'
                                                    onClick={() => {
                                                        if ((messages && messages?.length === 0) || (messages && Object.keys(messages).length === 0)) {
                                                            SHOW_TOAST(
                                                                'No Messages to show',
                                                                'info',
                                                            );
                                                        } else {
                                                            navigate(
                                                                `${ROUTE_NAMES.MAIN_ROUTES.FLAGGED_ACCOUNT_CHAT}?id=${bookingId}&senderType=${senderType}`,
                                                            );
                                                        }
                                                    }}>
                                                    {'View Chat'}
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={15} sx={styles.noDataCell}>
                                    <Box>{'No Data Found'}</Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ReportedDetailsTable;
