export const styles = {
    reportAndAnalytics: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    averageContainer: {
        justifyContent: 'center',
        marginTop: '10px',
    },
    earnAndTransContainer: {
        flex: 1,
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '54px',
        height: '50px',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        marginTop: '16px',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
        width: '100%',
    },
    toogleContainer: {
        width: '70%',
        height: '58px',
        padding: '3px',
        borderRadius: '5px',
        border: '1px solid #673AB7',
    },
    toogleBtn: {
        flex: 1,
        justifyContent: 'flex-start',
        margin: '0px 10px',
        font: 'normal normal 500 16px/18px Roboto',
        cursor: 'pointer',
    },
    tabularText: {
        flex: 1,
        justifyContent: 'flex-start',
        font: 'normal normal 500 18px/18px Roboto',
    },
    showMoreText: {
        margin: '20px 0px 0px 0px',
        textAlign: 'center',
        font: 'normal normal 500 18px/18px Roboto',
    },
    btn: {
        flex: 1,
        justifyContent: 'flex-start',
        margin: '0px 10px',
        font: 'normal normal 500 16px/18px Roboto',
        cursor: 'pointer',
        background: `linear-gradient(30.99deg,#0a6fc2 0%, #2196f3 100%)`,
    },
    allBtn: {
        flex: 1,
        justifyContent: 'flex-start',
        margin: '0px 10px',
        font: 'normal normal 500 16px/18px Roboto',
        cursor: 'pointer',
        background: `linear-gradient(30.99deg,#0a6fc2 0%, #673AB7 100%)`,
    },
    paddingCss: {
        padding: '10px 0px',
    },
    chartDimension: {
        height: '385px',
        width: '100%',
    },
    tableDimension: {
        minHeight: '292px',
        height: '330px',
        width: '100%',
        '& tbody tr:last-child th, & tbody tr:last-child td': {
            border: 0,
        },
    },
    categoryTableDimension: {
        minHeight: '105px',
        maxHeight: '544px',
        width: '100%',
        '& tbody tr:last-child th, & tbody tr:last-child td': {
            border: 0,
        },
    },
    durationBtnCon: {
        height: '40px',
        width: '126px',
        display: 'flex',
        borderRadius: '7px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    name: {
        fontWeight: 500,
        fontSize: '0.80rem',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: 'grey',
    },
    selectDropdown: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        '& > div': {
            background: 'none',
        },
        '& fieldset': {
            borderRadius: '4px',
            height: '40px',
        },
    },
    curve_dot: {
        color: '#673AB7',
        height: '10px',
        width: '10px',
        marginRight: '10px',
    },
};
