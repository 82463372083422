import * as React from 'react';
import { Formik } from 'formik';
import { styles } from '../styles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { AddReviewSchema } from 'utils/schema';
import { FormControl, FormHelperText, IconButton, OutlinedInput, Rating } from '@mui/material';
import ContainedButton from 'components/button/contained-button';
import { UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER } from 'store/actionTypes';
import { Close } from '@mui/icons-material';
import { addNewRating, getRatingAndReviewDetail } from '../action';

export default function AddReview({ ratingId, bookingId, setLoading }: any) {
    const dispatch: any = useDispatch();
    const [rating, setRating] = React.useState<number | null>(0);
    const initialState = {
        text: '',
    };

    const handleOnSubmit = (values: any) => {
        const dataToSend = {
            review: values.text,
            rating: rating,
            bookingId: bookingId,
        };
        setLoading(true);
        handleClose();
        dispatch(
            addNewRating(dataToSend, () => {
                dispatch(
                    getRatingAndReviewDetail(ratingId, () => {
                        setLoading(false);
                    }),
                );
            }),
        );
    };
    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER,
            payload,
        });
    };

    const handleClose = () => {
        dispatchValue({ showAddReviewPopup: false });
    };
    return (
        <Modal
            open={true}
            aria-labelledby='modal-photographers-management-title'
            aria-describedby='modal-photographers-management-description'>
            <Box sx={styles.modal_container}>
                <Box sx={[styles.headingBar, { justifyContent: 'center' }]}>
                    <Typography sx={styles.heading_ac} variant='h3' component='h2'>
                        {'Add a Review'}
                    </Typography>
                    <IconButton onClick={handleClose} sx={styles.icon}>
                        <Close />
                    </IconButton>
                </Box>
                <Formik
                    onSubmit={handleOnSubmit}
                    initialValues={initialState}
                    validationSchema={AddReviewSchema}>
                    {({ errors, handleChange, handleSubmit, touched, values }) => {
                        return (
                            <form noValidate onSubmit={handleSubmit}>
                                <Box sx={styles.inputBox}>
                                    <Rating
                                        name='rating'
                                        value={rating}
                                        sx={styles.add_review_rating}
                                        onChange={(event, newValue) => {
                                            setRating(newValue);
                                        }}
                                    />
                                    <FormControl
                                        fullWidth
                                        style={{
                                            marginTop: '50px',
                                        }}>
                                        <OutlinedInput
                                            value={values.text}
                                            onChange={handleChange}
                                            name='text'
                                            maxRows={6}
                                            minRows={6}
                                            multiline={true}
                                            placeholder='Write here...'
                                            inputProps={{
                                                maxLength: 1000,
                                                style: { fontWeight: 300 },
                                            }}
                                        />
                                        {touched.text && errors.text && (
                                            <FormHelperText
                                                error
                                                id='standard-weight-helper-text-vendor'>
                                                {errors.text}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>

                                <Box sx={styles.button}>
                                    <ContainedButton
                                        fullWidth
                                        disabled={rating === 0}
                                        type='submit'
                                        externalStyles={{ width: '220px' }}
                                        title={'Add Review'}
                                    />
                                </Box>
                            </form>
                        );
                    }}
                </Formik>
            </Box>
        </Modal>
    );
}
