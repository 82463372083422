import { Box, CardContent, Skeleton } from '@mui/material';
import { styles } from './styles';

const EditRewardSkeleton = () => {
    return (
        <CardContent>
            <Box sx={styles.selection_bar}>
                <Box sx={styles.radio_cont}>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '4px',
                            marginBottom: '10px',
                            height: '30px',
                            width: '250px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '4px',
                            marginBottom: '10px',
                            height: '30px',
                            width: '90px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '8px',
                            marginBottom: '10px',
                            height: '60px',
                            width: '800px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '4px',
                            marginBottom: '10px',
                            height: '30px',
                            width: '90px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '8px',
                            marginBottom: '10px',
                            height: '60px',
                            width: '800px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '4px',
                            marginBottom: '10px',
                            height: '30px',
                            width: '400px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '8px',
                            marginBottom: '10px',
                            height: '60px',
                            width: '800px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '4px',
                            marginBottom: '10px',
                            height: '30px',
                            width: '90px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '8px',
                            marginBottom: '10px',
                            height: '60px',
                            width: '800px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '4px',
                            marginBottom: '10px',
                            height: '30px',
                            width: '90px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '8px',
                            marginBottom: '10px',
                            height: '60px',
                            width: '800px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '4px',
                            marginBottom: '10px',
                            height: '30px',
                            width: '90px',
                        }}></Skeleton>
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            borderRadius: '8px',
                            marginBottom: '10px',
                            height: '150px',
                            width: '800px',
                        }}></Skeleton>
                </Box>
            </Box>
        </CardContent>
    );
};

export default EditRewardSkeleton;
