export const styles = {
    heading: { font: 'normal normal 700 24px/28px Roboto', color: 'black' },
    searchAndFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0 10px 0',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    switch_Cont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        marginRight: '20px',
    },
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'fit-content',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid lightGray',
        boxShadow: 24,
        borderRadius: '12px',
    },
    headingBar: {
        width: '100%',
        height: '50px',
        display: 'flex',
        borderBottom: '1px solid lightGray',
        alignItems: 'center',
        padding: '20px 10px 0px 20px',
        boxSizing: 'border-box',
    },

    heading_ac: {
        color: '#673ab7',
        textAlign: 'center',
        margin: '10% 0 15% 0',
    },
    button: {
        padding: '10px 20px 20px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '20px',
    },
    button_box: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '20px',
    },

    inputBox: {
        padding: '20px',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        height: '150px',
        width: '150px',
        border: '1px dotted lightGray',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '6px 0px 6px 0px',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
    },
    modal: {
        padding: '30px 20px 0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'column',
        gap: '10px',
    },
    selectDropdown: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: '0.8125rem',
        color: '#364152',
        fontWeight: '400',
        borderRadius: '12px',
        '& > div': {
            background: 'none',
            color: 'black',
            borderRadius: '12px',
            fontSize: '0.8125rem',
            fontWeight: '400',
        },
        '& fieldset': {
            color: 'black',
            borderRadius: '8px',
        },
    },
    csv_btn: {
        height: '45px',
        marginRight: '10px',
        borderRadius: '8px',
    },
    client_filter: {
        width: '110px',
        height: '45px',
        boxShadow: 'none',
        fontSize: '30px',
        borderRadius: '8px',
    },
};
