import { Box, Typography, styled } from '@mui/material';
import { styles } from './styles';
import USER_IMG from 'assets/images/user-image.png';
import { getUnixFormattedDate } from 'utils/constants';

const RightChatContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    float: 'right',
}));
const LeftChatContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    float: 'left',
}));
const Message = ({
    isClientActive,
    isPhotographerActive,
    message,
    messageTime,
    sameAsPreviousSender,
    clientInfo,
    photographerInfo,
    senderType,
}: any) => {
    if (isClientActive) {
        let userImg = clientInfo.picUrl ? clientInfo.picUrl : USER_IMG;
        if (parseInt(senderType) === 1) {
            return (
                <RightChatContainer sx={{ marginTop: sameAsPreviousSender ? '10px' : '16px' }}>
                    <Box sx={styles.chatBox}>
                        <Typography sx={styles.messageText}>{message}</Typography>
                        <Typography sx={styles.timeStampStyle}>
                            {getUnixFormattedDate(messageTime, 'hh:mm A')}
                        </Typography>
                    </Box>
                    <img
                        src={userImg}
                        alt='user-img'
                        style={{
                            ...styles.user_img,
                            marginLeft: '20px',
                            marginRight: '0px',
                        }}></img>
                </RightChatContainer>
            );
        } else {
            return (
                <LeftChatContainer sx={{ marginTop: sameAsPreviousSender ? '10px' : '18px' }}>
                    <img src={userImg} alt='user-img' style={styles.user_img}></img>
                    <Box sx={styles.chatBox}>
                        <Typography sx={styles.messageText}>{message}</Typography>
                        <Typography sx={styles.timeStampStyle}>
                            {getUnixFormattedDate(messageTime, 'hh:mm A')}
                        </Typography>
                    </Box>
                </LeftChatContainer>
            );
        }
    } else if (isPhotographerActive) {
        let userImg = photographerInfo.picUrl ? photographerInfo.picUrl : USER_IMG;
        if (parseInt(senderType) === 1) {
            return (
                <LeftChatContainer sx={{ marginTop: sameAsPreviousSender ? '10px' : '18px' }}>
                    <img src={userImg} alt='user-img' style={styles.user_img}></img>
                    <Box sx={[styles.chatBox, { background: '#EEF2F6', color: '#2196f3' }]}>
                        <Typography sx={styles.messageText}>{message}</Typography>
                        <Typography sx={styles.timeStampStyle}>
                            {getUnixFormattedDate(messageTime, 'hh:mm A')}
                        </Typography>
                    </Box>
                </LeftChatContainer>
            );
        } else {
            return (
                <RightChatContainer sx={{ marginTop: sameAsPreviousSender ? '10px' : '16px' }}>
                    <Box sx={[styles.chatBox, { background: '#EEF2F6', color: '#2196f3' }]}>
                        <Typography sx={styles.messageText}>{message}</Typography>
                        <Typography sx={styles.timeStampStyle}>
                            {getUnixFormattedDate(messageTime, 'hh:mm A')}
                        </Typography>
                    </Box>
                    <img
                        src={userImg}
                        alt='user-img'
                        style={{
                            ...styles.user_img,
                            marginLeft: '20px',
                            marginRight: '0px',
                        }}></img>
                </RightChatContainer>
            );
        }
    }

    return null;
};
export default Message;
