import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';

const NoInternetConnectionMemoised = () => {
    const [isOnline, setOnline] = useState(true);

    useEffect(() => {
        if (!isOnline) {
            toast('Please check your internet connection.', {
                type: 'info',
                theme: 'colored',
                position: 'top-right',
                toastId: 'internet-toast',
            });
        }
    }, [isOnline]);

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine);
    }, []);

    // event listeners to update the state
    window.addEventListener('online', () => {
        setOnline(true);
    });

    window.addEventListener('offline', () => {
        setOnline(false);
    });

    return <></>;
};

const NoInternetConnection = React.memo(NoInternetConnectionMemoised);
export default NoInternetConnection;
