import { ENDPOINTS } from 'utils/endpoints';
import { DELETE_API_CALL, GET_API_CALL, PATCH_API_CALL, POST_API_CALL } from 'utils/services';
import { APIResponseModal } from 'utils/modals';
import {
    UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER,
    UPDATE_RATING_AND_REVIEW_REDUCER,
} from 'store/actionTypes';
import SHOW_TOAST from 'utils/showToast';
import dayjs from 'dayjs';

/**
 *
 * @returns Rating and Review listing for photographer
 */
export const getRatingAndReviewListing = () => {
    return (dispatch: Function, getState: Function) => {
        const { page, limit, search, sortedBy, sortOrder, toDate, fromDate, ratingRangeFilter } =
            getState().RatingAndReviewReducer;

        let parameters = '?';

        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;
        if (fromDate) parameters += `fromDate=${dayjs(fromDate).format('YYYY-MM-DD')}&`;
        if (toDate) parameters += `toDate=${dayjs(toDate).format('YYYY-MM-DD')}&`;
        if (ratingRangeFilter?.value?.length > 0)
            parameters += `rating=${ratingRangeFilter.value}&`;

        dispatch({
            type: UPDATE_RATING_AND_REVIEW_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.RATING_AND_REVIEW_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_RATING_AND_REVIEW_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_RATING_AND_REVIEW_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param ratingId of specific rating
 * @param callback to exicute in response of API either in success or failure
 * @returns will return details of specific rating
 */

export const getRatingAndReviewDetail = (ratingId: string, callback: Function) => {
    return (dispatch: Function) => {
        GET_API_CALL(
            ENDPOINTS.RATING_AND_REVIEW_MANAGEMENT.DETAIL,
            `${ratingId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER,
                        payload: response.data,
                    });
                }
                callback(response);
            },
        );
    };
};

/**
 *
 * @param request will be payload of adding a new rating
 * @param callback to exicute in response of API either in success or failure
 * @returns Success or failure of adding a new rating
 */
export const addNewRating = (request: any, callback: Function) => {
    return (dispatch: Function) => {
        dispatch({
            type: UPDATE_RATING_AND_REVIEW_REDUCER,
            payload: { loader: true },
        });
        POST_API_CALL(
            ENDPOINTS.RATING_AND_REVIEW_MANAGEMENT.ADD_RATING_REVIEW,
            request,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                callback(response);
            },
        );
    };
};

/**
 *
 * @param params will contain feature type 1 and booles for rating on/off
 * @returns Success or failure of rating on/off
 */
export const ratingOnOff = (params: any) => {
    return (dispatch: Function) => {
        POST_API_CALL(
            `${ENDPOINTS.RATING_AND_REVIEW_MANAGEMENT.RATING_ON_OFF}`,
            params,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                dispatch({
                    type: UPDATE_RATING_AND_REVIEW_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param ratingId will be id of rating need's to remove
 * @param callBack will be the function want's to run after API success
 * @returns Response of API can be succes/failure
 */
export const removeRatingReviews = (ratingId: string, callBack: Function) => {
    return () => {
        DELETE_API_CALL(
            `${ENDPOINTS.RATING_AND_REVIEW_MANAGEMENT.DELETE}?ratingId=${ratingId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                    callBack();
                }
            },
        );
    };
};

/**
 *
 * @param data will be id of rating need's to hide
 * @param callback will be the function want's to run after API success
 * @returns Response of API can be succes/failure
 */
export const hideRating = (data: any, callback: Function) => {
    return () => {
        const dataToSend = {
            ratingId: data.ratingId,
            isHide: data.isHide,
        };
        PATCH_API_CALL(
            ENDPOINTS.RATING_AND_REVIEW_MANAGEMENT.HIDE_RATING_REVIEW,
            dataToSend,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                callback();
            },
        );
    };
};
