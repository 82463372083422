import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, Pagination, Navigation, Keyboard } from 'swiper';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { GalleryDataModal } from 'utils/modals';
import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';

type SwiperWrapperTypes = {
    data: GalleryDataModal[];
    imageId: number;
};

function SwiperWrapper({ data, imageId }: SwiperWrapperTypes) {
    const swiperRef: any = useRef(null);
    const [changedIndex, setChangedIndex] = useState(imageId);

    return (
        <Swiper
            ref={swiperRef}
            color={'white'}
            initialSlide={imageId}
            style={{ width: '100%', height: '100%' }}
            zoom
            navigation
            pagination={{
                clickable: true,
            }}
            keyboard={{
                enabled: true,
            }}
            modules={[Zoom, Pagination, Navigation, Keyboard]}
            onSlideChange={() => setChangedIndex(swiperRef?.current?.swiper?.activeIndex || 0)}>
            {data.map(({ fileUrl, mimeType, _id }, i) => {
                const isImage = mimeType.indexOf('image') > -1;
                return (
                    <SwiperSlide key={_id}>
                        {isImage ? (
                            <img
                                src={fileUrl}
                                alt={mimeType}
                                height='100%'
                                style={{ margin: '0 auto', display: 'block' }}
                            />
                        ) : (
                            <ReactPlayer
                                controls
                                url={fileUrl}
                                width={'100%'}
                                height={'100%'}
                                playing={i === changedIndex}
                            />
                        )}
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

export default SwiperWrapper;
