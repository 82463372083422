import { Box, TablePagination, Typography, styled } from '@mui/material';
import PackagesSkeleton from './bookingSkeleton';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import { styles } from './styles';
import { ReducersModal } from 'utils/modals';
import { useDispatch, useSelector } from 'react-redux';
import { getClientBookingMadeListing } from 'modules/clientManagement/action';
import { UPDATE_CLIENT_BOOKING_MADE_REDUCER } from 'store/actionTypes';
import { LoadingButton } from '@mui/lab';
import CALENDER_IMG from 'assets/images/calendar.png';
import {
    BOOKING_STATUS,
    BOOKING_STATUS_BORDER,
    BOOKING_STATUS_CSS,
    BOOKING_STATUS_FONT,
} from 'utils/enum';
import { getFormattedDate } from 'utils/constants';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '20px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    padding: '18px 32px 5px 32px',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
    cursor: 'pointer',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '550px',
    overflowY: 'auto',
    paddingRight: '18px',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
}));

const NoDataContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ClientBookingMade = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const { result, loader, page, limit, total } = useSelector(
        (state: ReducersModal) => state.ClientVendorBookingMadeReducer,
    );

    const clientId = new URLSearchParams(window.location.search).get('id');

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
            payload,
        });
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatchValue({ page: newPage + 1 });
        dispatch(getClientBookingMadeListing());
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        dispatchValue({ limit: event.target.value, page: 1 });
        dispatch(getClientBookingMadeListing());
    };

    useEffect(() => {
        dispatchValue({ clientId: clientId });
        dispatch(getClientBookingMadeListing());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderStatusTag = (status: number) => {
        return (
            <Box
                sx={{
                    background: BOOKING_STATUS_CSS[status],
                    border: BOOKING_STATUS_BORDER[status],
                    ...styles.bm_status_tag,
                }}>
                <Typography
                    variant='h3'
                    component={'h3'}
                    sx={{
                        fontWeight: 500,
                        fontSize: '18px',
                        color: BOOKING_STATUS_FONT[status],
                    }}>
                    {BOOKING_STATUS[status]}
                </Typography>
            </Box>
        );
    };

    const renderInstantBookingTag = (bookingType: number) => {
        let textToShow = 'Instant Booking';
        if (bookingType && bookingType === 2) {
            return (
                <LoadingButton sx={styles.instant_tag} loadingPosition='center' variant='outlined'>
                    {textToShow}
                </LoadingButton>
            );
        }
        return null;
    };

    const renderCalenderImg = () => {
        return (
            <Box sx={{ height: '20px', width: '20px', marginRight: '10px' }}>
                <img
                    src={CALENDER_IMG}
                    alt='calender-img'
                    style={{ height: '18px', width: '18px' }}></img>
            </Box>
        );
    };

    const renderHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '200px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderValue = (value: string | number) => {
        return (
            <Typography
                variant='h4'
                component={'h4'}
                sx={{ ...styles.value_text, maxWidth: '450px' }}>
                {value}
            </Typography>
        );
    };

    const renderLocation = (val: any) => {
        if (val?.street || val?.city || val?.postalCode || val?.country) {
            return (
                <Typography
                    variant='h4'
                    component={'h4'}
                    sx={{ ...styles.value_text, maxWidth: '320px' }}>
                    {`${val?.street}${val?.street && val?.city && ','}  ${val?.city} ${val?.country && val?.city && ','
                        } ${val?.country}${val?.postalCode && val?.country && ','}  ${val?.postalCode}`}
                </Typography>
            );
        }
        return (
            <Typography
                variant='h4'
                component={'h4'}
                sx={{ ...styles.value_text, maxWidth: '450px' }}>
                {'N/A'}
            </Typography>
        );
    };

    const renderPackages = () => {
        if (result.length === 0 && !loader) {
            return (
                <NoDataContainer>
                    <Typography
                        sx={{ color: '#673ab7', marginTop: '180px' }}
                        variant='h2'
                        component='h2'>
                        {'No Booking Exists.'}
                    </Typography>
                </NoDataContainer>
            );
        }
        return (
            <React.Fragment>
                <TableContainer sx={{ display: 'block' }}>
                    {result.map((item) => {
                        // const location = item?.clientDetails?.location;
                        const bookingLocation = item?.bookingDetails?.shootLocation;
                        return (
                            <ItemContainer
                                key={item._id}
                                onClick={() => {
                                    navigate(
                                        `${ROUTE_NAMES.MAIN_ROUTES.CLIENT_MANAGEMENT_BOOKING_DETAIL}?id=${item._id}`,
                                    );
                                }}>
                                <LineContainer>
                                    {renderHeading('Booking Ref')}
                                    {renderValue(item?.bookingRef)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Booking Location')}
                                    {renderLocation(bookingLocation)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Package Selected')}
                                    {renderValue(item?.bookingDetails?.package?.name || 'N/A')}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Photographer ')}
                                    {renderValue(item?.vendorDetails?.fullName || 'N/A')}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Pricing')}
                                    {renderValue(`${'$'}${item?.bookingDetails?.amount || '0'}`)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Date  and Time')}
                                    {renderCalenderImg()}
                                    {renderValue(
                                        item?.startTime
                                            ? `${getFormattedDate(
                                                item?.startTime,
                                                'MM/DD/YYYY, hh:mm A',
                                            )} ${' - '} ${getFormattedDate(
                                                item.endTime,
                                                'hh:mm A',
                                            )}`
                                            : 'N/A',
                                    )}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Category')}
                                    {renderValue(item?.bookingDetails?.category?.name || 'N/A')}
                                </LineContainer>
                                <Box sx={styles.tag_cont}>
                                    {renderInstantBookingTag(item?.bookingType)}
                                    {renderStatusTag(item?.status)}
                                </Box>
                            </ItemContainer>
                        );
                    })}
                </TableContainer>
                {result && result.length > 0 && (
                    <Box sx={styles.paginationContainer}>
                        <TablePagination
                            component='div'
                            count={total}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            rowsPerPage={limit}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                )}
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (result.length === 0 && loader) {
            return <PackagesSkeleton />;
        }
        return renderPackages();
    };

    return <Box sx={styles.container}>{renderView()}</Box>;
};

export default ClientBookingMade;
