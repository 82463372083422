import { UPDATE_AUTH } from 'store/actionTypes';
import { ENDPOINTS } from 'utils/endpoints';
import { APIResponseModal } from 'utils/modals';
import { PUT_API_CALL } from 'utils/services';
import SHOW_TOAST from 'utils/showToast';

export const changePassword = (data: any, callback: Function) => {
    return () => {
        PUT_API_CALL(ENDPOINTS.PROFILE.CHANGE_PASSWORD, data, (response: APIResponseModal) => {
            if (response.isSuccess) {
                const { message } = response?.data;
                SHOW_TOAST(message, 'success');
            }
            callback(response);
        });
    };
};
export const updateProfile = (data: any, callback: Function) => {
    return (dispatch: any) => {
        PUT_API_CALL(ENDPOINTS.PROFILE.UPDATE_PROFILE, data, (response: APIResponseModal) => {
            if (response.isSuccess) {
                const { message } = response?.data;
                SHOW_TOAST(message, 'success');
                dispatch({ type: UPDATE_AUTH, payload: { ...data } });
            }
            callback();
        });
    };
};
