import { onValue, ref } from '@firebase/database';
import { db } from '../firebase';
import { UPDATE_FLAGGED_REDUCER } from 'store/actionTypes';
import moment from 'moment-timezone';
export const DRAWER_WIDTH = 260;
export const APP_DRAWER_WIDTH = 320;
export const GRID_SPACING = 3;

export const formatResult = (result: any[]) => {
    let formatedResult: any[] = []
    result.map((data: any) => {
        const { date } = data;
        data['date'] = ((new Date(date)).toLocaleDateString('en-US'))
        formatedResult.push(data)
        return null;
    })
    return formatedResult;
}

export const getFormattedDate = (date: any, format: any) => {
    const d = new Date(date);

    return moment(d).tz('America/New_York').format(format);
};

export const getUnixFormattedDate = (timestamp: any, format: any) => {
    return moment.unix(timestamp).tz('America/New_York').format(format);
};

/**
 * function to calculate age from dob string
 * @param dob
 */
export const calculateAge = (dob: string) => {
    var diff_ms = Date.now() - moment(dob).toDate().getTime();
    var age_dt = new Date(diff_ms);

    return `${Math.abs(age_dt.getUTCFullYear() - 1970)} Years`;
};

/**
 *
 * @param number to convert in compact number
 * @returns will return compacted number
 */
export const DataFormater = (number: number) => {
    if (Number.isInteger(number)) {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(2).toString() + 'B';
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(2).toString() + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(2).toString() + 'K';
        } else {
            return number.toFixed(2).toString();
        }
    } else {
        if (number >= 1000000000) {
            return (number / 1000000000).toFixed(2).toString() + 'B';
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(2).toString() + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(2).toString() + 'K';
        } else {
            return number.toFixed(2).toString();
        }
    }
};

export const DataFormaterWithoutDecimal = (number: number) => {
    if (Number.isInteger(number)) {
        if (number >= 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number >= 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    } else {
        if (number >= 1000000000) {
            return (number / 1000000000).toString() + 'B';
        } else if (number >= 1000000) {
            return (number / 1000000).toString() + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toString() + 'K';
        } else {
            return number.toString();
        }
    }
};

export const getFirebaseMessageDetails = (bookingId: string, activeTab: number, dispatch: any) => {
    let messageCopy: any = [];
    let senderMessage: any = [];
    const dbRef = ref(db, 'chatData');
    dispatch({
        type: UPDATE_FLAGGED_REDUCER,
        payload: {
            bookingDetails: {},
            vendorDetails: {},
            clientDetails: {},
            messages: [],
            flaggedMessages: [],
            clientFlaggedCount: 0,
            vendorFlaggedCount: 0,
        },
    });
    onValue(dbRef, (QuerySnapshot) => {
        QuerySnapshot.forEach((doc) => {
            if (doc.key === bookingId) {
                const messageDeepCopy: any = { ...doc.val() };
                const deepCopy = { ...doc.val()?.flaggedMessages };
                const messageBySender: any = {};
                const messageByReceiver: any = {};
                Object.values(deepCopy)?.filter((entry: any, index: number) => {
                    if (entry?.senderType === activeTab) {
                        messageBySender[Object.keys(deepCopy)[index]] = entry;
                    } else {
                        messageByReceiver[Object.keys(deepCopy)[index]] = entry;
                    }
                    return null;
                });
                messageDeepCopy['flaggedMessages'] = messageBySender;
                senderMessage = messageBySender;
                messageCopy = messageDeepCopy;

                dispatch({
                    type: UPDATE_FLAGGED_REDUCER,
                    payload: {
                        bookingDetails: messageCopy?.bookingDetails,
                        vendorDetails: messageCopy?.vendorDetails,
                        clientDetails: messageCopy?.clientDetails,
                        messages: messageCopy?.messages || [],
                        flaggedMessages: Object.values(messageCopy?.flaggedMessages).sort(
                            (a: any, b: any) => {
                                let dateOne: any = b?.createdAt;
                                let dateTwo: any = a?.createdAt;
                                return new Date(dateOne).valueOf() - new Date(dateTwo).valueOf();
                            },
                        ),
                        clientFlaggedCount:
                            activeTab === 1
                                ? Object.keys(messageBySender)?.length
                                : Object.keys(messageByReceiver)?.length,
                        vendorFlaggedCount:
                            activeTab === 1
                                ? Object.keys(messageByReceiver)?.length
                                : Object.keys(messageBySender)?.length,
                    },
                });
            }
        });
    });
    return { messageCopy, senderMessage };
};

export const getFirebaseMessageList = (
    activeTab: number,
    flaggedPage: number,
    flaggedLimit: number,
    dispatch: any,
) => {
    let messageCopy: any = null;
    let senderMessage: any = null;
    const dbRef = ref(db, 'chatData');
    onValue(dbRef, (QuerySnapshot) => {
        let docArr: any = [];
        QuerySnapshot.forEach((doc) => {
            const messageDeepCopy: any = { ...doc.val() };
            const deepCopy = { ...doc.val()?.flaggedMessages };
            const messageBySender: any = {};
            const messageByReceiver: any = {};
            Object.values(deepCopy)?.filter((entry: any, index: number) => {
                if (entry?.senderType === activeTab) {
                    messageBySender[Object.keys(deepCopy)[index]] = entry;
                } else {
                    messageByReceiver[Object.keys(deepCopy)[index]] = entry;
                }
                return null;
            });
            messageDeepCopy['flaggedMessages'] = messageBySender;
            senderMessage = messageBySender;
            messageCopy = messageDeepCopy;
            let arrItem = {
                bookingDetails: messageCopy?.bookingDetails,
                vendorDetails: messageCopy?.vendorDetails,
                clientDetails: messageCopy?.clientDetails,
                messages: messageCopy?.messages,
                flaggedMessages: Object.values(messageCopy?.flaggedMessages).sort(
                    (a: any, b: any) => {
                        let dateOne: any = b?.createdAt;
                        let dateTwo: any = a?.createdAt;
                        return new Date(dateOne).valueOf() - new Date(dateTwo).valueOf();
                    },
                ),
                clientFlaggedCount:
                    activeTab === 1
                        ? Object.keys(messageBySender)?.length
                        : Object.keys(messageByReceiver)?.length,
                vendorFlaggedCount:
                    activeTab === 1
                        ? Object.keys(messageByReceiver)?.length
                        : Object.keys(messageBySender)?.length,
                chatMeta: messageCopy?.chatMeta,
            };
            if (Object.keys(messageCopy?.flaggedMessages).length > 0) {
                docArr.push(arrItem);
            }
        });
        if (docArr?.length > 0) {
            docArr.sort((a: any, b: any) => {
                let messageObjOne: any = Object.values(b?.flaggedMessages)[0];
                let dateOne: any = messageObjOne?.createdAt;
                let messageObjTwo: any = Object.values(a?.flaggedMessages)[0];
                let dateTwo: any = messageObjTwo?.createdAt;
                return new Date(dateOne).valueOf() - new Date(dateTwo).valueOf();
            });
        }

        dispatch({
            type: UPDATE_FLAGGED_REDUCER,
            payload: {
                flaggedListDataArray: docArr,
                total: docArr.length,
                loader: false,
            },
        });
    });
    return { messageCopy, senderMessage };
};
