import { styles } from '../styles';
import { Box, styled } from '@mui/material';
import Chart from '../graphRepresentation/chart';
import DurationFilter from '../durationFilter';
const AverageShootCost = ({
    filteredAvrShootCost,
    avrShootCostState,
    onDurationFilter,
    averageCost,
}: any) => {
    const ItemContainer = styled('div')(() => ({
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #000000;',
        padding: '20px',
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        position: 'relative',
    }));
    const xDataKey = '_id';
    const yDataKey = 'count';

    return (
        <ItemContainer sx={{ width: '48%' }}>
            <Box sx={styles.reportAndAnalytics}>
                <Box sx={styles.tabularText}>Average Photoshoot Cost</Box>
                <DurationFilter
                    durationState={avrShootCostState}
                    filterKey={12}
                    onDurationFilter={onDurationFilter}
                />
            </Box>
            <Box sx={styles.chartDimension}>
                <Chart
                    durationState={avrShootCostState}
                    result={filteredAvrShootCost}
                    activeTab={4}
                    xDataKey={xDataKey}
                    yDataKey={yDataKey}
                    yLabel={'Clients'}
                    xLabel={'Photoshoot cost (in $)'}
                />
            </Box>
            <Box sx={[styles.reportAndAnalytics, styles.averageContainer]}>
                <Box sx={[styles.reportAndAnalytics, { width: '80%' }]}>
                    <Box sx={[styles.tabularText, { flex: 'unset' }]}>Average cost</Box>
                    <Box sx={[styles.tabularText, { flex: 'unset' }]}>
                        {averageCost > 0 ? `$${averageCost?.toFixed(2)}` : `$${averageCost}`}
                    </Box>
                </Box>
            </Box>
        </ItemContainer>
    );
};

export default AverageShootCost;
