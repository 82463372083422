import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers';

interface Props {
    label: string;
    format: string;
    onChange: Function;
    value: Dayjs | null;
    width: number;
    disabled?: boolean;
}

export default function BasicTimePicker(props: Props) {
    const { label, format, onChange, value, width, disabled } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <TimePicker
                    sx={{
                        width,
                    }}
                    slotProps={{
                        popper: {
                            sx: {
                                '& .MuiList-root': {
                                    '&::after': {
                                        height: 'unset !important',
                                        content: 'none',
                                    },
                                },
                            },
                        },
                    }}
                    label={label}
                    disabled={disabled}
                    format={format}
                    value={value}
                    onChange={(e) => onChange(e)}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
