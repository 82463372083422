import {
    UPDATE_CLIENT_REDUCER,
    UPDATE_CLIENT_DETAIL_REDUCER,
    UPDATE_CLIENT_BOOKING_MADE_REDUCER,
    UPDATE_CLIENT_BOOKING_DETAIL_REDUCER,
} from 'store/actionTypes';
import {
    ActionType,
    ClientVendorBookingDetailModal,
    ClientVendorBookingMadeModal,
    ClientDetailModal,
    ClientListModal,
} from 'utils/modals';

export const ClientReducer = (
    state: ClientListModal = new ClientListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_CLIENT_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const ClientDetailReducer = (
    state: ClientDetailModal = new ClientDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_CLIENT_DETAIL_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const ClientVendorBookingMadeReducer = (
    state: ClientVendorBookingMadeModal = new ClientVendorBookingMadeModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_CLIENT_BOOKING_MADE_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const ClientVendorBookingDetailReducer = (
    state: ClientVendorBookingDetailModal = new ClientVendorBookingDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_CLIENT_BOOKING_DETAIL_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
