export const styles = {
    searchAndFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
    },
    toogleContainer: {
        width: '40%',
        height: '56px',
        padding: '3px',
        borderRadius: '5px',
        border: '1px solid #673AB7',
    },
    toogleBtn: {
        flex: 1,
        justifyContent: 'flex-start',
        margin: '0px 10px',
        font: 'normal normal 500 16px/18px Roboto',
        cursor: 'pointer',
    },
    paddingCss: {
        padding: '10px 0px',
    },
    btn: {
        flex: 1,
        justifyContent: 'flex-start',
        margin: '0px 10px',
        font: 'normal normal 500 16px/18px Roboto',
        cursor: 'pointer',
        background: `linear-gradient(30.99deg,#0a6fc2 0%, #2196f3 100%)`,
    },
};
