import { Close } from '@mui/icons-material';
import { Box, Modal } from '@mui/material';
import SwiperWrapper from 'components/swiperWrapper/swiperWrapper';
import { GalleryDataModal } from 'utils/modals';
import { styles } from './styles';

interface ImageModalProps {
    onClose: () => void;
    data: GalleryDataModal[];
    imageId: number;
}

export default function ImageModal({ onClose, data, imageId }: ImageModalProps) {
    return (
        <Modal
            open={true}
            aria-labelledby='photographer-image-modal-title'
            aria-describedby='photographer-image-modal-description'
        >
            <Box sx={styles.imageModalContainer}>
                <Close onClick={onClose} sx={styles.closeIcon} />
                <Box sx={styles.swiper}>
                    <SwiperWrapper data={data} imageId={imageId} />
                </Box>
            </Box>
        </Modal>
    );
}
