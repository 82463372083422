import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CALENDER_IMG from 'assets/images/calendar.png';
import { Box, Typography, styled, LinearProgress, Tooltip, Rating } from '@mui/material';
import DetailsSkeleton from './detailsSkeleton';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ReducersModal } from 'utils/modals';
import { getRatingAndReviewDetail } from 'modules/ratingReviewManagement/action';
import USER_IMG from 'assets/images/user-image.png';
import AVERAGE_RATING from 'assets/images/star.png';
import EMPTY_RATING from 'assets/images/empty_star.png';
import ContainedButton from 'components/button/contained-button';
import ROUTE_NAMES from 'routes/routeNames';
import AddReview from '../addReview';
import RANDOM_IMAGE from 'assets/images/image.png';
import { UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER } from 'store/actionTypes';
import { hideRating, removeRatingReviews } from 'modules/ratingReviewManagement/action';
import Loader from 'components/loader';
import BackArrowIcon from 'components/backArrow';
import { getFormattedDate } from 'utils/constants';

const ItemContainer = styled('div')(() => ({
    width: '100%',
    marginBottom: '24px',
    borderRadius: '10px',
    border: '1px solid #000000;',
    backgroundColor: '#ffffff',
    padding: '10px 24px 10px 18px',
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    position: 'relative',
}));

const TableContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '500px',
    overflowY: 'auto',
    paddingRight: '0px',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '17px',
    width: 'inherit',
}));

const RatingAndReviewDetails = () => {
    const {
        vendorDetails,
        statistics,
        list,
        clientDetails,
        bookingDetails,
        showAddReviewPopup,
        isAdminAdded,
    } = useSelector((state: ReducersModal) => state.RatingAndReviewDetailReducer);
    const dispatch: any = useDispatch();
    const navigate: any = useNavigate();

    const [loading, setLoading] = useState(false);
    const ratingArr = [5, 4, 3, 2, 1];
    const ratingId = new URLSearchParams(window.location.search).get('id') || '';

    useLayoutEffect(() => {
        setLoading(true);
        dispatchValue({
            vendorDetails: { _id: '' },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ratingId && ratingId.length > 0) {
            dispatch(
                getRatingAndReviewDetail(ratingId, () => {
                    setLoading(false);
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    /**
     *
     * @param payload will contain showPopup status for add review modal
     */
    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER,
            payload,
        });
    };

    const fetchRatingAndReviewDetail = () => {
        setLoading(true);
        dispatch(
            getRatingAndReviewDetail(ratingId, (data: any) => {
                if (data && data?.data?.list && data?.data?.list.length === 0) {
                    navigate(`${ROUTE_NAMES.MAIN_ROUTES.RATING_AND_REVIEW_MANAGEMENT}`);
                }
                setLoading(false);
            }),
        );
    };

    const onRemoveRatingClick = (ratingId: string) => {
        dispatch(
            removeRatingReviews(ratingId, () => {
                fetchRatingAndReviewDetail();
            }),
        );
    };

    const onHideRatingClick = (ratingId: string, isHide: boolean) => {
        const data = {
            ratingId,
            isHide,
        };
        dispatch(
            hideRating(data, () => {
                fetchRatingAndReviewDetail();
            }),
        );
    };

    const renderHeading = (label: string) => {
        return (
            <Typography variant='h3' component={'h3'} sx={{ width: '187px', fontSize: '18px' }}>
                {label}
            </Typography>
        );
    };

    const renderValue = (value: string | number, address: number) => {
        return (
            <Typography variant='h4' component={'h4'} sx={[styles.textCss, { maxWidth: 'unset' }]}>
                {value}
                {address ? ',' : ''}
            </Typography>
        );
    };

    const renderProfilePic = (profilePic: any) => {
        let imgSrc = USER_IMG;
        if (profilePic) {
            imgSrc = profilePic;
        }
        return (
            <Box sx={[styles.ratingContainer, { marginRight: '20px', alignSelf: 'baseline' }]}>
                <Box sx={styles.profileImgCon} id={'user-img'}>
                    <img src={imgSrc} style={styles.img} alt='User-img' />
                </Box>
            </Box>
        );
    };

    const renderTotalRating = (rating: number) => {
        return (
            <Box sx={[styles.ratingContainer, { width: '100px' }]}>
                <Box sx={styles.RatingImgCon} id={'rating-img'}>
                    <img src={AVERAGE_RATING} style={styles.img} alt='Rating-img' />
                </Box>
                {renderTotalRatingValue(rating)}
            </Box>
        );
    };

    const renderTotalRatingValue = (value: number) => {
        return (
            <Typography variant='h4' component={'h4'} sx={styles.totalRatingText}>
                {value} {value > 1 ? 'ratings' : 'rating'}
            </Typography>
        );
    };

    const renderCalenderImg = () => {
        return (
            <Box sx={{ height: '20px', width: '20px', marginRight: '10px' }}>
                <img
                    src={CALENDER_IMG}
                    alt='calender-img'
                    style={{ height: '18px', width: '18px' }}></img>
            </Box>
        );
    };

    const renderRatingSection = (totalRating: number, averageRating: number) => {
        return (
            <Box sx={[styles.ratingContainer, styles.ratingParentCont]}>
                {renderTotalRating(totalRating)}
                <Typography variant='h3' component={'h3'} sx={styles.averageRating}>
                    {`${averageRating?.toFixed(1)}`}
                </Typography>
            </Box>
        );
    };

    const renderRatingBar = (
        val: number = 0,
        rating: number = 0,
        totalRating: number = 0,
        index: number = 0,
    ) => {
        return (
            <Box sx={styles.ratingBarCont} key={index}>
                <Typography
                    variant='h4'
                    component={'h4'}
                    sx={[styles.textCss, { marginTop: '4px', fontWeight: 500 }]}>
                    {val}
                </Typography>
                <Box sx={{ height: '12px', width: '12px', margin: '0px 0px 4px 5px' }}>
                    <img src={EMPTY_RATING} style={styles.img} alt='Rating-img' />
                </Box>
                <Tooltip title={rating ? rating + (rating > 1 ? ' ratings' : ' rating') : ''}>
                    <LinearProgress
                        variant='determinate'
                        value={(rating * 100) / totalRating}
                        sx={styles.progressBarCss}
                    />
                </Tooltip>
            </Box>
        );
    };

    const renderLocation = (location: any, state: number) => {
        return (
            <LineContainer>
                {renderHeading(state === 1 ? 'Booking Location' : 'Location')}
                <Typography
                    sx={[styles.locationText, { width: 'unset' }]}
                    variant='h4'
                    component={'h4'}>
                    {`${location?.street}${location?.street && location?.city && ','}  ${location?.city
                        } ${location?.country && location?.city && ','} ${location?.country}${location?.postalCode && location?.country && ','
                        }  ${location?.postalCode}`}
                </Typography>
            </LineContainer>
        );
    };

    const renderVendorLocation = (location: any) => {
        return (
            <LineContainer sx={{ width: '550px' }}>
                {renderHeading('Location')}
                <Typography
                    sx={[styles.locationText, { maxWidth: '300px' }]}
                    variant='h4'
                    component={'h4'}>
                    {`${location?.street}${location?.street && location?.city && ','}  ${location?.city
                        } ${location?.country && location?.city && ','} ${location?.country}${location?.postalCode && location?.country && ','
                        }  ${location?.postalCode}`}
                </Typography>
            </LineContainer>
        );
    };

    /**
     *
     * @returns Booking details section against specific rating
     */
    const renderBookingDetails = () => {
        return (
            <React.Fragment>
                <Box sx={styles.headContainer}>
                    <Typography
                        sx={{ color: '#673ab7', marginTop: '24px' }}
                        variant='h3'
                        component='h3'>
                        {'Booking Details'}
                    </Typography>
                </Box>
                <TableContainer sx={{ display: 'block' }}>
                    {bookingDetails && (
                        <ItemContainer key={bookingDetails._id}>
                            <Box>
                                <LineContainer>
                                    {renderHeading('Booking Ref')}
                                    {renderValue(bookingDetails?.bookingRef || "", 0)}
                                </LineContainer>
                                {renderLocation(bookingDetails?.shootLocation, 1)}
                                <LineContainer>
                                    {renderHeading('Package Selected')}
                                    {renderValue(bookingDetails?.package?.name || 'N/A', 0)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Client Name')}
                                    {renderValue(clientDetails?.fullName || 'N/A', 0)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Date  and Time')}
                                    {renderCalenderImg()}
                                    {renderValue(
                                        bookingDetails?.startTime
                                            ? `${getFormattedDate(
                                                bookingDetails?.startTime,
                                                'MM/DD/YYYY, hh:mm A',
                                            )} ${' - '} ${getFormattedDate(
                                                bookingDetails?.endTime,
                                                'hh:mm A',
                                            )}`
                                            : 'N/A',
                                        0,
                                    )}
                                </LineContainer>
                                {renderLocation(vendorDetails?.location, 0)}
                            </Box>
                        </ItemContainer>
                    )}
                </TableContainer>
            </React.Fragment>
        );
    };

    /**
     * @returns rating and review detail against specific rating
     */
    const renderRatingAndReviewSection = () => {
        return (
            <React.Fragment>
                <Box sx={styles.headContainer}>
                    <Typography sx={{ color: '#673ab7' }} variant='h3' component='h3'>
                        {'Rating & Reviews'}
                    </Typography>
                    {!isAdminAdded && (
                        <Box sx={styles.flexContainer}>
                            <ContainedButton
                                onClick={() => {
                                    dispatchValue({ showAddReviewPopup: true });
                                }}
                                externalStyles={{ textTransform: 'inherit' }}
                                title={'Add a Review'}
                            />
                        </Box>
                    )}
                </Box>
                <TableContainer sx={{ display: 'block' }}>
                    {list &&
                        list.length > 0 &&
                        list.map(
                            ({
                                rating,
                                review,
                                images,
                                createdAt,
                                category,
                                clientDetails,
                                adminDetails,
                                _id,
                                isAdminRating,
                                isHide,
                            }) => {
                                return (
                                    <ItemContainer
                                        key={_id}
                                        sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Box sx={[styles.headContainer, { width: '100%' }]}>
                                            <Box sx={styles.flexContainer}>
                                                {renderProfilePic(clientDetails?.profilePic)}
                                                <Box sx={{ display: 'flex', marginLeft: '10px' }}>
                                                    <LineContainer sx={styles.ratingClientText}>
                                                        <Typography
                                                            sx={[
                                                                styles.textCss,
                                                                {
                                                                    maxWidth: '200px',
                                                                    fontWeight: 500,
                                                                    fontSize: '20px',
                                                                },
                                                            ]}>
                                                            {!isAdminRating
                                                                ? clientDetails?.fullName
                                                                : adminDetails?.name || 'N/A'}
                                                        </Typography>
                                                        <Box sx={styles.reviewRatingCont}>
                                                            <Typography
                                                                color={'black'}
                                                                fontSize={'15px'}>
                                                                {rating
                                                                    ? `${rating}${'.0'}`
                                                                    : '0.0'}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    '& > legend': { mt: 2 },
                                                                    marginTop: '2px',
                                                                }}>
                                                                <Rating
                                                                    name='simple-controlled'
                                                                    value={parseInt(rating)}
                                                                    readOnly
                                                                    size='small'
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Typography
                                                            variant='h4'
                                                            component={'h4'}
                                                            sx={[
                                                                styles.textCss,
                                                                {
                                                                    maxWidth: 'unset',
                                                                    marginTop: '8px',
                                                                },
                                                            ]}>
                                                            {createdAt
                                                                ? getFormattedDate(
                                                                    createdAt,
                                                                    'DD MMMM YYYY, hh:mm A',
                                                                )
                                                                : 'N/A'}
                                                        </Typography>
                                                    </LineContainer>
                                                    <LineContainer sx={styles.ratingCategoryText}>
                                                        {renderHeading('Category')}
                                                        <Typography
                                                            variant='h4'
                                                            component={'h4'}
                                                            sx={[
                                                                styles.textCss,
                                                                { marginTop: '10px' },
                                                            ]}>
                                                            {category?.name}
                                                        </Typography>
                                                    </LineContainer>
                                                </Box>
                                            </Box>
                                            <Box sx={[styles.headContainer]}>
                                                <ContainedButton
                                                    onClick={() => {
                                                        onRemoveRatingClick(_id);
                                                    }}
                                                    title={'Remove'}
                                                    externalStyles={{
                                                        width: '120px',
                                                        background: '#2196f3',
                                                        marginRight: '20px',
                                                    }}
                                                />
                                                <ContainedButton
                                                    onClick={() => {
                                                        onHideRatingClick(_id, !isHide);
                                                    }}
                                                    title={isHide ? 'Unhide' : 'Hide'}
                                                    externalStyles={{
                                                        width: '120px',
                                                        background: '#2196f3',
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        {images && images.length > 0 && (
                                            <Box
                                                sx={[styles.flexContainer, { margin: '20px 0px' }]}>
                                                {images.map(({ fileUrl }: any, index: number) => {
                                                    return renderRatingImg(fileUrl, index);
                                                })}
                                            </Box>
                                        )}
                                        <Typography
                                            variant='h4'
                                            component={'h4'}
                                            sx={styles.totalRatingText}>
                                            {review}
                                        </Typography>
                                    </ItemContainer>
                                );
                            },
                        )}
                </TableContainer>
            </React.Fragment>
        );
    };

    /**
     * @returns return rating image section inside rating and review detail
     */
    const renderRatingImg = (image: string, index: number) => {
        let format = image.substring(image.lastIndexOf('.') + 1, image.length),
            imgSrc = RANDOM_IMAGE;
        if (format === 'jpg' || format === 'png' || format === 'jpeg') {
            imgSrc = image;
        }
        return (
            <Box style={styles.input} key={index}>
                <Box sx={styles.reviewImage} id={'user-img'}>
                    <img src={imgSrc} style={styles.img} alt='User-img' />
                </Box>
            </Box>
        );
    };

    const renderDetails = () => {
        return (
            <React.Fragment>
                <Box sx={[styles.headContainer, { alignItems: 'center' }]}>
                    <Typography sx={{ color: '#673ab7' }} variant='h3' component='h3'>
                        {'Photographer Profile Overview'}
                    </Typography>
                    <Box sx={styles.flexContainer}>
                        <ContainedButton
                            onClick={() => {
                                navigate(
                                    `${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL}?id=${vendorDetails?._id}`,
                                );
                            }}
                            title={'View Profile'}
                        />
                    </Box>
                </Box>
                <TableContainer sx={{ display: 'block' }}>
                    {vendorDetails && (
                        <ItemContainer key={vendorDetails._id}>
                            {renderProfilePic(vendorDetails?.profilePic)}
                            <Box sx={{ width: '479px' }}>
                                <LineContainer>
                                    {renderHeading('Photographer Name')}
                                    {renderValue(vendorDetails?.fullName, 0)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Photographer Email')}
                                    {renderValue(vendorDetails?.email, 0)}
                                </LineContainer>
                                <LineContainer>
                                    {renderHeading('Phone Number')}
                                    {renderValue(vendorDetails?.mobileNo, 0)}
                                </LineContainer>
                                {renderVendorLocation(vendorDetails?.location)}
                            </Box>
                            {renderRatingSection(
                                statistics?.totalRatings,
                                statistics?.averageRatings,
                            )}
                            <Box sx={styles.ratingContainer}>
                                {statistics &&
                                    statistics?.ratingStatistics &&
                                    ratingArr.map((val: number, index: number) => {
                                        return renderRatingBar(
                                            val,
                                            statistics?.ratingStatistics[val?.toString()],
                                            statistics?.totalRatings,
                                            index,
                                        );
                                    })}
                            </Box>
                        </ItemContainer>
                    )}
                </TableContainer>
                {renderBookingDetails()}
                {renderRatingAndReviewSection()}
                {showAddReviewPopup && (
                    <AddReview
                        ratingId={ratingId}
                        bookingId={bookingDetails?.bookingId}
                        setLoading={setLoading}
                    />
                )}
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (loading && !vendorDetails?._id) {
            return <DetailsSkeleton />;
        }
        return renderDetails();
    };

    return (
        <React.Fragment>
            <BackArrowIcon />
            <Box sx={styles.paper}>
                <Box sx={styles.container}>
                    {renderView()}
                    {loading && vendorDetails?._id && <Loader />}
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default RatingAndReviewDetails;
