import { Dayjs } from 'dayjs';
export type ActionType = {
    type: string;
    payload: any;
};

export class AuthModal {
    name: string = '';
    title: string = '';
    token: string = '';
    email: string = '';
    adminId: string = '';
    mobileNo: string = '';
    isSessionValid: boolean = false;
}

export class LoginFormModal {
    email: string = '';
    password: string = '';
}

export class OtpFormModal {
    otp: string = '';
}

export class ResetPasswordModal {
    NewPassword: string = '';
    ConfirmPassword: string = '';
}
export class ChangePasswordModal {
    oldPassword: string = '';
    newPassword: string = '';
    confirmPassword: string = '';
}

export class ForgotPasswordModal {
    email: string = '';
}

export class AccountSettingsModal {
    userName: string = 'Test';
    title: string = 'Mr.';
    email: string = 'mailto:abc@yopmail.com';
    mobileNumber: string = '8978877667';
}

export type APIResponseModal = {
    data: any;
    isSuccess: boolean;
};

// Client_Management
export class ClientListModal {
    total: number = 0;
    next: boolean = false;
    page: number = 1;
    limit: number = 10;
    result: ClientData[] = [];
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    fromDate: string = '';
    toDate: string = '';
    selectedValues: string[] = [];
    loader: boolean = false;
    showBlockPopup: boolean = false;
    toBlockId: string = '';
    status: number = 5;
    blockedStatus: number = 0;
    clientStatus: number = 5;
    type: number = 0;
    reasonData: string[] = [];
    cityFilter: CityZipCodeItemModal[] = [];
    zipCodeFilter: CityZipCodeItemModal[] = [];
    isVerified: string = '';
}

export class ClientData {
    _id: string = '';
    email: string = '';
    mobileNo?: string = '';
    firstName: string = '';
    lastName: string = '';
    gender: string = '';
    status: number = 0;
    blockedStatus: number = 2;
    createdAt?: string = '';
    bookingsCount: number = 0;
    location: ratingLocationData = new ratingLocationData();
}

export class ClientDetailModal {
    clientId: string = '';
    firstName: string = '';
    lastName: string = '';
    fullName: string = '';
    mobileNo: string = '';
    email: string = '';
    dob: string = '';
    status: number = 0;
    blockedStatus: number = 0;
    gender: string = '';
    createdAt?: string = '';
    totalBookings: number = 0;
    totalAcceptedBookings: number = 0;
    declinedBookings: number = 0;
    referralAmount: number = 0;
    cancelledBookings: number = 0;
    totalCompletedBookings: number = 0;
    location: ratingLocationData = new ratingLocationData();
    loader = false;
    profilePic: string = '';
}

export class BookingDetailModal {
    date: string = '';
    amount: number = 0;
    shootLocation: ratingLocationData = new ratingLocationData();
    bookingId: string = '';
    addOnServices: [] = [];
    cancellationPolicy: CancellationPolicy = new CancellationPolicy();
    package: PackageDataModal = new PackageDataModal();
    category: CategoriesDataModal = new CategoriesDataModal();
}

export class ClientVendorBookingMadeModal {
    _id: string = '';
    total: number = 0;
    next: boolean = false;
    loader = false;
    page: number = 1;
    result: ClientVendorBookingMadeModal[] = [];
    limit: number = 10;
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    clientId: string = '';
    pricing: number = 0;
    location: string = '';
    category: string = '';
    status: number = 1;
    startTime: string = '';
    endTime: string = '';
    bookingRef: string = "";
    bookingType: number = 2;
    bookingStatusFilter: string[] = [];
    bookingTypeFilter: string[] = [];
    bookingStatusValueFilter: string[] = [];
    addOnServices: string[] = [];
    clientDetails: ClientDetailModal = new ClientDetailModal();
    vendorDetails: VendorDetailModal = new VendorDetailModal();
    bookingDetails: BookingDetailModal = new BookingDetailModal();
    vendorLocation: VendorLocation = new VendorLocation();
}

export class CancellationPolicy {
    title: string = '';
    id: number = 0;
    refund: number = 0;
    time: string = '';
    details: string = '';
    expiry: string = '';
}

export class Category {
    name: string = '';
    categoryId: string = '';
    image: string = '';
    icon: string = '';
}

export class ClientPackageData {
    name: string = '';
    description: string = '';
    packageHours: string = '';
    editedPhotosCount: number = 0;
    editedVideosCount: number = 0;
    videoLength: string = '';
    price: number = 0;
    isAssistant: boolean = false;
}

export class VendorLocation {
    street: string = '';
    city: string = '';
    postalCode: string = '';
    country: string = '';
}

export class ClientLocation {
    street: string = '';
    city: string = '';
    postalCode: string = '';
    country: string = '';
}

export class ShootLocation {
    street: string = '';
    city: string = '';
    postalCode: string = '';
    country: string = '';
}

export class ClientVendorBookingDetailModal {
    _id: string = '';
    clientId: string = '';
    vendorId: string = '';
    bookingRef: string = '';
    description: string = '';
    startTime: string = '';
    endTime: string = '';
    status: number = 1;
    bookingType: number = 1;
    shootLocationType: number = 1;
    date: string = '';
    amount: number = 0;
    createdAt: string = '';
    updatedAt: string = '';
    transactionId: string = '';
    cancellationDetails: CancellationDetails = new CancellationDetails();
    bookingDetails: BookingDetailModal = new BookingDetailModal();
    transactionData: TransactionData = new TransactionData();
    tipPaymentDetails: TipPaymentDetails = new TipPaymentDetails();
    paymentDetails: PaymentDetails = new PaymentDetails();
    album: BookingAlbumData = new BookingAlbumData();
    ratingReviews: RatingAndReviewData[] = [];
}

export class CancellationDetails {
    clientId: string = '';
    vendorId: string = '';
    date: string = '';
}

export class PaymentDetails {
    paid: number = 0;
    paidOn: string = '';
    paymentMethod: string[] = [];
}

export class TransactionData {
    _id: string = '';
    clientId: string = '';
    vendorId: string = '';
    bookingId: string = '';
    amount: number = 0;
    status: number = 0;
    paymentMethodTypes: string[] = [];
    paymentDetails: HoldPaymentDetails = new HoldPaymentDetails();
    orderCalculations: OrderCalculationModal = new OrderCalculationModal();
    createdAt: string = '';
    updatedAt: string = '';
}

export class MetaData {
    bookingDate: string = '';
    bookingRef: string = '';
    bookingType: string = '';
    slotEndTime: string = '';
    slotStartTime: number = 0;
    vendorId: number = 0;
}

export class PaymentMethodOptions {
    bookingDate: string = '';
    bookingRef: string = '';
    bookingType: string = '';
    slotEndTime: string = '';
    slotStartTime: number = 0;
    vendorId: number = 0;
}

export class TipPaymentDetails {
    date: string = '';
    amount: number = 0;
    createdAt: string = '';
}

export class BookingAlbumData {
    title: string = '';
    dropboxLink1: string = '';
    dropboxLink2: string = '';
}

export class AdminDetails {
    name: string = '';
    _id: string = '';
}

export class HoldPaymentDetails {
    id: string = '';
    object: string = '';
    amount: number = 0;
    amount_capturable: number = 0;
    amount_received: number = 0;
    application: any;
    application_fee_amount: any;
    automatic_payment_methods: any;
    canceled_at: any;
    cancellation_reason: any;
    capture_method: string = '';
    client_secret: string = '';
    confirmation_method: string = '';
    created: number = 0;
    currency: string = '';
    customer: string = '';
    description: string = '';
    invoice: any;
    last_payment_error: any;
    latest_charge: any;
    livemode: boolean = false;
    metaData: MetaData = new MetaData();
    next_action: any;
    on_behalf_of: any;
    payment_method: any;
    processing: any;
    receipt_email: any;
    review: any;
    setup_future_usage: any;
    shipping: any;
    source: any;
    payment_intent?: any;
    statement_descriptor: any;
    statement_descriptor_suffix: any;
    status: string = '';
    transfer_data: any;
    transfer_group: any;
}

export class StatusFilterModal {
    label: string = '';
    value: string = '';
}

// Photographer_Management
export class PhotographerListModal {
    total: number = 0;
    next: boolean = false;
    page: number = 1;
    limit: number = 10;
    result: PhotographerData[] = [];
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    fromDate: string = '';
    toDate: string = '';
    selectedValues: string[] = [];
    loader = false;
    blockedStatus: number = 0;
    showBlockPopup: boolean = false;
    showBlockConfirmPopup: boolean = false;
    toBlockId: string = '';
    status: number = 5;
    vendorStatus: number = 5;
    type: number = 0;
    reasonData: string[] = [];
    statusFilter: StatusFilterModal | null = null;
    cityFilter: CityZipCodeItemModal[] = [];
    zipCodeFilter: CityZipCodeItemModal[] = [];
}
export class PhotographerData {
    _id: string = '';
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    gender: string = '';
    status: number = 0;
    blockedStatus: number = 2;
    createdAt?: string = '';
    isTopVendor: boolean = false;
    isSpecialVendor: boolean = false;
    bookingsCount: number = 0;
    isVerified: number = 1;
    mobileNo?: string = '';
    gallery: any[] = [];
    location: ratingLocationData = new ratingLocationData();
}

export class GalleryDataModal {
    _id: string = '';
    index: number = 1;
    fileUrl: string = '';
    mimeType: string = '';
}

export class RatesDataModal {
    hourlyRate: number = 0;
    editPhotoCount: number = 0;
    instantBookingRate: number = 0;
}

export class CategoriesDataModal {
    name: string = '';
    categoryId: string = '';
    image: string = '';
    icon: string = '';
}

export class AlbumDataModal {
    _id: string = '';
    title: string = '';
    thumbnailUrl: string = '';
    selected?: boolean = false;
}

export class PhotographerDetailModal {
    photographerId: string = '';
    fullName: string = '';
    mobileNo: string = '';
    email: string = '';
    location: ratingLocationData = new ratingLocationData();
    vendorType: number = 0;
    cancellationPolicy: CancellationPolicy = new CancellationPolicy();
    creatorType: number = 0;
    governmentDocuments: string[] = [];
    govtId: string[] = [];
    gallery: GalleryDataModal[] = [];
    totalBookings: number = 0;
    dob: string = '';
    bio: string = '';
    gender: string = '';
    totalCompletedBookings: number = 0;
    totalAcceptedBookings: number = 0;
    declinedBookings: number = 0;
    cancelledBookings: number = 0;
    totalEarnings: number = 0;
    referralAmount: number = 0;
    status: number = 0;
    policy: number = 0;
    blockedStatus: number = 0;
    showRejectPopup: boolean = false;
    isTopVendor: boolean = false;
    isSpecialVendor: boolean = false;
    perviousSpecialVendor: boolean = false;
    instantService: boolean = false;
    reasonData: string[] = [];
    availabilities: string[] = [];
    rejectionLogs: string[] = [];
    addOnServices: string[] = [];
    albums: AlbumDataModal[] = [];
    categories: CategoriesDataModal[] = [];
    deviceType: number[] = [];
    type: number = 0;
    isVerified: number = 1;
    rates: RatesDataModal = new RatesDataModal();
    profilePic: string = '';
    governmentId: string = '';
    stripeIdentity: StripeIdentity = new StripeIdentity();
}

export class StripeIdentity {
    status: string = '';
}

export class locationData {
    street: string = '';
    city: string = '';
    postalCode: string = '';
    country: string = '';
}

export class ratingLocationData {
    street: string = '';
    city: string = '';
    postalCode: string = '';
    country: string = '';
    coordinates: number[] = [];
}
//category_Management
export class CategoryListModal {
    total: number = 0;
    next: boolean = false;
    page: number = 1;
    limit: number = 10;
    result: CategoryData[] = [];
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    selectedValues: string[] = [];
    loader = false;
}

export class ReportsAndAnalyticsDataModal {
    clientCount: number = 0;
    photographerCount: number = 0;
    type: number | null = null;
    month: string = '';
    createdAt: string = '';
}

export class AverageCommonModal {
    _id: string = '';
    count: number = 0;
}
export class PopularCategoryModal {
    categoryId: string = '';
    name: string = '';
    icon: string = '';
    image: string = '';
}
export class MostPopularCategoryModal {
    _id: string = '';
    category: PopularCategoryModal = new PopularCategoryModal();
    amount: number = 0;
    bookingsCount: number = 0;
}

export class InstantVsScheduleEarningModal {
    bookingsCount: number = 0;
    bookingType: number = 0;
    amount: number = 0;
}

export class PhotographyVsVideographyModal {
    bookingsCount: number = 0;
    creatorType: number = 0;
    amount: number = 0;
}
export class topActiveClientModal {
    clientData: vendorData = new vendorData();
    amount: number = 0;
    bookingsCount: number = 0;
}

export class MostBookedPhotographerModal {
    _id: string = '';
    fullName: string = '';
    profilePic: string = '';
    amount: number = 0;
    bookingsCount: number = 0;
}

export class MostBookedLocationModal {
    city: string = '';
    vendorCount: number = 0;
    amount: number = 0;
}
export class MostBookedCategoryModal {
    category: string = '';
    amount: number = 0;
    bookingsCount: number = 0;
}
export class TopRatedPhotographerModal {
    vendorName: string = '';
    vendorProfilePic: string = '';
    averageRatings: number = 0;
    ratedBy: number = 0;
}

export class BookingVsTipsEarningModal {
    tip: number = 0;
    amount: number = 0;
    month: string = '';
}

export class TotalBookingVsTipsModal {
    createdAt: string = '';
    bookingCount: number = 0;
    tipCount: number = 0;
    month: string = '';
}

export class ReportsAndAnalyticsModal {
    avrShootCost: AverageCommonModal[] = [];
    avrHourlyRate: AverageCommonModal[] = [];
    avrShootDuration: AverageCommonModal[] = [];
    activeClient: ReportsAndAnalyticsDataModal[] = [];
    activeAllUsers: ReportsAndAnalyticsDataModal[] = [];
    activePhotographer: ReportsAndAnalyticsDataModal[] = [];
    mostPopularCategory: MostPopularCategoryModal[] = [];
    instantVsScheduleEarn: InstantVsScheduleEarningModal[] = [];
    photographyVsVideographyEarn: PhotographyVsVideographyModal[] = [];
    bookingVsTipsEarning: BookingVsTipsEarningModal[] = [];
    mostBookedPhotographer: MostBookedPhotographerModal[] = [];
    mostBookedPriceRange: AverageCommonModal[] = [];
    mostBookedLocation: MostBookedLocationModal[] = [];
    mostBookedCategory: MostPopularCategoryModal[] = [];
    topRatedPhotographer: TopRatedPhotographerModal[] = [];
    topActiveClient: topActiveClientModal[] = [];
    totalBookingVsTipsData: TotalBookingVsTipsModal[] = [];
    averageCost: number = 0;
    averageTip: number = 0;
    averageHourlyCharges: number = 0;
    bookedPriceRange: string = '';
    averageDuration: string = '';
    loader: boolean = false;
    page: number = 1;
    limit: number = 5;
}

export class CategoryData {
    _id: string = '';
    name: string = '';
    icon: string = '';
    image: string = '';
    status: number = 0;
    bookingsCount: number = 0;
    isOpen?: boolean = false;
}

export class NotificationsListModal {
    total: number = 0;
    next: boolean = false;
    page: number = 1;
    limit: number = 10;
    result: NotificationListData[] = [];
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    selectedValues: string[] = [];
    loader = false;
    showDeletePopup: boolean = false;
    toDeleteId: string = '';
    fromDate: Dayjs | null = null;
    toDate: Dayjs | null = null;
    filterStatus: string = '';
}

export class NotificationListData {
    _id: string = '';
    title: string = '';
    date: string = '';
    sentTo: string = '';
    status: number = 0;
}

export class NotificationDetailModal {
    _id: string = '';
    not_idToEdit: string = '';
    senderId: string = '';
    receiverIds: string[] = [];
    image: string = '';
    title: string = '';
    description: string = '';
    notificationType: number = 0;
    recipientType: number = 0;
    isSent: boolean = false;
    status: number = 0;
    createdAt: string = '';
    loader: boolean = false;
    subject: string = '';
    editMode: boolean = false;
}

export class EarnAndTransactionListModal {
    total: number = 0;
    page: number = 1;
    limit: number = 10;
    totalPlandidEarnings?: number = 0;
    totalOrderValue?: number = 0;
    reports: [] = [];
    result: EarnAndTransactionData[] = [];
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    selectedValues: string[] = [];
    loader = false;
    fromDate: Dayjs | null = null;
    toDate: Dayjs | null = null;
    fromRange: number = 0;
    toRange: number = 0;
    filterStatus: StatusFilterModal | null = null;
}

export class OrderCalculationModal {
    totalOrderValue?: number = 0;
    totalAmount?: number = 0;
    tip?: number = 0;
    addOnCost?: number = 0;
    addOnsCost?: number = 0;
    packagesCost?: number = 0;
    hourlyCharges?: number = 0;
    totalStripeCharges?: number = 0;
    vendorEarning?: number = 0;
    referralAmount?: number = 0;
    offerAmount?: number = 0;
    plandidEarning?: number = 0;
    plandidEarnings?: number = 0;
    tips?: number = 0;
    platformCharges?: number = 0;
    serviceCharges?: number = 0;
    totalVendorEarnings?: number = 0;
    shootDuration?: number = 0;
    totalBaseShootCharges?: number = 0;
    totalPlandidEarnings?: number = 0;
    refundAmount?: number = 0;
    penaltyAmount?: number = 0;
}

export class EarnAndTransactionData {
    _id: string = '';
    title: string = '';
    transactionRef: string = '';
    orderCalculations: OrderCalculationModal = new OrderCalculationModal();
    date: string = new Date().toString();
    sentTo: string = '';
    status: number = 1;
    vendorId: string = '';
    vendorFullName: string = '';
    vendorEmail: string = '';
    vendorCreatedAt: string = '';
    vendorLocation: ratingLocationData = new ratingLocationData();
    clientId: string = '';
    clientFullName: string = '';
    clientEmail: string = '';
    clientCreatedAt: string = '';
    clientLocation: ratingLocationData = new ratingLocationData();
}
export class TransactionDataModal {
    _id: string = '';
    updatedAt: string = '';
    createdAt: string = '';
    amount: number = 0;
    clientId: string = '';
    vendorID: string = '';
    orderCalculations: OrderCalculationModal = new OrderCalculationModal();
    bookingId: string = '';
    paymentDetails: any;
    transactionRef: string = '';
    status: number = 0;
}

export class TransactionModal {
    result: TransactionArrayModal[] = [];
}
export class TransactionArrayModal {
    _id: string = '';
    transactionType: number = 0;
    orderCalculations: OrderCalculationModal = new OrderCalculationModal();
    paymentDetails: TransactionPaymentModal = new TransactionPaymentModal();
}

export class TransactionPaymentModal {
    amount: number = 0;
    metadata: TransactionMetadataModal = new TransactionMetadataModal();
}

export class TransactionMetadataModal {
    bookingType?: string = '';
    vendorId?: string = '';
    typeOfPayment?: string = '';
    transactionId?: string = '';
    bookingRef?: string = '';
    bookingDate?: string = '';
    package?: string = '';
    slotEndTime?: string = '';
    editCount?: string = '';
    editCost?: string = '';
    clientId?: string = '';
    transactionRef?: string = '';
    slotStartTime?: string = '';
    packageName?: string = '';
    packageHours?: string = '';
    packagePrice?: string = '';
    requestId?: string = '';
    tipAmount?: string = '';
    addOnRate?: string = '';
    addOnId?: string = '';
    addOnIsHourly?: string = '';
    bookingId?: string = '';
    addOnName?: string = '';
}

export interface AmountDetails {
    tip: Tip;
}

export interface Tip { }

export interface Metadata {
    bookingDate: string;
    bookingRef: string;
    bookingType: string;
    package: string;
    slotEndTime: string;
    slotStartTime: string;
    transactionRef: string;
}
export interface PaymentMethodOptions {
    card: Card;
}

export interface Card {
    installments: any;
    mandate_options: any;
    network: any;
    request_three_d_secure: string;
}
export interface HoldPaymentDetails {
    id: string;
    object: string;
    amount: number;
    amount_capturable: number;
    amount_details: AmountDetails;
    amount_received: number;
    application: any;
    application_fee_amount: any;
    automatic_payment_methods: any;
    canceled_at: any;
    cancellation_reason: any;
    capture_method: string;
    client_secret: string;
    confirmation_method: string;
    created: number;
    currency: string;
    customer: string;
    description: string;
    invoice: any;
    last_payment_error: any;
    latest_charge: any;
    livemode: boolean;
    metadata: Metadata;
    next_action: any;
    on_behalf_of: any;
    payment_method: any;
    payment_method_options: PaymentMethodOptions;
    payment_method_types: string[];
    processing: any;
    receipt_email: any;
    review: any;
    setup_future_usage: any;
    shipping: any;
    source: any;
    statement_descriptor: any;
    statement_descriptor_suffix: any;
    status: string;
    transfer_data: any;
    transfer_group: any;
}
export class EarnAndTransactionDetailModal {
    _id: string = '';
    bookingRef: string = '';
    bookingId: string = '';
    shootLocationType: number = 1;
    status: number = 1;
    description: string = '';
    startTime: string = '';
    endTime: string = '';
    ratingReviews: [] = [];
    paymentDetails: PaymentDetails = new PaymentDetails();
    transactionData: TransactionDataModal = new TransactionDataModal();
    orderCalculations: OrderCalculationModal = new OrderCalculationModal();
    clientDetails: VendorDetailModal = new VendorDetailModal();
    vendorDetails: VendorDetailModal = new VendorDetailModal();
    bookingDetails: BookingDetailModal = new BookingDetailModal();
}
export class ImagesDataModal {
    _id: string = '';
    index: number = 1;
    fileUrl: string = '';
    mimeType: string = '';
}

export class PackageDataModal {
    _id: string = '';
    name: string = '';
    price: number = 0;
    description: string = '';
    videoLength: string = '';
    packageHours: string = '';
    isAssistant: boolean = false;
    editedPhotosCount: number = 0;
    editedVideosCount: number = 0;
}

export class CityZipCodeItemModal {
    city?: string;
    postalCode?: string;
}

// Client_Management
export class RatingAndReviewListModal {
    total: number = 0;
    next: boolean = false;
    page: number = 1;
    limit: number = 10;
    result: RatingAndReviewData[] = [];
    ratingReviewFeature: boolean = true;
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    fromDate: Dayjs | null = null;
    toDate: Dayjs | null = null;
    ratingRangeFilter: StatusFilterModal | null = null;
    selectedValues: string[] = [];
    loader: boolean = false;
    status: number | null = null;
    type: number = 0;
}

export class vendorData {
    _id: string = '';
    fullName: string = '';
    profilePic: string = '';
}

export class RatingAndReviewData {
    _id: string = '';
    bookingId: string = '';
    bookingRef: string = '';
    adminDetails: AdminDetails = new AdminDetails();
    vendorDetails: vendorData = {
        _id: '',
        fullName: '',
        profilePic: '',
    };
    clientDetails: vendorData = new vendorData();
    category: ratingCategoryData = new ratingCategoryData();
    rating: string = '';
    review: string = '';
    images: ImagesDataModal[] = [];
    createdAt?: string = '';
    numOfReviews: number = 0;
    adminId?: string = '';
    isAdminRating: boolean = false;
    isHide?: boolean = false;
    status?: number = 1;
    updatedAt?: string = '';
}

export class RatingAndReviewDetailData {
    totalReviews: number = 0;
    totalRatings: number = 0;
    averageRatings: number = 0;
    _id: string = '';
    ratingStatistics: any = {
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
        '5': 0,
    };
}

export class VendorDetailModal {
    _id: string = '';
    fullName: string = '';
    profilePic: string = '';
    mobileNo: string = '';
    email: string = '';
    location: ratingLocationData = new ratingLocationData();
    categories: ratingCategoryData[] = [];
    createdAt: string = '';
    id: string = '';
}

export class ratingCategoryData {
    name: string = '';
    categoryId: string = '';
}

export class bookingDetailModal {
    _id: string = '';
    date: string = '';
    amount: number = 0;
    shootLocation: ratingLocationData = new ratingLocationData();
    bookingRef: string = '1234';
    bookingId: string = '';
    addOnServices: [] = [];
    cancellationPolicy: CancellationPolicy = new CancellationPolicy();
    package: PackageDataModal = new PackageDataModal();
    category?: CategoriesDataModal = new CategoriesDataModal();
    startTime: string = '';
    endTime: string = '';
}

export class ContentManagementModal {
    PrivacyPolicy: ContentData = {
        _id: '',
        pageId: '',
        url: '',
    };
    Faq: ContentData = {
        _id: '',
        pageId: '',
        url: '',
    };
    Tnc: ContentData = {
        _id: '',
        pageId: '',
        url: '',
    };
    loader: boolean = false;
}

export class ContentData {
    _id: string = '';
    pageId: string = '';
    url: string = '';
}

export class reviewData {
    category: ratingCategoryData = {
        name: '',
        categoryId: '',
    };
    images: ImagesDataModal[] = [];
    createdAt: string = '';
    rating: number = 0;
    review: string = '';
    clientDetails?: VendorDetailModal = new VendorDetailModal();
    adminDetails?: VendorDetailModal = new VendorDetailModal();
}
export class RatingAndReviewDetailModal {
    vendorDetails: VendorDetailModal = new VendorDetailModal();
    statistics: RatingAndReviewDetailData = new RatingAndReviewDetailData();
    bookingDetails: bookingDetailModal = new bookingDetailModal();
    list: RatingAndReviewData[] = [];
    clientDetails?: vendorData = new vendorData();
    adminDetails?: AdminDetails = new AdminDetails();
    showAddReviewPopup: boolean = false;
    isAdminAdded: boolean = false;
}

export class ManageDisputeData {
    _id: string = '';
    reportId: string = '';
    clientDetails: VendorDetailModal = new VendorDetailModal();
    vendorDetails: VendorDetailModal = new VendorDetailModal();
    createdAt: string = '';
    comment: string = '';
    requestStatus: string = '';
    blockStatus: number = 1;
    givenBy: number = 0;
    clientStatus: number = 0;
    vendorStatus: number = 0;
}

export class MessageDetails {
    sender: VendorDetailModal = new VendorDetailModal();
    messageContent: string = '';
    flagStatus: boolean = false;
    createdAt: string = '';
    updatedAt: string = '';
    readFlag: boolean = false;
    senderType: number = 0;
    messageId: string = '';
}

export class FlaggedBookingData {
    bookingId: string = '';
    bookingRef: string = 'PD89';
}

export class FlaggedClientData {
    id: string = '';
    email: string = '';
    fcmToken: string = '';
    fullName: string = '';
    phoneNumber: string = '';
    picUrl: string = '';
}

export class FlaggedAccountNewData {
    bookingDetails: FlaggedBookingData = new FlaggedBookingData();
    vendorDetails: FlaggedClientData = new FlaggedClientData();
    clientDetails: FlaggedClientData = new FlaggedClientData();
    messages: MessageDetails[] = [];
    flaggedMessages: MessageDetails[] = [];
    blockStatus: number = 1;
    chatMeta: any = [];
}

export class ManageDisputeListModal {
    total: number = 0;
    page: number = 1;
    limit: number = 10;
    next: boolean = false;
    result: ManageDisputeListData[] = [];
    grievanceType: number = 1;
    grievances: string[] = [];
    senderType: number = 1;
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    fromDate: Dayjs | null = null;
    toDate: Dayjs | null = null;
    selectedValues: string[] = [];
    loader: boolean = false;
    statusFilter: StatusFilterModal | null = null;
    commentFilter: StatusFilterModal | null = null;
    toBlockId: string = '';
    // flaggedContentResult: FlaggedAccountNewData[] = [];
}

export class FlaggedAccountListDataModal {
    bookingDetails: FlaggedBookingData = new FlaggedBookingData();
    vendorDetails: FlaggedClientData = new FlaggedClientData();
    clientDetails: FlaggedClientData = new FlaggedClientData();
    clientFlaggedCount: number = 0;
    vendorFlaggedCount: number = 0;
    messages: MessageDetails[] = [];
    flaggedMessages: MessageDetails[] = [];
}
export class FlaggedAccountListModal {
    total: number = 0;
    page: number = 1;
    limit: number = 10;
    result: [] = [];
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    selectedValues: string[] = [];
    loader = false;
    flaggedListDataArray: FlaggedAccountListDataModal[] = [];
    bookingDetails: FlaggedBookingData = new FlaggedBookingData();
    vendorDetails: FlaggedClientData = new FlaggedClientData();
    clientDetails: FlaggedClientData = new FlaggedClientData();
    clientFlaggedCount: number = 0;
    vendorFlaggedCount: number = 0;
    messages: MessageDetails[] = [];
    flaggedMessages: MessageDetails[] = [];
    blockStatus: number = 1;
    chatMeta: any = [];
}
export class OffersListModal {
    total: number = 0;
    page: number = 1;
    limit: number = 10;
    offerFeature: boolean = false;
    result: OfferDetailModal[] = [];
    redeemedBy: redeemedByModal[] = [];
    clientsCount: number = 0;
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    selectedValues: string[] = [];
    loader = false;
}
export class OfferDetailModal {
    _id: string = '';
    status: number = 0;
    createdAt: string = '';
    updatedAt: string = '';
    title: string = '';
    value: number = 0;
    count: number = 0;
    total: number = 0;
    benefits: string[] = [];
}

export class redeemedByModal {
    transactionType: number = 0;
    count: number = 0;
}
export class RewardsListModal {
    total: number = 0;
    page: number = 1;
    limit: number = 10;
    result: RewardsDetailsModal[] = [];
    clientsCount: number = 0;
    photographersCount: number = 0;
    usersCount: number = 0;
    redeemedBy: redeemedByModal[] = [];
    search: string = '';
    sortedBy: string = '';
    sortOrder: string = '';
    selectedValues: string[] = [];
    loader = false;
}
export class RewardsDetailsModal {
    _id: string = '';
    status: number = 0;
    createdAt: string = '';
    updatedAt: string = '';
    title: number = 0;
    description: string = '';
    rewardType: number = 0;
    value: number = 0;
    benefits: string[] = [];
    count: number = 0;
    total: number = 0;
    conditions: string[] = [];
    startDate: Dayjs | null = null;
    endDate: Dayjs | null = null;
    isExpiry: boolean = false;
}
export class ManageDisputeListData {
    _id: string = '';
    grievanceRef: string = '';
    vendorDetails: VendorDetailModal = new VendorDetailModal();
    clientDetail: ClientDetailModal = new ClientDetailModal();
    grievances: string[] = [];
    grievanceType: number = 0;
    requestStatus: number = 0;
    refundInitiated: boolean = false;
    givenBy: number = 0;
    createdAt: string = '';
}

export class ManageDisputeDetailModal {
    _id: string = '';
    grievanceRef: string = '';
    clientDetails?: VendorDetailModal = new VendorDetailModal();
    bookingRef: string[] = [];
    bookingId: string = '';
    grievances: number = 0;
    PaymentDetails: HoldPaymentDetails = new HoldPaymentDetails();
    refundInitiated: boolean = false;
    message: string = '';
    title: string = '';
    other: string = '';
    givenBy: number = 1;
    requestStatus: number = 2;
    clientStatus: number = 0;
    vendorStatus: number = 0;
    vendorDetails: VendorDetailModal = new VendorDetailModal();
    bookingDetails: bookingDetailModal = new bookingDetailModal();
    startTime: string = '';
    endTime: string = '';
    paid: string = '';
    paidOn: string = '';
    paymentMethod: string[] = [];
    createdAt: string = '';
    loader: boolean = false;
}
export class ReportedDetailModal {
    _id: string = '';
    createdAt: string = '';
    reportedBy: string = '';
    reportedTo: string = '';
}
export class FlaggedAccountDetailModal {
    _id: string = '';
    flaggedCount: number = 0;
    bookingCount: number = 0;
    bookingRef: string = '';
    bookingId: string = '';
    clientDetails?: VendorDetailModal = new VendorDetailModal();
    vendorDetails: VendorDetailModal = new VendorDetailModal();
    reportedDetails: ReportedDetailModal[] = [
        {
            _id: '',
            createdAt: '',
            reportedBy: '',
            reportedTo: '',
        },
    ];
}

// Reducer Model
export type ReducersModal = {
    AuthReducer: AuthModal;
    ClientReducer: ClientListModal;
    ClientDetailReducer: ClientDetailModal;
    ClientVendorBookingMadeReducer: ClientVendorBookingMadeModal;
    ClientVendorBookingDetailReducer: ClientVendorBookingDetailModal;
    PhotographerBookingMadeReducer: ClientVendorBookingMadeModal;
    PhotographerReducer: PhotographerListModal;
    PhotographerDetailReducer: PhotographerDetailModal;
    CategoryReducer: CategoryListModal;
    ReportsAndAnalyticsReducer: ReportsAndAnalyticsModal;
    RatingAndReviewReducer: RatingAndReviewListModal;
    RatingAndReviewDetailReducer: RatingAndReviewDetailModal;
    NotificationReducer: NotificationsListModal;
    NotificationDetailReducer: NotificationDetailModal;
    ContentReducer: ContentManagementModal;
    EarnAndTransactionReducer: EarnAndTransactionListModal;
    ManageDisputeReducer: ManageDisputeListModal;
    ManageDisputeDetailsReducer: ManageDisputeDetailModal;
    FlaggedAccountDetailsReducer: FlaggedAccountDetailModal;
    FlaggedAccountReducer: FlaggedAccountListModal;
    EarnAndTransactionDetailReducer: EarnAndTransactionDetailModal;
    TransactionDetailReducer: TransactionModal;
    RewardsManagementReducer: RewardsListModal;
    RewardsManagementDetailsReducer: RewardsDetailsModal;
    OffersManagementReducer: OffersListModal;
    OffersDetailReducer: OfferDetailModal;
};
