import {
    UPDATE_RATING_AND_REVIEW_REDUCER,
    UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER,
} from 'store/actionTypes';
import { ActionType, RatingAndReviewDetailModal, RatingAndReviewListModal } from 'utils/modals';

export const RatingAndReviewReducer = (
    state: RatingAndReviewListModal = new RatingAndReviewListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_RATING_AND_REVIEW_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const RatingAndReviewDetailReducer = (
    state: RatingAndReviewDetailModal = new RatingAndReviewDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_RATING_AND_REVIEW_DETAIL_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
