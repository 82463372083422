import { Card, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import ContainedButton from 'components/button/contained-button';
import { useEffect, useRef, useState } from 'react';
import { styles } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationDetail } from '../action';
import { APIResponseModal, ReducersModal } from 'utils/modals';
import { UPDATE_NOTIFICATION_DETAIL_REDUCER } from 'store/actionTypes';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import Loader from 'components/loader';
import EditPush from './editOptions/editPush';
import EditSms from './editOptions/editSms';
import EditEmail from './editOptions/editEmail';
import NotificationSkeleton from './skeleton';

const EditNotifications = () => {
    const navigate = useNavigate();
    const { isSent } = useSelector((state: ReducersModal) => state.NotificationDetailReducer);
    const [value, setValue] = useState('push');
    const dispatch: any = useDispatch();
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const PushTabRef: any = useRef();
    const SmsTabRef: any = useRef();
    const EmailTabRef: any = useRef();
    const notificationId = new URLSearchParams(window.location.search).get('id') || '';

    const onSendClick = () => {
        dispatchValue({ not_idToEdit: notificationId });
        if (value === 'push' && PushTabRef?.current?.runSubmitOnSendClick) {
            PushTabRef.current.runSubmitOnSendClick();
            return;
        }
        if (value === 'sms' && SmsTabRef?.current?.runSubmitOnSendClick) {
            SmsTabRef.current.runSubmitOnSendClick();
            return;
        }
        if (value === 'email' && EmailTabRef?.current?.runSubmitOnSendClick) {
            EmailTabRef.current.runSubmitOnSendClick();
            return;
        }
    };

    const onSaveClick = () => {
        dispatchValue({ not_idToEdit: notificationId });
        if (value === 'push' && PushTabRef?.current?.runSubmitOnSaveClick) {
            PushTabRef.current.runSubmitOnSaveClick();
            return;
        }
        if (value === 'sms' && SmsTabRef?.current?.runSubmitOnSaveClick) {
            SmsTabRef.current.runSubmitOnSaveClick();
            return;
        }
        if (value === 'email' && EmailTabRef?.current?.runSubmitOnSaveClick) {
            EmailTabRef.current.runSubmitOnSaveClick();
            return;
        }
    };

    useEffect(() => {
        setLoading(true);
        dispatch(
            getNotificationDetail(notificationId, ({ data, isSuccess }: APIResponseModal) => {
                if (isSuccess) {
                    const { notificationType } = data;
                    if (notificationType === 5) {
                        setValue('sms');
                    } else if (notificationType === 6) {
                        setValue('email');
                    }
                } else {
                    navigate(ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT);
                }
                setLoading(false);
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, notificationId]);

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DETAIL_REDUCER,
            payload,
        });
    };

    const onEditClick = () => {
        dispatchValue({ editMode: true });
        setEditMode(true);
    };

    const onCancelClick = () => {
        navigate(ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT);
        dispatchValue({ editMode: false });
        setEditMode(false);
    };

    const renderView = () => {
        if (!loading && value === 'push') {
            return <EditPush ref={PushTabRef} />;
        }
        if (!loading && value === 'sms') {
            return <EditSms ref={SmsTabRef} />;
        }
        if (!loading && value === 'email') {
            return <EditEmail ref={EmailTabRef} />;
        }
        return <Loader />;
    };
    return (
        <Box sx={styles.paper}>
            <Card sx={{ minHeight: 84 + 'vh' }}>
                {!loading ? (
                    <CardContent>
                        <Box sx={styles.selection_bar}>
                            <Box sx={styles.radio_cont}>
                                <Typography variant='h5'>
                                    <span>{'Notification Type'}</span>
                                </Typography>
                                <RadioGroup sx={styles.radio_grp} value={value}>
                                    {value === 'push' ? (
                                        <FormControlLabel
                                            disabled={true}
                                            value='push'
                                            control={<Radio />}
                                            label='Push'
                                            onChange={(event: any) => {
                                                setValue(event.target.value);
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {value === 'sms' ? (
                                        <FormControlLabel
                                            disabled={true}
                                            value='sms'
                                            control={<Radio />}
                                            label='SMS'
                                            onChange={(event: any) => {
                                                setValue(event.target.value);
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {value === 'email' ? (
                                        <FormControlLabel
                                            disabled={true}
                                            value='email'
                                            control={<Radio />}
                                            label='Email'
                                            onChange={(event: any) => {
                                                setValue(event.target.value);
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </RadioGroup>
                            </Box>
                            {isSent ? (
                                <Box sx={styles.button_box}>
                                    <ContainedButton
                                        style={styles.cont_button}
                                        title='Cancel'
                                        onClick={() => onCancelClick()}
                                    />
                                    <ContainedButton
                                        style={styles.cont_button}
                                        title={(isSent && 'Resend') || 'Send'}
                                        onClick={() => onSendClick()}
                                    />
                                </Box>
                            ) : (
                                <Box sx={styles.button_box}>
                                    {!editMode && (
                                        <ContainedButton
                                            style={{
                                                ...styles.cont_button,
                                                position: 'relative',
                                                left: '40px',
                                            }}
                                            title='Cancel'
                                            onClick={() => onCancelClick()}
                                        />
                                    )}
                                    {!editMode && (
                                        <ContainedButton
                                            title='Edit'
                                            style={
                                                (editMode && styles.cont_button) ||
                                                styles.edit_button
                                            }
                                            onClick={() => onEditClick()}></ContainedButton>
                                    )}
                                    {editMode && (
                                        <ContainedButton
                                            style={styles.cont_button}
                                            title='Cancel'
                                            onClick={() => onCancelClick()}
                                        />
                                    )}
                                    {editMode && (
                                        <ContainedButton
                                            style={styles.cont_button}
                                            title='Save Draft'
                                            onClick={() => onSaveClick()}
                                        />
                                    )}
                                    {editMode && (
                                        <ContainedButton
                                            style={styles.cont_button}
                                            title={(isSent && 'Resend') || 'Send'}
                                            onClick={() => onSendClick()}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                        {renderView()}
                    </CardContent>
                ) : (
                    <NotificationSkeleton />
                )}
            </Card>
        </Box>
    );
};

export default EditNotifications;
