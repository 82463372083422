import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Formik } from 'formik';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SmsNotificationSchema } from 'utils/schema';
import { styles } from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'modules/notificationsManagement/action';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import ClientSelection from '../selectionView/clientSelection';
import VendorSelection from '../selectionView/vendorSelection';
import { ReducersModal } from 'utils/modals';
import {
    UPDATE_CLIENT_REDUCER,
    UPDATE_NOTIFICATION_DETAIL_REDUCER,
    UPDATE_PHOTOGRAPHERS_REDUCER,
} from 'store/actionTypes';

const Sms = forwardRef((_, ref) => {
    const { receiverIds } = useSelector((state: ReducersModal) => state.NotificationDetailReducer);
    const theme: any = useTheme();
    const formRef: any = useRef();
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [recipientType, setRecipientType] = useState(1);

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DETAIL_REDUCER,
            payload,
        });
    };

    const dispatchInitialValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_REDUCER,
            payload,
        });
        dispatch({
            type: UPDATE_PHOTOGRAPHERS_REDUCER,
            payload,
        });
    };

    useEffect(() => {
        dispatchValue({ receiverIds: [], notificationType: 5 });
        dispatchInitialValue({ total: 0, page: 1, limit: 10 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onRadioClick = (e: any) => {
        setRecipientType(+e.target.value);
        dispatchValue({ receiverIds: [] });
        dispatchInitialValue({ search: '' });
    };

    useImperativeHandle(ref, () => ({
        runSubmitOnSendClick() {
            formRef.current.values['isSent'] = true;
            formRef?.current?.handleSubmit();
        },
        runSubmitOnSaveClick() {
            formRef.current.values['isSent'] = false;
            formRef?.current?.handleSubmit();
        },
    }));

    const handleOnSubmit = ({ description, isSent }: any) => {
        let dataToSend = {
            title: description,
            isSent,
            description,
            recipientType,
            receiverIds: receiverIds,
            notificationType: 5,
        };
        dispatch(
            addNotification(dataToSend, () =>
                navigate(ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT),
            ),
        );
    };
    return (
        <Box>
            <Formik
                innerRef={formRef}
                onSubmit={handleOnSubmit}
                initialValues={{ description: '', recipientType: 1 }}
                validationSchema={SmsNotificationSchema}>
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Typography variant='h5'>
                            <span>{'Description*'}</span>
                        </Typography>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.description && errors.description)}
                            sx={{ ...theme.typography.customInput }}>
                            <OutlinedInput
                                sx={{ width: '75%' }}
                                value={values.description}
                                onChange={handleChange}
                                name='description'
                                maxRows={6}
                                minRows={6}
                                multiline={true}
                                onBlur={handleBlur}
                                placeholder='Start writing description here...'
                                inputProps={{
                                    maxLength: 250,
                                    style: { fontWeight: 300 },
                                }}
                                endAdornment={
                                    <InputAdornment position='start' sx={styles.adornment}>
                                        <Typography variant='inherit'>
                                            {'Max. 250 Characters'}
                                        </Typography>
                                    </InputAdornment>
                                }
                            />
                            {touched.description && errors.description && (
                                <FormHelperText error id='standard-weight-helper-text-email-login'>
                                    {errors.description}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Box sx={styles.radio_cont_notification_type}>
                            <Typography variant='h5'>
                                <span>{'Recipient*'}</span>
                            </Typography>
                            <RadioGroup
                                sx={styles.radio_grp_notification_type}
                                value={recipientType}
                                defaultValue={1}
                                name='recipientType'
                                onChange={(e: any) => {
                                    onRadioClick(e);
                                }}>
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={1}
                                    control={<Radio />}
                                    label='All Users'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={4}
                                    control={<Radio />}
                                    label='Selected Clients'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={2}
                                    control={<Radio />}
                                    label='All Clients'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={3}
                                    control={<Radio />}
                                    label='All Photographers'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={5}
                                    control={<Radio />}
                                    label='Selected Photographers'
                                />
                            </RadioGroup>
                        </Box>
                    </form>
                )}
            </Formik>
            {recipientType && recipientType === 4 ? <ClientSelection /> : <></>}
            {recipientType && recipientType === 5 ? <VendorSelection /> : <></>}
        </Box>
    );
});

export default Sms;
