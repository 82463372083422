import { Button, ButtonProps } from '@mui/material';
import styles from './styles';

interface Props {
    title: string;
    externalStyles?: object;
    primary?: boolean;
    btnType?: string;
    count?: number;
}

const ContainedButton = ({
    title,
    externalStyles,
    btnType,
    count,
    ...otherProps
}: Props & ButtonProps) => {
    return (
        <Button variant='contained' sx={{ ...styles.btn, ...externalStyles }} {...otherProps}>
            {title}{' '}
            {btnType && btnType === 'graphBtn' && (
                <span style={{ marginLeft: '20px' }}>{count}</span>
            )}
        </Button>
    );
};

export default ContainedButton;
