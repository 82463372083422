import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

//components imports
import PublicRoute from './publicRoute';
import ROUTE_NAMES from './routeNames';
import RoutesContainer from 'components/routesContainer';

//pages import
const Login = RoutesContainer(lazy(() => import('modules/auth/login')));
const ResetPassword = RoutesContainer(lazy(() => import('modules/auth/resetpassword')));
const ForgotPassword = RoutesContainer(lazy(() => import('modules/auth/forgotpassword')));
const OtpVerification = RoutesContainer(lazy(() => import('modules/auth/otpverification')));
const TwoFactorAuthentication = RoutesContainer(lazy(() => import('modules/auth/twoFactorAuth')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: ROUTE_NAMES.BASE_ROUTE,
    element: <Outlet />,
    children: [
        {
            path: ROUTE_NAMES.BASE_ROUTE,
            element: (
                <PublicRoute>
                    <Login />
                </PublicRoute>
            ),
        },
        {
            path: ROUTE_NAMES.AUTH_ROUTES.TWO_FACTOR_AUTH,
            element: (
                <PublicRoute>
                    <TwoFactorAuthentication />
                </PublicRoute>
            ),
        },
        {
            path: ROUTE_NAMES.AUTH_ROUTES.FORGOT_PASS,
            element: (
                <PublicRoute>
                    <ForgotPassword />
                </PublicRoute>
            ),
        },
        {
            path: ROUTE_NAMES.AUTH_ROUTES.RESET_PASS,
            element: (
                <PublicRoute>
                    <ResetPassword />
                </PublicRoute>
            ),
        },
        {
            path: ROUTE_NAMES.AUTH_ROUTES.OTP_VERIFICATION,
            element: (
                <PublicRoute>
                    <OtpVerification />
                </PublicRoute>
            ),
        },
    ],
};

export default AuthenticationRoutes;
