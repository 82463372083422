import { toast, TypeOptions } from 'react-toastify';

const SHOW_TOAST = (
    message: string = 'OOPS! something went wrong',
    type: TypeOptions = 'error',
    toastId: string = '',
) => {
    toast(message, {
        type,
        theme: 'colored',
        pauseOnHover: false,
        position: 'top-right',
        pauseOnFocusLoss: false,
        ...(toastId.length > 0 && { toastId }),
    });
};

export const SHOW_INTERNET_TOAST = () => {
    toast('Please check your internet connection.', {
        type: 'info',
        theme: 'colored',
        position: 'top-right',
        toastId: 'internet-toast',
    });
};

export default SHOW_TOAST;
