// material-ui
import { styled } from '@mui/material/styles';
import { Box, IconButton, InputAdornment, OutlinedInput } from '@mui/material';

// assets
import { shouldForwardProp } from '@mui/system';
import { Search, Close } from '@mui/icons-material';
import { useEffect, useMemo, useRef } from 'react';
//@ts-ignore
import debounce from 'lodash.debounce';

// styles
const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    minWidth: 300,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important',
        fontWeight: 300,
        padding: '14px',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.input': {
        '&:focus': {
            border: `2px solid #5e35b1`,
        },
    },
    [theme.breakpoints.down('lg')]: {
        width: 250,
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: '#fff',
    },
}));

// ==============================|| SEARCH INPUT ||============================== //

export interface Props {
    value: string;
    placeholder: string;
    isDisabled: boolean;
    onChange: (val: string) => void;
}

const SearchSection = ({ onChange, placeholder, value, isDisabled }: Props) => {
    const inputRef = useRef();
    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        onChange(target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <OutlineInputStyle
                ref={inputRef}
                defaultValue={value}
                readOnly={isDisabled}
                id='input-search-header'
                onChange={debouncedResults}
                placeholder={placeholder}
                startAdornment={
                    <InputAdornment position='start'>
                        <Search />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position='end'>
                        {value.length > 0 && (
                            <IconButton
                                onClick={() => {
                                    //@ts-ignore
                                    inputRef.current.getElementsByTagName('input')[0].value = '';
                                    debouncedResults({ target: { value: '' } });
                                }}>
                                <Close />
                            </IconButton>
                        )}
                    </InputAdornment>
                }
                aria-describedby='search-helper-text'
                inputProps={{ 'aria-label': 'weight' }}
            />
        </Box>
    );
};

export default SearchSection;
