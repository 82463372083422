import React from 'react';
import { Box } from '@mui/material';
import {
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Label,
    LineChart,
    Line,
    CartesianGrid,
} from 'recharts';
import { commonTableStyles as styles } from 'components/tables/styles';
import { DataFormater, DataFormaterWithoutDecimal, getFormattedDate } from 'utils/constants';

const CurveChart = ({ result, durationState }: any) => {
    const customizedGroupTick = (props: any) => {
        const { x, y, payload } = props;
        if (payload?.value) {
            return (
                <g>
                    {durationState === 1 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {payload?.values}
                        </text>
                    )}

                    {durationState === 2 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {payload?.value}
                        </text>
                    )}

                    {durationState === 3 && (
                        <text x={x - 25} y={y + 20} fill='#262626'>
                            {getFormattedDate(payload?.value, 'MM/DD/YYYY')}
                        </text>
                    )}

                    {durationState === 4 && (
                        <text x={x - 17} y={y + 40} fill='#262626'>
                            {payload?.value}
                        </text>
                    )}
                </g>
            );
        }
        return <g></g>;
    };

    const customizedMonthTick = (props: any) => {
        const { x, y, payload } = props;

        if (payload?.value) {
            return (
                <g>
                    <text x={x - 12} y={y - 10}>
                        {payload?.value?.slice(0, 3)}
                    </text>
                </g>
            );
        }
        return <text></text>;
    };

    const customizedDateTick = (props: any) => {
        const { x, y, payload } = props;

        if (payload?.value) {
            return (
                <g>
                    <text x={x - 35} y={y + 20}>
                        {payload?.value}
                    </text>
                </g>
            );
        }
        return <text></text>;
    };

    const xDataKey =
        durationState === 1
            ? 'hour'
            : durationState === 2
                ? 'day'
                : durationState === 3
                    ? 'date'
                    : 'year';
    const xDataLabel =
        durationState === 1
            ? 'Today'
            : durationState === 2
                ? 'Week'
                : durationState === 3
                    ? 'Month'
                    : 'Year';

    const defaultState = [
        {
            bookingCount: 0,
            tipCount: 0,
            month: '',
            createdAt: '',
        },
    ];

    return (
        <Box sx={styles.chartDimension}>
            <React.Fragment>
                <ResponsiveContainer width='100%' height='100%'>
                    <LineChart
                        width={300}
                        height={100}
                        margin={{
                            top: 35,
                            right: 40,
                            bottom: 43,
                        }}
                        data={result?.length > 0 ? result : defaultState}>
                        <CartesianGrid strokeDasharray='3 3' vertical={false} />
                        {durationState === 4 && (
                            <XAxis
                                dataKey='month'
                                axisLine={false}
                                tickLine={false}
                                tick={customizedMonthTick}
                                xAxisId='quarter'
                            />
                        )}
                        {durationState === 2 &&
                            <XAxis
                                dataKey='date'
                                axisLine={false}
                                tickLine={false}
                                interval={0}
                                tick={customizedDateTick}
                                xAxisId='dateId'
                            />
                        }
                        <XAxis dataKey={xDataKey} stroke='#673AB7' tick={customizedGroupTick}>
                            <Label
                                position='center'
                                value={xDataLabel}
                                dy={durationState === 4 ? 55 : durationState === 2 ? 70 : 36}
                            />
                        </XAxis>
                        <YAxis tickFormatter={DataFormaterWithoutDecimal}>
                            <Label
                                style={{
                                    textAnchor: 'middle',
                                }}
                                position='insideLeft'
                                offset={8}
                                angle={270}
                                value={'Amount (in $)'}
                            />
                        </YAxis>
                        {result?.length > 0 && (
                            <Tooltip
                                formatter={(value) =>
                                    `${DataFormater(parseFloat(value.toString()))}`
                                }
                                labelFormatter={(value) => {
                                    if (durationState === 3) {
                                        return `${getFormattedDate(value, 'MM/DD/YYYY')}`;
                                    } else {
                                        return `${value}`;
                                    }
                                }}
                            />
                        )}
                        <Line
                            type='monotone'
                            dataKey='bookingCount'
                            stroke='#673AB7'
                            strokeWidth={3}
                            dot={false}
                        />
                        <Line
                            type='monotone'
                            dataKey='tipCount'
                            stroke='#2196f3'
                            strokeWidth={3}
                            dot={false}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </React.Fragment>
        </Box>
    );
};
export default CurveChart;
