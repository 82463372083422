import { styles } from '../styles';
import { FormControl, MenuItem, Select } from '@mui/material';
import { durationOptions } from 'utils/enum';
interface DurationFilterProps {
    filterKey: number;
    durationState: number;
    onDurationFilter: Function;
}
const DurationFilter = ({ filterKey, durationState, onDurationFilter }: DurationFilterProps) => {
    return (
        <FormControl
            sx={{
                width: '140px',
                height: '38px',
                boxShadow: 'none',
                fontSize: '30px',
            }}>
            <Select
                displayEmpty
                onChange={(e: any) => onDurationFilter(e, filterKey)}
                sx={styles.selectDropdown}
                value={durationState.toString()}
                inputProps={{ 'aria-label': 'Without label' }}>
                {Object.entries(durationOptions).map((duration: any) => {
                    return (
                        <MenuItem
                            key={duration[0]}
                            value={duration[0].toString()}
                            disabled={durationState === parseInt(duration[0])}>
                            {duration[1]}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default DurationFilter;
