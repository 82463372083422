import { styled } from '@mui/material';
import { motion } from 'framer-motion';

//assets import
import Logo from 'assets/images/logo.svg';

const LoaderContainer = styled('div')(() => ({
    top: 0,
    left: 0,
    flex: 1,
    right: 0,
    bottom: 0,
    zIndex: 10,
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.5)',
}));

export default function Loader() {
    return (
        <LoaderContainer>
            <motion.div
                style={{ filter: 'drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))' }}
                initial={{ scale: 0.1, opacity: 0.1 }}
                animate={{ scale: 1.2, opacity: 1 }}
                transition={{
                    duration: 2,
                    repeatDelay: 0.2,
                    repeat: Infinity,
                    repeatType: 'loop',
                }}
            >
                <img src={Logo} alt={'loader_logo'} />
            </motion.div>
        </LoaderContainer>
    );
}
