import ImageModal from '../imageModal';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { getAlbumDetail } from 'modules/photographersManagement/action';
import { APIResponseModal, AlbumDataModal, GalleryDataModal } from 'utils/modals';
import { CircularProgress, Dialog, IconButton, Typography, styled } from '@mui/material';

const Container = styled('div')(() => ({
    width: '100%',
    maxHeight: '750px',
    minHeight: '350px',
    backgroundColor: '#fff',
}));

const HeaderBox = styled('div')(() => ({
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    justifyContent: 'center',
    padding: '0px 10px 0px 10px',
    borderRadius: '12px 12px 0px 0px',
    borderBottom: '1px solid lightGray',
}));

const LowerContainer = styled('div')(() => ({
    gap: '60px',
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '48px 53px',
    backgroundColor: '#fff',
}));

const AlbumItemContainer = styled('div')(() => ({
    width: '152px',
    height: '152px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(80, 82, 102, 0.1)',
}));

const NoDataContainer = styled('div')(() => ({
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ImageItem = styled('img')(() => ({
    width: '100%',
    height: '100%',
}));

interface Props {
    onClose: () => void;
    albumData: AlbumDataModal;
}

export default function AlbumDetailPopup({ albumData, onClose }: Props) {
    const dispatch: any = useDispatch();

    const [loading, setLoading] = useState(false);
    const [albumsData, setAlbumsData] = useState<GalleryDataModal[]>([]);

    const [activeIndex, setActiveIndex] = useState(-1);
    const [showCarousel, setShowCarousel] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(
            getAlbumDetail(albumData._id, (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { data } = response;
                    let dataToReturn = [];
                    for (let i = 0; i < Object.keys(data).length - 1; i += 1) {
                        dataToReturn.push(data[`${i}`]);
                    }
                    setAlbumsData([...dataToReturn]);
                }
                setLoading(false);
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleCarouselView = (index: number) => {
        setActiveIndex(index);
        setShowCarousel((oldVal) => !oldVal);
    };

    const renderAlbumView = () => {
        if (albumsData.length === 0 && loading) {
            return (
                <CircularProgress size={40} variant={'indeterminate'} sx={{ margin: '0 auto' }} />
            );
        }
        if (albumsData.length === 0 && !loading) {
            return (
                <NoDataContainer>
                    <Typography variant='h3' component='h3' sx={{ marginTop: '80px' }}>
                        {'No Data Exists.'}
                    </Typography>
                </NoDataContainer>
            );
        }
        return (
            <React.Fragment>
                {albumsData.map((item, i) => {
                    const { _id, mimeType, fileUrl } = item;
                    const isImage = mimeType.indexOf('image') > -1;
                    return (
                        <AlbumItemContainer key={_id} onClick={() => toggleCarouselView(i)}>
                            {isImage ? (
                                <ImageItem src={fileUrl} alt={`album-img-${i}`} />
                            ) : (
                                <ReactPlayer
                                    light={true}
                                    url={fileUrl}
                                    width={'100%'}
                                    playing={false}
                                    height={'100%'}
                                    style={{ pointerEvents: 'none' }}
                                />
                            )}
                        </AlbumItemContainer>
                    );
                })}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Dialog open={true} fullWidth maxWidth='md'>
                <Container>
                    <HeaderBox>
                        <Typography sx={{ color: '#673ab7' }} variant='h2' component='h2'>
                            {albumData.title}
                        </Typography>
                        <IconButton sx={{ position: 'absolute', right: 10 }} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </HeaderBox>
                    <LowerContainer>{renderAlbumView()}</LowerContainer>
                </Container>
            </Dialog>
            {showCarousel && (
                <ImageModal
                    onClose={() => toggleCarouselView(-1)}
                    data={albumsData}
                    imageId={activeIndex}
                />
            )}
        </React.Fragment>
    );
}
