import React, { useEffect, useLayoutEffect, useState } from 'react';
import Loader from 'components/loader';
import { styles } from './styles';
import { Box, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_EARN_AND_TRANSACTION_REDUCER } from 'store/actionTypes';
import { ReducersModal } from 'utils/modals';
import SearchSection from 'components/search';
import EarningsTransactionsTable from 'components/tables/earningTransactionManagement';
import RenderBarChart from 'modules/earningTransactionManagement/graphRepresentation/graph';
import { exportToCSV, getEarnAndTransactionListing, getTransactionReportData } from './action';
import ContainedButton from 'components/button/contained-button';
import EarnAndTransactionFilter from 'components/filter/earnAndTransFilter';
import { formatResult, getFormattedDate } from 'utils/constants';

const EarningAndTransactionManagement = () => {
    const {
        search,
        loader,
        result,
        page,
        reports,
        sortOrder,
        sortedBy,
        fromDate,
        toDate,
        fromRange,
        toRange,
        filterStatus,
        totalOrderValue,
        totalPlandidEarnings
    } = useSelector((state: ReducersModal) => state.EarnAndTransactionReducer);
    const [filteredEarnAndTransReports, setFilteredEarnAndTransReportsData] = useState([]);
    const [earnAndTransState, setEarnAndTransState] = useState(4);

    const dispatch: any = useDispatch();
    useEffect(() => {
        dispatch(getEarnAndTransactionListing());
    }, [dispatch, search, fromDate, toDate, fromRange, toRange, filterStatus]);

    useEffect(() => {
        dispatch(getTransactionReportData(earnAndTransState));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let uniqueData: any = [];
        if (reports.length > 0 && earnAndTransState !== 1 && earnAndTransState !== 5) {
            uniqueData = formatResult(reports.splice(1, reports.length));
        } else {
            uniqueData = formatResult(reports);
        }
        setFilteredEarnAndTransReportsData(uniqueData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reports]);

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload,
        });
    };

    const onExportToCSVClick = () => {
        const date = getFormattedDate(new Date(), 'MMDDYYHH:mm a');
        const fileName = `${'Transaction_'}${date}.csv`;
        const dataToSend = {
            ...(sortOrder?.length && { sortOrder }),
            ...(sortedBy?.length && { sortedBy }),
            ...(search?.length && { search }),
            ...(fromDate && { fromDate }),
            ...(toDate && { toDate }),
            ...(fromRange && { fromRange }),
            ...(toRange && { toRange }),
            fileName: fileName,
        };
        dispatch(
            exportToCSV(dataToSend, (cbData: any) => {
                let { data } = cbData;
                var binaryData = [];
                binaryData.push(data);
                const href = window.URL.createObjectURL(
                    new Blob(binaryData, { type: 'application/csv' }),
                );
                const link = document.createElement('a');
                link.href = href;
                link.download = fileName;
                link.click();
            }),
        );
    };

    useLayoutEffect(() => {
        dispatchValue({
            reports: [],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDurationFilter = (e: any, filterKey: any) => {
        dispatch(getTransactionReportData(parseInt(e.target.value)));
        setEarnAndTransState(parseInt(e.target.value));
        setFilteredEarnAndTransReportsData([])
    };

    const handleSearch = (searchVal: string) => {
        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload: { search: searchVal, page: 1 },
        });
    };

    const onApplyFilters = ({
        selectedFromDate,
        selectedToDate,
        selectedFromRange,
        selectedToRange,
        selectedTransStatus,
    }: any) => {
        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload: {
                fromDate: selectedFromDate,
                toDate: selectedToDate,
                fromRange: selectedFromRange,
                toRange: selectedToRange,
                filterStatus: selectedTransStatus,
            },
        });
    };

    const renderLoading = () => {
        return (
            <React.Fragment>
                <Box sx={styles.searchAndFilter}>
                    <Skeleton
                        height={48}
                        width={300}
                        variant='rectangular'
                        sx={{ borderRadius: '12px' }}
                    />
                </Box>
                <Skeleton
                    height={500}
                    width={'100%'}
                    variant='rectangular'
                    sx={{ borderRadius: '8px', marginTop: '16px' }}
                />
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (
            loader &&
            result.length === 0 &&
            page === 1 &&
            search.length === 0 &&
            reports.length === 0
        ) {
            return renderLoading();
        }
        return (
            <React.Fragment>
                <RenderBarChart
                    filteredData={filteredEarnAndTransReports}
                    durationState={earnAndTransState}
                    totalOrderValue={totalOrderValue}
                    totalPlandidEarnings={totalPlandidEarnings}
                    onDurationFilter={onDurationFilter}
                />
                <br></br>
                <Box sx={styles.searchAndFilter}>
                    <SearchSection
                        value={search}
                        isDisabled={loader}
                        onChange={handleSearch}
                        placeholder={'Search by Name, Transaction Id'}
                    />
                    <Box sx={styles.flexContainer}>
                        <ContainedButton
                            title='Export to CSV'
                            style={styles.csv_btn}
                            onClick={() => onExportToCSVClick()}></ContainedButton>
                        <EarnAndTransactionFilter
                            fromDate={fromDate}
                            fromRange={fromRange}
                            toRange={toRange}
                            toDate={toDate}
                            transactionFilter={filterStatus}
                            applyFilters={onApplyFilters}
                        />
                    </Box>
                </Box>
                <EarningsTransactionsTable />
            </React.Fragment>
        );
    };

    return (
        <Box>
            {renderView()}
            {loader && (result.length > 0 || (result.length === 0 && search.length > 0)) && (
                <Loader />
            )}
        </Box>
    );
};

export default EarningAndTransactionManagement;
