import { UPDATE_CATEGORY_REDUCER } from 'store/actionTypes';
import { ActionType, CategoryListModal } from 'utils/modals';

export const CategoryReducer = (
    state: CategoryListModal = new CategoryListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_CATEGORY_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
