import React from 'react';
import { GalleryDataModal } from 'utils/modals';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Typography, styled, IconButton } from '@mui/material';
import ImageModal from '../imageModal';
import { styles } from './styles';

const Container = styled('div')(() => ({
    backgroundColor: '#fff',
}));

const HeaderBox = styled('div')(() => ({
    width: '400px',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    justifyContent: 'center',
    padding: '10px',
    borderRadius: '12px 12px 0px 0px',
    borderBottom: '1px solid lightGray',
}));

const BodyContainer = styled('div')(() => ({
    gap: '36px',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '86px 72px',
}));

const ItemContainer = styled('div')(() => ({
    width: '110px',
    height: '110px',
    cursor: 'pointer',
    backgroundColor: 'rgba(80, 82, 102, 0.1)',
}));

const ImageItem = styled('img')(() => ({
    width: '100%',
    height: '100%',
}));

const VideoItem = styled('video')(() => ({
    width: '100%',
    height: '100%',
}));

interface Props {
    onClose: () => void;
    data: GalleryDataModal[];
}

export default function GalleryImages({ onClose, data }: Props) {
    const [openImageModal, setOpenImageModal] = React.useState<boolean>(false);
    const [id, setId] = React.useState(-1);
    const handleOpenImageModal = (id: number) => {
        setOpenImageModal(true);
        setId(id);
    };
    const handleCloseImageModal = () => {
        setOpenImageModal(false);
        setId(-1);
    };

    const renderData = () => {
        return (
            <React.Fragment>
                {data.map(({ _id, mimeType, fileUrl }, i) => {
                    const isImage = mimeType.indexOf('image') > -1;
                    const isVideo = mimeType.indexOf('video') > -1;

                    return (
                        <ItemContainer key={_id}>
                            {isImage && (
                                <ImageItem
                                    src={fileUrl}
                                    alt={`gallery-image-${i}`}
                                    onClick={() => handleOpenImageModal(i)}
                                />
                            )}
                            {isVideo && (
                                <VideoItem
                                    src={fileUrl}
                                    onClick={() => handleOpenImageModal(i)}
                                />
                            )}
                        </ItemContainer>
                    );
                })}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Dialog sx={styles.imageSlider} open={true}>
                <Container>
                    <HeaderBox>
                        <Typography sx={{ color: '#673ab7' }} variant='h2' component='h2'>
                            {'Uploaded Photos'}
                        </Typography>
                        <IconButton sx={{ position: 'absolute', right: 10 }} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </HeaderBox>
                    <BodyContainer>{renderData()}</BodyContainer>
                </Container>
            </Dialog>
            {openImageModal && (
                <ImageModal onClose={handleCloseImageModal} data={data} imageId={id} />
            )}
        </React.Fragment>
    );
}
