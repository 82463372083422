import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Modal,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { commonFilterStyles } from '../styles';
import AnimateButton from 'components/animateButton';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CLIENT_BOOKING_MADE_REDUCER } from 'store/actionTypes';
import { useState } from 'react';
import { ReducersModal } from 'utils/modals';

interface Props {
    updateShowPopup: Function;
    bookingStatusFilter: string[];
    bookingTypeFilter: string[];
}

const bookingStatusObj: any = {
    PENDING: ['1', '2'],
    UPCOMING: ['6', '7', '9', '14', '22', '23', '24', '25', '26', '27', '28', '34'],
    COMPLETED: ['10', '11', '12', '13', '15', '16', '17', '18', '29', '30', '31', '32', '33'],
    CANCELLED: ['3', '4', '19', '20', '21', '35', '36', '37', '38'],
    REJECTED: ['5', '8'],
};

const BookingFilter = ({ updateShowPopup }: Props) => {
    const { bookingStatusValueFilter, bookingTypeFilter } = useSelector(
        (state: ReducersModal) => state.ClientVendorBookingMadeReducer,
    );
    const dispatch: any = useDispatch();
    const [bookingType, setBookingType] = useState<any[]>(bookingTypeFilter);
    const [bookingStatus, setBookingStatus] = useState<string[]>(bookingStatusValueFilter);
    const handleCloseFilter = () => {
        updateShowPopup(false);
    };

    const onApplyClick = () => {
        let bookingStatusToSend: any[] = [],
            oldBookingStatus = [...bookingStatus];
        bookingStatusToSend = bookingStatus.map((item: any) => bookingStatusObj[item]);
        const _ = require('lodash');
        bookingStatusToSend = _.compact(_.flatten(bookingStatusToSend));
        dispatch({
            type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
            payload: {
                bookingTypeFilter: bookingType,
                bookingStatusFilter: bookingStatusToSend,
                bookingStatusValueFilter: oldBookingStatus,
            },
        });
        handleCloseFilter();
    };

    const onClearClick = () => {
        setBookingStatus([]);
        setBookingType([]);
        dispatch({
            type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
            payload: {
                bookingStatusValueFilter: [],
                bookingStatusFilter: [],
                bookingTypeFilter: [],
            },
        });
        handleCloseFilter();
    };

    const handleBookingTypeCheck = ({ target }: any) => {
        let oldArr = [...bookingType],
            { value } = target,
            findIndex = oldArr.findIndex((a) => a === value);

        if (findIndex > -1) {
            oldArr.splice(findIndex, 1);
        } else {
            oldArr.push(value);
        }
        setBookingType([...oldArr]);
    };

    const handleBookingStatusCheck = ({ target }: any) => {
        let oldArr = [...bookingStatus],
            { value } = target,
            findIndex = oldArr.findIndex((a) => a === value);

        if (findIndex > -1) {
            oldArr.splice(findIndex, 1);
        } else {
            oldArr.push(value);
        }
        setBookingStatus([...oldArr]);
    };

    return (
        <Modal open={true}>
            <Box sx={commonFilterStyles.booking_modal}>
                <Box sx={commonFilterStyles.bkg_flt_top_bar}>
                    <Typography variant='h4'>{'Filter'}</Typography>
                    <CloseIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            handleCloseFilter();
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '30px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
                        <FormGroup sx={commonFilterStyles.formGroup}>
                            <Typography variant='h5' sx={{ marginBottom: '5px' }}>
                                {'Booking Status'}
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={'PENDING'}
                                        name={'Pending'}
                                        onChange={handleBookingStatusCheck}
                                        defaultChecked={bookingStatusValueFilter.includes(
                                            'PENDING',
                                        )}
                                    />
                                }
                                label={'Pending'}
                                labelPlacement='end'></FormControlLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'Upcoming'}
                                        value={'UPCOMING'}
                                        onChange={handleBookingStatusCheck}
                                        defaultChecked={bookingStatusValueFilter.includes(
                                            'UPCOMING',
                                        )}
                                    />
                                }
                                label={'Upcoming'}
                                labelPlacement='end'></FormControlLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'Completed'}
                                        value={'COMPLETED'}
                                        onChange={handleBookingStatusCheck}
                                        defaultChecked={bookingStatusValueFilter.includes(
                                            'COMPLETED',
                                        )}
                                    />
                                }
                                label={'Completed'}
                                labelPlacement='end'></FormControlLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'Cancelled'}
                                        value={'CANCELLED'}
                                        onChange={handleBookingStatusCheck}
                                        defaultChecked={bookingStatusValueFilter.includes(
                                            'CANCELLED',
                                        )}
                                    />
                                }
                                label={'Cancelled'}
                                labelPlacement='end'></FormControlLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={'Rejected'}
                                        value={'REJECTED'}
                                        onChange={handleBookingStatusCheck}
                                        defaultChecked={bookingStatusValueFilter.includes(
                                            'REJECTED',
                                        )}
                                    />
                                }
                                label={'Rejected'}
                                labelPlacement='end'></FormControlLabel>
                        </FormGroup>
                        <FormGroup sx={commonFilterStyles.formGroup}>
                            <Typography variant='h5' sx={{ marginBottom: '5px' }}>
                                {'Booking Type'}
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={'2'}
                                        name={'Instant Booking'}
                                        onChange={handleBookingTypeCheck}
                                        defaultChecked={bookingTypeFilter.includes('2')}
                                    />
                                }
                                label={'Instant Booking'}
                                labelPlacement='end'></FormControlLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={'1'}
                                        name={'Scheduled Booking'}
                                        onChange={handleBookingTypeCheck}
                                        defaultChecked={bookingTypeFilter.includes('1')}
                                    />
                                }
                                label={'Scheduled Booking'}
                                labelPlacement='end'></FormControlLabel>
                        </FormGroup>
                    </Box>
                    <Box sx={commonFilterStyles.buttonBox_booking}>
                        <AnimateButton>
                            <Button
                                style={{ width: '140px' }}
                                disableElevation
                                size='large'
                                type='submit'
                                variant='contained'
                                onClick={onClearClick}>
                                {'Reset Filter'}
                            </Button>
                        </AnimateButton>
                        <AnimateButton>
                            <Button
                                style={{ width: '140px' }}
                                disableElevation
                                size='large'
                                type='submit'
                                variant='contained'
                                onClick={onApplyClick}>
                                {'Apply Filter'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default BookingFilter;
