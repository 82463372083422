export const isValid = (name: string) => {
    const matchString = /^[a-zA-Z ]+$/;
    return matchString.test(name);
};

export const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));

export const passwordError =
    'Please enter minimum 8 character password with at least 1 uppercase, one numeric digit, 1 lower case & 1 special character.';
export const passRegrex =
    //eslint-disable-next-line
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?!.* ).{8,40}$/;
//eslint-disable-next-line
export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
