import { Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getClientDetail } from 'modules/clientManagement/action';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    UPDATE_CLIENT_DETAIL_REDUCER,
    UPDATE_CLIENT_BOOKING_MADE_REDUCER,
} from 'store/actionTypes';
import { ReducersModal } from 'utils/modals';
import { styles } from './styles';
import USER_IMG from 'assets/images/user-image.png';
import { genderEnum } from 'utils/enum';
import { DataFormater, calculateAge, getFormattedDate } from 'utils/constants';
import ClientTotalBookingPopup from './clientTotalBookingPopup';

const BasicProfile = () => {
    const {
        fullName,
        mobileNo,
        email,
        dob,
        gender,
        totalCompletedBookings,
        loader,
        profilePic,
        cancelledBookings,
        declinedBookings,
        totalAcceptedBookings,
        referralAmount,
    } = useSelector((state: ReducersModal) => state.ClientDetailReducer);

    const dispatch: any = useDispatch();
    const [showPopup, setShowPopup] = useState(false);
    const clientId = new URLSearchParams(window.location.search).get('id');
    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_DETAIL_REDUCER,
            payload,
        });
    };

    useLayoutEffect(() => {
        dispatch({
            type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
            payload: { result: [] },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatchValue({ clientId: clientId });
        dispatch(getClientDetail());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAge = useMemo(() => {
        return calculateAge(dob) || 'N/A';
    }, [dob]);

    const renderProfilePic = () => {
        let imgSrc = profilePic && profilePic.length ? profilePic : USER_IMG;
        return (
            <Box sx={styles.profileImgCon} id={'user-img'}>
                <img src={imgSrc} style={styles.img} alt='User-img' />
            </Box>
        );
    };

    const renderLoading = () => {
        return (
            <React.Fragment>
                <Box sx={styles.container}>
                    <Box sx={styles.upper}>
                        <Skeleton height='150px' width='150px' variant='circular'></Skeleton>
                        <Box sx={styles.counts_stack}>
                            <Skeleton height='120px' width='114px' variant='rectangular'></Skeleton>
                            <Skeleton height='120px' width='114px' variant='rectangular'></Skeleton>
                            <Skeleton height='120px' width='114px' variant='rectangular'></Skeleton>
                            <Skeleton height='120px' width='114px' variant='rectangular'></Skeleton>
                            <Skeleton height='120px' width='114px' variant='rectangular'></Skeleton>
                        </Box>
                    </Box>
                    <Box sx={styles.lower}>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                        <Skeleton height={35} width='50%' variant='rectangular'></Skeleton>
                    </Box>
                </Box>
            </React.Fragment>
        );
    };

    const renderCountsBoxes = () => {
        return (
            <Box sx={styles.counts_stack}>
                <Box
                    sx={{ ...styles.counts_container, cursor: 'pointer' }}
                    onClick={() => setShowPopup(true)}>
                    <Typography variant='h2'>{totalCompletedBookings || '0'}</Typography>
                    <Typography variant='h4'>{'All Comp. Booking'}</Typography>
                </Box>
                <Box sx={styles.counts_container}>
                    <Typography variant='h2'>{totalAcceptedBookings || '0'}</Typography>
                    <Typography variant='h4'>{'Accepted Booking'}</Typography>
                </Box>
                <Box sx={styles.counts_container}>
                    <Typography variant='h2'>{declinedBookings || '0'}</Typography>
                    <Typography variant='h4'>{'Declined Booking'}</Typography>
                </Box>
                <Box sx={styles.counts_container}>
                    <Typography variant='h2'>{cancelledBookings || '0'}</Typography>
                    <Typography variant='h4'>{'Cancelled Booking'}</Typography>
                </Box>
                <Box sx={styles.counts_container}>
                    <Typography variant='h2'>
                        {referralAmount
                            ? `$${DataFormater(parseFloat(referralAmount.toString()))}`
                            : `$0.00`}
                    </Typography>
                    <Typography variant='h4'>{'Ref. Earning'}</Typography>
                </Box>
            </Box>
        );
    };

    const renderView = () => {
        if (loader) {
            return renderLoading();
        }
        return (
            <Box sx={styles.container}>
                <Box sx={styles.upper}>
                    <Box sx={styles.img_container}>{renderProfilePic()}</Box>
                    {renderCountsBoxes()}
                </Box>
                <Box sx={styles.lower}>
                    <Box style={styles.data}>
                        <Typography variant='subtitle1' sx={styles.item_name}>
                            {'Client Name'}
                        </Typography>
                        <Typography style={styles.name}>{fullName || 'N/A'}</Typography>
                    </Box>
                    <Box style={styles.data}>
                        <Typography variant='subtitle1' sx={styles.item_name}>
                            {'Phone Number'}
                        </Typography>
                        <Typography style={styles.name}>{mobileNo || 'N/A'}</Typography>
                    </Box>
                    <Box style={styles.data}>
                        <Typography variant='subtitle1' sx={styles.item_name}>
                            {'Email ID'}
                        </Typography>
                        <Typography style={styles.name}>{email || 'N/A'}</Typography>
                    </Box>
                    <Box style={styles.data}>
                        <Typography variant='subtitle1' sx={styles.item_name}>
                            {'Age'}
                        </Typography>
                        <Typography style={styles.name}>{getAge}</Typography>
                    </Box>
                    <Box style={styles.data}>
                        <Typography variant='subtitle1' sx={styles.item_name}>
                            {'Date of Birth'}
                        </Typography>
                        <Typography style={styles.name}>
                            {dob ? getFormattedDate(dob, 'DD MMM YYYY') : 'N/A'}
                        </Typography>
                    </Box>
                    <Box style={styles.data}>
                        <Typography variant='subtitle1' sx={styles.item_name}>
                            {'Gender'}
                        </Typography>
                        <Typography style={styles.name}>{genderEnum[gender] || 'N/A'}</Typography>
                    </Box>
                </Box>
            </Box>
        );
    };
    return (
        <Box>
            {renderView()}
            {showPopup && <ClientTotalBookingPopup onClose={() => setShowPopup(false)} />}
        </Box>
    );
};

export default BasicProfile;
