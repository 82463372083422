import {
    UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
    UPDATE_MANAGE_DISPUTE_REDUCER,
} from 'store/actionTypes';
import { ENDPOINTS } from 'utils/endpoints';
import { APIResponseModal } from 'utils/modals';
import { GET_API_CALL, POST_API_CALL } from 'utils/services';
import dayjs from 'dayjs';
import SHOW_TOAST from 'utils/showToast';

export const getManageAndDisputeListing = () => {
    return (dispatch: Function, getState: Function) => {
        const {
            page,
            limit,
            search,
            sortedBy,
            sortOrder,
            toDate,
            fromDate,
            statusFilter,
            commentFilter,
            grievanceType,
            senderType,
        } = getState().ManageDisputeReducer;

        let parameters = '?';

        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (grievanceType) parameters += `grievanceType=${grievanceType}&`;
        if (senderType) parameters += `role=${senderType}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;
        if (fromDate) parameters += `fromDate=${dayjs(fromDate).format('YYYY-MM-DD')}&`;
        if (toDate) parameters += `toDate=${dayjs(toDate).format('YYYY-MM-DD')}&`;
        if (statusFilter?.value?.length > 0) {
            parameters += `requestStatus=${statusFilter.value}&`;
        }
        if (commentFilter?.value?.length > 0) {
            parameters += `grievances=${parseInt(commentFilter.value)}&`;
        }
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.CUSTOMER_SATISFACTION.MANAGE_DISPUTE_LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_MANAGE_DISPUTE_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_MANAGE_DISPUTE_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

export const getManageDisputeDetails = (grievanceId: any, grievanceType: number) => {
    return (dispatch: Function, getState: Function) => {
        let parameters = '?';

        if (grievanceType) parameters += `grievanceType=${grievanceType}&`;
        if (grievanceId) parameters += `grievanceId=${grievanceId}`;

        dispatch({
            type: UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.CUSTOMER_SATISFACTION.MANAGE_DISPUTE_DETAILS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
                        payload: { ...response.data.result },
                    });
                }
                dispatch({
                    type: UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

export const updateDisputeStatus = (data: any, callback: Function) => {
    return () => {
        const dataToSend = {
            grievanceId: data.grievanceId,
            requestStatus: data.status,
        };
        POST_API_CALL(
            ENDPOINTS.CUSTOMER_SATISFACTION.UPDATE_DISPUTE_STATUS,
            dataToSend,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                    callback();
                }
            },
        );
    };
};

export const sendWarningMail = (data: any, callback: Function) => {
    return () => {
        const dataToSend = {
            userId: data.senderId,
            bookingId: data.bookingId,
        };
        POST_API_CALL(
            ENDPOINTS.CUSTOMER_SATISFACTION.SEND_WARNING_MAIL,
            dataToSend,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                    callback();
                }
            },
        );
    };
};
