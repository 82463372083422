import { styles } from '../styles';
import { Box, styled } from '@mui/material';
import Chart from '../graphRepresentation/chart';
import DurationFilter from '../durationFilter';
const AverageHourlyRate = ({
    filteredAvrHourlyRate,
    onDurationFilter,
    avrHourlyRateState,
    averageRate,
}: any) => {
    const ItemContainer = styled('div')(() => ({
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #000000;',
        padding: '20px',
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        position: 'relative',
    }));
    const xDataKey = '_id';
    const yDataKey = 'count';

    return (
        <ItemContainer sx={{ width: '48%' }}>
            <Box sx={styles.reportAndAnalytics}>
                <Box sx={styles.tabularText}>Average Hourly Rate</Box>
                <DurationFilter
                    durationState={avrHourlyRateState}
                    filterKey={10}
                    onDurationFilter={onDurationFilter}
                />
            </Box>
            <Box sx={styles.chartDimension}>
                <Chart
                    durationState={avrHourlyRateState}
                    result={filteredAvrHourlyRate}
                    activeTab={4}
                    xDataKey={xDataKey}
                    yDataKey={yDataKey}
                    yLabel={'Photographer'}
                    xLabel={'Photoshoot price (in $)'}
                />
            </Box>
            <Box sx={[styles.reportAndAnalytics, styles.averageContainer]}>
                <Box sx={[styles.reportAndAnalytics, { width: '80%' }]}>
                    <Box sx={[styles.tabularText, { flex: 'unset' }]}>Average hourly rate</Box>
                    <Box sx={[styles.tabularText, { flex: 'unset' }]}>
                        {averageRate ? `$${averageRate?.toFixed(2)}` : `$${averageRate}`}
                    </Box>
                </Box>
            </Box>
        </ItemContainer>
    );
};

export default AverageHourlyRate;
