import { Box, Skeleton } from '@mui/material';
import React from 'react';

export default function DetailsSkeleton() {
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton
                    height={'28px'}
                    width={'300px'}
                    variant='rectangular'
                    sx={{ marginBottom: '18px' }}
                />
                <Skeleton
                    height={'45px'}
                    width={'110px'}
                    variant='rectangular'
                    sx={{ marginBottom: '18px', borderRadius: '4px' }}
                />
            </Box>
            <Skeleton
                height={'200px'}
                width={'100%'}
                variant='rectangular'
                sx={{ borderRadius: '10px' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Skeleton
                    height={'28px'}
                    width={'300px'}
                    variant='rectangular'
                    sx={{ marginBottom: '18px' }}
                />
            </Box>
            <Skeleton
                height={'200px'}
                width={'100%'}
                variant='rectangular'
                sx={{ borderRadius: '10px' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Skeleton
                    height={'28px'}
                    width={'300px'}
                    variant='rectangular'
                    sx={{ marginBottom: '18px' }}
                />
                <Skeleton
                    height={'45px'}
                    width={'110px'}
                    variant='rectangular'
                    sx={{ marginBottom: '18px', borderRadius: '4px' }}
                />
            </Box>
            <Skeleton
                height={'200px'}
                width={'100%'}
                variant='rectangular'
                sx={{ borderRadius: '10px' }}
            />
        </React.Fragment>
    );
}
