import { combineReducers } from 'redux';
import AuthReducer from 'modules/auth/reducer';
import {
    ClientReducer,
    ClientDetailReducer,
    ClientVendorBookingMadeReducer,
    ClientVendorBookingDetailReducer,
} from 'modules/clientManagement/reducer';
import {
    PhotographerReducer,
    PhotographerDetailReducer,
    PhotographerBookingMadeReducer,
} from 'modules/photographersManagement/reducer';
import { CategoryReducer } from 'modules/categoryManagement/reducer';
import { ReportsAndAnalyticsReducer } from 'modules/reportAndAnalytics/reducer';
import {
    ManageDisputeReducer,
    ManageDisputeDetailsReducer,
    FlaggedAccountDetailsReducer,
    FlaggedAccountReducer,
} from 'modules/customerSatisfaction/reducer';
import {
    RatingAndReviewReducer,
    RatingAndReviewDetailReducer,
} from 'modules/ratingReviewManagement/reducer';
import {
    EarnAndTransactionReducer,
    EarnAndTransactionDetailReducer,
    TransactionDetailReducer,
} from 'modules/earningTransactionManagement/reducer';
import {
    NotificationReducer,
    NotificationDetailReducer,
} from 'modules/notificationsManagement/reducer';
import { ContentReducer } from 'modules/contentManagement/reducer';
import {
    RewardsManagementReducer,
    RewardsManagementDetailsReducer,
} from 'modules/rewardsProgram/reducer';
import { OffersManagementReducer } from 'modules/offerManagement/reducer';
const RootReducer = combineReducers({
    AuthReducer,
    ClientReducer,
    PhotographerReducer,
    CategoryReducer,
    ClientDetailReducer,
    ClientVendorBookingMadeReducer,
    ClientVendorBookingDetailReducer,
    PhotographerDetailReducer,
    ReportsAndAnalyticsReducer,
    PhotographerBookingMadeReducer,
    RatingAndReviewReducer,
    RatingAndReviewDetailReducer,
    NotificationReducer,
    NotificationDetailReducer,
    EarnAndTransactionReducer,
    EarnAndTransactionDetailReducer,
    ManageDisputeReducer,
    FlaggedAccountReducer,
    ManageDisputeDetailsReducer,
    FlaggedAccountDetailsReducer,
    ContentReducer,
    RewardsManagementReducer,
    OffersManagementReducer,
    RewardsManagementDetailsReducer,
    TransactionDetailReducer,
});

export default RootReducer;
