import { Box, Button, FormControl, Input, Skeleton, Typography } from '@mui/material';
import { styles } from './styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editContent, getContentListing } from './action';
import { ReducersModal } from 'utils/modals';

const ContentManagement = () => {
    const { PrivacyPolicy, Faq, Tnc, loader } = useSelector(
        (state: ReducersModal) => state.ContentReducer,
    );
    const dispatch: any = useDispatch();
    const [editPolicy, setEditPolicy] = useState('Edit');
    const [editFaq, setEditFaq] = useState('Edit');
    const [editTnc, setEditTnc] = useState('Edit');
    const [privacyPolicy, setPrivacyPolicy] = useState(PrivacyPolicy.url);
    const [faq, setFaq] = useState(Faq.url);
    const [tnc, setTnc] = useState(Tnc.url);

    useEffect(() => {
        setPrivacyPolicy(PrivacyPolicy.url);
        setFaq(Faq.url);
        setTnc(Tnc.url);
    }, [PrivacyPolicy, Faq, Tnc]);

    const renderHeading = (text: any) => {
        return (
            <Typography variant='h3' component={'h3'}>
                {text}
            </Typography>
        );
    };

    const checkText = (editPolicy: any) => {
        const text = editPolicy;
        if (text === 'Edit') {
            return 'Save';
        }
        return 'Edit';
    };

    const onPolicyEditClick = () => {
        setEditPolicy(checkText(editPolicy));
        if (editPolicy === 'Save') {
            const dataToSend = {
                pageId: 1,
                url: privacyPolicy,
            };
            dispatch(
                editContent(dataToSend, () => {
                    dispatch(getContentListing());
                }),
            );
        }
        return;
    };

    const onFaqEditClick = () => {
        setEditFaq(checkText(editFaq));
        if (editFaq === 'Save') {
            const dataToSend = {
                pageId: 2,
                url: faq,
            };
            dispatch(
                editContent(dataToSend, () => {
                    dispatch(getContentListing());
                }),
            );
        }
        return;
    };

    const onTncEditClick = () => {
        setEditTnc(checkText(editTnc));
        if (editTnc === 'Save') {
            const dataToSend = {
                pageId: 3,
                url: tnc,
            };
            dispatch(
                editContent(dataToSend, () => {
                    dispatch(getContentListing());
                }),
            );
        }
        return;
    };

    useEffect(() => {
        dispatch(getContentListing());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderSkeleton = () => {
        return (
            <Box sx={styles.paper}>
                <Skeleton
                    sx={{ ...styles.item_cont, border: 'none' }}
                    variant='rectangular'></Skeleton>
                <Skeleton
                    sx={{ ...styles.item_cont, border: 'none' }}
                    variant='rectangular'></Skeleton>
                <Skeleton
                    sx={{ ...styles.item_cont, border: 'none' }}
                    variant='rectangular'></Skeleton>
            </Box>
        );
    };

    const renderView = () => {
        return (
            <Box sx={styles.paper}>
                <Box sx={styles.item_cont}>
                    <Box sx={styles.item_cont_upper}>
                        {renderHeading('Privacy Policy')}
                        <Box sx={styles.button}>
                            <Button onClick={() => onPolicyEditClick()}>{editPolicy}</Button>
                        </Box>
                    </Box>
                    <Box sx={styles.item_cont_lower}>
                        <FormControl fullWidth>
                            <Input
                                disabled={editPolicy === 'Edit'}
                                type='url'
                                value={privacyPolicy}
                                inputProps={{ autoComplete: 'new-password' }}
                                sx={styles.input}
                                disableUnderline={true}
                                onChange={(e) => setPrivacyPolicy(e.target.value)}></Input>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={styles.item_cont}>
                    <Box sx={styles.item_cont_upper}>
                        {renderHeading('FAQ')}
                        <Box sx={styles.button}>
                            <Button onClick={() => onFaqEditClick()}>{editFaq}</Button>
                        </Box>
                    </Box>
                    <Box sx={styles.item_cont_lower}>
                        <FormControl fullWidth>
                            <Input
                                disabled={editFaq === 'Edit'}
                                type='url'
                                value={faq}
                                inputProps={{ autoComplete: 'new-password' }}
                                sx={styles.input}
                                disableUnderline={true}
                                onChange={(e) => setFaq(e.target.value)}></Input>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={styles.item_cont}>
                    <Box sx={styles.item_cont_upper}>
                        {renderHeading('Terms of Use')}
                        <Box sx={styles.button}>
                            <Button onClick={() => onTncEditClick()}>{editTnc}</Button>
                        </Box>
                    </Box>
                    <Box sx={styles.item_cont_lower}>
                        <FormControl fullWidth>
                            <Input
                                disabled={editTnc === 'Edit'}
                                type='url'
                                value={tnc}
                                inputProps={{ autoComplete: 'new-password' }}
                                sx={styles.input}
                                disableUnderline={true}
                                onChange={(e) => setTnc(e.target.value)}></Input>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        );
    };

    return <Box>{loader ? renderSkeleton() : renderView()}</Box>;
};

export default ContentManagement;
