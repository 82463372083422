export const commonFilterStyles = {
    buttonStyle: {
        marginLeft: '10px',
        height: '45px',
        width: '45px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#ede7f6',
        border: '1px solid #5e35b1',
        '& svg': { color: '#5e35b1' },
        '&:hover': { background: '#5e35b1', '& svg': { color: 'white' } },
    },
    iconColor: { color: 'fff' },
    paper: {
        width: 'fit-content',
        marginTop: '4px',
        borderRadius: '4px',
    },
    heading: {
        marginBottom: '25px',
    },
    filterLabel: {
        font: 'normal normal 400 14px/24px Roboto',
        textAlign: 'start',
        color: 'black',
        padding: '5px',
    },
    buttonBox: {
        width: '100%',
        display: 'flex',
        marginTop: '25px',
        justifyContent: 'space-between',
    },
    zipCodeField: {
        width: 300,
        margin: '25px 0px',
        '& input': {
            fontSize: '0.8125rem',
            color: '#364152',
            fontWeight: '300',
            boxShadow: 'none',
        },
    },
    commentfield: {
        width: 300,
        margin: '25px 0px',
        '& input': {
            fontSize: '0.8125rem',
            color: '#364152',
            fontWeight: '300',
            boxShadow: 'none',
        },
    },
    selectDropdown: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: '0.8125rem',
        color: '#364152',
        fontWeight: '400',
        boxShadow: 'none',
        '& input': {
            fontSize: '0.8125rem',
            color: '#364152',
            fontWeight: '300',
            boxShadow: 'none',
        },
        '& > div': {
            background: 'none',
            color: 'black',
            borderRadius: '8px',
            fontSize: '0.8125rem',
            fontWeight: '400',
        },
        '& fieldset': {
            color: 'black',
            borderRadius: '8px',
        },
    },
    bkg_flt_top_bar: {
        height: '40px',
        width: '100%',
        position: 'fixed',
        top: '0px',
        padding: '10px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid black',
    },
    booking_modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minHeight: 420,
        height: 'fit-content',
        width: 350,
        bgcolor: 'background.paper',
        border: '1px solid lightGray',
        boxShadow: 24,
        borderRadius: '12px',
    },
    buttonBox_booking: {
        width: '100%',
        display: 'flex',
        marginTop: '25px',
        justifyContent: 'space-between',
        padding: '0px 25px',
        position: 'absolute',
        bottom: '20px',
    },
    formGroup: {
        marginTop: '10px',
        '& label': {
            maxHeight: '30px',
        },
    },
    dateFilter: {
        height: '50px',
        border: '1px solid lightGray',
        borderRadius: '12px',
        padding: '12px',
        background: '#F8FAFC',
        width: 300,
        margin: '25px 0px',
    },
};
