import { ENDPOINTS } from 'utils/endpoints';
import { GET_API_CALL, POST_API_CALL_FILE_DOWNLOAD } from 'utils/services';
import { APIResponseModal } from 'utils/modals';
import {
    UPDATE_EARN_AND_TRANSACTION_DETAIL_REDUCER,
    UPDATE_EARN_AND_TRANSACTION_REDUCER,
    UPDATE_TRANSACTION_DETAIL_REDUCER,
} from 'store/actionTypes';
import SHOW_TOAST from 'utils/showToast';
import dayjs from 'dayjs';

/**
 *
 * @returns Earn and transaction listing
 */
export const getEarnAndTransactionListing = () => {
    return (dispatch: Function, getState: Function) => {
        const {
            page,
            limit,
            search,
            sortedBy,
            sortOrder,
            toDate,
            fromDate,
            fromRange,
            toRange,
            filterStatus,
        } = getState().EarnAndTransactionReducer;

        let parameters = '?';

        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;
        if (fromDate) parameters += `fromDate=${dayjs(fromDate).format('YYYY-MM-DD')}&`;
        if (toDate) parameters += `toDate=${dayjs(toDate).format('YYYY-MM-DD')}&`;
        if (fromRange) parameters += `fromRange=${fromRange}&`;
        if (toRange) parameters += `toRange=${toRange}&`;
        if (filterStatus?.value?.length > 0)
            parameters += `transactionStatus=${filterStatus.valueArr}&`;

        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.EARN_AND_TRANSACTION_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param bookingId required to get earn and transaction details corresponding to the booking
 * @param callback will trigger on the response of API success/failure
 * @returns will return earn and transaction details of particular booking
 */
export const getEarnAdTransactionDetail = (bookingId: string, callback: Function) => {
    return (dispatch: Function) => {
        GET_API_CALL(
            ENDPOINTS.EARN_AND_TRANSACTION_MANAGEMENT.DETAIL,
            `${bookingId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_EARN_AND_TRANSACTION_DETAIL_REDUCER,
                        payload: { ...response.data },
                    });
                }
                callback(response);
            },
        );
    };
};

/**
 *
 * @param dateFilter will be the duration for which report is required can be today/week/month/year
 * @returns will return reports data accordingly
 */

export const getTransactionReportData = (dateFilter: number) => {
    return (dispatch: Function) => {
        let parameters = '?';
        parameters += `dateFilter=${dateFilter}`;

        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.EARN_AND_TRANSACTION_MANAGEMENT.REPORTS,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
                        payload: {
                            reports: response.data.result,
                            totalOrderValue: response.data.totalOrderValue,
                            totalPlandidEarnings: response.data.totalPlandidEarnings
                        },
                    });
                }
                dispatch({
                    type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param data will contain all the filters and filename to be
 * @param callback will execute on success/failure of API response
 * @returns will return success or failure on the basis of success/failure
 */

export const exportToCSV = (data: any, callback: Function) => {
    return () => {
        POST_API_CALL_FILE_DOWNLOAD(
            ENDPOINTS.EARN_AND_TRANSACTION_MANAGEMENT.EXPORT_TO_CSV,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const message = 'CSV Downloaded Successfully. ';
                    SHOW_TOAST(message, 'success');
                }
                callback(response);
            },
        );
    };
};

/**
 *
 * @param bookingId required to get transaction details corresponding to the booking
 * @param callback will trigger on the response of API success/failure
 * @returns will return transaction details of particular booking
 */
export const getTransactionDetail = (bookingId: any, callback: Function) => {
    return (dispatch: Function) => {
        GET_API_CALL(
            ENDPOINTS.EARN_AND_TRANSACTION_MANAGEMENT.TRANSACTION_DETAILS,
            `?bookingId=${bookingId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_TRANSACTION_DETAIL_REDUCER,
                        payload: { ...response.data },
                    });
                }
                callback(response);
            },
        );
    };
};
