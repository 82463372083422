import { ArrowDownward } from '@mui/icons-material';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonTableStyles as styles } from '../styles';
import { ReducersModal } from 'utils/modals';
import { UPDATE_MANAGE_DISPUTE_REDUCER } from 'store/actionTypes';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import { REQUEST_STATUS } from 'utils/enum';
import { getManageAndDisputeListing } from 'modules/customerSatisfaction/action';
import { getFormattedDate } from 'utils/constants';

const FeedbackTable = () => {
    const { result, page, limit, total, senderType } = useSelector(
        (state: ReducersModal) => state.ManageDisputeReducer,
    );
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [isSort, setIsSort] = useState<{ [key: string]: boolean }>({
        createdAt: false,
        fullName: false,
    });

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_REDUCER,
            payload,
        });
    };

    const handleSort = (sortCol: string) => {
        const newObject: any = {};
        Object.keys(isSort).forEach(function sort(key) {
            if (key === sortCol) {
                newObject[key] = !isSort[key];
            } else {
                newObject[key] = false;
            }
        });
        setIsSort(newObject);
        dispatchValue({
            page: 1,
            sortedBy: sortCol,
            sortOrder: newObject[sortCol] ? '1' : '-1',
        });

        dispatch(getManageAndDisputeListing());
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatchValue({ page: newPage + 1 });
        dispatch(getManageAndDisputeListing());
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        dispatchValue({ limit: event.target.value, page: 1 });
        dispatch(getManageAndDisputeListing());
    };

    return (
        <Box sx={styles.paper}>
            <TableContainer>
                <Table sx={styles.table}>
                    <TableHead sx={{ ...styles.tableHeader_cnt_algn }}>
                        <TableRow sx={styles.headerRow}>
                            <TableCell>{'S.No.'}</TableCell>
                            <TableCell>{'Issue ID'}</TableCell>
                            <TableCell>
                                {senderType === 1 ? 'Client Name' : 'Photographer Name'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['fullName'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('fullName')}
                                />
                            </TableCell>
                            <TableCell>
                                {'Date And Time'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['createdAt'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('createdAt')}
                                />
                            </TableCell>
                            <TableCell>{'Request Status'}</TableCell>
                            <TableCell className='action-table-header'>{'Action'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result && result.length > 0 ? (
                            result.map((val: any, idx: number) => {
                                let serialNumber = `${(page - 1) * limit + (idx + 1)}.`,
                                    userName = val?.clientDetails
                                        ? `${val.clientDetails?.fullName}`
                                        : `${val.vendorDetails?.fullName}`;

                                return (
                                    <TableRow
                                        key={val._id}
                                        sx={{ ...styles.customer_satisfaction }}>
                                        <TableCell>{serialNumber}</TableCell>
                                        <TableCell>{val?.grievanceRef || 'N/A'}</TableCell>
                                        <TableCell title={userName}>{userName}</TableCell>
                                        <TableCell>
                                            {getFormattedDate(val.createdAt, 'MM/DD/YYYY, hh:mm A')}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                            {val?.requestStatus
                                                ? REQUEST_STATUS[val?.requestStatus]
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={styles.action_table_cell_container}>
                                                <Button
                                                    className='action-view-btn'
                                                    onClick={() => {
                                                        navigate(
                                                            `${ROUTE_NAMES.MAIN_ROUTES.FEEDBACK_DETAIL}?id=${val._id}`,
                                                        );
                                                    }}>
                                                    {'View'}
                                                </Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={15} sx={styles.noDataCell}>
                                    <Box>{'No Data Found'}</Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={styles.paginationContainer}>
                <TablePagination
                    component='div'
                    count={total}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

export default FeedbackTable;
