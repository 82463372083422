import { UPDATE_OFFERS_REDUCER } from 'store/actionTypes';
import { ENDPOINTS } from 'utils/endpoints';
import { APIResponseModal } from 'utils/modals';
import { GET_API_CALL, PATCH_API_CALL, POST_API_CALL } from 'utils/services';
import SHOW_TOAST from 'utils/showToast';

/**
 *
 * @returns Will return all the available offers list
 */
export const getOffersList = () => {
    return (dispatch: Function) => {
        let parameters = '?';

        dispatch({
            type: UPDATE_OFFERS_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.OFFER_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_OFFERS_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_OFFERS_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param data will be payload to send in API request
 * @param callback will be the trigger after success/failure of API response
 * @returns will return API success/failure
 */
export const updateIndivisualOfferStatus = (data: any, callback: Function) => {
    return () => {
        PATCH_API_CALL(ENDPOINTS.OFFER_MANAGEMENT.LISTING, data, (response: APIResponseModal) => {
            if (response.isSuccess) {
                const { message } = response?.data;
                SHOW_TOAST(message, 'success');
            }
            callback();
        });
    };
};

/**
 *
 * @param data will be payload to send in API request
 * @param callback will be the trigger after success/failure of API response
 * @returns will return API success/failure
 */

export const offerActivateDeactivation = (data: any, callback: Function) => {
    return () => {
        POST_API_CALL(
            ENDPOINTS.OFFER_MANAGEMENT.ACTIVATE_DEACTIVATE,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                    callback();
                }
            },
        );
    };
};
