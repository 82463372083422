import { commonTableStyles as styles } from 'components/tables/styles';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import AVERAGE_RATING from 'assets/images/star.png';
import { DataFormater } from 'utils/constants';
import { bookingType, creatorType } from 'utils/enum';
import USER_IMG from 'assets/images/user-image.png';

const CommonTabularGraph = ({ result, keysObj, listData }: any) => {
    const textLeft =
        Object.keys(keysObj).includes('category') ||
        Object.keys(keysObj).includes('fullName') ||
        Object.keys(keysObj).includes('vendorName');
    return (
        <Box sx={styles.paper}>
            <TableContainer sx={{ maxHeight: '544px' }}>
                <Table sx={[styles.table, { marginBottom: 0 }]} stickyHeader>
                    <TableHead sx={styles.tableHeader_cnt_algn}>
                        <TableRow sx={styles.headerRow}>
                            {listData && <TableCell>S.No.</TableCell>}
                            {Object.values(keysObj).map((item: any, idx: number) => {
                                if (idx === 0 && textLeft) {
                                    return (
                                        <TableCell key={idx} sx={{ textAlign: 'left !important' }}>
                                            {item}
                                        </TableCell>
                                    );
                                }
                                return <TableCell key={idx}>{item}</TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result && result.length > 0 ? (
                            result.map((val: any, idx: number) => {
                                return (
                                    <TableRow key={idx} sx={{ ...styles.row_earnings }}>
                                        {listData && <TableCell>{idx + 1}.</TableCell>}
                                        {Object.keys(keysObj).map((key: any, index: number) => {
                                            let imgSrc = USER_IMG;
                                            if (
                                                (typeof result[idx][key] === 'object' &&
                                                    key !== '_id') ||
                                                key === 'fullName' ||
                                                key === 'vendorName'
                                            ) {
                                                if (
                                                    result[idx][key]?.icon ||
                                                    result[idx]['profilePic'] ||
                                                    result[idx]['vendorProfilePic']
                                                ) {
                                                    let tempImgSrc =
                                                        result[idx][key]?.icon ||
                                                        result[idx]['profilePic'] ||
                                                        result[idx]['vendorProfilePic'];
                                                    let format = tempImgSrc?.substring(
                                                        tempImgSrc?.lastIndexOf('.') + 1,
                                                        tempImgSrc?.length,
                                                    );
                                                    if (
                                                        format === 'jpg' ||
                                                        format === 'png' ||
                                                        format === 'jpeg'
                                                    ) {
                                                        imgSrc = tempImgSrc;
                                                    }
                                                }
                                            }
                                            if (
                                                (typeof result[idx][key] === 'object' &&
                                                    key !== '_id') ||
                                                key === 'fullName' ||
                                                key === 'vendorName'
                                            ) {
                                                return (
                                                    <TableCell
                                                        title={result[idx][key]?.fullName}
                                                        key={index}>
                                                        <Box
                                                            sx={[
                                                                styles.icon_box,
                                                                { justifyContent: 'flex-start' },
                                                            ]}>
                                                            <img
                                                                alt='graphImg'
                                                                src={imgSrc}
                                                                style={{
                                                                    ...styles.categoryImg,
                                                                    borderRadius: '30px',
                                                                }}
                                                            />
                                                            {result[idx]['fullName'] ||
                                                                result[idx]['vendorName'] ||
                                                                result[idx][key]?.name ||
                                                                'N/A'}
                                                        </Box>
                                                    </TableCell>
                                                );
                                            } else if (key !== '_id') {
                                                if (key === 'averageRatings') {
                                                    return (
                                                        <TableCell
                                                            className='action-table-header'
                                                            key={index}>
                                                            <Box sx={styles.icon_box}>
                                                                <img
                                                                    src={AVERAGE_RATING}
                                                                    style={styles.img}
                                                                    alt='Rating-img'
                                                                />
                                                                {result[idx][key]?.toFixed(1) || 0}
                                                            </Box>
                                                        </TableCell>
                                                    );
                                                } else {
                                                    let defaultValue =
                                                        key === 'amount' ||
                                                        key === 'bookingsCount' ||
                                                        key === 'bookingCount'
                                                            ? 0
                                                            : 'N/A';
                                                    return (
                                                        <TableCell
                                                            title={
                                                                key !== 'bookingType' &&
                                                                key !== 'creatorType' &&
                                                                result[idx][key]
                                                            }
                                                            key={index}>
                                                            {key === 'amount'
                                                                ? `$${DataFormater(
                                                                      parseFloat(val['amount']),
                                                                  )}`
                                                                : key === 'creatorType'
                                                                ? creatorType[result[idx][key]]
                                                                : key === 'bookingType'
                                                                ? bookingType[result[idx][key]]
                                                                : result[idx][key] || defaultValue}
                                                            {key === 'bookingType' &&
                                                                bookingType[result[idx][key]] &&
                                                                ' Booking'}
                                                        </TableCell>
                                                    );
                                                }
                                            }
                                            return null;
                                        })}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={15} sx={styles.noDataCell}>
                                    <Box>{'No Data Found'}</Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CommonTabularGraph;
