import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { commonTableStyles as styles } from 'components/tables/styles';
import { styles as reportStyles } from '../styles';
import CircleIcon from '@mui/icons-material/Circle';
import DurationFilter from '../durationFilter';
import CurveChart from '../graphRepresentation/lineChart';

const TotalBookingVsTips = ({
    totalBookingVsTipsState,
    onDurationFilter,
    filteredTotalBookingVsTips,
}: any) => {
    const ItemContainer = styled('div')(() => ({
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #000000;',
        marginTop: '20px',
        padding: '20px',
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        position: 'relative',
    }));
    return (
        <ItemContainer>
            <Box sx={reportStyles.reportAndAnalytics}>
                <Box sx={reportStyles.tabularText}>No. of Booking v/s No. of Tip </Box>
                <DurationFilter
                    durationState={totalBookingVsTipsState}
                    filterKey={8}
                    onDurationFilter={onDurationFilter}
                />
            </Box>
            <Box sx={[reportStyles.paper, { display: 'flex', border: 0 }]}>
                <Box sx={styles.tips_container}>
                    <Box sx={[styles.icon_box, { justifyContent: 'space-around' }]}>
                        <CircleIcon sx={[reportStyles.curve_dot, { color: '#2196f3' }]} />
                        <Typography color={'#2196f3'} variant='h5'>
                            {'Tips'}
                        </Typography>
                    </Box>
                    <Box sx={[styles.icon_box, { justifyContent: 'space-around' }]}>
                        <CircleIcon sx={reportStyles.curve_dot} />
                        <Typography color={'#673AB7'} variant='h5'>
                            {'Bookings'}
                        </Typography>
                    </Box>
                </Box>
                <CurveChart
                    result={filteredTotalBookingVsTips}
                    durationState={totalBookingVsTipsState}
                />
            </Box>
        </ItemContainer>
    );
};
export default TotalBookingVsTips;
