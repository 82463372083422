import * as Yup from 'yup';
import { passRegrex, passwordError, phoneRegExp } from './validation';

export const LoginSchema = () =>
    Yup.object().shape({
        email: Yup.string()
            .trim()
            .required('Enter the required field')
            .email('Enter a valid email')
            .max(40, 'Email should be maximum of 40 characters'),
        password: Yup.string()
            .trim()
            .required('Enter the required field')
            .max(16, 'Password should be maximum of 16 characters')
            .min(8, 'Password should be minimum of 8 characters')
            .matches(passRegrex, passwordError),
    });

export const ForgotPasswordSchema = () =>
    Yup.object().shape({
        email: Yup.string()
            .trim()
            .required('Enter the required field')
            .email('Please enter a valid email address'),
    });

export const OtpSchema = () => {
    return Yup.object().shape({
        otp: Yup.string()
            .trim()
            .required('Please fill in the required field')
            .matches(/^[0-9]/, 'OTP must be a number')
            .max(6, 'OTP must be 6 digit number')
            .min(6, 'OTP must be 6 digit number'),
    });
};

export const ResetPasswordSchema = () =>
    Yup.object().shape({
        NewPassword: Yup.string()
            .trim()
            .required('Password is required')
            .matches(passRegrex, passwordError)
            .max(16, 'Password should be maximum of 16 characters')
            .min(8, 'Password should be minimum of 8 characters'),
        ConfirmPassword: Yup.string()
            .trim()
            .required('Password is required')
            .matches(passRegrex, passwordError)
            .max(16, 'Password should be maximum of 16 characters')
            .min(8, 'Password should be minimum of 8 characters')
            .oneOf([Yup.ref('NewPassword')], 'New and Confirm Password should be same.'),
    });

export const ChangePasswordSchema = () =>
    Yup.object().shape({
        oldPassword: Yup.string().trim().required('Old Password is required'),
        newPassword: Yup.string()
            .trim()
            .required('New Password is required')
            .matches(passRegrex, passwordError)
            .max(16, 'Password should be maximum of 16 characters')
            .min(8, 'Password should be minimum of 8 characters'),
        confirmPassword: Yup.string()
            .trim()
            .required('Confirm Password is required ')
            .matches(passRegrex, passwordError)
            .max(16, 'Password should be maximum of 16 characters')
            .min(8, 'Password should be minimum of 8 characters')
            .oneOf([Yup.ref('newPassword')], 'New and Confirm Password should be same.'),
    });

export const AccountSettingsSchema = () => {
    return Yup.object().shape({
        email: Yup.string().trim().required('Email is required').email('Must be a valid email'),
        name: Yup.string().trim().required('Name is required'),
        mobile: Yup.string()
            .trim()
            .required('Enter the required field')
            .matches(phoneRegExp, 'Please enter valid Mobile Number')
            .min(10, 'Mobile Number must be 10 digit number'),
    });
};

export const CategoryManagementSchema = () => {
    return Yup.object().shape({
        image: Yup.string().required('Please upload the image.'),
        icon: Yup.string().required('Please upload category icon.'),
        name: Yup.string()
            .trim()
            .required('Please enter the required field')
            .max(40, 'Category name should be maximum of 40 characters'),
    });
};

export const AddReviewSchema = () => {
    return Yup.object().shape({
        text: Yup.string().trim().max(500, 'Description should be maximum of 500 characters'),
    });
};

export const UserBlockedSchema = () => {
    return Yup.object().shape({
        reason: Yup.string().required('Please select a reason'),
        text: Yup.string().when('reason', (reason, schema) => {
            if (reason[0] === 'Others') return schema.required('Enter the required field');
            return schema;
        }),
    });
};

export const PushNotificationSchema = () => {
    return Yup.object().shape({
        description: Yup.string()
            .trim()
            .required('Please enter the required field')
            .max(500, 'Description should be maximum of 500 characters'),
        title: Yup.string()
            .trim()
            .required('Please enter the required field')
            .max(120, 'Title should be maximum of 120 characters'),
        recipientType: Yup.string().required('Please select recipient'),
    });
};

export const SmsNotificationSchema = () => {
    return Yup.object().shape({
        description: Yup.string()
            .trim()
            .required('Please enter the required field')
            .max(500, 'Description should be maximum of 500 characters'),
        recipientType: Yup.string().required('Please select recipient'),
    });
};

export const EmailNotificationSchema = () => {
    return Yup.object().shape({
        description: Yup.string()
            .trim()
            .required('Please enter the required field')
            .max(500, 'Description should be maximum of 500 characters'),
        tagline: Yup.string()
            .trim()
            .required('Please enter the required field')
            .max(120, 'Title should be maximum of 100 characters'),
        recipientType: Yup.string().required('Please select recipient'),
    });
};
