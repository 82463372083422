import { styles } from '../styles';
import { Box, styled } from '@mui/material';
import Chart from '../graphRepresentation/chart';
import DurationFilter from '../durationFilter';
const AverageShootDuration = ({
    filteredAvrShootDuration,
    avrShootDurationState,
    onDurationFilter,
    averageDuration,
}: any) => {
    const ItemContainer = styled('div')(() => ({
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #000000;',
        padding: '20px',
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        position: 'relative',
    }));
    const xDataKey = '_id';
    const yDataKey = 'count';
    return (
        <ItemContainer sx={{ width: '48%' }}>
            <Box sx={styles.reportAndAnalytics}>
                <Box sx={styles.tabularText}>Average Photoshoot Duration</Box>
                <DurationFilter
                    durationState={avrShootDurationState}
                    filterKey={11}
                    onDurationFilter={onDurationFilter}
                />
            </Box>
            <Box sx={styles.chartDimension}>
                <Chart
                    durationState={avrShootDurationState}
                    result={filteredAvrShootDuration}
                    activeTab={4}
                    xDataKey={xDataKey}
                    yDataKey={yDataKey}
                    yLabel={'Client Booked'}
                    xLabel={'Photoshoot Duration (in hour)'}
                />
            </Box>
            <Box sx={[styles.reportAndAnalytics, styles.averageContainer]}>
                <Box sx={[styles.reportAndAnalytics, { width: '80%' }]}>
                    <Box sx={[styles.tabularText, { flex: 'unset' }]}>
                        Average photoshoot duration
                    </Box>
                    <Box sx={[styles.tabularText, { flex: 'unset' }]}>
                        {averageDuration
                            ? `${averageDuration?.split('-')[0]}h - ${
                                  averageDuration?.split('-')[1]
                              }h`
                            : '0h'}
                    </Box>
                </Box>
            </Box>
        </ItemContainer>
    );
};

export default AverageShootDuration;
