import { Box, LinearProgress, Switch, Typography, styled } from '@mui/material';
import { styles } from './styles';
import TICK_ICON from 'assets/images/tick.png';
import { useDispatch, useSelector } from 'react-redux';
import { getOffersList, offerActivateDeactivation, updateIndivisualOfferStatus } from './action';
import { ReducersModal } from 'utils/modals';
import { useEffect } from 'react';
import { UPDATE_OFFERS_REDUCER } from 'store/actionTypes';
import RewardsSkeleton from 'modules/rewardsProgram/rewardsSkeleton';
import Loader from 'components/loader';

const OfferManagement = () => {
    const NoDataContainer = styled('div')(() => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));
    const dispatch: any = useDispatch();
    const { result, loader, offerFeature, clientsCount } = useSelector(
        (state: ReducersModal) => state.OffersManagementReducer,
    );
    useEffect(() => {
        dispatch(getOffersList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const renderOfferDetail = (benefit: string, index: any) => {
        return (
            <Box sx={styles.reward_detail_text} key={index}>
                <img style={styles.tick} alt='tick' src={TICK_ICON}></img>
                <Typography variant='caption'>{benefit}</Typography>
            </Box>
        );
    };

    const handleOfferActivation = (e: any) => {
        const params = {
            featureType: 2,
            flag: !e.target.checked,
        };

        dispatch({
            type: UPDATE_OFFERS_REDUCER,
            offerFeature: e.target.checked,
        });
        dispatch(
            offerActivateDeactivation(params, () => {
                dispatch(getOffersList());
            }),
        );
    };

    const handleIndivisualOfferActivation = (e: any, offerId: string, status: number) => {
        const params = {
            offerId: offerId,
            status: status,
        };
        dispatch(
            updateIndivisualOfferStatus(params, () => {
                dispatch(getOffersList());
            }),
        );
    };

    const renderView = () => {
        if (result.length === 0 && loader) {
            return <RewardsSkeleton />;
        }
        if (result.length === 0 && !loader) {
            return (
                <NoDataContainer>
                    <Typography
                        sx={{ color: '#673ab7', marginTop: '180px' }}
                        variant='h2'
                        component='h2'>
                        {'No Offer Exists.'}
                    </Typography>
                </NoDataContainer>
            );
        }
        return (
            <Box>
                {result.length > 0 &&
                    result.map(({ benefits, _id, title, value, status, count }) => {
                        let redeemedCount = count || 0;

                        return (
                            <Box sx={styles.item_cont} key={_id}>
                                <Box sx={styles.top_bar}>
                                    <Typography variant='h2'>{title}</Typography>
                                    <Box
                                        sx={styles.btn_box}
                                        onClick={(e: any) => {
                                            handleIndivisualOfferActivation(
                                                e,
                                                _id,
                                                status === 1 ? 2 : 1,
                                            );
                                        }}>
                                        <Box sx={styles.tag}>
                                            {status === 1 ? 'Active' : 'In-Active'}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={styles.bottom_box}>
                                    <Box sx={styles.bottom_left}>
                                        <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                            {'Benefits for Client'}
                                        </Typography>
                                        {benefits.map((benefit, index) => {
                                            return renderOfferDetail(benefit, index);
                                        })}
                                    </Box>
                                    <Box sx={styles.bottom_right}>
                                        <Typography variant='h2'>
                                            {`$`}
                                            {value}
                                            {` OFF`}
                                        </Typography>
                                        <Typography variant='h5' sx={styles.client_count_text}>
                                            {`Offer redeemed by : ${redeemedCount} ${
                                                redeemedCount > 1 ? 'Clients' : 'Client'
                                            }`}
                                        </Typography>
                                        <Box sx={{ width: '300px' }}>
                                            <LinearProgress variant='determinate' value={50} />
                                        </Box>
                                        <Typography variant='caption' sx={{ fontStyle: 'italic' }}>
                                            {`${clientsCount} ${
                                                clientsCount > 1 ? 'Clients' : 'Client'
                                            }`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
        );
    };
    return (
        <Box sx={styles.paper}>
            <Box sx={[styles.toogleContainer, { justifyContent: 'flex-end', marginBottom: '7px' }]}>
                <Box sx={styles.toogleContainer}>
                    <Typography variant='h5' sx={{ fontSize: '1rem' }}>
                        Activate
                    </Typography>
                    <Switch
                        aria-label='offer'
                        checked={!offerFeature}
                        onChange={(e) => {
                            handleOfferActivation(e);
                        }}></Switch>
                    <Typography variant='h5' sx={{ fontSize: '1rem' }}>
                        Deactivate
                    </Typography>
                </Box>
            </Box>
            <>
                <Box>{renderView()}</Box>
                {loader && result.length > 0 && <Loader />}
            </>
        </Box>
    );
};

export default OfferManagement;
