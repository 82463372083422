import { styles } from '../styles';
import { Box, styled } from '@mui/material';
import CommonTabularGraph from '../graphRepresentation/commonTabularGraph';
import ContainedButton from 'components/button/contained-button';
import { useEffect, useState } from 'react';
const MostBookedLocation = ({ filteredMostBookedLocation }: any) => {
    const [showMore, setShowMore] = useState(false);
    const [data, setData] = useState([]);
    const onShowMoreClick = () => {
        setShowMore(!showMore);
    };
    useEffect(() => {
        let tempState: any = [...filteredMostBookedLocation];
        if (tempState.length > 0 && tempState.length > 10) {
            setData(tempState.splice(1, 10));
        } else {
            setData(tempState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredMostBookedLocation]);
    useEffect(() => {
        let tempState: any = [...filteredMostBookedLocation];
        if (tempState.length > 0 && showMore) {
            setData(tempState);
        } else if (tempState.length > 0 && !showMore) {
            setData(tempState.splice(1, 10));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMore]);

    const keysObj = {
        city: 'Cities Name',
        bookingCount: 'Bookings',
        amount: 'Value',
    };
    const ItemContainer = styled('div')(() => ({
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #000000;',
        padding: '20px',
        marginTop: '20px',
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        position: 'relative',
    }));

    return (
        <ItemContainer>
            <Box sx={styles.reportAndAnalytics}>
                <Box sx={styles.tabularText}>Most Booked Location</Box>
            </Box>
            <Box sx={styles.categoryTableDimension}>
                <CommonTabularGraph keysObj={keysObj} result={data} listData={true} />
            </Box>
            {filteredMostBookedLocation.length > 10 && !showMore && (
                <Box sx={styles.showMoreText}>
                    <ContainedButton onClick={onShowMoreClick} title={'Show more location'} />
                </Box>
            )}
            {filteredMostBookedLocation.length > 10 && showMore && (
                <Box sx={styles.showMoreText}>
                    <ContainedButton onClick={onShowMoreClick} title={'Show less location'} />
                </Box>
            )}
        </ItemContainer>
    );
};

export default MostBookedLocation;
