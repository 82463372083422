export const styles = {
    container: {
        padding: '8px 0px 32px 15px',
        // overflow: 'scroll',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
        minHeight: '600px',
        padding: '8px',
    },
    span: {
        marginLeft: '4px',
        fontWeight: 400,
        fontSize: '16px',
    },
    table: {
        width: '70%',
        height: 'fit-content',
        marginTop: '10px',
    },
    table_head: {
        width: '100%',
        height: '35px',
        display: 'flex',
        alignItems: 'space-between',
        flexDirection: 'row',
    },
    table_row: {
        width: '100%',
        height: '35px',
        display: 'flex',
        alignItems: 'space-between',
    },
    table_cell: {
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid black',
        justifyContent: 'center',
        fontSize: '1.25rem',
    },
    table_cell_left: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        background: '#FFFFFF',
        border: '.5px solid #000000',
    },
    table_cell_right: {
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid black',
        justifyContent: 'center',
        fontWeight: 500,
    },
    availability: {
        backgroundColor: 'black',
        color: '#fff',
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid #fff',
        justifyContent: 'center',
    },
    menu: {
        height: '45px',
        width: '45px',
        position: 'absolute',
        top: '15px',
        right: '0px',
    },
    userInfo: {
        height: 'inheit',
        width: '900px',
        paddingleft: '10px',
    },
    icon: {
        height: '45px',
        width: '45px',
        marginRight: '10px',
    },
    topBar: {
        height: '50px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0px 10px 0px',
    },
    topBar_admin: {
        height: '30px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        margin: '10px 0px 20px 0px',
    },
    img: {
        height: '40px',
        width: '40px',
        borderRadius: '20px',
    },
    addons_para: {
        fontWeight: 400,
        fontSize: '16px',
        maxWidth: '500px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
    bm_status_tag: {
        height: '40px',
        width: '120px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '10px',
    },
    status_tag: {
        height: '40px',
        width: '120px',
        borderRadius: '8px',
        position: 'absolute',
        top: '18px',
        right: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tag_cont: {
        height: '40px',
        width: '270px',
        position: 'absolute',
        top: '18px',
        right: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        alignItems: 'center',
    },
    link_text: {
        fontWeight: 400,
        fontSize: '16px',
        maxWidth: '500px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
    vendor_view: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        width: '250px',
        background: 'lightgray',
        borderRadius: '8px',
        padding: '5px',
        gap: '10px',
        cursor: 'pointer',
    },
    value_text: {
        fontWeight: 400,
        fontSize: '16px',
        maxWidth: '600px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
    star_rating_cont: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: '5px',
        '& img': {
            height: '18px',
            width: '18px',
        },
    },
    instant_tag: {
        width: '185px',
        height: '100%',
        borderRadius: '8px',
        fontWeight: 500,
        fontSize: '18px',
    },
    paginationContainer: {
        padding: '6px 20px',
        '& .MuiToolbar-root.MuiTablePagination-toolbar': {
            padding: '0',
        },
    },
};
