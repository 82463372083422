import {
    Button,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    TableBody,
} from '@mui/material';
import moment from 'moment-timezone';

import { styles } from './styles';
import { Box } from '@mui/system';
import BPSkeleton from './bpSkeleton';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RejectPhotographerPopup from './rejectPhotographer';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import { APIResponseModal, ReducersModal } from 'utils/modals';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import USER_IMG from 'assets/images/user-image.png';
import {
    UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
    UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
} from 'store/actionTypes';
import {
    getPhotographerDetail,
    markSpecialPhotographer,
    markTopPhotographer,
    verifyVendor,
} from 'modules/photographersManagement/action';
import GalleryImages from '../galleryImages';
import {
    USER_DEVICE_TYPES,
    genderEnum,
    statusCss,
    goverIdStatus,
    servicesEnum,
    creatorType as creatorTypeEnum,
    policyEnum,
    policyTextEnum,
} from 'utils/enum';
import SHOW_TOAST from 'utils/showToast';
//@ts-ignore
import VendorTotalBookingPopup from './vendorTotalBookingPopup';
import { DataFormater, getFormattedDate } from 'utils/constants';

const BasicProfile = () => {
    const {
        fullName,
        mobileNo,
        email,
        gallery,
        totalCompletedBookings,
        totalAcceptedBookings,
        declinedBookings,
        cancelledBookings,
        totalEarnings,
        referralAmount,
        showRejectPopup,
        availabilities,
        rejectionLogs,
        isTopVendor,
        isSpecialVendor,
        perviousSpecialVendor,
        isVerified,
        instantService,
        deviceType,
        rates,
        profilePic,
        categories,
        status,
        dob,
        gender,
        location,
        vendorType,
        creatorType,
        policy: cancellationPolicy,
        bio,
    } = useSelector((state: ReducersModal) => state.PhotographerDetailReducer);

    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showGalleryPopup, setShowGalleryPopup] = useState(false);

    const dispatch: any = useDispatch();
    const photographerId = new URLSearchParams(window.location.search).get('id') || '';

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
            payload,
        });
    };

    useLayoutEffect(() => {
        dispatch({
            type: UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
            payload: { result: [] },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (photographerId && photographerId.length > 0) {
            setLoading(true);
            dispatch(
                getPhotographerDetail(photographerId, (response: APIResponseModal) => {
                    setLoading(false);
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = ({ target: { value } }: SelectChangeEvent) => {
        if (value === '2') {
            //approved
            const data = {
                status: 2,
                vendorId: photographerId,
            };
            dispatch(
                verifyVendor(data, () => {
                    dispatchValue({ status: 2 });
                }),
            );
        } else if (value === '7') {
            dispatchValue({ showRejectPopup: true });
        }
    };

    const isApproveDisabled = useMemo(() => {
        return status === 2;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const isRejcetDisabled = useMemo(() => {
        return status === 7;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const [buttonLoader, setButtonLoader] = useState(false);

    const markAsTopPhotographer = () => {
        setButtonLoader(true);
        dispatch(
            markTopPhotographer(photographerId, !isTopVendor, () => {
                setButtonLoader(false);
            }),
        );
    };

    const markAsSpecialPhotographer = () => {
        setButtonLoader(true);
        dispatch(
            markSpecialPhotographer(photographerId, !isSpecialVendor, () => {
                dispatch(
                    getPhotographerDetail(photographerId, (response: APIResponseModal) => {
                        setLoading(false);
                        setButtonLoader(false);
                    }),
                );
            }),
        );
    };

    const toggleGalleryVisibility = () => {
        if (gallery && gallery.length > 0) {
            setShowGalleryPopup((oldVal) => !oldVal);
        } else {
            SHOW_TOAST('User has not selected image/video while profile set up.', 'info');
        }
    };

    const renderTopPGButton = () => {
        let textToShow = isTopVendor ? 'Unmark as top photographer' : 'Mark as top photographer';
        if (status === 2) {
            return (
                <LoadingButton
                    sx={{ width: '250px', height: '100%' }}
                    loading={buttonLoader}
                    loadingPosition='center'
                    variant='outlined'
                    onClick={markAsTopPhotographer}>
                    {textToShow}
                </LoadingButton>
            );
        }
        return null;
    };

    const renderProfilePic = () => {
        let imgSrc = profilePic && profilePic.length ? profilePic : USER_IMG;
        return (
            <Box sx={styles.profileImgCon} id={'user-img'}>
                <img src={imgSrc} style={styles.img} alt='User-img' />
            </Box>
        );
    };

    const renderCountsBoxes = () => {
        return (
            <Box sx={styles.counts_stack}>
                <Box
                    sx={{ ...styles.counts_container, cursor: 'pointer' }}
                    onClick={() => setShowPopup(true)}>
                    <Typography variant='h2'>{totalCompletedBookings || '0'}</Typography>
                    <Typography variant='h4'>{'All Comp. Booking'}</Typography>
                </Box>
                <Box sx={styles.counts_container}>
                    <Typography variant='h2'>{totalAcceptedBookings || '0'}</Typography>
                    <Typography variant='h4'>{'Accepted Booking'}</Typography>
                </Box>
                <Box sx={styles.counts_container}>
                    <Typography variant='h2'>{declinedBookings || '0'}</Typography>
                    <Typography variant='h4'>{'Declined Booking'}</Typography>
                </Box>
                <Box sx={styles.counts_container}>
                    <Typography variant='h2'>{cancelledBookings || '0'}</Typography>
                    <Typography variant='h4'>{'Cancelled Booking'}</Typography>
                </Box>
                <Tooltip arrow title={totalEarnings.toFixed(2)}>
                    <Box sx={{ ...styles.counts_container, cursor: 'pointer' }}>
                        <Typography variant='h2'>{`${'$'}${
                            DataFormater(parseFloat(totalEarnings.toString())) || '0'
                        }`}</Typography>
                        <Typography variant='h4'>{'Tot. Earning'}</Typography>
                    </Box>
                </Tooltip>

                <Tooltip arrow title={referralAmount.toFixed(2)}>
                    <Box sx={{ ...styles.counts_container, cursor: 'pointer' }}>
                        <Typography variant='h2'>
                            {referralAmount
                                ? `$${DataFormater(parseFloat(referralAmount.toString()))}`
                                : `$0.00`}
                        </Typography>
                        <Typography variant='h4'>{'Ref. Earning'}</Typography>
                    </Box>
                </Tooltip>
            </Box>
        );
    };

    const renderLocation = (val: any) => {
        if (val?.street || val?.city || val?.postalCode) {
            return (
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Location'}
                    </Typography>
                    <Typography style={styles.name}>
                        {' '}
                        {`${val?.street}${val?.street && val?.city && ','}  ${val?.city} ${
                            val?.postalCode && val?.city && ','
                        }   ${val?.postalCode}`}
                    </Typography>
                </Box>
            );
        }
        return (
            <Box style={styles.data}>
                <Typography variant='subtitle1' sx={styles.item_name}>
                    {'Location'}
                </Typography>
                <Typography style={styles.name}>{'N/A'}</Typography>
            </Box>
        );
    };

    const renderVerifiedStatus = () => {
        if (status === 1) {
            //pending state
            return (
                <FormControl
                    sx={{
                        width: '110px',
                        height: '38px',
                        boxShadow: 'none',
                        fontSize: '30px',
                    }}>
                    <Select
                        disabled={isVerified === 1}
                        displayEmpty
                        onChange={handleChange}
                        sx={styles.selectDropdown}
                        value={status.toString()}
                        inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem disabled value={'1'}>
                            {'Pending'}
                        </MenuItem>
                        <MenuItem value={'2'} disabled={isApproveDisabled}>
                            {'Approved'}
                        </MenuItem>
                        <MenuItem value={'7'} disabled={isRejcetDisabled}>
                            {'Rejected'}
                        </MenuItem>
                    </Select>
                </FormControl>
            );
        }

        if (status === 2 && isVerified === 2) {
            //approved
            return (
                <Box sx={[styles.approvedRejectedBtnCon, { background: statusCss[status] }]}>
                    <Typography sx={[styles.name, { color: '#ffffff', fontSize: '16px' }]}>
                        {'Verified'}
                    </Typography>
                </Box>
            );
        }

        if (status === 7 && isVerified === 2) {
            //rejected
            return (
                <Box sx={[styles.approvedRejectedBtnCon, { background: statusCss[status] }]}>
                    <Typography sx={[styles.name, { color: '#ffffff', fontSize: '16px' }]}>
                        {'Rejected'}
                    </Typography>
                </Box>
            );
        }

        return;
    };

    const renderSpecialPhotographer = () => {
        return (
            <Box sx={{ ...styles.button_stack, marginBottom: '0px' }}>
                <FormControl
                    sx={{
                        width: '90px',
                        height: '38px',
                        boxShadow: 'none',
                        fontSize: '30px',
                    }}>
                    <Select
                        disabled={status !== 2 || (!isSpecialVendor && perviousSpecialVendor)}
                        displayEmpty
                        onChange={markAsSpecialPhotographer}
                        sx={styles.selectDropdown}
                        value={isSpecialVendor ? '1' : '2'}
                        inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem value={'1'}>{'Yes'}</MenuItem>
                        <MenuItem value={'2'}>{'No'}</MenuItem>
                    </Select>
                </FormControl>
                {!isSpecialVendor && perviousSpecialVendor && status === 2 && (
                    <Tooltip
                        arrow
                        title="Sorry, you can't make this photographer a special photographer again.">
                        <InfoIcon />
                    </Tooltip>
                )}
                {status === 1 && (
                    <Tooltip
                        arrow
                        title="Either this photographer's government ID is pending/rejected from Stripe's end and Admin both, or the photographer has not uploaded his government ID yet. Please ensure the government ID is successfully processed by Stripe or kindly request the photographer to upload their ID to be a special photographer.">
                        <InfoIcon />
                    </Tooltip>
                )}
            </Box>
        );
    };

    const renderUpperRightSection = () => {
        return (
            <Box sx={styles.upper_right}>
                <Box sx={styles.button_stack}>
                    <Box>{renderTopPGButton()}</Box>
                    {status === 1 && isVerified === 1 && (
                        <Tooltip
                            arrow
                            title="Either this photographer's government ID is pending/rejected from Stripe's end, or the photographer has not uploaded his government ID yet. Please ensure the government ID is successfully processed by Stripe or kindly request the photographer to upload their ID to proceed.">
                            <InfoIcon />
                        </Tooltip>
                    )}
                    {renderVerifiedStatus()}
                </Box>
                {renderCountsBoxes()}
            </Box>
        );
    };

    const renderDevices = () => {
        let deivesStrArr: string[] = ['N/A'];
        if (deviceType && deviceType.length > 0) {
            deivesStrArr = deviceType.map((item) => USER_DEVICE_TYPES[item]);
        }
        return <Typography style={styles.name}>{deivesStrArr.join(', ')}</Typography>;
    };

    const renderCategories = () => {
        let categoriesStrArr: string[] = ['N/A'];
        if (categories && categories.length > 0) {
            categoriesStrArr = categories.map((item) => item.name);
        }
        return <Typography style={styles.name}>{categoriesStrArr.join(', ')}</Typography>;
    };

    const renderUserDetails = () => {
        return (
            <React.Fragment>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Photographer Name'}
                    </Typography>
                    <Typography style={styles.name}>{fullName || 'N/A'}</Typography>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Phone Number'}
                    </Typography>
                    <Typography style={styles.name}>{mobileNo || 'N/A'}</Typography>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Email ID'}
                    </Typography>
                    <Typography style={styles.name}>{email || 'N/A'}</Typography>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Date of Birth'}
                    </Typography>
                    <Typography style={styles.name}>
                        {dob ? getFormattedDate(dob, 'DD MMM YYYY') : 'N/A'}
                    </Typography>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Gender'}
                    </Typography>
                    <Typography style={styles.name}>{genderEnum[gender] || 'N/A'}</Typography>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Creator Account Type'}
                    </Typography>
                    <Typography style={styles.name}>{servicesEnum[vendorType] || 'N/A'}</Typography>
                </Box>
                <Box sx={{ ...styles.data, alignItems: 'flex-start' }}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Bio'}
                    </Typography>
                    <Typography style={{ ...styles.name, maxWidth: '593px' }}>
                        {bio || 'N/A'}
                    </Typography>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Government ID'}
                    </Typography>
                    <Typography style={styles.name}>
                        {goverIdStatus[isVerified] || 'N/A'}
                    </Typography>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Service Type'}
                    </Typography>
                    <Typography style={styles.name}>
                        {creatorType === 3
                            ? 'Both ( Photography & Videography )'
                            : creatorTypeEnum[creatorType] || 'N/A'}
                    </Typography>
                </Box>
                {renderLocation(location)}
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Gallery'}
                    </Typography>
                    <IconButton onClick={toggleGalleryVisibility} sx={{ p: 0 }}>
                        <FolderCopyIcon />
                    </IconButton>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Device'}
                    </Typography>
                    {renderDevices()}
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Categories'}
                    </Typography>
                    {renderCategories()}
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Instant Booking Rate (30 Min) '}
                    </Typography>
                    <Typography style={styles.name}>{`$${
                        rates.instantBookingRate || '0'
                    }`}</Typography>
                </Box>
                <Box style={styles.data}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Standard Hourly Rate'}
                    </Typography>
                    <Typography style={styles.name}>{`$${rates.hourlyRate || '0'}`}</Typography>
                </Box>
                {vendorType === 2 && (
                    <Box style={styles.data}>
                        <Typography variant='subtitle1' sx={styles.item_name}>
                            {'No. of Images Edited '}
                        </Typography>
                        <Typography style={styles.name}>
                            {rates?.editPhotoCount
                                ? `${rates.editPhotoCount} ${'Images / Standard Hour'}`
                                : `${'0'} ${'Images / Standard Hour'}`}
                        </Typography>
                    </Box>
                )}
                <Box sx={{ ...styles.data, alignItems: 'flex-start' }}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Cancellation Policy'}
                    </Typography>
                    <Typography style={{ ...styles.name, maxWidth: '593px' }}>
                        {policyEnum[cancellationPolicy] || 'N/A'}
                        {cancellationPolicy && `\n( ${policyTextEnum[cancellationPolicy]} )`}
                    </Typography>
                </Box>

                <Box style={{ ...styles.data, marginBottom: '50px' }}>
                    <Typography variant='subtitle1' sx={styles.item_name}>
                        {'Special Photographer'}
                    </Typography>
                    <Box>{renderSpecialPhotographer()}</Box>
                </Box>
            </React.Fragment>
        );
    };

    const renderAvailability = () => {
        if (availabilities && availabilities.length > 0) {
            return (
                <Box>
                    <Box style={styles.table}>
                        <Typography variant='subtitle1' sx={{ mt: '20px', mb: '10px' }}>
                            {'Availability'}
                        </Typography>
                        <TableContainer>
                            <Table sx={{ width: '100%', border: '1px solid black' }}>
                                <TableHead sx={styles.table_head}>
                                    <TableRow style={styles.table_row}>
                                        <TableCell sx={styles.table_cell}>{'Days'}</TableCell>
                                        <TableCell sx={styles.table_cell}>{'Time Slots'}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {availabilities.map((item: any, i) => {
                                        return (
                                            <TableRow
                                                style={styles.table_row}
                                                key={`availability-${i}`}>
                                                <TableCell
                                                    sx={
                                                        item?.status === 1
                                                            ? styles.availability
                                                            : styles.table_cell_left
                                                    }>
                                                    {moment(item?.day, 'ddd').format('dddd')}
                                                </TableCell>
                                                <TableCell sx={styles.table_cell_right}>
                                                    {item.status === 1 ? item.startTime : 'N/A'}
                                                </TableCell>
                                                <TableCell sx={styles.table_cell_right}>
                                                    {item.status === 1 ? item.endTime : 'N/A'}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    const renderRejectionLogs = () => {
        if (rejectionLogs && rejectionLogs.length > 0) {
            return (
                <Box>
                    <Typography variant='subtitle1' sx={{ mt: '20px', mb: '10px' }}>
                        {'Rejection Logs'}
                    </Typography>
                    <Box style={styles.logs_container}>
                        <Box style={styles.logs_header}>
                            <Typography
                                variant='subtitle1'
                                color={'white'}
                                sx={{ marginLeft: '50px', width: '30%' }}>
                                {'Rejected on'}
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                color={'white'}
                                sx={{
                                    marginLeft: '45px',
                                    width: '70%',
                                    textAlign: 'left',
                                }}>
                                {'Reason for rejection'}
                            </Typography>
                        </Box>
                        {rejectionLogs.map((item: any, i) => {
                            return (
                                <Box sx={styles.logs_cont} key={`rejection-${i}`}>
                                    <Typography
                                        variant='caption'
                                        color='black'
                                        sx={{ marginLeft: '20px', width: '35%' }}>
                                        {getFormattedDate(item?.time, 'MMM DD, yyyy, hh:mm A')}
                                    </Typography>
                                    <Typography
                                        variant='caption'
                                        color='black'
                                        sx={{ width: '65%' }}>
                                        {item?.rejectionReason}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            );
        }
        return null;
    };

    const renderView = () => {
        if (loading) {
            return <BPSkeleton />;
        }
        return (
            <Box sx={styles.container}>
                <Box sx={styles.upper}>
                    <Box sx={styles.img_container}>
                        {renderProfilePic()}
                        {instantService === true && (
                            <Button
                                variant='outlined'
                                sx={{
                                    width: '200px',
                                    marginTop: '10px',
                                    pointerEvents: 'none',
                                }}>
                                {'Instant Booking Available'}
                            </Button>
                        )}
                    </Box>
                    {renderUpperRightSection()}
                </Box>
                <Box sx={styles.lower}>
                    {renderUserDetails()}
                    {renderAvailability()}
                    {rejectionLogs && renderRejectionLogs()}
                </Box>
                {showRejectPopup && <RejectPhotographerPopup photographerId={photographerId} />}
                {showGalleryPopup && (
                    <GalleryImages data={gallery} onClose={toggleGalleryVisibility} />
                )}
            </Box>
        );
    };

    return (
        <Box sx={{ overflowX: 'auto' }}>
            {renderView()}
            {showPopup && <VendorTotalBookingPopup onClose={() => setShowPopup(false)} />}
        </Box>
    );
};

export default BasicProfile;
