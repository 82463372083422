import { Skeleton } from '@mui/material';
import React from 'react';

export default function BookingSkeleton() {
    return (
        <React.Fragment>
            <Skeleton
                height={'260px'}
                width={'100%'}
                variant='rectangular'
                sx={{ borderRadius: '10px' }}
            />
        </React.Fragment>
    );
}
