import { UPDATE_OFFERS_REDUCER, UPDATE_OFFER_DETAIL_REDUCER } from 'store/actionTypes';
import { ActionType, OfferDetailModal, OffersListModal } from 'utils/modals';

export const OffersManagementReducer = (
    state: OffersListModal = new OffersListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_OFFERS_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const OffersManagementDetailsReducer = (
    state: OfferDetailModal = new OfferDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_OFFER_DETAIL_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
