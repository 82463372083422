import { ENDPOINTS } from 'utils/endpoints';
import { GET_API_CALL, PUT_API_CALL } from 'utils/services';
import { APIResponseModal } from 'utils/modals';
import { UPDATE_CONTENT_MANAGEMENT_REDUCER } from 'store/actionTypes';
import SHOW_TOAST from 'utils/showToast';

export const editContent = (data: any, callback: Function) => {
    return (dispatch: Function) => {
        dispatch({
            type: UPDATE_CONTENT_MANAGEMENT_REDUCER,
            payload: { loader: true },
        });
        PUT_API_CALL(ENDPOINTS.CONTENT_MANAGEMENT.EDIT, data, (response: APIResponseModal) => {
            if (response.isSuccess) {
                const { message } = response?.data;
                SHOW_TOAST(message, 'success');
            }
            callback(response);
            dispatch({
                type: UPDATE_CONTENT_MANAGEMENT_REDUCER,
                payload: { loader: false },
            });
        });
    };
};

export const getContentListing = () => {
    return (dispatch: Function) => {
        let parameters = '?';

        dispatch({
            type: UPDATE_CONTENT_MANAGEMENT_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.CONTENT_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const data = { ...response.data };
                    dispatch({
                        type: UPDATE_CONTENT_MANAGEMENT_REDUCER,
                        payload: {
                            PrivacyPolicy: { ...data[0] },
                            Faq: { ...data[1] },
                            Tnc: { ...data[2] },
                        },
                    });
                }
                dispatch({
                    type: UPDATE_CONTENT_MANAGEMENT_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};
