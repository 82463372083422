import { Outlet } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Toolbar, useTheme, styled } from '@mui/material';

//component imports
import SideBar from './sideBar';
import Header from 'components/header';

const Main = styled('main')(({ theme }: any) => ({
    ...theme.typography.mainContent,
    ...{
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - 300px)`,
    },
}));

const MainLayout = () => {
    const theme = useTheme();

    const renderAppBar = () => {
        return (
            <AppBar
                enableColorOnDark
                position='fixed'
                color='inherit'
                elevation={0}
                sx={{ bgcolor: theme.palette.background.default }}>
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>
        );
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {renderAppBar()}
            {/* drawer */}
            <SideBar />
            <Main theme={theme}>
                <Outlet />
            </Main>
        </Box>
    );
};

export default MainLayout;
