export const styles = {
    container: {
        padding: '20px',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
        minHeight: '600px',
        padding: '8px',
    },
    headContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: '16px',
    },
    flaggedHeadContainer: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        marginBottom: '16px',
    },
    img: {
        height: '40px',
        width: '40px',
        borderRadius: '20px',
    },
    icon: {
        height: '45px',
        width: '45px',
        marginRight: '10px',
    },
    value_text: {
        fontWeight: 400,
        fontSize: '16px',
        maxWidth: '600px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
    link_text: {
        fontWeight: 400,
        fontSize: '16px',
        maxWidth: '500px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
    vendor_view: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        width: '250px',
        background: 'lightgray',
        borderRadius: '8px',
        padding: '5px',
        gap: '10px',
    },
    clientProfilePicCont: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        minWidth: '250px',
        background: 'lightgray',
        borderRadius: '8px',
        padding: '5px 10px',
        gap: '10px',
        marginLeft: '50px',
    },
    status_tag: {
        height: '40px',
        width: '120px',
        borderRadius: '8px',
        position: 'absolute',
        top: '18px',
        right: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    profile_cont: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '36px',
    },
    chat_cont: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    selectDropdown: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        '& > div': {
            background: 'none',
        },
        '& fieldset': {
            borderRadius: '4px',
            height: '40px',
        },
    },
    textCss: {
        fontWeight: 400,
        fontSize: '16px',
        maxWidth: '450px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        marginRight: '2px',
    },
    profileContainer: {
        flexDirection: 'column',
        marginRight: '20px',
        alignSelf: 'baseline',
    },
    columnStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    profileImgCon: {
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
};
