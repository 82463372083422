import {
    UPDATE_PHOTOGRAPHERS_REDUCER,
    UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
    UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
} from 'store/actionTypes';
import {
    ActionType,
    PhotographerDetailModal,
    PhotographerListModal,
    ClientVendorBookingMadeModal,
} from 'utils/modals';

export const PhotographerReducer = (
    state: PhotographerListModal = new PhotographerListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_PHOTOGRAPHERS_REDUCER: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
};

export const PhotographerDetailReducer = (
    state: PhotographerDetailModal = new PhotographerDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_PHOTOGRAPHER_DETAIL_REDUCER: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
};

export const PhotographerBookingMadeReducer = (
    state: ClientVendorBookingMadeModal = new ClientVendorBookingMadeModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
};
