import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import { styles } from '../../styles';
import CLOUD_IMG from 'assets/images/cloud-image.png';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SHOW_TOAST from 'utils/showToast';
import { PushNotificationSchema } from 'utils/schema';
import { APIResponseModal, ReducersModal } from 'utils/modals';
import {
    addNotification,
    requestFileUpload,
    uploadImageToS3,
} from 'modules/notificationsManagement/action';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import ClientSelection from '../selectionView/clientSelection';
import VendorSelection from '../selectionView/vendorSelection';
import {
    UPDATE_CLIENT_REDUCER,
    UPDATE_NOTIFICATION_DETAIL_REDUCER,
    UPDATE_PHOTOGRAPHERS_REDUCER,
} from 'store/actionTypes';

const Push = forwardRef((_, ref) => {
    const { receiverIds } = useSelector((state: ReducersModal) => state.NotificationDetailReducer);
    const theme: any = useTheme();
    const fileRef = React.useRef<HTMLInputElement>(null);
    const [loading, setLoading] = React.useState(false);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const formRef: any = useRef();
    const [recipientType, setRecipientType] = useState(1);
    const [showUpload, setShowUpload] = useState(false);

    const onUploadImageClick = () => {
        fileRef.current?.click();
    };

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DETAIL_REDUCER,
            payload,
        });
    };

    const dispatchInitialValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_REDUCER,
            payload,
        });
        dispatch({
            type: UPDATE_PHOTOGRAPHERS_REDUCER,
            payload,
        });
    };

    useEffect(() => {
        dispatchInitialValue({ total: 0, page: 1, limit: 10 });
        dispatchValue({ receiverIds: [], notificationType: 4 });
        setShowUpload(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRadioClick = (e: any) => {
        setRecipientType(+e.target.value);
        dispatchValue({ receiverIds: [] });
        dispatchInitialValue({ search: '' });
    };
    useImperativeHandle(ref, () => ({
        runSubmitOnSendClick() {
            formRef.current.values['isSent'] = true;
            formRef?.current?.handleSubmit();
        },
        runSubmitOnSaveClick() {
            formRef.current.values['isSent'] = false;
            formRef?.current?.handleSubmit();
        },
    }));

    const handleOnSubmit = ({ title, description, isSent }: any) => {
        let dataToSend = {
            title,
            // images: image,
            isSent,
            description,
            recipientType,
            notificationType: 4,
            receiverIds: receiverIds,
        };
        dispatch(
            addNotification(dataToSend, () =>
                navigate(ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT),
            ),
        );
    };

    const startImageUpload = (file: File, folder: number, callback: Function) => {
        setLoading(true);
        const dataToSend = {
            fileName: file.name,
            mimeType: file.type,
            sizeInByte: file.size,
            folder, //NOTIFICATION_IMAGE_FOLDER
        };
        dispatch(
            requestFileUpload(dataToSend, (resp: APIResponseModal) => {
                if (resp.isSuccess) {
                    const { fields, url } = resp.data;
                    dispatch(
                        uploadImageToS3(
                            { ...fields, ...{ file, url } },
                            ({ isSuccess, imgUrl }: any) => {
                                if (isSuccess) {
                                    callback({ imgUrl });
                                }
                                setLoading(false);
                            },
                        ),
                    );
                } else {
                    setLoading(false);
                }
            }),
        );
    };

    const onImagePicked = (evt: React.ChangeEvent<HTMLInputElement>, setFieldValue: Function) => {
        setLoading(true);
        if (evt && evt?.target && evt?.target?.files && evt?.target?.files?.length > 0) {
            const file = evt.target.files[0];
            validateFile(file, 100)
                .then(() => {
                    startImageUpload(file, 8, ({ imgUrl }: any) => {
                        setFieldValue('image', imgUrl);
                        setLoading(false);
                    });
                })
                .catch((err) => {
                    SHOW_TOAST(err.message);
                    setLoading(false);
                });
        }
        setLoading(false);
    };

    const validateFile = async (file: File, validDimension: number) => {
        return new Promise((resolve, reject) => {
            if (
                file.type === 'image/png' ||
                file.type === 'image/jpg' ||
                file.type === 'image/jpeg'
            ) {
                let img = new Image(),
                    _URL = window.URL || window.webkitURL;
                var objectUrl = _URL.createObjectURL(file);
                img.onload = function () {
                    const { width, height }: any = this,
                        { size } = file;
                    if (size / 1024 / 1024 < 1) {
                        //converting to MB and if size is less than 1 mb then only proceed
                        if (width <= validDimension && height <= validDimension) {
                            //check if file dimensions are in check
                            resolve({});
                        } else {
                            reject({
                                message: `Image dimensions incorrect. Please upload an image that is less than ${validDimension}*${validDimension} pixels.`,
                            });
                        }
                    } else {
                        reject({
                            message:
                                'File size too large. Please upload a file that is no larger than 1 MB in size.',
                        });
                    }
                    _URL.revokeObjectURL(objectUrl);
                };
                img.src = objectUrl;
            } else {
                reject({
                    message: 'Invalid file format. Please upload a JPG or PNG image.',
                });
            }
        });
    };

    const renderSmallLoading = () => {
        return (
            <Box>
                <CircularProgress size={40} variant={'indeterminate'} sx={{ mt: '60px' }} />
            </Box>
        );
    };

    return (
        <Box>
            <Formik
                innerRef={formRef}
                onSubmit={handleOnSubmit}
                initialValues={{ title: '', description: '', image: '', recipientType: 1 }}
                validationSchema={PushNotificationSchema}>
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Typography variant='h5'>
                            <span>{'Title*'}</span>
                        </Typography>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.title && errors.title)}
                            sx={{ ...theme.typography.customInput }}>
                            <OutlinedInput
                                sx={{ width: '75%' }}
                                value={values.title}
                                onChange={handleChange}
                                name='title'
                                maxRows={6}
                                minRows={1}
                                multiline={true}
                                placeholder='Enter title here'
                                onBlur={handleBlur}
                                inputProps={{
                                    maxLength: 120,
                                    style: { fontWeight: 300 },
                                }}
                                endAdornment={
                                    <InputAdornment position='start' sx={styles.adornment}>
                                        <Typography variant='inherit'>
                                            {'Max. 120 Characters'}
                                        </Typography>
                                    </InputAdornment>
                                }
                            />
                            {touched.title && errors.title && (
                                <FormHelperText error id='standard-weight-helper-text-email-login'>
                                    {errors.title}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {showUpload && (
                            <FormControl sx={styles.uploadArea}>
                                <Box sx={styles.image_Box} onClick={onUploadImageClick}>
                                    <Box sx={styles.image_cnt_algn}>
                                        {loading ? (
                                            <Box sx={styles.loader}>{renderSmallLoading()}</Box>
                                        ) : (
                                            <Box sx={styles.image_cnt_algn}>
                                                <img
                                                    style={
                                                        values.image
                                                            ? styles.uploaded_image
                                                            : styles.image
                                                    }
                                                    src={values.image || CLOUD_IMG}
                                                    alt='cloud_img'
                                                />
                                                {!values.image && (
                                                    <Typography variant='caption'>
                                                        {'Upload Image'}
                                                    </Typography>
                                                )}
                                                {values.image && (
                                                    <Close
                                                        sx={styles.close_Icon}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setFieldValue('image', '');
                                                            //@ts-ignore
                                                            fileRef.current.value = null;
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                    <input
                                        id='notification_image'
                                        style={{ display: 'none' }}
                                        type={'file'}
                                        ref={fileRef}
                                        accept='.jpg, .jpeg, .png'
                                        onBlur={handleBlur}
                                        onChange={(e) => onImagePicked(e, setFieldValue)}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        marginTop: '5px',
                                        marginBottom: '15px',
                                        width: '500px',
                                        alignSelf: 'baseline',
                                        fontSize: '12px',
                                    }}>
                                    {
                                        'Maximum size allowed: 1 MB, Format supported: JPEG, PNG, JPG only.'
                                    }
                                </Typography>
                            </FormControl>
                        )}

                        <Typography variant='h5'>
                            <span>{'Description*'}</span>
                        </Typography>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.description && errors.description)}
                            sx={{ ...theme.typography.customInput }}>
                            <OutlinedInput
                                sx={{ width: '75%' }}
                                value={values.description}
                                onChange={handleChange}
                                name='description'
                                maxRows={6}
                                minRows={6}
                                multiline={true}
                                onBlur={handleBlur}
                                placeholder='Start writing description here...'
                                inputProps={{
                                    maxLength: 500,
                                    style: { fontWeight: 300 },
                                }}
                                endAdornment={
                                    <InputAdornment position='start' sx={styles.adornment}>
                                        <Typography variant='inherit'>
                                            {'Max. 500 Characters'}
                                        </Typography>
                                    </InputAdornment>
                                }
                            />
                            {touched.description && errors.description && (
                                <FormHelperText error id='standard-weight-helper-text-email-login'>
                                    {errors.description}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Box sx={styles.radio_cont_notification_type}>
                            <Typography variant='h5'>
                                <span>{'Recipient*'}</span>
                            </Typography>
                            <RadioGroup
                                sx={styles.radio_grp_notification_type}
                                value={recipientType}
                                defaultValue={1}
                                name='recipientType'
                                onChange={(e: any) => onRadioClick(e)}>
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={1}
                                    control={<Radio />}
                                    label='All Users'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={4}
                                    control={<Radio />}
                                    label='Selected Clients'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={2}
                                    control={<Radio />}
                                    label='All Clients'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={3}
                                    control={<Radio />}
                                    label='All Photographers'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={5}
                                    control={<Radio />}
                                    label='Selected Photographers'
                                />
                            </RadioGroup>
                        </Box>
                    </form>
                )}
            </Formik>
            {recipientType && recipientType === 4 ? <ClientSelection /> : <></>}
            {recipientType && recipientType === 5 ? <VendorSelection /> : <></>}
        </Box>
    );
});

export default Push;
