import { UPDATE_REWARDS_DETAILS_REDUCER, UPDATE_REWARDS_REDUCER } from 'store/actionTypes';
import { ActionType, RewardsListModal, RewardsDetailsModal } from 'utils/modals';

export const RewardsManagementReducer = (
    state: RewardsListModal = new RewardsListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_REWARDS_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const RewardsManagementDetailsReducer = (
    state: RewardsDetailsModal = new RewardsDetailsModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_REWARDS_DETAILS_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
