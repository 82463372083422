export const status: any = {
    1: 'PENDING',
    2: 'VERIFIED',
    3: 'REJECTED',
};

export const goverIdStatus: any = {
    1: 'Pending',
    2: 'Approved',
    3: 'Rejected',
};

export const servicesEnum: any = {
    1: 'Iphone creator',
    2: 'Pro',
};

export const statusCss: any = {
    1: '#808080',
    2: '#00BF63',
    7: '#FF0000',
};

export const categoryStatus: any = {
    1: 'Active',
    2: 'Inactive',
};

export const policyEnum: any = {
    1: 'Flexible',
    2: 'Typical',
    3: 'Moderate',
    4: 'Strict',
};

export const policyTextEnum: any = {
    1: 'Client can receive a full refund up to 48 hours before the shoot. Anytime afterwards they will receive a 50% refund.',
    2: 'Client can receive a full refund up to 7 days before the shoot. Anytime afterwards they will receive a 50% refund.',
    3: 'Client can receive a full refund up to 14 days before the shoot. Anytime afterwards they will receive a 50% refund.',
    4: 'Client can receive a full refund up to 30 days before the shoot. Anytime afterwards they will receive a 50% refund.',
};

export const bookingType: any = {
    2: 'Instant',
    1: 'Scheduled',
};

export const creatorType: any = {
    1: 'Photography',
    2: 'Videography',
    3: 'Both',
};

export const categoryStatusCss: any = {
    1: '#00BF63',
    2: '#FF0000',
};

export const USER_PROFILE_STATUS: any = {
    1: 'PENDING', // Once the user registers on the platform => Status will be in the pending.
    2: 'VERIFIED', // Once the admin verifies the pending user = > Status will change to verify.
    3: 'COMPLETED',
    4: 'INACTIVE',
    5: 'BLOCKED',
    6: 'DELETED',
};

export const photographerStatus: any = {
    1: 'PENDING',
    2: 'VERIFIED',
    7: 'REJECTED',
    4: 'DELETED',
};

export const photographerStatusCss: any = {
    1: '#808080',
    2: '#00BF63',
    3: '#FF0000',
};

export const commentList: any = {
    1: 'The photographer cancelled during the no cancellation period',
    2: 'The photographer did not show up',
    3: 'Photographer is not who they say they are',
    4: 'Photographer did not send the photos',
    5: 'The user cancelled during the no cancellation period',
    6: 'User is not who say they are',
    7: 'Other',
};

export const genderEnum: any = {
    1: 'Male',
    2: 'Female',
    3: 'Other',
};

export const USER_DEVICE_TYPES: any = {
    1: 'IPhone',
    2: 'Digital Camera',
    3: 'Drone',
    4: 'Film',
};

export const durationOptions: any = {
    1: '24 HOURS',
    2: 'PAST 7 DAYS',
    3: 'PAST 30 DAYS',
    4: 'PAST YEAR',
    5: 'OVERALL',
};

export const referralTitle: any = {
    9: 'CLIENT REFERRAL',
    8: 'PHOTOGRAPHER REFERRAL',
    10: 'CROSS REFERRAL',
};

export const RECIPIENT_TYPE: any = {
    1: 'allUsers',
    2: 'allClients',
    3: 'allPhotographers',
    4: 'selectedClients',
    5: 'selectedPhotographers',
};

export const NOTIFICATION_TYPE: any = {
    1: 'push',
    2: 'sms',
    3: 'email',
};

export const NOTIFICATION_STATUS: any = {
    1: 'ACTIVE',
    2: 'DELETED',
};

export const BOOKING_STATUS: any = {
    1: 'Pending',
    2: 'Pending',
    10: 'Completed',
    11: 'Completed',
    12: 'Completed',
    13: 'Completed',
    15: 'Completed',
    16: 'Completed',
    17: 'Completed',
    18: 'Completed',
    29: 'Completed',
    30: 'Completed',
    31: 'Completed',
    32: 'Completed',
    33: 'Completed',
    3: 'Cancelled',
    4: 'Cancelled',
    19: 'Cancelled',
    20: 'Cancelled',
    21: 'Cancelled',
    35: 'Cancelled',
    36: 'Cancelled',
    37: 'Cancelled',
    38: 'Cancelled',
    6: 'Upcoming',
    7: 'Upcoming',
    9: 'Upcoming',
    14: 'Upcoming',
    22: 'Upcoming',
    23: 'Upcoming',
    24: 'Upcoming',
    25: 'Upcoming',
    26: 'Upcoming',
    27: 'Upcoming',
    28: 'Upcoming',
    34: 'Upcoming',
    5: 'Rejected',
    8: 'Rejected',
};

export const BOOKING_STATUS_CSS: any = {
    //pending
    1: 'rgba(202, 170, 58, 0.25)',
    2: 'rgba(202, 170, 58, 0.25)',
    //completed
    10: '#00BF63',
    11: '#00BF63',
    12: '#00BF63',
    13: '#00BF63',
    15: '#00BF63',
    16: '#00BF63',
    17: '#00BF63',
    18: '#00BF63',
    29: '#00BF63',
    30: '#00BF63',
    31: '#00BF63',
    32: '#00BF63',
    33: '#00BF63',
    //cancelled
    3: '#D71F1F',
    4: '#D71F1F',
    19: '#D71F1F',
    20: '#D71F1F',
    21: '#D71F1F',
    35: '#D71F1F',
    36: '#D71F1F',
    37: '#D71F1F',
    38: '#D71F1F',
    //upcoming
    6: '#979797',
    7: '#979797',
    9: '#979797',
    14: '#979797',
    22: '#979797',
    23: '#979797',
    24: '#979797',
    25: '#979797',
    26: '#979797',
    27: '#979797',
    28: '#979797',
    34: '#979797',
    //rejected
    5: '#389AC3',
    8: '#389AC3',
};

export const BOOKING_STATUS_BORDER: any = {
    1: '1px solid #D1B93B',
    2: '1px solid #D1B93B',
    //completed
    10: '#none',
    11: '#none',
    12: '#none',
    13: '#none',
    15: '#none',
    16: '#none',
    17: '#none',
    18: '#none',
    29: '#none',
    30: '#none',
    31: '#none',
    32: '#none',
    33: '#none',
    //cancelled
    3: 'none',
    4: 'none',
    19: '#none',
    20: '#none',
    21: '#none',
    35: 'none',
    36: '#none',
    37: '#none',
    38: '#none',
    //upcoming
    6: '1px solid #000000',
    7: '1px solid #000000',
    9: '1px solid #000000',
    14: '1px solid #000000',
    22: '1px solid #000000',
    23: '1px solid #000000',
    24: '1px solid #000000',
    25: '1px solid #000000',
    26: '1px solid #000000',
    27: '1px solid #000000',
    28: '1px solid #000000',
    34: '1px solid #000000',
    //rejected
    5: 'none',
    8: 'none',
};

export const DISPUTE_STATUS: any = {
    1: 'Solved',
    2: 'Received',
    3: 'In Process',
    4: 'On-Hold',
};

export const BOOKING_STATUS_FONT: any = {
    1: '#FFB800',
    2: '#FFB800',
    //completed
    10: '#FFFFFF',
    11: '#FFFFFF',
    12: '#FFFFFF',
    13: '#FFFFFF',
    15: '#FFFFFF',
    16: '#FFFFFF',
    17: '#FFFFFF',
    18: '#FFFFFF',

    29: '#FFFFFF',
    30: '#FFFFFF',
    31: '#FFFFFF',
    32: '#FFFFFF',
    33: '#FFFFFF',

    //cancelled
    3: '#FFFFFF',
    4: '#FFFFFF',
    19: '#FFFFFF',
    20: '#FFFFFF',
    21: '#FFFFFF',
    35: '#FFFFFF',
    36: '#FFFFFF',
    37: '#FFFFFF',
    38: '#FFFFFF',
    //upcoming
    6: '#000000',
    7: '#000000',
    9: '#000000',
    14: '#000000',
    22: '#000000',
    23: '#000000',
    24: '#000000',
    25: '#000000',
    26: '#000000',
    27: '#000000',
    28: '#000000',
    34: '#000000',
    //rejected
    5: '#000000',
    8: '#000000',
};

export const REQUEST_STATUS: any = {
    1: 'Solved',
    2: 'Received',
    3: 'In-Process',
    4: 'On-Hold',
};

export const TRANSACTION_TYPE: any = {
    BOOKING: 1,
    ADD_ON: 2,
    ADDITIONAL_EDIT: 3,
    SHOOT_COMPLETION: 4,
    VENDOR_PENALTY: 5,
    CLIENT_REFUND: 6,
    VENDOR_PAYOUT: 7,
};
