export const styles = {
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
        padding: '20px',
        height: '575px',
    },
    item_cont: {
        height: '100px',
        width: '100%',
        backGround: 'gray',
        border: '1px solid gray',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
    item_cont_upper: {
        height: '50%',
        width: '100%',
        padding: '0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid gray',
        background: '#D8D5D4',
        borderTopRightRadius: 'inherit',
        borderTopLeftRadius: 'inherit',
    },
    item_cont_lower: {
        height: '50%',
        width: '100%',
        padding: '0px 20px',
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        cursor: 'pointer',
        '&:hover': {
            // background: 'lightgray',
            borderRadius: '8px',
        },
        '& Button': {
            color: '#2196f3',
            fontWeight: 500,
            fontFamily: 'Roboto, sans-serif',
            fontSize: '18px',
            lineHeight: '1.25',
            '&:hover': {
                color: '#2196f3',
            },
        },
    },
    input: {
        height: '45px',
        border: 'none',
        '& textarea:focus': {
            outline: 'none',
            border: 'none',
        },
        '& input:focus': {
            outline: 'none',
            border: 'none',
        },
        '& focused': {
            outline: 'none',
            border: 'none',
        },
        '& underline': {},
    },
};
