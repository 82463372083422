import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AlbumDetailPopup from './albumDetailPopup';
import { Typography, styled } from '@mui/material';
import { AlbumDataModal, ReducersModal } from 'utils/modals';

import DEMO_ALBUM_THUMBNAIL from 'assets/images/demo_album_thumbnail.png';

const PageContainer = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '22px 8px 46px 24px',
}));

const NoDataContainer = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const AlbumItemContainer = styled('div')(() => ({
    width: '236px',
    height: '250px',
    marginRight: '16px',
    marginBottom: '16px',
    backgroundColor: '#D9D9D9',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const AlbumInnerThumbnailContainer = styled('div')(() => ({
    width: '150px',
    height: '150px',
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
}));

const ImageItem = styled('img')(() => ({
    width: '77px',
    height: '98px',
}));

const TitleText = styled('span')(() => ({
    maxWidth: '85%',
    fontSize: '16px',
    fontWeight: '700',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textAlign: 'center',
    marginBottom: '14px',
    fontFamily: 'inherit',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
}));

const Albums = () => {
    const [selectedAlbum, setSelectedAlbum] = useState<AlbumDataModal | null>(null);

    const { albums } = useSelector((state: ReducersModal) => state.PhotographerDetailReducer);

    const renderNoAlbumView = () => {
        return (
            <NoDataContainer>
                <Typography
                    sx={{ color: '#673ab7', marginTop: '180px' }}
                    variant='h2'
                    component='h2'
                >
                    {'No Album Exists.'}
                </Typography>
            </NoDataContainer>
        );
    };

    const renderAlbums = () => {
        if (albums && albums.length > 0) {
            return (
                <React.Fragment>
                    {albums.map((item, i) => {
                        const { _id, thumbnailUrl = DEMO_ALBUM_THUMBNAIL, title } = item;
                        return (
                            <AlbumItemContainer key={_id}>
                                <AlbumInnerThumbnailContainer>
                                    <ImageItem src={thumbnailUrl} alt={`album-thumbnail-${i}`} />
                                </AlbumInnerThumbnailContainer>
                                <TitleText>{title}</TitleText>
                                <Typography
                                    color='secondary'
                                    variant='subtitle1'
                                    sx={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        color: '#000',
                                    }}
                                    onClick={() =>
                                        setSelectedAlbum({ ...item, ...{ selected: true } })
                                    }
                                >
                                    {'View'}
                                </Typography>
                            </AlbumItemContainer>
                        );
                    })}
                </React.Fragment>
            );
        }

        return renderNoAlbumView();
    };

    return (
        <PageContainer>
            {renderAlbums()}
            {selectedAlbum && selectedAlbum.selected && (
                <AlbumDetailPopup
                    albumData={selectedAlbum}
                    onClose={() => setSelectedAlbum(null)}
                />
            )}
        </PageContainer>
    );
};

export default Albums;
