export const styles = {
    container: {
        padding: '0px 20px 0px 20px',
    },
    upper: {
        width: '100%',
        height: '150px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
    },
    counts_stack: {
        gap: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    img_container: {
        height: 'auto',
        width: '150px',
        border: '1px solid lightGray',
        borderRadius: '75px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    profileImgCon: {
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
    },
    img: {
        width: '100%',
        height: '100%',
    },
    counts_container: {
        height: '120px',
        width: '114px',
        border: '2px dotted lightGray',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '',
    },
    lower: {
        width: '100%',
        height: 'fix-content',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
        gap: '2px',
    },
    data: {
        display: 'flex',
        marginTop: '10px',
        alignItems: 'center',
    },
    item_name: {
        width: '200px',
    },
    name: {
        fontWeight: 500,
        fontSize: '0.80rem',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: 'grey',
    },
    email: {
        fontFamily: "'Roboto'",
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: '#979797',
        display: 'flex',
    },
    user_img: {
        height: '40px',
        width: '40px',
        marginRight: '10px',
    },
};
