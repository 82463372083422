import { useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    IconButton,
    Menu,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import AnimateButton from 'components/animateButton';
import { commonFilterStyles as styles } from '../styles';
import { StatusFilterModal } from 'utils/modals';
import BasicDatePicker from 'components/datePicker';
import { Dayjs } from 'dayjs';

interface Props {
    applyFilters: Function;
    requestStatusValue: StatusFilterModal | null;
    commentValue?: StatusFilterModal | null;
    commentState: boolean;
    requestState: boolean;
    dateState: boolean;
    fromDate: Dayjs | null;
    toDate: Dayjs | null;
    activeTab: number;
}

const statusList = [
    { label: 'Solved', value: '1' },
    { label: 'Received', value: '2' },
    { label: 'In Process', value: '3' },
    { label: 'On Hold', value: '4' },
];

const vendorCommentList = [
    {
        label: 'The user cancelled during the no cancellation period',
        value: '5',
    },
    { label: 'User is not who say they are', value: '6' },
    { label: 'Other', value: '7' },
];

const clientCommentList = [
    {
        label: 'The photographer cancelled during the no cancellation period',
        value: '1',
    },
    { label: 'The photographer did not show up', value: '2' },
    {
        label: 'Photographer is not who they say they are',
        value: '3',
    },
    {
        label: 'Photographer did not send the photos',
        value: '4',
    },
    { label: 'Other', value: '7' },
];

const CustomerSatisfactionFilter = ({
    applyFilters,
    requestStatusValue,
    commentValue,
    commentState,
    requestState,
    dateState,
    fromDate,
    toDate,
    activeTab,
}: Props) => {
    const theme = useTheme();
    const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLButtonElement>(null);
    const open = Boolean(anchorFilterEl);

    const [requestStatus, setRequestStatus] = useState<StatusFilterModal | null>(
        requestStatusValue,
    );
    const [comment, setComment] = useState<StatusFilterModal | any>(commentValue);
    const [selectedFromDate, setSelectedFromDate] = useState(fromDate);
    const [selectedToDate, SetSelectedToDate] = useState(toDate);

    const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorFilterEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorFilterEl(null);
    };

    const onClearClick = () => {
        setRequestStatus(null);
        setComment(null);
        setSelectedFromDate(null);
        SetSelectedToDate(null);
        applyFilters({ requestStatus: null, comment: null, fromDate: null, toDate: null });
        handleCloseFilter();
    };

    const onApplyClick = () => {
        applyFilters({ requestStatus, comment, selectedFromDate, selectedToDate });
        handleCloseFilter();
    };

    return (
        <Box>
            <Box sx={styles.buttonStyle}>
                <IconButton disableRipple onClick={handleClickFilter}>
                    <TuneIcon sx={styles.iconColor} />
                </IconButton>
            </Box>
            <Menu
                open={open}
                anchorEl={anchorFilterEl}
                onClose={() => handleCloseFilter()}
                PaperProps={{ elevation: 1, sx: styles.paper }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Box sx={{ ...theme.typography, m: 2 }}>
                    <Box>
                        <Typography sx={styles.heading} variant='h4'>
                            Filter
                        </Typography>
                    </Box>
                    {commentState && (
                        <Autocomplete
                            autoHighlight
                            value={comment}
                            options={activeTab === 1 ? clientCommentList : vendorCommentList}
                            sx={styles.commentfield}
                            getOptionLabel={(option) => option.label || ''}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            onChange={(_: any, value) => setComment(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Comment'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )}
                        />
                    )}
                    {requestState && (
                        <Autocomplete
                            autoHighlight
                            value={requestStatus}
                            options={statusList}
                            sx={styles.zipCodeField}
                            getOptionLabel={(option) => option.label || ''}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            onChange={(_: any, value) => setRequestStatus(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Request Status'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                />
                            )}
                        />
                    )}
                    {dateState && (
                        <FormControl
                            sx={{ ...styles.selectDropdown, margin: '25px 0px', width: 300 }}>
                            <BasicDatePicker
                                width={300}
                                label='From Date'
                                format='MM/DD/YYYY'
                                value={selectedFromDate}
                                onChange={(date: any) => setSelectedFromDate(date)}
                            />
                        </FormControl>
                    )}
                    {dateState && (
                        <FormControl sx={styles.selectDropdown}>
                            <BasicDatePicker
                                width={300}
                                label='To Date'
                                format='MM/DD/YYYY'
                                value={selectedToDate}
                                onChange={(date: any) => SetSelectedToDate(date)}
                            />
                        </FormControl>
                    )}

                    <Box sx={styles.buttonBox}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                size='large'
                                type='submit'
                                variant='contained'
                                onClick={onClearClick}>
                                {'Reset Filter'}
                            </Button>
                        </AnimateButton>
                        <AnimateButton>
                            <Button
                                disableElevation
                                size='large'
                                type='submit'
                                variant='contained'
                                onClick={onApplyClick}>
                                {'Apply Filter'}
                            </Button>
                        </AnimateButton>
                    </Box>
                </Box>
            </Menu>
        </Box>
    );
};

export default CustomerSatisfactionFilter;
