import React from 'react';
import { styles } from './styles';
import { Box, Skeleton } from '@mui/material';

const countsArr = [1, 2, 3, 4, 5, 6];
const dataArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function BPSkeleton() {
    return (
        <React.Fragment>
            <Box sx={styles.container}>
                <Box sx={styles.upper}>
                    <Box sx={styles.img_container}>
                        <Box>
                            <Skeleton height='190px' width='190px' variant='circular'></Skeleton>
                        </Box>
                        <Box>
                            <Skeleton
                                height='40px'
                                width='200px'
                                sx={{ borderRadius: '4px', mt: '10px' }}
                                variant='rectangular'></Skeleton>
                        </Box>
                    </Box>
                    <Box sx={styles.upper_right}>
                        <Box sx={styles.button_stack}>
                            <Skeleton
                                height='35px'
                                width='250px'
                                variant='rectangular'
                                sx={{ borderRadius: '4px' }}></Skeleton>
                            <Skeleton
                                height='35px'
                                width='110px'
                                variant='rectangular'
                                sx={{ borderRadius: '4px' }}></Skeleton>
                        </Box>
                        <Box sx={styles.counts_stack}>
                            {countsArr.map((item) => (
                                <Skeleton
                                    height='120px'
                                    width='114px'
                                    key={`${item}`}
                                    variant='rectangular'
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box sx={styles.lower}>
                    {dataArr.map((item) => (
                        <Skeleton height={35} width='70%' key={`${item}`} variant='rectangular' />
                    ))}
                </Box>
            </Box>
        </React.Fragment>
    );
}
