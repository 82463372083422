import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@emotion/react';
import { styles } from './styles';
import BasicProfile from './tabs/basic-profile';
import Albums from './tabs/albums';
import Packages from './tabs/packages';
import BookingMade from './tabs/booking';
import ROUTE_NAMES from 'routes/routeNames';
import { useNavigate } from 'react-router';

const StyledTabs = styled((props: any) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
        style={{ width: '100%' }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgb(33, 150, 243)',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 800,
        width: '100%',
        backgroundColor: 'rgb(33, 150, 243)',
    },
});

const StyledTab = styled((props: any) => <Tab {...props} style={{ width: '700px' }} />)(
    ({ theme }) => ({
        textTransform: 'none',
        marginRight: theme.spacing(1),
        color: 'black',
        fontWeight: 500,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '0.875rem',
        lineHeight: '1.25',
        maxWidth: '24.4%',

        '&.Mui-selected': {
            color: 'rgb(33, 150, 243)',
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#30c1d1',
        },
    }),
);

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function PhotographerDetail() {
    const [value, setValue] = React.useState(0);
    const theme: any = useTheme();

    const tabType: any = new URLSearchParams(window.location.search).get('tabType');
    const search: any = new URLSearchParams(window.location.search).get('id');
    const type = !tabType ? 0 : parseInt(tabType);
    const navigate: any = useNavigate();

    React.useEffect(() => {
        setValue(type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabType]);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
        if (newValue === 0) {
            navigate(`${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL}?id=${search}`);
        } else if (newValue === 1) {
            navigate(
                `${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL}?id=${search}&tabType=1`,
            );
        } else if (newValue === 2) {
            navigate(
                `${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL}?id=${search}&tabType=2`,
            );
        } else if (newValue === 3) {
            navigate(
                `${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL}?id=${search}&tabType=3`,
            );
        }
    };

    return (
        <React.Fragment>
            <Box sx={styles.paper}>
                <Card sx={{ minHeight: 84 + 'vh' }}>
                    <CardContent>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                                <StyledTabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label='styled tabs example'>
                                    <StyledTab label='Basic Profile' {...a11yProps(0)} />
                                    <StyledTab
                                        label='Albums'
                                        {...a11yProps(1)}
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            transition: 'all .2s ease-in-out',
                                        }}
                                    />
                                    <StyledTab
                                        label='Packages'
                                        {...a11yProps(1)}
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            transition: 'all .2s ease-in-out',
                                        }}
                                    />
                                    <StyledTab
                                        label='Bookings Made'
                                        {...a11yProps(1)}
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            transition: 'all .2s ease-in-out',
                                        }}
                                    />
                                </StyledTabs>
                            </Box>

                            <TabPanel value={value} index={0}>
                                <BasicProfile />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Albums />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Packages />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <BookingMade />
                            </TabPanel>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </React.Fragment>
    );
}
