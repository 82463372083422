export const styles = {
    container: {
        padding: '8px 45px 32px 15px',
        // overflow: 'scroll',
    },
    span: {
        marginLeft: '4px',
        fontWeight: 400,
        fontSize: '16px',
    },
    table: {
        width: '70%',
        height: 'fit-content',
        marginTop: '10px',
    },
    table_head: {
        width: '100%',
        height: '35px',
        display: 'flex',
        alignItems: 'space-between',
        flexDirection: 'row',
    },
    table_row: {
        width: '100%',
        height: '35px',
        display: 'flex',
        alignItems: 'space-between',
    },
    table_cell: {
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid black',
        justifyContent: 'center',
        fontSize: '1.25rem',
    },
    table_cell_left: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        background: '#FFFFFF',
        border: '.5px solid #000000',
    },
    table_cell_right: {
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid black',
        justifyContent: 'center',
        fontWeight: 500,
    },
    availability: {
        backgroundColor: 'black',
        color: '#fff',
        width: '50%',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        border: '0.5px solid #fff',
        justifyContent: 'center',
    },
};
