import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
    useTheme,
} from '@mui/material';
import { Formik } from 'formik';
import { AccountSettingsSchema } from 'utils/schema';
import { ReducersModal } from 'utils/modals';
import AnimateButton from 'components/animateButton';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { updateProfile } from './action';
import Loader from 'components/loader';

export default function AccountSettings() {
    const theme: any = useTheme();
    const dispatch: any = useDispatch();
    const [loading, setLoading] = useState(false);

    const { name, email, mobileNo, title } = useSelector(
        (state: ReducersModal) => state.AuthReducer,
    );

    const handleOnSubmit = (data: { name: string }) => {
        let nameToSend = data.name
        if (name === nameToSend) {
            return;
        }
        const dataToSend = {
            name: nameToSend,
        };
        setLoading(true);
        dispatch(
            updateProfile(dataToSend, () => {
                setLoading(false);
            }),
        );
    };

    const formatNumber = (phNumber: string) => {
        let input = phNumber.replace(/\D/g, '');
        var size = input.length;
        if (size > 0) {
            input = '(' + input;
        }
        if (size > 3) {
            input = input.slice(0, 4) + ') ' + input.slice(4, 11);
        }
        if (size > 6) {
            input = input.slice(0, 9) + '-' + input.slice(9);
        }
        return input;
    };

    return (
        <Formik
            onSubmit={handleOnSubmit}
            validationSchema={AccountSettingsSchema}
            initialValues={{ name, email, mobile: mobileNo, title }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
            }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ mt: '20' }}>
                        <Grid item xs={6} pr={1}>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.name && errors.name)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor='as-name-field'>{'Name'}</InputLabel>
                                <OutlinedInput
                                    type='text'
                                    name='name'
                                    label='name'
                                    onBlur={handleBlur}
                                    onInput={({ target }: any) =>
                                        setFieldValue('name', target.value)
                                    }
                                    id='as-name-field'
                                    value={values.name}
                                />
                                {touched.name && errors.name && (
                                    <FormHelperText error id='as-helper-name'>
                                        {errors.name}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} pr={1}>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.email && errors.email)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor='as-email-field'>{'Email Address'}</InputLabel>
                                <OutlinedInput
                                    type='email'
                                    name='email'
                                    label='Email'
                                    disabled={true}
                                    id='as-email-field'
                                    value={values.email}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.mobile && errors.mobile)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor='as-mobile-field' shrink={true}>
                                    {'Mobile Number'}
                                </InputLabel>
                                <OutlinedInput
                                    type='text'
                                    id='as-mobile-field'
                                    disabled={true}
                                    value={formatNumber(values.mobile)}
                                    name='mobile'
                                    label='Mobile Number'
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            <Typography
                                                sx={{
                                                    padding: '30.5px 0px 11.5px !important',
                                                    color: '#000',
                                                }}
                                            >
                                                {'+1'}
                                            </Typography>
                                        </InputAdornment>
                                    }
                                />

                            </FormControl>
                        </Grid>
                        <Box
                            sx={{
                                mt: 2,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size='large'
                                    type='submit'
                                    variant='contained'
                                >
                                    {'Update Profile'}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                    {loading && <Loader />}
                </form>
            )}
        </Formik>
    );
}
