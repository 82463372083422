import { UPDATE_AUTH } from 'store/actionTypes';
import { ActionType, AuthModal } from 'utils/modals';

const AuthReducer = (state: AuthModal = new AuthModal(), action: ActionType) => {
    switch (action.type) {
        case UPDATE_AUTH:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default AuthReducer;
