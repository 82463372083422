import { Formik } from 'formik';
import { useState } from 'react';
import {
    Box,
    Grid,
    useTheme,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
    InputAdornment,
    IconButton,
    Button,
} from '@mui/material';

//custom imports
import Loader from 'components/loader';
import { ChangePasswordSchema } from 'utils/schema';
import { APIResponseModal, ChangePasswordModal } from 'utils/modals';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { changePassword } from './action';
import AnimateButton from 'components/animateButton';

export default function ChangePassword() {
    const theme: any = useTheme();

    const dispatch: any = useDispatch();

    const [loading, setLoading] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleOnSubmit = (data: ChangePasswordModal, resetForm: Function) => {
        const dataToSend = {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
        };
        setLoading(true);
        dispatch(
            changePassword(dataToSend, (resp: APIResponseModal) => {
                if (resp.isSuccess) {
                    resetForm();
                }
                setLoading(false);
            }),
        );
    };

    const renderLoginForm = () => {
        return (
            <Formik
                onSubmit={(values, { resetForm }) => handleOnSubmit(values, resetForm)}
                validationSchema={ChangePasswordSchema}
                initialValues={{ ...new ChangePasswordModal() }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2} sx={{ mt: '20' }}>
                            <Grid item xs={12}>
                                <Grid item xs={6} pr={1}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.oldPassword && errors.oldPassword)}
                                        sx={{ ...theme.typography.customInput }}
                                    >
                                        <InputLabel htmlFor='outlined-adornment-password-login'>
                                            {'Old Password'}
                                        </InputLabel>
                                        <OutlinedInput
                                            type={showOldPassword ? 'text' : 'password'}
                                            value={values.oldPassword}
                                            name='oldPassword'
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge='end'
                                                        size='large'
                                                    >
                                                        {showOldPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label='Password'
                                            inputProps={{}}
                                        />
                                        {touched.oldPassword && errors.oldPassword && (
                                            <FormHelperText
                                                error
                                                id='standard-weight-helper-text-password-login'
                                            >
                                                {errors.oldPassword}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.newPassword && errors.newPassword)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor='outlined-adornment-password-login'>
                                        {'New Password'}
                                    </InputLabel>
                                    <OutlinedInput
                                        type={showNewPassword ? 'text' : 'password'}
                                        value={values.newPassword}
                                        name='newPassword'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                    size='large'
                                                >
                                                    {showNewPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Password'
                                        inputProps={{}}
                                    />
                                    {touched.newPassword && errors.newPassword && (
                                        <FormHelperText
                                            error
                                            id='standard-weight-helper-text-password-login'
                                        >
                                            {errors.newPassword}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(
                                        touched.confirmPassword && errors.confirmPassword,
                                    )}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor='outlined-adornment-password-login'>
                                        {'Confirm Password'}
                                    </InputLabel>
                                    <OutlinedInput
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirmPassword}
                                        name='confirmPassword'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        onKeyDown={(e) => {
                                            if (e.code === 'Space') e.preventDefault();
                                        }}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowConfirmPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge='end'
                                                    size='large'
                                                >
                                                    {showConfirmPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label='Password'
                                        inputProps={{}}
                                    />
                                    {touched.confirmPassword && errors.confirmPassword && (
                                        <FormHelperText
                                            error
                                            id='standard-weight-helper-text-password-login'
                                        >
                                            {errors.confirmPassword}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Box
                                sx={{
                                    mt: 2,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size='large'
                                        type='submit'
                                        variant='contained'
                                    >
                                        {'Change Password'}
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </Grid>
                        {loading && <Loader />}
                    </form>
                )}
            </Formik>
        );
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box>
                    <Grid>{renderLoginForm()}</Grid>
                </Box>
            </Grid>
        </Grid>
    );
}
