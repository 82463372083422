import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from '@mui/material';
import { styles } from './styles';
import { Formik } from 'formik';
import ADD_ICON from 'assets/images/add.png';
import BasicDatePicker from 'components/datePicker';
import BasicTimePicker from 'components/timePicker';
import ContainedButton from 'components/button/contained-button';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRewardDetail, updateRewardData } from './action';
import { ReducersModal } from 'utils/modals';
import EditRewardSkeleton from './editRewardSkeleton';
import dayjs, { Dayjs } from 'dayjs';

const EditRewards = () => {
    const navigate = useNavigate();
    const theme: any = useTheme();
    const dispatch: any = useDispatch();
    const [loading, setLoading] = useState(true);
    const rewardId = new URLSearchParams(window.location.search).get('id');
    const {
        benefits,
        conditions,
        description,
        rewardType,
        _id,
        endDate,
        startDate,
        title,
        value,
        isExpiry,
    } = useSelector((state: ReducersModal) => state.RewardsManagementDetailsReducer);
    const [selectedFromDate, setSelectedFromDate] = useState<Dayjs | null>(startDate);
    const [selectedToDate, setSelectedToDate] = useState<Dayjs | null>(endDate);
    const [isExpiryDate, setIsExpiryDate] = useState<boolean>(isExpiry);
    const [conditionData, setConditionData] = useState<string[]>(conditions);
    const [benefitData, setBenefitData] = useState<string[]>(benefits);
    const [error, setError] = useState({ startDateError: '', endDateError: '' });

    useEffect(() => {
        setLoading(true);
        dispatch(
            getRewardDetail(rewardId, (response: any) => {
                setLoading(false);
                setSelectedFromDate(dayjs(response?.data?.startDate));
                setSelectedToDate(dayjs(response?.data?.endDate));
                setIsExpiryDate(response?.data?.isExpiry);
                setBenefitData(response?.data?.benefits);
                setConditionData(response?.data?.conditions);
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addBenefit = () => {
        setBenefitData([...benefitData, '']);
    };

    const addCondition = () => {
        setConditionData([...conditionData, '']);
    };

    const renderBenefits = (
        benefit: string,
        benefits: string[],
        index: any,
        setFieldValue: any,
    ) => {
        return (
            <OutlinedInput
                key={index}
                sx={{
                    width: '75%',
                }}
                value={benefit}
                onChange={(e) => {
                    benefitData.splice(index, 1, e.target.value);
                    setBenefitData(benefitData);
                    setFieldValue(benefitData);
                }}
                name={`benefit${index}`}
                maxRows={6}
                minRows={1}
                multiline={true}
                placeholder=''
                inputProps={{
                    maxLength: 120,
                    style: { fontWeight: 300 },
                }}
                endAdornment={
                    index === benefits.length - 1 && (
                        <InputAdornment
                            position='end'
                            sx={{ position: 'relative', left: '40px', cursor: 'pointer' }}
                            onClick={addBenefit}>
                            <img
                                style={{ height: '30px', width: '30px' }}
                                src={ADD_ICON}
                                alt='Add'></img>
                        </InputAdornment>
                    )
                }
            />
        );
    };

    const renderConditions = (
        condition: string,
        conditions: string[],
        index: any,
        setFieldValue: any,
    ) => {
        return (
            <OutlinedInput
                key={index}
                sx={{
                    width: '75%',
                }}
                value={condition}
                onChange={(e) => {
                    conditionData.splice(index, 1, e.target.value);
                    setConditionData(conditionData);
                    setFieldValue(conditionData);
                }}
                name={`condition${index}`}
                maxRows={6}
                minRows={1}
                multiline={true}
                placeholder=''
                inputProps={{
                    maxLength: 120,
                    style: { fontWeight: 300 },
                }}
                endAdornment={
                    index === conditions.length - 1 && (
                        <InputAdornment
                            position='end'
                            sx={{ position: 'relative', left: '40px', cursor: 'pointer' }}
                            onClick={addCondition}>
                            <img
                                style={{ height: '30px', width: '30px' }}
                                src={ADD_ICON}
                                alt='Add'></img>
                        </InputAdornment>
                    )
                }
            />
        );
    };

    const handleStartDateChange = (date: any) => {
        if (selectedToDate && selectedToDate < date) {
            setError({
                startDateError: 'Start date must be less than end date.',
                endDateError: '',
            });
        } else {
            setError({
                startDateError: '',
                endDateError: '',
            });
            setSelectedFromDate(date);
        }
    };

    const handleEndDateChange = (date: any) => {
        if (selectedFromDate && selectedFromDate > date) {
            setError({
                startDateError: '',
                endDateError: 'End date must be greator than start date.',
            });
        } else {
            setError({
                startDateError: '',
                endDateError: '',
            });
            setSelectedToDate(date);
        }
    };

    const handleOnSubmit = ({ title, about, discountType, type }: any) => {
        const dataToSend: any = {
            referralId: _id,
            title: title,
            description: about,
            benefits: benefitData.filter((n) => n),
            conditions: conditionData.filter((n) => n),
            rewardType: discountType,
            value: type,
            startDate: selectedFromDate,
            isExpiry: isExpiryDate,
        };
        if (!isExpiryDate) {
            dataToSend['endDate'] = selectedToDate;
        }

        dispatch(
            updateRewardData(dataToSend, () => {
                navigate(ROUTE_NAMES.MAIN_ROUTES.REWARDS_PROGRAM);
            }),
        );
    };

    const renderView = () => {
        return (
            <Formik
                onSubmit={handleOnSubmit}
                initialValues={{
                    title: title,
                    about: description,
                    type: value,
                    benefits: benefitData,
                    discountType: rewardType,
                    conditions: conditions,
                }}>
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                }) => (
                    <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                            <span>{'Edit Referral Details'}</span>
                        </Typography>
                        <Typography variant='h5'>
                            <span>{'Referral Title'}</span>
                        </Typography>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.title && errors.title)}
                            sx={{ ...theme.typography.customInput, marginBottom: '20px' }}>
                            <OutlinedInput
                                sx={{ width: '75%' }}
                                value={values.title}
                                onChange={handleChange}
                                name='title'
                                maxRows={6}
                                minRows={1}
                                multiline={true}
                                placeholder='Enter title'
                                onBlur={handleBlur}
                                inputProps={{
                                    maxLength: 120,
                                    style: { fontWeight: 300 },
                                }}
                            />
                            {touched.title && errors.title && (
                                <FormHelperText error id='standard-weight-helper-text-email-login'>
                                    {errors.title}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Typography variant='h5'>
                            <span>{'About Referral'}</span>
                        </Typography>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.about && errors.about)}
                            sx={{ ...theme.typography.customInput, marginBottom: '20px' }}>
                            <OutlinedInput
                                sx={{ width: '75%' }}
                                value={values.about}
                                onChange={handleChange}
                                name='about'
                                maxRows={6}
                                minRows={1}
                                multiline={true}
                                placeholder='Enter here'
                                onBlur={handleBlur}
                                inputProps={{
                                    maxLength: 120,
                                    style: { fontWeight: 300 },
                                }}
                            />
                            {touched.about && errors.about && (
                                <FormHelperText error id='standard-weight-helper-text-email-login'>
                                    {errors.about}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Box sx={styles.reward_type_cont}>
                            <Typography variant='h5'>
                                <span>{'Reward Type'}</span>
                            </Typography>
                            <RadioGroup
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                value={values.discountType}
                                defaultValue={1}
                                name='discountType'
                                onChange={(e: any) =>
                                    setFieldValue('discountType', +e.target.value)
                                }>
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={1}
                                    control={<Radio />}
                                    label='Discounted Amount'
                                />
                                <FormControlLabel
                                    sx={{ width: '200px' }}
                                    value={2}
                                    control={<Radio />}
                                    label='Percentage'
                                />
                            </RadioGroup>
                        </Box>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.type && errors.type)}
                            sx={{ ...theme.typography.customInput, marginBottom: '20px' }}>
                            <OutlinedInput
                                sx={{ width: '75%' }}
                                value={values.type}
                                onChange={handleChange}
                                name='type'
                                maxRows={6}
                                minRows={1}
                                multiline={true}
                                placeholder='Discount Value'
                                onBlur={handleBlur}
                                inputProps={{
                                    maxLength: 120,
                                    style: { fontWeight: 300 },
                                }}
                            />
                            {touched.type && errors.type && (
                                <FormHelperText error id='standard-weight-helper-text-email-login'>
                                    {errors.type}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Typography variant='h5'>
                            <span>{'Benefits'}</span>
                        </Typography>
                        {benefitData.length > 0 ? (
                            benefitData.map((benefit, index) => {
                                return (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.benefits && errors.benefits)}
                                        sx={{
                                            ...theme.typography.customInput,
                                            marginBottom:
                                                index < benefitData.length - 1 ? 'unset' : '20px',
                                        }}>
                                        {renderBenefits(benefit, benefitData, index, setFieldValue)}
                                        {touched.benefits && errors.benefits && (
                                            <FormHelperText
                                                error
                                                id='standard-weight-helper-text-email-login'>
                                                {errors.benefits}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                );
                            })
                        ) : (
                            <FormControl
                                fullWidth
                                error={Boolean(touched.benefits && errors.benefits)}
                                sx={{ ...theme.typography.customInput, marginBottom: '20px' }}>
                                <OutlinedInput
                                    sx={{ width: '75%' }}
                                    value={values.benefits}
                                    onChange={handleChange}
                                    name='benefits'
                                    maxRows={6}
                                    minRows={1}
                                    multiline={true}
                                    placeholder=''
                                    onBlur={handleBlur}
                                    inputProps={{
                                        maxLength: 120,
                                        style: { fontWeight: 300 },
                                    }}
                                    endAdornment={
                                        <InputAdornment
                                            position='end'
                                            sx={{ position: 'relative', left: '40px' }}>
                                            <img
                                                style={{ height: '30px', width: '30px' }}
                                                src={ADD_ICON}
                                                alt='Add'></img>
                                        </InputAdornment>
                                    }
                                />

                                {touched.benefits && errors.benefits && (
                                    <FormHelperText
                                        error
                                        id='standard-weight-helper-text-email-login'>
                                        {errors.benefits}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}
                        <Typography variant='h5'>
                            <span>{'Conditions'}</span>
                        </Typography>
                        {conditionData.length > 0 ? (
                            conditionData.map((condition, index) => {
                                return (
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.conditions && errors.conditions)}
                                        sx={{
                                            ...theme.typography.customInput,
                                            marginBottom:
                                                index < conditionData.length - 1 ? 'unset' : '20px',
                                        }}>
                                        {renderConditions(
                                            condition,
                                            conditionData,
                                            index,
                                            setFieldValue,
                                        )}
                                        {touched.conditions && errors.conditions && (
                                            <FormHelperText
                                                error
                                                id='standard-weight-helper-text-email-login'>
                                                {errors.conditions}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                );
                            })
                        ) : (
                            <FormControl
                                fullWidth
                                error={Boolean(touched.conditions && errors.conditions)}
                                sx={{ ...theme.typography.customInput, marginBottom: '20px' }}>
                                <OutlinedInput
                                    sx={{ width: '75%' }}
                                    value={values.conditions}
                                    onChange={handleChange}
                                    name='conditions'
                                    maxRows={6}
                                    minRows={1}
                                    multiline={true}
                                    placeholder=''
                                    onBlur={handleBlur}
                                    inputProps={{
                                        maxLength: 120,
                                        style: { fontWeight: 300 },
                                    }}
                                    endAdornment={
                                        <InputAdornment
                                            position='end'
                                            sx={{ position: 'relative', left: '40px' }}>
                                            <img
                                                style={{ height: '30px', width: '30px' }}
                                                src={ADD_ICON}
                                                alt='Add'></img>
                                        </InputAdornment>
                                    }
                                />

                                {touched.conditions && errors.conditions && (
                                    <FormHelperText
                                        error
                                        id='standard-weight-helper-text-email-login'>
                                        {errors.conditions}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        )}

                        <Typography variant='h5' sx={{ marginBottom: '10px' }}>
                            <span>{'Valid Till'}</span>
                        </Typography>
                        <Box
                            sx={{
                                height: error.endDateError ? '150px' : '130px',
                                width: '100%',
                                display: 'flex',
                                background: '#F8FAFC',
                            }}>
                            <Box sx={styles.validity_left}>
                                <FormControl
                                    sx={{
                                        ...styles.selectDropdown,
                                        margin: '10px ',
                                    }}>
                                    <BasicDatePicker
                                        width={150}
                                        label='Start Date'
                                        format='MM/DD/YYYY'
                                        value={selectedFromDate}
                                        onChange={(date: any) => handleStartDateChange(date)}
                                    />
                                    {error.startDateError && (
                                        <FormHelperText
                                            sx={{ marginLeft: '0px', marginRight: '0px' }}
                                            error
                                            id='standard-weight-helper-text-email-login'>
                                            {error.startDateError}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    sx={{
                                        ...styles.selectDropdown,
                                        margin: '10px',
                                    }}>
                                    <BasicTimePicker
                                        width={150}
                                        label='Start Time'
                                        format='hh:mm'
                                        value={selectedFromDate}
                                        onChange={(date: any) => setSelectedFromDate(date)}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={styles.validity_right}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        marginTop: '4.5px',
                                    }}>
                                    <FormControl
                                        sx={{
                                            ...styles.selectDropdown,
                                            margin: '10px ',
                                        }}>
                                        <BasicDatePicker
                                            width={150}
                                            disabled={isExpiryDate}
                                            label='End Date'
                                            format='MM/DD/YYYY'
                                            value={isExpiryDate ? null : selectedToDate}
                                            onChange={(date: any) => handleEndDateChange(date)}
                                        />
                                        {error.endDateError && (
                                            <FormHelperText
                                                sx={{ marginLeft: '0px', marginRight: '0px' }}
                                                error
                                                id='standard-weight-helper-text-email-login'>
                                                {error.endDateError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            ...styles.selectDropdown,
                                            margin: '10px',
                                        }}>
                                        <BasicTimePicker
                                            width={150}
                                            disabled={isExpiryDate}
                                            label='End Time'
                                            format='hh:mm'
                                            value={isExpiryDate ? null : selectedToDate}
                                            onChange={(date: any) => setSelectedToDate(date)}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ marginLeft: '70px' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={'Pending'}
                                                checked={isExpiryDate}
                                                onChange={(e: any) => {
                                                    setIsExpiryDate(e.target.checked);
                                                }}
                                            />
                                        }
                                        label={'No End Date'}
                                        labelPlacement='end'></FormControlLabel>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={styles.button_box}>
                            <ContainedButton
                                style={{ minWidth: '100px', height: '40px' }}
                                title='Cancel '
                                onClick={() => navigate(ROUTE_NAMES.MAIN_ROUTES.REWARDS_PROGRAM)}
                            />
                            <ContainedButton
                                style={{ minWidth: '100px', height: '40px' }}
                                title='Save'
                                type='submit'
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        );
    };
    return (
        <Box sx={[styles.paper, { padding: loading ? 'unset' : '24px' }]}>
            {!loading ? renderView() : <EditRewardSkeleton />}
        </Box>
    );
};

export default EditRewards;
