import { Skeleton } from '@mui/material';
import React from 'react';

export default function PackagesSkeleton() {
    return (
        <React.Fragment>
            <Skeleton
                height={'23px'}
                width={'176px'}
                variant='rectangular'
                sx={{ marginBottom: '24px' }}
            />
            <Skeleton
                height={'310px'}
                width={'100%'}
                variant='rectangular'
                sx={{ borderRadius: '10px' }}
            />
        </React.Fragment>
    );
}
