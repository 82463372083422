export const styles = {
    user_img: {
        height: '56px',
        width: '56px',
        marginRight: '20px',
        borderRadius: '30px',
    },
    chatBox: {
        width: '460px',
        borderRadius: '18px',
        minHeight: '50px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        color: '#5e35b1',
        backgroundColor: '#ede7f6',
    },
    dateStyle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '33px',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflowY: 'scroll',
        overflowX: 'hidden',
        marginTop: '16px',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
        height: '486px',
        padding: '0px 30px 30px 30px',
    },
    timeStampStyle: {
        display: 'inline-block',
        fontSize: '11px',
        marginRight: '25px',
        alignSelf: 'flex-end',
        marginBottom: '4px',
        color: '#000000',
        position: 'absolute',
        right: 0,
    },
    messageText: {
        padding: '15px 25px',
        maxWidth: '100%',
        wordWrap: 'break-word',
    },
};
