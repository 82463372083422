import {
    UPDATE_EARN_AND_TRANSACTION_REDUCER,
    UPDATE_EARN_AND_TRANSACTION_DETAIL_REDUCER,
    UPDATE_TRANSACTION_DETAIL_REDUCER,
} from 'store/actionTypes';
import {
    ActionType,
    EarnAndTransactionListModal,
    EarnAndTransactionDetailModal,
    TransactionModal,
} from 'utils/modals';

export const EarnAndTransactionReducer = (
    state: EarnAndTransactionListModal = new EarnAndTransactionListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_EARN_AND_TRANSACTION_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const EarnAndTransactionDetailReducer = (
    state: EarnAndTransactionDetailModal = new EarnAndTransactionDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_EARN_AND_TRANSACTION_DETAIL_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const TransactionDetailReducer = (
    state: TransactionModal = new TransactionModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_TRANSACTION_DETAIL_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
