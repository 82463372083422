import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { commonTableStyles as styles } from 'components/tables/styles';
import { styles as reportStyles } from '../styles';
import CircleIcon from '@mui/icons-material/Circle';
import DurationFilter from '../durationFilter';
import DualBarChart from '../graphRepresentation/dualBarChart';

const BookingVsTipEarning = ({
    bookingVsTipsState,
    onDurationFilter,
    filteredBookingVsTipsData,
    averageTip,
}: any) => {
    const ItemContainer = styled('div')(() => ({
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #000000;',
        marginTop: '20px',
        padding: '20px',
        boxSizing: 'border-box',
        WebkitBoxSizing: 'border-box',
        MozBoxSizing: 'border-box',
        position: 'relative',
    }));
    return (
        <ItemContainer>
            <Box sx={[styles.paper, { border: 0, marginTop: 0 }]}>
                <Box sx={styles.dual_report_top_container}>
                    <Box sx={{ flex: 1, justifyContent: 'flex-start', display: 'flex' }}>
                        <Box sx={[styles.top_container_info, { marginLeft: '0px', width: '84%' }]}>
                            <Box sx={styles.top_cnt_int}>
                                <CircleIcon sx={{ color: '#673AB7' }} />
                                <Typography color={'#673AB7'} variant='h5'>
                                    {'Bookings'}
                                </Typography>
                                <CircleIcon sx={{ color: '#2196f3' }} />
                                <Typography color={'#2196f3'} variant='h5'>
                                    {'Tips'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
                        <Box
                            sx={[
                                reportStyles.reportAndAnalytics,
                                { width: averageTip ? '90%' : '66%' },
                            ]}>
                            <Box sx={[reportStyles.tabularText, { flex: 'unset' }]}>
                                Average tip received
                            </Box>
                            <Box sx={[reportStyles.tabularText, { flex: 'unset' }]}>
                                {averageTip ? `$${averageTip?.toFixed(2)}` : `$${averageTip}`}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
                        <DurationFilter
                            durationState={bookingVsTipsState}
                            filterKey={9}
                            onDurationFilter={onDurationFilter}
                        />
                    </Box>
                </Box>
                <br></br>
                <DualBarChart
                    result={filteredBookingVsTipsData}
                    durationState={bookingVsTipsState}
                />
            </Box>
        </ItemContainer>
    );
};
export default BookingVsTipEarning;
