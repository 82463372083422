import { UPDATE_CONTENT_MANAGEMENT_REDUCER } from 'store/actionTypes';
import { ActionType, ContentManagementModal } from 'utils/modals';

export const ContentReducer = (
    state: ContentManagementModal = new ContentManagementModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_CONTENT_MANAGEMENT_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
