import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ContainedButton from 'components/button/contained-button';
import { useRef, useState } from 'react';
import { styles } from '../styles';
import Push from './options/push';
import Sms from './options/sms';
import Email from './options/email';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';

const AddNotifications = () => {
    const [value, setValue] = useState('push');
    const navigate = useNavigate();
    const PushTabRef: any = useRef();
    const SmsTabRef: any = useRef();
    const EmailTabRef: any = useRef();

    const onSendClick = () => {
        if (value === 'push' && PushTabRef?.current?.runSubmitOnSendClick) {
            PushTabRef.current.runSubmitOnSendClick();
            return;
        }
        if (value === 'sms' && SmsTabRef?.current?.runSubmitOnSendClick) {
            SmsTabRef.current.runSubmitOnSendClick();
            return;
        }
        if (value === 'email' && EmailTabRef?.current?.runSubmitOnSendClick) {
            EmailTabRef.current.runSubmitOnSendClick();
            return;
        }
    };

    const onSaveClick = () => {
        if (value === 'push' && PushTabRef?.current?.runSubmitOnSaveClick) {
            PushTabRef.current.runSubmitOnSaveClick();
            return;
        }
        if (value === 'sms' && SmsTabRef?.current?.runSubmitOnSaveClick) {
            SmsTabRef.current.runSubmitOnSaveClick();
            return;
        }
        if (value === 'email' && EmailTabRef?.current?.runSubmitOnSaveClick) {
            EmailTabRef.current.runSubmitOnSaveClick();
            return;
        }
    };

    const renderView = () => {
        if (value === 'push') {
            return <Push ref={PushTabRef} />;
        }
        if (value === 'sms') {
            return <Sms ref={SmsTabRef} />;
        }
        if (value === 'email') {
            return <Email ref={EmailTabRef} />;
        }
        return null;
    };

    return (
        <Box sx={styles.paper}>
            <Card sx={{ minHeight: 84 + 'vh' }}>
                <CardContent>
                    <Box sx={styles.selection_bar}>
                        <Box sx={styles.radio_cont}>
                            <Typography variant='h5'>
                                <span>{'Notification Type (Select One)*'}</span>
                            </Typography>
                            <RadioGroup sx={styles.radio_grp} value={value}>
                                <FormControlLabel
                                    value='push'
                                    control={<Radio />}
                                    label='Push'
                                    onChange={(event: any) => {
                                        setValue(event.target.value);
                                    }}
                                />
                                <FormControlLabel
                                    value='sms'
                                    control={<Radio />}
                                    label='SMS'
                                    onChange={(event: any) => {
                                        setValue(event.target.value);
                                    }}
                                />
                                <FormControlLabel
                                    value='email'
                                    control={<Radio />}
                                    label='Email'
                                    onChange={(event: any) => {
                                        setValue(event.target.value);
                                    }}
                                />
                            </RadioGroup>
                        </Box>
                        <Box sx={styles.button_box}>
                            <ContainedButton
                                style={{ minWidth: '100px', height: '40px' }}
                                title='Cancel'
                                onClick={() =>
                                    navigate(ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT)
                                }
                            />
                            <ContainedButton
                                style={{ minWidth: '100px', height: '40px' }}
                                title='Save Draft '
                                onClick={onSaveClick}
                            />
                            <ContainedButton
                                style={{ minWidth: '100px', height: '40px' }}
                                title='Send'
                                onClick={onSendClick}
                            />
                        </Box>
                    </Box>
                    {renderView()}
                </CardContent>
            </Card>
        </Box>
    );
};

export default AddNotifications;
