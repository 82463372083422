import { UPDATE_NOTIFICATION_DETAIL_REDUCER, UPDATE_NOTIFICATION_REDUCER } from 'store/actionTypes';
import { ActionType, NotificationDetailModal, NotificationsListModal } from 'utils/modals';

export const NotificationReducer = (
    state: NotificationsListModal = new NotificationsListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_NOTIFICATION_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const NotificationDetailReducer = (
    state: NotificationDetailModal = new NotificationDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_NOTIFICATION_DETAIL_REDUCER: {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
};
