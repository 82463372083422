import React from 'react';
import { useTheme } from '@mui/material';

interface Props {
    name: string;
    source?: string;
    extraClass?: string;
}

const styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 'inherit',
        justifyContent: 'center',
    },
    text: {
        fontWright: 600,
        fontSize: '16px',
        color: '#ffffff',
        fontFamily: 'inherit',
    },
};

const PlandidAvatar = React.memo(function Avatar({ name, source }: Props) {
    const theme = useTheme();

    if (name === undefined) {
        //set name to empty string if it's undefined
        name = '';
    }

    const userName = name.toUpperCase().split(' ');
    if (userName.length === 1) {
        name = `${userName[0].charAt(0)}`;
    } else if (userName.length > 1) {
        name = `${userName[0].charAt(0)}${userName[1].charAt(0)}`;
    } else {
        name = '';
    }

    if (source && source.length > 0) {
        return (
            <img
                style={{
                    ...styles.container,
                    ...{ backgroundColor: theme.palette.secondary.light },
                }}
                src={source}
                alt=''
            />
        );
    }

    return (
        <div style={{ ...styles.container, ...{ backgroundColor: theme.palette.secondary.main } }}>
            <span style={styles.text}>{name}</span>
        </div>
    );
});

export default PlandidAvatar;
