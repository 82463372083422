import React, { useEffect, useLayoutEffect } from 'react';
import Loader from 'components/loader';
import { styles } from './styles';
import { Box, MenuItem, Select, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PHOTOGRAPHERS_REDUCER } from 'store/actionTypes';
import { ReducersModal } from 'utils/modals';
import SearchSection from 'components/search';
import VendorSelectionTable from 'components/tables/notificationsManagement/vendorSelection';
import { getPhotoGrapherListing } from 'modules/photographersManagement/action';

const VendorSelection = () => {
    const { search, loader, result, page, vendorStatus } = useSelector(
        (state: ReducersModal) => state.PhotographerReducer,
    );

    const dispatch: any = useDispatch();
    useEffect(() => {
        dispatch(getPhotoGrapherListing());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, search, vendorStatus, page]);

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_PHOTOGRAPHERS_REDUCER,
            payload,
        });
    };
    useLayoutEffect(() => {
        if (vendorStatus !== 5) {
            dispatchValue({ vendorStatus: 5 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (searchVal: string) => {
        dispatch({ type: UPDATE_PHOTOGRAPHERS_REDUCER, payload: { search: searchVal, page: 1 } });
    };

    const handleChange = (e: any) => {
        let vendorStatus = e.target.value;
        dispatchValue({ vendorStatus: vendorStatus, page: 1 });
    };

    const renderLoading = () => {
        return (
            <React.Fragment>
                <Box sx={styles.searchAndFilter}>
                    <Skeleton
                        height={48}
                        width={300}
                        variant='rectangular'
                        sx={{ borderRadius: '12px' }}
                    />
                </Box>
                <Skeleton
                    height={500}
                    width={'100%'}
                    variant='rectangular'
                    sx={{ borderRadius: '8px', marginTop: '16px' }}
                />
            </React.Fragment>
        );
    };

    const renderView = () => {
        if (loader && result.length === 0 && page === 1 && search.length === 0) {
            return renderLoading();
        }
        return (
            <React.Fragment>
                <Box sx={styles.searchAndFilter}>
                    <SearchSection
                        value={search}
                        isDisabled={loader}
                        onChange={handleSearch}
                        placeholder={'Search by Name, Email, Phone No'}
                    />
                    <Box sx={styles.flexContainer}>
                        <Select
                            sx={styles.selectDropdown}
                            onChange={handleChange}
                            value={vendorStatus}>
                            <MenuItem value={5}>{'All'}</MenuItem>
                            <MenuItem value={2}>{'Active'}</MenuItem>
                            <MenuItem value={3}>{'Blocked'}</MenuItem>
                        </Select>
                    </Box>
                </Box>
                <VendorSelectionTable />
            </React.Fragment>
        );
    };

    return (
        <Box>
            {renderView()}
            {loader && (result.length > 0 || (result.length === 0 && search.length > 0)) && (
                <Loader />
            )}
        </Box>
    );
};

export default VendorSelection;
