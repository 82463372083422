import {
    Box,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonTableStyles as styles } from '../styles';
import { ClientData, ReducersModal } from 'utils/modals';
import { UPDATE_CLIENT_REDUCER, UPDATE_NOTIFICATION_DETAIL_REDUCER } from 'store/actionTypes';
import { getClientListing } from 'modules/clientManagement/action';
import { getClientListingForSelection } from 'modules/notificationsManagement/action';
import { getFormattedDate } from 'utils/constants';

const ClientSelectionTable = () => {
    const { result, page, limit, total, clientStatus } = useSelector(
        (state: ReducersModal) => state.ClientReducer,
    );

    const { receiverIds, recipientType, notificationType } = useSelector(
        (state: ReducersModal) => state.NotificationDetailReducer,
    );
    const dispatch: any = useDispatch();

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_REDUCER,
            payload,
        });
    };

    const dispatchRecieverId = (payload: any) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DETAIL_REDUCER,
            payload,
        });
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatchValue({ page: newPage + 1 });
        dispatch(getClientListing());
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        dispatchValue({ limit: event.target.value, page: 1 });
        dispatch(getClientListing());
    };

    const checkRecieverId = () => {
        if (recipientType === 4) {
            return receiverIds;
        }
        return [];
    };

    useEffect(() => {
        dispatch(
            getClientListingForSelection((cbData: any) => {
                setCbData(cbData);
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientStatus]);

    const [selected, setSelected] = useState<readonly string[]>(checkRecieverId());
    const [cbData, setCbData] = useState<readonly string[]>(checkRecieverId());

    const handleAllChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && notificationType === 4) {
            dispatch(
                getClientListingForSelection((cbData: any) => {
                    const newArr = cbData.filter((n: any) => n.status === 2);
                    const newSelected = newArr.map((n: any) => n._id);
                    setCbData(newArr);
                    setSelected(newSelected);
                    dispatchRecieverId({ receiverIds: newSelected });
                }),
            );
        } else if (event.target.checked && notificationType !== 4) {
            dispatch(
                getClientListingForSelection((cbData: any) => {
                    const newSelected = cbData.map((n: any) => n._id);
                    setCbData(cbData);
                    setSelected(newSelected);
                    dispatchRecieverId({ receiverIds: newSelected });
                }),
            );
        } else {
            setSelected([]);
            dispatchRecieverId({ receiverIds: [] });
        }
    };
    const handleClick = (event: React.MouseEvent<unknown>, _id: string) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected: readonly string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        dispatchRecieverId({ receiverIds: newSelected });
    };
    const isSelected = (_id: string) => selected.indexOf(_id) !== -1;
    return (
        <Box sx={styles.paper}>
            <TableContainer>
                <Table sx={styles.table}>
                    <TableHead sx={{ ...styles.not_tableHeader }}>
                        <TableRow sx={styles.headerRow}>
                            <TableCell>
                                <Checkbox
                                    disabled={notificationType === 4 && clientStatus === 3}
                                    sx={{ padding: '0px 0px' }}
                                    checked={cbData.length === selected.length && cbData.length > 0}
                                    indeterminate={
                                        selected.length > 0 && cbData.length !== selected.length
                                    }
                                    onChange={handleAllChecked}></Checkbox>
                            </TableCell>
                            <TableCell>{'S.No.'}</TableCell>
                            <TableCell>{'Name'}</TableCell>
                            <TableCell>{'Mobile Number'}</TableCell>
                            <TableCell>{'Email ID'}</TableCell>
                            <TableCell>{'Added on'}</TableCell>
                            <TableCell>{'Status'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result && result.length > 0 ? (
                            result.map((val: ClientData, idx: number) => {
                                let serialNumber = `${(page - 1) * limit + (idx + 1)}`,
                                    userName = `${val.firstName} ${val.lastName}`;
                                let statusToShow = val.status === 3 ? 'Blocked' : 'Active';
                                const isItemSelected = isSelected(val._id);
                                return (
                                    <TableRow key={val._id} sx={{ ...styles.row }}>
                                        <TableCell>
                                            <Checkbox
                                                disabled={
                                                    notificationType === 4 && val.status === 3
                                                }
                                                onClick={(e) => handleClick(e, val._id)}
                                                sx={{ padding: '0px' }}
                                                color='primary'
                                                checked={isItemSelected}
                                            />
                                        </TableCell>
                                        <TableCell>{serialNumber}</TableCell>
                                        <TableCell title={userName}>{userName}</TableCell>
                                        <TableCell>{val?.mobileNo || 'N/A'}</TableCell>
                                        <TableCell title={val.email}>
                                            {val?.email || 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            {val?.createdAt
                                                ? getFormattedDate(val.createdAt, 'MM/DD/YYYY')
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell sx={{ textTransform: 'capitalize' }}>
                                            {statusToShow}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={15} sx={styles.noDataCell}>
                                    <Box>{'No Data Found'}</Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={styles.paginationContainer}>
                <TablePagination
                    component='div'
                    count={total}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

export default ClientSelectionTable;
