export const commonTableStyles = {
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        marginTop: '16px',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
    },
    chartDimension: {
        height: '385px',
        width: '100%',
    },
    sno_col: {
        maxWidth: '70px !important',
        minWidth: '70px !important',
        textAlign: 'center !important',
    },
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'fit-content',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid lightGray',
        boxShadow: 24,
        borderRadius: '12px',
    },
    modal: {
        padding: '30px 20px 0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'column',
        gap: '10px',
    },
    button: {
        padding: '10px 20px 20px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '20px',
    },
    headerRow: {
        '& th': {
            textAlign: 'center',
        },
    },
    tableHeader: {
        '& th': {
            padding: '18px 16px',
            font: 'normal normal 500 14px/18px Roboto',
            color: '#673AB7',
            whiteSpace: 'nowrap',
        },
    },
    not_tableHeader: {
        '& th': {
            padding: '14px 16px',
            font: 'normal normal 500 14px/18px Roboto',
            color: '#673AB7',
            whiteSpace: 'nowrap',
        },
    },
    table: {
        fontFamily: 'Roboto',
        display: 'table',
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: '0px',
        marginBottom: '10px',
    },
    row: {
        '& td': {
            padding: '14px',
            maxWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            font: 'normal normal 300 13px/18.2px Roboto',
            textAlign: 'center',
        },
        '& td:nth-last-of-type(1)': {
            '& svg': {
                color: '#673AB7',
                cursor: 'pointer',
                fontSize: '20px',
                margin: '0 2px',
            },
        },
    },
    sortIcon: { paddingLeft: '3px', height: '11px', cursor: 'pointer' },
    noDataCell: {
        font: 'normal normal 500 16px/24px Roboto',
        textAlign: 'center',
        padding: '16px',
    },
    noData: { display: 'flex', justifyContent: 'center' },
    dwnIcnStyl: {
        cursor: 'pointer',
        width: '13px',
        verticalAlign: 'middle',
        transition: '1s all',
    },
    rotateIcon: {
        transform: 'rotate(180deg)',
        transition: '1s all',
    },
    paginationContainer: {
        padding: '6px 20px',
        '& .MuiToolbar-root.MuiTablePagination-toolbar': {
            padding: '0',
        },
    },
    statusColumn: {
        height: '25px',
        width: '78px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        margin: 'auto',
    },
    icon: {
        height: '30px',
        width: '30px',
    },
    profileIcon: {
        height: '30px',
        width: '30px',
        borderRadius: '50%',
    },
    tableHeader_cnt_algn: {
        '& th': {
            padding: '18px 16px',
            font: 'normal normal 500 14px/18px Roboto',
            color: '#673AB7',
            whiteSpace: 'nowrap',
            textAlign: 'center',
        },
    },
    row_cnt_algn: {
        '& td': {
            padding: '14px',
            maxWidth: '200px',
            minWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            font: 'normal normal 300 13px/18.2px Roboto',
            textAlign: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
        },
        '& td:nth-last-of-type(1)': {
            '& svg': {
                color: '#673AB7',
                cursor: 'pointer',
                fontSize: '20px',
                margin: '0 2px',
            },
        },
    },
    customer_satisfaction: {
        '& td': {
            padding: '14px',
            maxWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            font: 'normal normal 300 13px/18.2px Roboto',
            textAlign: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
        },
        '& td:nth-last-of-type(1)': {
            padding: '14px',
            maxWidth: '350px',
            minWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            font: 'normal normal 300 13px/18.2px Roboto',
            textAlign: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            '& svg': {
                color: '#673AB7',
                cursor: 'pointer',
                fontSize: '20px',
                margin: '0 2px',
            },
        },
    },
    row_notification: {
        '& td': {
            padding: '14px',
            maxWidth: '200px',
            minWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            font: 'normal normal 300 13px/18.2px Roboto',
            textAlign: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
        },
        '& td:nth-last-of-type(1)': {
            padding: '14px',
            maxWidth: '350px',
            minWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            font: 'normal normal 300 13px/18.2px Roboto',
            textAlign: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            '& svg': {
                color: '#673AB7',
                cursor: 'pointer',
                fontSize: '20px',
                margin: '0 2px',
            },
        },
    },
    row_earnings: {
        '& td': {
            padding: '14px',
            maxWidth: '200px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            font: 'normal normal 300 13px/18.2px Roboto',
            textAlign: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
        },
        '& td:nth-last-of-type(1)': {
            maxWidth: '350px',
            '& svg': {
                color: '#673AB7',
                cursor: 'pointer',
                fontSize: '20px',
                margin: '0 2px',
            },
        },
    },
    icon_box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    action_table_cell_container: {
        gap: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    topPGHeading: {
        margin: '20px 0px',
    },
    selectDropdown: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: '0.8125rem',
        color: '#364152',
        fontWeight: '400',
        borderRadius: '8px',
        '& > div': {
            background: 'none',
            color: 'black',
            borderRadius: '8px',
            fontSize: '0.8125rem',
            fontWeight: '400',
        },
        '& fieldset': {
            color: 'black',
            borderRadius: '8px',
        },
    },
    top_container: {
        width: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    dual_report_top_container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '20px',
        paddingTop: '0px',
        height: 'unset',
        marginTop: '0px',
    },
    top_container_info: {
        height: '50px',
        width: '380px',
        border: '1px solid lightGrey',
        borderRadius: '8px',
        margin: '0px 0px 10px 30px',
        background: '#F8FAFC',
    },
    top_cnt_int: {
        height: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
    },
    top_container_filter: {
        width: '120px',
        height: '45px',
        boxShadow: 'none',
        fontSize: '30px',
        borderRadius: '8px',
        margin: '0px 30px 10px 0px',
    },
    img: {
        width: '12px',
        height: '12px',
        marginRight: '5px',
        marginBottom: '2px',
    },
    categoryImg: {
        width: '24px',
        height: '24px',
        marginRight: '5px',
    },
    tips_container: {
        height: '70px',
        marginRight: '70px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingBottom: '20px',
    },
};
