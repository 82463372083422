import { ENDPOINTS } from 'utils/endpoints';
import {
    DELETE_API_CALL,
    GET_API_CALL,
    PATCH_API_CALL,
    POST_API_CALL,
    POST_API_CALL_FILE_DOWNLOAD,
    PUT_API_CALL,
} from 'utils/services';
import { APIResponseModal, PhotographerDetailModal } from 'utils/modals';
import {
    UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
    UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
    UPDATE_PHOTOGRAPHERS_REDUCER,
} from 'store/actionTypes';
import SHOW_TOAST from 'utils/showToast';

export const getPhotoGrapherListing = () => {
    return (dispatch: Function, getState: Function) => {
        const {
            page,
            limit,
            search,
            sortedBy,
            sortOrder,
            toDate,
            fromDate,
            cityFilter,
            zipCodeFilter,
            statusFilter,
            vendorStatus,
        } = getState().PhotographerReducer;

        let parameters = '?';

        let searchKey = search[0] === '+' ? search.slice(1, search.length) : search;
        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (vendorStatus === 2 || vendorStatus === 3) parameters += `vendorStatus=${vendorStatus}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${searchKey}&`;
        if (fromDate) parameters += `fromDate=${fromDate}&`;
        if (toDate) parameters += `toDate=${toDate}&`;
        if (cityFilter.length > 0) parameters += `city=${cityFilter.map((a: any) => a.city)}&`;
        if (zipCodeFilter.length > 0)
            parameters += `zipCode=${zipCodeFilter.map((a: any) => a.postalCode)}&`;
        if (statusFilter?.value?.length > 0) {
            parameters += `status=${statusFilter.value}&`;
        }

        dispatch({
            type: UPDATE_PHOTOGRAPHERS_REDUCER,
            payload: { loader: true },
        });

        GET_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_PHOTOGRAPHERS_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_PHOTOGRAPHERS_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

export const blockUnblock = (param: string, callback: any) => {
    return () => {
        PUT_API_CALL(
            `${ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.BLOCK_UNBLOCK}/${param}`,
            {},
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                callback(response);
            },
        );
    };
};

export const getPhotographerDetail = (photographerId: string, callback: Function) => {
    return (dispatch: Function) => {
        GET_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.DETAIL,
            `?vendorId=${photographerId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
                        payload: { ...new PhotographerDetailModal(), ...response.data },
                    });
                }
                callback(response);
            },
        );
    };
};

export const getPhotographerPackageListing = (photographerId: string, callback: Function) => {
    return () => {
        GET_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.PACKAGE_LISTING,
            `?vendorId=${photographerId}&page=1&limit=100`,
            (response: APIResponseModal) => {
                callback(response);
            },
        );
    };
};

export const getRejectionReason = (type: number) => {
    return (dispatch: Function, getState: Function) => {
        let parameters = '?';
        if (type) parameters += `type=${type}`;
        GET_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.BLOCK_UNBLOCK_REASON,
            parameters === '?' ? '' : `${parameters.slice()}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const tempArr: string[] = [];
                    const { data } = response;
                    for (let i = 0; i < Object.keys(data).length - 1; i += 1) {
                        tempArr.push(data[`${i}`]);
                    }
                    tempArr.push('Others');
                    dispatch({
                        type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
                        payload: { reasonData: [...tempArr] },
                    });
                }
            },
        );
    };
};

export const verifyVendor = (data: any, callback: Function) => {
    return (dispatch: Function) => {
        POST_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.VERIFY_VENDOR,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                dispatch({
                    type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
                    payload: { showRejectPopup: false },
                });
                callback();
            },
        );
    };
};

export const markTopPhotographer = (
    photographerId: any,
    topVendor: Boolean,
    callBack: Function,
) => {
    const data = {
        vendorId: photographerId,
        isTopVendor: topVendor,
    };
    return (dispatch: Function) => {
        PUT_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.MARK_TOP_PHOTOGRAPHER,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                dispatch({
                    type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
                    payload: { isTopVendor: topVendor },
                });
                callBack();
            },
        );
    };
};

export const markSpecialPhotographer = (
    photographerId: any,
    specialVendor: Boolean,
    callBack: Function,
) => {
    const data = {
        vendorId: photographerId,
        isSpecialVendor: specialVendor,
    };
    return (dispatch: Function) => {
        PUT_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.MARK_SPECIAL_PHOTOGRAPHER,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                dispatch({
                    type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
                    payload: { isSpecialVendor: specialVendor },
                });
                callBack();
            },
        );
    };
};

export const getAlbumDetail = (albumId: string, callback: Function) => {
    return () => {
        GET_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.ALBUM_DETAIL,
            `?albumId=${albumId}`,
            (response: APIResponseModal) => {
                callback(response);
            },
        );
    };
};

/**
 * function to get client Booking Made Listing
 * @param vendorId
 * @param callback
 * @returns
 */
export const getVendorBookingMadeListing = () => {
    return (dispatch: Function, getState: Function) => {
        const { page, limit, search, sortedBy, sortOrder, vendorId } =
            getState().PhotographerBookingMadeReducer;

        const { bookingStatusFilter, bookingTypeFilter } =
            getState().ClientVendorBookingMadeReducer;

        let parameters = '?';
        if (vendorId) parameters += `vendorId=${vendorId}&`;
        if (page) parameters += `page=${page}&`;
        if (limit) parameters += `limit=${limit}&`;
        if (sortOrder) parameters += `sortOrder=${sortOrder}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;
        if (sortedBy) parameters += `sortedBy=${sortedBy}&`;
        if (search) parameters += `search=${search}&`;
        if (bookingStatusFilter && bookingStatusFilter.length > 0)
            parameters += `bookingStatus=${bookingStatusFilter.map((a: any) => a)}&`;
        if (bookingTypeFilter && bookingTypeFilter.length > 0)
            parameters += `bookingType=${bookingTypeFilter.map((a: any) => a)}&`;

        dispatch({
            type: UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.BOOKING_MADE,
            parameters === '?' ? '' : `${parameters.slice(0, -1)}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_PHOTOGRAPHER_BOOKING_MADE_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 * function to get Vendor Booking Made detail
 * @param bookingId
 * @param callback
 * @returns
 */
export const getVendorBookingDetail = (_id: string | null, callback: Function) => {
    return () => {
        GET_API_CALL(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.BOOKING_MADE_DETAILS,
            _id || '',
            (response: APIResponseModal) => {
                callback(response);
            },
        );
    };
};

/**
 * function to Delete review
 * @param ratingId
 * @param callback
 * @returns
 */
export const removeRatingReviews = (ratingId: string, callBack: Function) => {
    return () => {
        DELETE_API_CALL(
            `${ENDPOINTS.RATING_AND_REVIEW_MANAGEMENT.DELETE}?ratingId=${ratingId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                    callBack();
                }
            },
        );
    };
};

export const hideRating = (data: any, callback: Function) => {
    return (dispatch: Function) => {
        const dataToSend = {
            ratingId: data.ratingId,
            isHide: data.isHide,
        };
        PATCH_API_CALL(
            ENDPOINTS.RATING_AND_REVIEW_MANAGEMENT.HIDE_RATING_REVIEW,
            dataToSend,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                dispatch({
                    type: UPDATE_PHOTOGRAPHER_DETAIL_REDUCER,
                    payload: { showRejectPopup: false },
                });
                callback();
            },
        );
    };
};

export const exportToCSV = (data: any, callback: Function) => {
    return () => {
        POST_API_CALL_FILE_DOWNLOAD(
            ENDPOINTS.PHOTOGRAPHER_MANAGEMENT.EXPORT_TO_CSV,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const message = 'CSV Downloaded Successfully. ';
                    SHOW_TOAST(message, 'success');
                }
                callback(response);
            },
        );
    };
};
