import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Table,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { commonTableStyles as styles } from '../styles';
import { ReducersModal } from 'utils/modals';
import { UPDATE_EARN_AND_TRANSACTION_REDUCER } from 'store/actionTypes';
import { useState } from 'react';
import { ArrowDownward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import {
    BOOKING_STATUS,
    BOOKING_STATUS_BORDER,
    BOOKING_STATUS_CSS,
    BOOKING_STATUS_FONT,
} from 'utils/enum';
import { getEarnAndTransactionListing } from 'modules/earningTransactionManagement/action';
import { DataFormater, getFormattedDate } from 'utils/constants';

const EarningsTransactionsTable = () => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const { page, limit, total, result } = useSelector(
        (state: ReducersModal) => state.EarnAndTransactionReducer,
    );
    const [isSort, setIsSort] = useState<{ [key: string]: boolean }>({
        createdAt: false,
        clientName: false,
        amount: false,
        totalPlandidEarnings: false,
    });

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_EARN_AND_TRANSACTION_REDUCER,
            payload,
        });
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatchValue({ page: newPage + 1 });
        dispatch(getEarnAndTransactionListing());
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        dispatchValue({ limit: event.target.value, page: 1 });
        dispatch(getEarnAndTransactionListing());
    };

    const handleSort = (sortCol: string) => {
        const newObject: any = {};
        Object.keys(isSort).forEach(function sort(key) {
            if (key === sortCol) {
                newObject[key] = !isSort[key];
            } else {
                newObject[key] = false;
            }
        });
        setIsSort(newObject);
        dispatchValue({
            page: 1,
            sortedBy: sortCol,
            sortOrder: newObject[sortCol] ? '1' : '-1',
        });
        dispatch(getEarnAndTransactionListing());
    };

    return (
        <Box sx={styles.paper}>
            <TableContainer>
                <Table sx={styles.table}>
                    <TableHead sx={{ ...styles.tableHeader_cnt_algn }}>
                        <TableRow sx={styles.headerRow}>
                            <TableCell>{'S.No.'}</TableCell>
                            <TableCell>{'Transaction ID'}</TableCell>
                            <TableCell>
                                {'Client Name'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['clientName'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('clientName')}
                                />
                            </TableCell>
                            <TableCell>
                                {'Date and Time'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['createdAt'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('createdAt')}
                                />
                            </TableCell>
                            <TableCell>
                                {'P&L'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['totalPlandidEarnings'] && {
                                            ...styles.rotateIcon,
                                        }),
                                    }}
                                    onClick={() => handleSort('totalPlandidEarnings')}
                                />
                            </TableCell>
                            <TableCell>{'Platform Fee on Admin'}</TableCell>
                            <TableCell>
                                {'Transaction Amount'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['amount'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('amount')}
                                />
                            </TableCell>
                            <TableCell>{'Transaction Status '}</TableCell>
                            <TableCell className='action-table-header'>{'Action'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result && result.length > 0 ? (
                            result.map((val: any, idx: number) => {
                                let serialNumber = `${(page - 1) * limit + (idx + 1)}.`;

                                return (
                                    <TableRow key={val._id} sx={{ ...styles.row_earnings }}>
                                        <TableCell>{serialNumber}</TableCell>
                                        <TableCell>{val?.transactionRef || 'N/A'}</TableCell>
                                        <TableCell title={val?.clientDetails?.fullName}>
                                            {val?.clientDetails?.fullName || 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            {getFormattedDate(val.createdAt, 'MM/DD/YYYY, hh:mm A')}
                                        </TableCell>
                                        <TableCell
                                            title={val?.orderCalculations?.totalPlandidEarnings}
                                            sx={{ textTransform: 'capitalize' }}>
                                            {val?.orderCalculations?.totalPlandidEarnings
                                                ? `$${DataFormater(
                                                      parseFloat(
                                                          val?.orderCalculations
                                                              ?.totalPlandidEarnings,
                                                      ),
                                                  )}`
                                                : '-'}
                                        </TableCell>
                                        <TableCell
                                            title={val?.orderCalculations?.platformCharges}
                                            sx={{ textTransform: 'capitalize' }}>
                                            {val?.orderCalculations?.platformCharges
                                                ? `$${DataFormater(
                                                      parseFloat(
                                                          val?.orderCalculations?.platformCharges,
                                                      ),
                                                  )}`
                                                : '-'}
                                        </TableCell>
                                        <TableCell title={val?.orderCalculations?.totalOrderValue}>
                                            {val?.orderCalculations?.totalOrderValue
                                                ? `$${DataFormater(
                                                      parseFloat(
                                                          val?.orderCalculations?.totalOrderValue,
                                                      ),
                                                  )}`
                                                : '-'}
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    ...styles.statusColumn,
                                                    background: BOOKING_STATUS_CSS[val.status],
                                                    border: BOOKING_STATUS_BORDER[val.status],
                                                    color: BOOKING_STATUS_FONT[val.status],
                                                }}>
                                                {val?.status ? BOOKING_STATUS[val?.status] : 'N/A'}
                                            </Box>
                                        </TableCell>
                                        <TableCell className='action-table-header'>
                                            <Button
                                                className='action-view-btn'
                                                onClick={() =>
                                                    navigate(
                                                        `${ROUTE_NAMES.MAIN_ROUTES.EARNINGS_TRANSACTIONS_MANAGEMENT_DETAIL}?id=${val?.bookingId}`,
                                                    )
                                                }>
                                                {'View'}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={15} sx={styles.noDataCell}>
                                    <Box>{'No Data Found'}</Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={styles.paginationContainer}>
                <TablePagination
                    component='div'
                    count={total}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};
export default EarningsTransactionsTable;
