import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import { CircularProgress, Dialog, IconButton, Typography, styled } from '@mui/material';
import USER_IMG from '../../../../assets/images/user-image.png';
import { styles } from './styles';
import ContainedButton from 'components/button/contained-button';
import { useDispatch, useSelector } from 'react-redux';
import { getClientBookingMadeListing } from 'modules/clientManagement/action';
import { UPDATE_CLIENT_BOOKING_MADE_REDUCER } from 'store/actionTypes';
import { ReducersModal } from 'utils/modals';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';

const Container = styled('div')(() => ({
    width: '100%',
    maxHeight: '700px',
    minHeight: '350px',
    backgroundColor: '#fff',
}));

const HeaderBox = styled('div')(() => ({
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    justifyContent: 'center',
    padding: '0px 10px 0px 10px',
    borderRadius: '12px 12px 0px 0px',
    borderBottom: '1px solid lightGray',
    position: 'sticky',
}));

const LowerContainer = styled('div')(() => ({
    width: '100%',
    maxHeight: '450px',
    minHeight: '150px',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    overflowY: 'auto',
    paddingBottom: '30px',
}));

const NoDataContainer = styled('div')(() => ({
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
}));

const LineContainer = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    height: '60px',
    margin: '0px 20px 0px 20px',
    borderBottom: '1px solid lightGray',
    padding: '20px',
}));

const UserInfoContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    alignitem: 'center',
    width: '33.3%',
}));

const RatingContainer = styled('div')(() => ({
    width: '33.3%',
    display: 'flex',
    justifyContent: 'center',
}));

const ButtonContainer = styled('div')(() => ({
    width: '33.3%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
}));

interface Props {
    onClose: () => void;
}

export default function ClientTotalBookingPopup({ onClose }: Props) {
    const { result, loader } = useSelector(
        (state: ReducersModal) => state.ClientVendorBookingMadeReducer,
    );
    const clientId = new URLSearchParams(window.location.search).get('id');
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_BOOKING_MADE_REDUCER,
            payload,
        });
    };

    useEffect(() => {
        dispatchValue({
            clientId: clientId,
            limit: 100,
            bookingStatusFilter: [
                '10',
                '11',
                '12',
                '13',
                '15',
                '16',
                '17',
                '18',
                '29',
                '30',
                '31',
                '32',
                '33',
            ],
        });
        dispatch(getClientBookingMadeListing());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    const renderAlbumView = () => {
        if (result.length === 0 && loader) {
            return (
                <CircularProgress size={40} variant={'indeterminate'} sx={{ margin: '0 auto' }} />
            );
        }
        if (result.length === 0 && !loader) {
            return (
                <NoDataContainer>
                    <Typography variant='h3' component='h3' sx={{ marginTop: '80px' }}>
                        {'No Data Exists.'}
                    </Typography>
                </NoDataContainer>
            );
        }
        return (
            <React.Fragment>
                {result &&
                    result.map((item: any) => {
                        const vendorName = item?.vendorDetails?.fullName;
                        const averageRating =
                            item?.ratingDetails?.rating?.averageRatings.toFixed(1);
                        const vendorId = item?.vendorDetails?.id;
                        const vendorImage = item?.vendorDetails?.profilePic;
                        return (
                            <LineContainer>
                                <UserInfoContainer>
                                    <img
                                        src={vendorImage ? vendorImage : USER_IMG}
                                        alt='user-img'
                                        style={{ ...styles.user_img, borderRadius: '20px' }}></img>
                                    <Typography>{vendorName}</Typography>
                                </UserInfoContainer>
                                <RatingContainer>
                                    <Typography>{`${'Rating: '}${averageRating}`}</Typography>
                                </RatingContainer>
                                <ButtonContainer>
                                    <ContainedButton
                                        title='View Profile'
                                        style={{ height: '40px' }}
                                        onClick={() => {
                                            navigate(
                                                `${ROUTE_NAMES.MAIN_ROUTES.PHOTOGRAPHERS_MANAGEMENT_DETAIL}?id=${vendorId}`,
                                            );
                                        }}
                                    />
                                </ButtonContainer>
                            </LineContainer>
                        );
                    })}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Dialog open={true} fullWidth maxWidth='md'>
                <Container sx={{ overflowY: 'hidden' }}>
                    <HeaderBox>
                        <Typography sx={{ color: '#673ab7' }} variant='h2' component='h2'>
                            {'Photographer Booked'}
                        </Typography>
                        <IconButton sx={{ position: 'absolute', right: 10 }} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </HeaderBox>
                    {/* <HeaderBox sx={{ padding: '0px 70px 0px 60px' }}>
                        <UserInfoContainer>
                            <Typography variant='h5'>{'Photographer Name'}</Typography>
                        </UserInfoContainer>
                        <RatingContainer>
                            <Typography variant='h5' sx={{ marginLeft: '15px' }}>
                                {'Rating Received '}
                            </Typography>
                        </RatingContainer>
                        <ButtonContainer>
                            <Typography variant='h5'>{'Action'}</Typography>
                        </ButtonContainer>
                    </HeaderBox> */}
                    <LowerContainer>{renderAlbumView()}</LowerContainer>
                </Container>
            </Dialog>
        </React.Fragment>
    );
}
