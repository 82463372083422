export const styles = {
    heading: { font: 'normal normal 700 24px/28px Roboto', color: 'black' },
    searchAndFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0 10px 0',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'fit-content',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid lightGray',
        boxShadow: 24,
        borderRadius: '12px',
    },
    headingBar: {
        width: '100%',
        height: '50px',
        display: 'flex',
        borderBottom: '1px solid lightGray',
        alignItems: 'center',
        padding: '0px 10px 0px 20px',
        boxSizing: 'border-box',
    },

    heading_ac: {
        color: '#673ab7',
    },
    button_box: {
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '20px',
    },
    button: {
        padding: '10px 20px 20px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '20px',
    },
    inputBox: {
        padding: '20px',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(38, 38, 38)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        border: '1px solid rgb(230, 235, 241)',
        borderRadius: '8px',
    },
    modal: {
        padding: '30px 20px 0px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'column',
        gap: '10px',
    },
    selection_bar: {
        width: '100%',
        height: '60px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '15px',
    },
    radio_cont: {
        height: '100%',
        width: '85%',
    },
    radio_grp: {
        display: 'flex',
        flexDirection: 'row',
        gap: '50px',
    },
    uploadArea: {
        height: 'auto',
        width: '150px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        flexDirection: 'column',
    },
    image_Box: {
        border: '2px dotted gray',
        marginTop: '15px',
        borderRadius: '5px',
        display: 'flex',
        height: '175px',
        flexDirection: 'column',
        alignItems: 'center',
        width: '150px',
    },
    image_Box_Error: {
        border: '2px dotted red',
        marginTop: '15px',
        borderRadius: '5px',
        display: 'flex',
        height: '175px',
        flexDirection: 'column',
        alignItems: 'center',
        width: '150px',
        justifyContent: 'center',
    },
    image_cnt_algn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        height: '120px',
        width: '120px',
    },
    uploaded_image: {
        marginTop: '35px',
        height: '100px',
        width: '100px',
        alignSelf: 'center',
        borderRadius: '5px',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mb: '60px',
    },
    input: {
        width: '150px',
        height: '150px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px dotted Gray',
    },
    close_Icon: {
        height: '30px',
        width: '30px',
        position: 'absolute',
        top: '20px',
        left: '115px',
        color: 'black',
    },
    input_Error: {
        width: '150px',
        height: '150px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid red',
    },
    radio_cont_notification_type: {
        height: '100%',
        width: '60%',
        marginTop: '10px',
    },
    radio_grp_notification_type: {
        display: 'flex',
        flexDirection: 'row',
    },
    adornment: {
        alignSelf: 'end',
        padding: '0px 10px 10px 0px',
        fontSize: '9px',
    },
    addOnText: {
        fontWeight: 400,
        fontSize: '16px',
        maxWidth: '500px',
        display: 'block,',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
    },
    button_stack: {
        height: '40px',
        alignSelf: 'end',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        right: '28px',
        top: '23px',
    },
    approvedRejectedBtnCon: {
        height: '40px',
        width: '126px',
        display: 'flex',
        borderRadius: '7px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    name: {
        fontWeight: 500,
        lineHeight: '28px',
        letterSpacing: '-0.0025em',
        color: '#ffffff',
        fontSize: '16px',
    },
    clientProfilePicCont: {
        display: 'flex',
        alignItems: 'center',
        height: '55px',
        width: '250px',
        background: 'lightgray',
        borderRadius: '8px',
        padding: '5px 10px',
        gap: '10px',
    },
    img: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
    },
    locationText: {
        maxWidth: '300px',
    },
    calendarImgCont: { height: '20px', width: '20px', marginRight: '10px' },
    csv_btn: {
        height: '45px',
        marginRight: '10px',
        borderRadius: '8px',
    },
};
