import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { legacy_createStore, applyMiddleware, compose } from 'redux';

//custom imports below
import RootReducer from './rootReducer';

const logger = createLogger({
    predicate: () => process.env.NODE_ENV === 'development',
});

const enhancer = compose(applyMiddleware(thunk, logger));

const persistConfig = {
    key: 'root',
    blacklist: [],
    storage: storageSession,
    whitelist: ['AuthReducer'],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

/**
 * create new store
 */
export const store = legacy_createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
