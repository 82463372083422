import { UPDATE_REWARDS_DETAILS_REDUCER, UPDATE_REWARDS_REDUCER } from 'store/actionTypes';
import { ENDPOINTS } from 'utils/endpoints';
import { APIResponseModal } from 'utils/modals';
import { GET_API_CALL, PATCH_API_CALL } from 'utils/services';
import SHOW_TOAST from 'utils/showToast';

/**
 *
 * @returns Will return all the available rewards list
 */
export const getRewardsList = () => {
    return (dispatch: Function) => {
        let parameters = '?';

        dispatch({
            type: UPDATE_REWARDS_REDUCER,
            payload: { loader: true },
        });
        GET_API_CALL(
            ENDPOINTS.REWARDS_MANAGEMENT.LISTING,
            parameters === '?' ? '' : `${parameters}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REWARDS_REDUCER,
                        payload: { ...response.data },
                    });
                }
                dispatch({
                    type: UPDATE_REWARDS_REDUCER,
                    payload: { loader: false },
                });
            },
        );
    };
};

/**
 *
 * @param rewardId will be the id for which want details to fetch
 * @param callback will exicute after success
 * @returns will return details of reward on the basis of provided reward id
 */
export const getRewardDetail = (rewardId: any, callback: Function) => {
    return (dispatch: Function) => {
        GET_API_CALL(
            ENDPOINTS.REWARDS_MANAGEMENT.LISTING,
            `/${rewardId}`,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    dispatch({
                        type: UPDATE_REWARDS_DETAILS_REDUCER,
                        payload: response.data,
                    });
                }
                callback(response);
            },
        );
    };
};

/**
 *
 * @param data will be payload to send in API request
 * @param callback will be the trigger after success/failure of API response
 * @returns will return API success/failure
 */
export const updateRewardData = (data: any, callback: Function) => {
    return () => {
        PATCH_API_CALL(ENDPOINTS.REWARDS_MANAGEMENT.LISTING, data, (response: APIResponseModal) => {
            if (response.isSuccess) {
                const { message } = response?.data;
                SHOW_TOAST(message, 'success');
            }
            callback();
        });
    };
};

/**
 *
 * @param data will be payload to send in API request
 * @param callback will be the trigger after success/failure of API response
 * @returns will return API success/failure
 */
export const updateIndivisualRewardStatus = (data: any, callback: Function) => {
    return () => {
        PATCH_API_CALL(
            ENDPOINTS.REWARDS_MANAGEMENT.UPDATE_STATUS,
            data,
            (response: APIResponseModal) => {
                if (response.isSuccess) {
                    const { message } = response?.data;
                    SHOW_TOAST(message, 'success');
                }
                callback();
            },
        );
    };
};
