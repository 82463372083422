import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { commonTableStyles as styles } from '../styles';
import { ReducersModal } from 'utils/modals';
import { UPDATE_FLAGGED_REDUCER, UPDATE_MANAGE_DISPUTE_REDUCER } from 'store/actionTypes';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAMES from 'routes/routeNames';
import { ArrowDownward } from '@mui/icons-material';
import { useLayoutEffect, useState } from 'react';
import { getUnixFormattedDate } from 'utils/constants';

const FlaggedAccountTable = ({ activeTab, flaggedSearchArr, flaggedTotal }: any) => {
    const { page, limit } = useSelector((state: ReducersModal) => state.FlaggedAccountReducer);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [isSort, setIsSort] = useState<{ [key: string]: boolean }>({
        createdAt: false,
        fullName: false,
    });
    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_MANAGE_DISPUTE_REDUCER,
            payload,
        });
    };

    useLayoutEffect(() => {
        dispatchFlaggedValue({
            loader: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatchFlaggedValue = (payload: any) => {
        dispatch({
            type: UPDATE_FLAGGED_REDUCER,
            payload,
        });
    };

    const handleSort = (sortCol: string) => {
        const newObject: any = {};
        Object.keys(isSort).forEach(function sort(key) {
            if (key === sortCol) {
                newObject[key] = !isSort[key];
            } else {
                newObject[key] = false;
            }
        });
        setIsSort(newObject);
        dispatchValue({
            page: 1,
            sortedBy: sortCol,
            sortOrder: newObject[sortCol] ? '1' : '-1',
        });
    };
    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatchFlaggedValue({ page: newPage + 1 });
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        dispatchFlaggedValue({ limit: event.target.value, page: 1 });
    };

    const fill: any = [];
    return (
        <Box sx={styles.paper}>
            <TableContainer>
                <Table sx={{ ...styles.tableHeader_cnt_algn }}>
                    <TableHead sx={{ ...styles.tableHeader }}>
                        <TableRow sx={styles.headerRow}>
                            <TableCell>{'S.No.'}</TableCell>
                            <TableCell>{'Booking Id'}</TableCell>
                            <TableCell>
                                {activeTab === 1 ? 'Client Name' : 'Photographer Name'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['fullName'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('fullName')}
                                />
                            </TableCell>
                            <TableCell>
                                {'Date And Time'}
                                <ArrowDownward
                                    sx={{
                                        ...styles.dwnIcnStyl,
                                        ...(isSort['createdAt'] && { ...styles.rotateIcon }),
                                    }}
                                    onClick={() => handleSort('createdAt')}
                                />
                            </TableCell>
                            <TableCell>{'Flagged Content'}</TableCell>
                            <TableCell>{'Flagged count'}</TableCell>
                            <TableCell className='action-table-header'>{'Action'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {flaggedSearchArr.length > 0 ? (
                            flaggedSearchArr.map((flaggedData: any, index: number) => {
                                if (Object.keys(flaggedData?.flaggedMessages).length > 0) {
                                    return Object.values(flaggedData?.flaggedMessages)?.map(
                                        (message: any, idx: number) => {
                                            let serialNumber = `${
                                                    (page - 1) * limit + (index + 1)
                                                }.`,
                                                userName =
                                                    message?.senderType === 1
                                                        ? `${flaggedData?.clientDetails?.fullName}`
                                                        : `${flaggedData?.vendorDetails?.fullName}`;
                                            let flaggedContent: any = message?.messageContent;
                                            let flaggedCount: number = 0;
                                            if (
                                                !fill.includes(
                                                    flaggedData?.bookingDetails?.bookingRef,
                                                )
                                            ) {
                                                fill.push(flaggedData?.bookingDetails?.bookingRef);
                                                flaggedCount = Object.values(
                                                    flaggedData?.flaggedMessages,
                                                ).length;
                                                return (
                                                    <TableRow
                                                        key={idx}
                                                        sx={{ ...styles.customer_satisfaction }}>
                                                        <TableCell>{serialNumber}</TableCell>
                                                        <TableCell>
                                                            {
                                                                flaggedData?.bookingDetails
                                                                    ?.bookingRef
                                                            }
                                                        </TableCell>
                                                        <TableCell title={userName}>
                                                            {userName}
                                                        </TableCell>
                                                        <TableCell>
                                                            {message?.createdAt
                                                                ? getUnixFormattedDate(
                                                                      message?.createdAt,
                                                                      'MM/DD/YYYY, hh:mm A',
                                                                  )
                                                                : 'N/A'}
                                                        </TableCell>
                                                        <TableCell title={flaggedContent}>
                                                            {flaggedContent || 'N/A'}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ textTransform: 'capitalize' }}>
                                                            {flaggedCount || 'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box
                                                                sx={
                                                                    styles.action_table_cell_container
                                                                }>
                                                                <Button
                                                                    className='action-view-btn'
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `${ROUTE_NAMES.MAIN_ROUTES.FLAGGED_ACCOUNT_DETAIL}?id=${flaggedData?.bookingDetails?.bookingId}&senderType=${activeTab}`,
                                                                        );
                                                                    }}>
                                                                    {'View'}
                                                                </Button>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                            return null;
                                        },
                                    );
                                }
                                return null;
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={15} sx={styles.noDataCell}>
                                    <Box>{'No Data Found'}</Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={styles.paginationContainer}>
                <TablePagination
                    component='div'
                    count={flaggedTotal}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

export default FlaggedAccountTable;
