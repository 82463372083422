export const styles = {
    searchAndFilter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    modal_container: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minHeight: 300,
        height: 'fit-content',
        width: 500,
        bgcolor: 'background.paper',
        border: '1px solid lightGray',
        boxShadow: 24,
        borderRadius: '12px',
    },
    heading_ac: {
        color: '#673ab7',
    },
    headingBar: {
        width: '100%',
        height: '50px',
        display: 'flex',
        borderBottom: '1px solid lightGray',
        alignItems: 'center',
        padding: '0px 10px 0px 20px',
        boxSizing: 'border-box',
    },
    button: {
        padding: '24px 20px 20px 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '15px ',
        gap: '20px',
    },
    inputBox: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        position: 'fixed',
        right: '10px',
    },
    add_review_rating: {
        fontSize: '4rem',
    },
};
