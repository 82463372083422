import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { Formik } from 'formik';
import { styles } from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from 'utils/modals';
import ClientSelection from 'modules/notificationsManagement/addNotifications/selectionView/clientSelection';
import VendorSelection from 'modules/notificationsManagement/addNotifications/selectionView/vendorSelection';
import {
    UPDATE_CLIENT_REDUCER,
    UPDATE_NOTIFICATION_DETAIL_REDUCER,
    UPDATE_PHOTOGRAPHERS_REDUCER,
} from 'store/actionTypes';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    addNotification,
    editNotification,
    getNotificationDetail,
    getNotificationListing,
} from 'modules/notificationsManagement/action';
import ROUTE_NAMES from 'routes/routeNames';

const EditEmail = forwardRef((_, ref) => {
    const { description, recipientType, editMode, title, receiverIds, not_idToEdit } = useSelector(
        (state: ReducersModal) => state.NotificationDetailReducer,
    );
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const formRef: any = useRef();
    const theme: any = useTheme();

    useEffect(() => {
        dispatchValue({ editMode: false, notificationType: 6 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const dispatchValue = (payload: any) => {
        dispatch({
            type: UPDATE_NOTIFICATION_DETAIL_REDUCER,
            payload,
        });
    };

    useImperativeHandle(ref, () => ({
        runSubmitOnSendClick() {
            formRef.current.values['isSent'] = true;
            formRef?.current?.handleSubmit();
        },
        runSubmitOnSaveClick() {
            formRef.current.values['isSent'] = false;
            formRef?.current?.handleSubmit();
        },
    }));
    const dispatchInitialValue = (payload: any) => {
        dispatch({
            type: UPDATE_CLIENT_REDUCER,
            payload,
        });
        dispatch({
            type: UPDATE_PHOTOGRAPHERS_REDUCER,
            payload,
        });
    };
    const onRecipientTypeClick = (value: any) => {
        dispatchInitialValue({ search: '' });
        dispatchValue({ recipientType: +value });
        if (editMode) {
            dispatchValue({ receiverIds: [] });
        }
    };

    const handleOnSubmit = ({ tagline, description, isSent }: any) => {
        let dataToSend = {
            isSent,
            description,
            title: tagline,
            recipientType,
            notificationType: 6,
            receiverIds: receiverIds,
            notificationId: not_idToEdit,
        };
        if (isSent) {
            dispatch(
                getNotificationDetail(not_idToEdit, ({ data, isSuccess }: any) => {
                    if (isSuccess) {
                        const dataToSend = {
                            isSent: true,
                            title: data.title,
                            description: data.description,
                            recipientType: data.recipientType,
                            receiverIds: data.receiverIds,
                            notificationType: data.notificationType,
                        };
                        dispatch(
                            addNotification(dataToSend, () => {
                                dispatch(getNotificationListing());
                                navigate(ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT);
                            }),
                        );
                    }
                }),
            );
        } else {
            dispatch(
                editNotification(dataToSend, () =>
                    navigate(ROUTE_NAMES.MAIN_ROUTES.NOTIFICATIONS_MANAGEMENT),
                ),
            );
        }
    };
    return (
        <Box>
            <Formik
                innerRef={formRef}
                onSubmit={handleOnSubmit}
                // validationSchema={EmailNotificationSchema}
                initialValues={{
                    tagline: title,
                    description: description,
                    recipient: recipientType,
                }}>
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Typography variant='h5'>
                            <span>{'Subject/Tagline'}</span>
                        </Typography>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.tagline && errors.tagline)}
                            sx={{ ...theme.typography.customInput }}>
                            <OutlinedInput
                                disabled={!editMode}
                                sx={{ width: '75%' }}
                                value={values.tagline}
                                onChange={handleChange}
                                name='tagline'
                                maxRows={6}
                                minRows={1}
                                multiline={true}
                                onBlur={handleBlur}
                                placeholder='Enter Subject/Tagline'
                                inputProps={{
                                    maxLength: 120,
                                    style: { fontWeight: 300 },
                                }}
                                endAdornment={
                                    <InputAdornment position='start' sx={styles.adornment}>
                                        <Typography variant='inherit'>
                                            {'Max. 100 Characters'}
                                        </Typography>
                                    </InputAdornment>
                                }
                            />
                            {touched.tagline && errors.tagline && (
                                <FormHelperText error id='standard-weight-helper-text-email-login'>
                                    {errors.tagline}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Typography variant='h5'>
                            <span>{'Description'}</span>
                        </Typography>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.description && errors.description)}
                            sx={{ ...theme.typography.customInput }}>
                            <OutlinedInput
                                disabled={!editMode}
                                sx={{ width: '75%' }}
                                value={values.description}
                                onChange={handleChange}
                                name='description'
                                maxRows={6}
                                minRows={6}
                                onBlur={handleBlur}
                                multiline={true}
                                placeholder='Start writing description here...'
                                inputProps={{
                                    maxLength: 500,
                                    style: { fontWeight: 300 },
                                }}
                                endAdornment={
                                    <InputAdornment position='start' sx={styles.adornment}>
                                        <Typography variant='inherit'>
                                            {'Max. 500 Characters'}
                                        </Typography>
                                    </InputAdornment>
                                }
                            />
                            {touched.description && errors.description && (
                                <FormHelperText error id='standard-weight-helper-text-email-login'>
                                    {errors.description}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Box sx={styles.radio_cont_notification_type}>
                            <Typography variant='h5'>
                                <span>{'Recipient'}</span>
                            </Typography>
                            <RadioGroup
                                sx={styles.radio_grp_notification_type}
                                value={recipientType}
                                defaultValue={1}
                                name='recipientType'
                                onChange={(e: any) => onRecipientTypeClick(e.target.value)}>
                                <FormControlLabel
                                    disabled={!editMode}
                                    sx={{ width: '200px' }}
                                    value={1}
                                    control={<Radio />}
                                    label='All Users'
                                />
                                <FormControlLabel
                                    disabled={!editMode}
                                    sx={{ width: '200px' }}
                                    value={4}
                                    control={<Radio />}
                                    label='Selected Clients'
                                />
                                <FormControlLabel
                                    disabled={!editMode}
                                    sx={{ width: '200px' }}
                                    value={2}
                                    control={<Radio />}
                                    label='All Clients'
                                />
                                <FormControlLabel
                                    disabled={!editMode}
                                    sx={{ width: '200px' }}
                                    value={3}
                                    control={<Radio />}
                                    label='All Photographers'
                                />
                                <FormControlLabel
                                    disabled={!editMode}
                                    sx={{ width: '200px' }}
                                    value={5}
                                    control={<Radio />}
                                    label='Selected Photographers'
                                />
                            </RadioGroup>
                        </Box>
                    </form>
                )}
            </Formik>
            {editMode && recipientType && recipientType === 4 ? <ClientSelection /> : <></>}
            {editMode && recipientType && recipientType === 5 ? <VendorSelection /> : <></>}
        </Box>
    );
});

export default EditEmail;
