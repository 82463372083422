import {
    UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER,
    UPDATE_MANAGE_DISPUTE_REDUCER,
    UPDATE_FLAGGED_DETAIL_REDUCER,
    UPDATE_FLAGGED_REDUCER,
} from 'store/actionTypes';
import {
    ActionType,
    FlaggedAccountDetailModal,
    FlaggedAccountListModal,
    ManageDisputeDetailModal,
    ManageDisputeListModal,
} from 'utils/modals';

export const ManageDisputeReducer = (
    state: ManageDisputeListModal = new ManageDisputeListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_MANAGE_DISPUTE_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export const ManageDisputeDetailsReducer = (
    state: ManageDisputeDetailModal = new ManageDisputeDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_MANAGE_DISPUTE_DETAIL_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const FlaggedAccountDetailsReducer = (
    state: FlaggedAccountDetailModal = new FlaggedAccountDetailModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_FLAGGED_DETAIL_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const FlaggedAccountReducer = (
    state: FlaggedAccountListModal = new FlaggedAccountListModal(),
    action: ActionType,
) => {
    switch (action.type) {
        case UPDATE_FLAGGED_REDUCER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
